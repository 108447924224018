import "./LoanFormDetails.css";

import React, { useEffect, useState } from "react";


import {convertToWords} from "../../../../ComonFunctionsProvider/CommonFunctionsProvider"
import useMyAuthService from "../../../../authenticationServiceProvider/useMyAuthService";

const LoanFormDetails = (props) => {

  const { loanForm, setLoanFormValues, loggedInMemberDetails, loanType, loanLimitDetails, loggedInMemberSuretyWeight, loanAmountInWords, setLoanAmountInWord} = props;

  
  // const [maxLoanAmount, setMaxLoanAmount] = useState(0);

  const [currentOutstanding, setCurrentOutstanding] = useState(0);
  //getting the loan limits details based on the gradepay and loanType....................

useEffect(() => {
 
  setFixedDateForCurrentMonth();
  setCurrentOutStandingAmount();
}, []);



const setCurrentOutStandingAmount = () =>{
    (loanType === "ML")?
    (setCurrentOutstanding(loggedInMemberDetails.currentMLOutstanding)):
    (setCurrentOutstanding(loggedInMemberDetails.currentELOutstanding));
}


const setFixedDateForCurrentMonth =()=>{
    // Get the current date
    const currentDate = new Date();

    // Set the day to 10 if the current day is less than 10
    if (currentDate.getDate() < 10) {
      currentDate.setDate(10);
    } else {
      // If the current day is greater than or equal to 10, set the month to the next month
      currentDate.setMonth(currentDate.getMonth() + 1);
      currentDate.setDate(10);
    }

    // Format the date as ddmmyyyy
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
    const day = currentDate.getDate().toString().padStart(2, '0');

    // Format the date as ddmmyyyy
    const formattedDate = `${day}-${month}-${year}`;;

    setLoanFormValues({
      ...loanForm,
      dateFirstInstallment: formattedDate,
    })
    
}


//Function to see the loan Amount in words
const handleInputChange = (event) => {
  setLoanFormValues(prevState => ({
    ...prevState, // Spread the previous state
    loanAmount: event.target.value, // Update loanAmount with event value
    totalSuretyWeightRequired: loanForm.loanAmount < 100000 ? 0.5 : Math.round(loanForm.loanAmount / 100000) - loggedInMemberSuretyWeight // Calculate totalSuretyWeightRequired
  }));
  

  const number = parseInt(event.target.value);
  const words = convertToWords(number);
  setLoanAmountInWord(words);

  

};


  //console.log(loanForm);
  return (
    <div className="LoanFormDetailsMainDiv">
      <fieldset>
        <legend>🖥️ Please fill in the {loanType} Details</legend>
        <div className="eligibilityDetailsDiv">
          <span>Your Current Outstanding is : {currentOutstanding}</span>
          <span></span>
          <span>Amount Avail for loan :  {parseInt(loanLimitDetails.loanLimits)-parseInt(currentOutstanding)}</span>
        </div>
        <hr/>
        <div className="eligibilityDetailsDiv"><span>Amount You Want : ₹ {loanAmountInWords}</span></div>
        
        <div className="gridRow">
          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Loan Amount</label>
              </div>
              <div>
                <input
                  type={"number"}
                  name="loanAmount"
                  placeholder="Fill Required Loan Amount "
                  min="1000"
                  value={loanForm.loanAmount}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Loan Purpose</label>
              </div>
              <div>
                <input
                  type={"text"}
                  name="loanPurpose"
                  placeholder="Fill Your Loan Purpose"
                  value={loanForm.loanPurpose}
                  onChange={(e) =>
                    setLoanFormValues({
                      ...loanForm,
                      loanPurpose: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Rate Of Interest</label>
              </div>
              <div>
                <input
                  type={"number"}
                  name="loanRateOfInt"
                  placeholder="Rate of Interest"
                  value={loanForm.rateOfInterest}
                  readOnly
                />
              </div>
            </div>
          </div>
          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>No of Installments</label>
              </div>
              <div>
                <input
                  type={"number"}
                  name="noOfInstallments"
                  placeholder="No. of Installments Required"
                  min="1"
                  max="60"
                  value={loanForm.noOfIntallments}
                  onChange={(e) =>
                    setLoanFormValues({
                      ...loanForm,
                      noOfIntallments: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Month of First Installment</label>
              </div>
              <div>
                <input
                  type={"text"}
                  name="dateFirstInstallment"
                  placeholder="First Installment Date"
                  value={loanForm.dateFirstInstallment}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default LoanFormDetails;
