import React from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Chip, Stack } from "@mui/material";

import "./FormDownLoadWidget.css";

import { AutoStories } from "@mui/icons-material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { useState } from "react";

//const formTitle = "New Membership Form";

function FormDownLoadWidget(Props) {
  const [openFormInfoDialog, setOpenFormInfoDialog] = useState(false);
   
  //  console.log(Props.fileName);

  const handleCloseFormInfoDialog = () => {
     setOpenFormInfoDialog(false);
  }

  const handleClickReadMore = () => {
    setOpenFormInfoDialog(true);
  };

  return (
    <div className="FormDownLoadWidget">
      <div className="FormDownLoadHeader">{Props.title}</div>
      <div className="FormDownLoadContent">
        <Stack direction="row" spacing={1}>
          <Chip
            className="readMore"
            label="Know More"
            icon={<AutoStories></AutoStories>}
            color="secondary"
            variant="filled"
            onClick={handleClickReadMore}
          />
          <a href={Props.fileName} download>
            <Chip
              label={"DownLoad PDF"}
              icon={<FileDownloadIcon></FileDownloadIcon>}
              color="primary"
              variant="outlined"
            />
          </a>
        </Stack>
      </div>

      {/* Dialog to view the other society details of the members............................ */}
      <Dialog
        open={openFormInfoDialog}
        onClose={handleCloseFormInfoDialog}
        //scroll={body}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="lg"
      >
        <DialogActions>
          <span className="titleDialogBox">{Props.title}</span>
          <Button variant="outlined" onClick={handleCloseFormInfoDialog}>❌</Button>
        </DialogActions>
        <DialogContent>{/* get the nominee table here */}
          <div className="pdfDisplayerDialogContentDiv">
            <iframe src={Props.fileName}></iframe>
          </div>
        </DialogContent>
        
      </Dialog>
    </div>
  );
}

export default FormDownLoadWidget;
