import React, {useState} from "react";
import Button from "@mui/material/Button";

import "../TableDataDisplayer.css"

import { formatDate } from "../../../../ComonFunctionsProvider/CommonFunctionsProvider";
import PaginationNumberRendering from "../PaginationNumberRendering/PaginationNumberRendering";


const MembershipApprovalsTable = ({
  memberApplicationDetails,//details of all the membership applications
  displayStaffDetails,
  displayAdminDetails,
  // handleClickOpenMemberDetailsInApplication,
  // handleOpenPaySlipDisplayer,
  // handleCheckPaymentDetails,
  // handleClickOpenMarkApplicationForReview,
  // handleClickOpenVerifyApplication,
  // selectedMember,
  handleClickOpenMenu,
  openMenu,
 
  // handleClickOpenNomineeDetailsInApplication,
  // handleClickOpenOtherSocietyDetailsInApplication,
}) => {


  const toCamelCase = (str) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+|-|_/g, "");
  };

  
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPage = 12; // Number of items to display per page

  // Calculate the total number of pages
  const totalPages = Math.ceil(memberApplicationDetails.length / itemsPerPage);

  // Get the current page's items based on the currentPage and itemsPerPage
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = memberApplicationDetails
    .filter((memberApp) =>
      Object.values(memberApp)
        .join(" ")
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    )
    .slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle search query change
  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page when search query changes
  };

  
  const maxPageNumbersToShow = 5; // Adjust the number of page numbers to show

  
  return (
    <div className="table-data-displayer">
      <div className="quickSearchBoxDiv">
        <input
          type="text"
          placeholder="Search RUID..."
          value={searchQuery}
          onChange={handleSearchQueryChange}
        />
      </div>
     
        <table className="dataDisplayerTable centerContent">
          <thead>
            <tr>
              <th>Sl No.</th>
              <th>RUID</th>
              <th>Name</th>
              <th>Application Date</th>
              <th>Application status</th>
              <th>Verified By</th>
              <th>Approved By</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((memberApp, index) => {
              return (
                <tr key={index + 1}>
                  <td>{index + 1}</td>
                  <td>{memberApp.rbMemberRUID}</td>
                  <td className="justifyContent">
                    Shri {memberApp.rbmembers.name}
                  </td>
                  <td>{formatDate(memberApp.dateOfMembershipApplication)}</td>
                  <td>{memberApp.approvalStatus}</td>
                  <td>
                    {memberApp.MVSRUID ? (
                      <Button
                        variant="outlined"
                        onClick={(e) => {
                          displayStaffDetails(e, memberApp.MVSRUID);
                        }}
                      >
                        {memberApp.MVSRUID}
                      </Button>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    {memberApp.MAAID ? (
                      <Button
                        variant="outlined"
                        onClick={(e) => {
                          displayAdminDetails(e, memberApp.MAAID);
                        }}
                      >
                        {memberApp.MAAID}
                      </Button>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    <Button
                      id="basic-button"
                      aria-controls={openMenu ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMenu ? "true" : undefined}
                      onClick={(e) => handleClickOpenMenu(e, memberApp)}
                    >
                      ⚙️
                    </Button>
                    
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
    
    
    {/* Pagination */}
    <PaginationNumberRendering 
        maxPageNumbersToShow={maxPageNumbersToShow}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
    />
    </div>
  );
};

export default MembershipApprovalsTable;
