import "../VerifyLoanApplications.css";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import OpenDocumentDisplayer from "../../../../generalComponents/DialogComponents/OpenDocumentDisplayer/OpenDocumentDisplayer";
import DialogContainer from "../../../../generalComponents/DialogComponents/DialogContainer/DialogContainer";
import LoanApplicationDetailsForPreview from "../LoanApplicationDetailsForPreview/LoanApplicationDetailsForPreview";
import ProgressorBarDisplayer from "../../../../generalComponents/DialogComponents/ProgressorBarDisplayer/ProgressorBarDisplayer";
import LoanApplicationsMarkForReview from "../LoanApplicationsMarkForReview/LoanApplicationsMarkForReview";

import {
  partForReview,
  useVerifyLoanApplicationsState,
} from "../StateForVerifyLoanApplications";
import LoanApplicationsTable from "../../../../generalComponents/TableDataDisplayer/LoanApplicationsTable/LoanApplicationsTable";
import LoanAppsVerificationsDataLoaderFunctions from "../../../../../dataLoaderInitFunctions/loanappsVerificationsDataLoaderFunctions";
import MenuLoanApplicationsVerification from "../MenuLoanApplicationsVerification/MenuLoanApplicationsVerification";
import LoanApplicationSuretiesDetailsViewer from "../LoanApplicationSuretiesDetailsViewer/LoanApplicationSuretiesDetailsViewer";
import LoanApplicationsVerifyOKSubmit from "../LoanApplicationsVerifyOKSubmit/LoanApplicationsVerifyOKSubmit";


const LoanApplicationsToBeVerified = ({ applicationStatusOptions }) => {
  const navigate = useNavigate();

  const state = useVerifyLoanApplicationsState();

  const {
    api, // for calling the api on the server.....
    // LoanDetails,
    LoanSuretyDetailsData,
    LoanApplicationID,
    // setLoanDetails,
    setLoanSuretyDetailsData,
    adminName,
    setAdminName,
    adminRBCTCSDetails,
    setAdminRBCTCSDetails,
    applicationPartForReview,
    setApplicationPartForReview,
    // isLoadingData,
    // setIsLoadingData,
    openAdminDisplayDialog,
    setOpenAdminDisplayDialog,
    openLoanDetailsInApplication,
    setOpenLoanDetailsInApplication,
    openLoanSuretyDetailsInApplication,
    setOpenLoanSuretyDetailsInApplication,
    openMarkLoanApplicationForReview,
    setOpenMarkLoanApplicationForReview,
    openPaySlipDisplayer,
    setOpenPaySlipDisplayer,
    openVerifyApplication,
    setOpenVerifyApplication,
    ruidLoggedIn,
    setRUIDLoggedIn,
    updatedForReRender,
    setUpdatedForReRender,
    SelectedLoanApplication,
    setSelectedLoanApplication,
    openStaffDisplayDialog,
    setOpenStaffDisplayDialog,
    staffRBCTCSDetails,
    setStaffRBCTCSDetails,
    paySlipFile,
    setPaySlipFile,
    openProgressBarDialog,
    setOpenProgressBarDialog,
    reviewRemarks,
    setReviewRemarks,
  } = state;
  

  //The application status is required here to be befitting every version.........
  const [applicationStatus, setApplicationStatus] = useState(
    applicationStatusOptions[0].value
  );


  // the use effect for initial data loads...............
  const {LoanApplicationDetails, isLoadingData } =
    LoanAppsVerificationsDataLoaderFunctions(
      applicationStatus,
      setOpenProgressBarDialog,
      updatedForReRender
    );

  

  //  Functions for the MENU open and closure...................
  //action menu for the verification............................of the applications................start.....
  const [anchorActionsMenu, setAnchorActionsMenu] = React.useState(null);
  const openMenu = Boolean(anchorActionsMenu);

  const handleClickOpenMenu = (event, LoanApp) => {
    //console.log(openMenu);
   //  console.log(LoanApp);
    setSelectedLoanApplication(LoanApp);
   //setLoanSuretyDetailsData(LoanApp.loan_sureties_by_loan_id);
    setAnchorActionsMenu(event.currentTarget);
  };

  // Function for the Menu close
  const handleCloseMenu = () => {
    setAnchorActionsMenu(null);
  };  
  
  //function for open and close of the Dialog Box....................................starts

  const handleClickOpenLoanDetailsInApplication = ()  => {
    //console.log(rbMemberRUID);
    setAnchorActionsMenu(null);
    setOpenLoanDetailsInApplication(true);
  };

  const handleClickOpenLoanSuretyDetailsInApplication = () => {
    setAnchorActionsMenu(null);
    setOpenLoanSuretyDetailsInApplication(true);
  };

  const handleClickOpenMarkLoanApplicationForReview = () => {
    //alert(applicationId);
    // setApplicationPartForReview({
    //   ...applicationPartForReview,
    // });
     //console.log("helloworld");
    setAnchorActionsMenu(null);
    setOpenMarkLoanApplicationForReview(true);
  };

  const handleClickOpenVerifyApplication = () => {
   // console.log("hello world");

    // setApplicationPartForReview({
    //   ...applicationPartForReview,
    //  // LoanApplicationID: applicationId,
    // });
    setAnchorActionsMenu(null);
    setOpenVerifyApplication(true);
  };

  const handleCloseLoanDetailsInApplication = () => {
    setOpenLoanDetailsInApplication(false);
  };

  const handleCloseLoanSuretyDetailsInApplication = () => {
    setOpenLoanSuretyDetailsInApplication(false);
  };

  const handleCloseVerifyApplication = () => {
    setOpenVerifyApplication(false);
  };

  //function for open and close of the Dialog Box....................................ends

  const handleCloseMarkLoanApplicationForReview = () => {
    setOpenMarkLoanApplicationForReview(false);
    // setApplicationPartForReview({partForReview});

    // alert(JSON.stringify(applicationPartForReview));
  };

  const handleCloseAdminDisplayDialog = () => {
    setOpenAdminDisplayDialog(false);
  };

  const handleCloseStaffDisplayDialog = () => {
    setOpenStaffDisplayDialog(false);
  };

  const displayAdminDetails = (e, adminId) => {
    api
      .get(
        `/getAdminRBCTCSDetailsByAdminId/${adminId}`
      )
      .then((response) => {
        setAdminRBCTCSDetails(response.data);
        setAdminName(response.data.users.name);
        setOpenAdminDisplayDialog(true);
      });
  };

  const displayStaffDetails = (e, staffId) => {
    api
      .get(`/ViewRBCTCSStaffDetails/${staffId}`)
      .then((response) => {
        //console.log(response.data);
        setStaffRBCTCSDetails(response.data[0]);
        setOpenStaffDisplayDialog(true);
      });
  };

  const handleOpenPaySlipDisplayer = (ruid) => {
    //  console.log(ruid);
    setAnchorActionsMenu(null);
    api({
      url: `/GetPaySlipFile/${ruid}`,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      //console.log(response.data);
      setPaySlipFile(fileURL);
      setOpenPaySlipDisplayer(true);
    });
  };

  // const handleClosePaySlipDisplayerDialog = () => {
  //   setOpenPaySlipDisplayer(false);
  // };

  const handleChangeApplicationsStatusOptions = (status) => {
    // console.log(status);
    setApplicationStatus(status);
  };

  return (
    <>
      <div className="FilterApplicationsDiv">
        <select
          onChange={(e) =>
            handleChangeApplicationsStatusOptions(e.target.value)
          }
        >
          {applicationStatusOptions.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      {isLoadingData ? (
        <div className="isLoadingDiv">Loading Data .....</div>
      ) : (
        <>
          <LoanApplicationsTable
            loanApplicationDetails={LoanApplicationDetails}
            handleClickOpenMenu={handleClickOpenMenu}
            displayStaffDetails={displayStaffDetails}
            displayAdminDetails={displayAdminDetails}
            openMenu={openMenu}
          />

          <MenuLoanApplicationsVerification
            selectedLoanApplication={SelectedLoanApplication}
            displayStaffDetails={displayStaffDetails}
            displayAdminDetails={displayAdminDetails}
            anchorActionsMenu={anchorActionsMenu}
            setAnchorActionsMenu={setAnchorActionsMenu}
            openMenu={openMenu}
            handleCloseMenu={handleCloseMenu}
            applicationStatus={applicationStatus}
            handleClickOpenLoanDetailsInApplication={
              handleClickOpenLoanDetailsInApplication
            }
            handleOpenPaySlipDisplayer={handleOpenPaySlipDisplayer}
            handleClickOpenMarkLoanApplicationForReview={
              handleClickOpenMarkLoanApplicationForReview
            }
            handleClickOpenVerifyApplication={handleClickOpenVerifyApplication}
            handleClickOpenLoanSuretyDetailsInApplication={
              handleClickOpenLoanSuretyDetailsInApplication
            }
          />
        </>
      )}

      {/* Dialog to open and display the Pay Slip */}
      <OpenDocumentDisplayer
        docTitle={"View Pay Slip"}
        docFile={paySlipFile}
        openDocDisplayer={openPaySlipDisplayer}
        setOpenDocDisplayer={setOpenPaySlipDisplayer}
      />

      {/* Dialog to display Staff Details ................ */}
      <DialogContainer
        titleMessage={"Verifying Staff"}
        openDialogBox={openStaffDisplayDialog}
        maxWidth="sm"
        handleCloseDialog={handleCloseStaffDisplayDialog}
      >
        <div className="adminDisplayDiv">
          <table>
            <tbody>
              <tr>
                <td>Staff ID:</td>
                <td>{staffRBCTCSDetails.staffRBCTCSId}</td>
              </tr>
              <tr>
                <td>Shri/Smt./Ms</td>
                <td>{staffRBCTCSDetails.name}</td>
              </tr>
              <tr>
                <td>Designation</td>
                <td>{staffRBCTCSDetails.designation}/RBCTCS</td>
              </tr>
            </tbody>
          </table>
        </div>
      </DialogContainer>

      {/* Dialog to display Admin Details ................ */}
      <DialogContainer
        titleMessage={"Approving Admin"}
        openDialogBox={openAdminDisplayDialog}
        maxWidth="sm"
        handleCloseDialog={handleCloseAdminDisplayDialog}
      >
        <div className="adminDisplayDiv">
          <table>
            <tbody>
              <tr>
                <td>RUID:</td>
                <td>{adminRBCTCSDetails.adminRBCTCSRUID}</td>
              </tr>
              <tr>
                <td>Shri/Smt./Ms</td>
                <td>{adminName}</td>
              </tr>
              <tr>
                <td>Designation</td>
                <td>{adminRBCTCSDetails.designationRBCTCS}/RBCTCS</td>
              </tr>
            </tbody>
          </table>
        </div>
      </DialogContainer>

      <DialogContainer
        titleMessage={"Loan and Member details"}
        openDialogBox={openLoanDetailsInApplication}
        maxWidth="sm"
        handleCloseDialog={handleCloseLoanDetailsInApplication}
      >
        <LoanApplicationDetailsForPreview
          SelectedLoanApplication={SelectedLoanApplication}
        />
      </DialogContainer>

      <DialogContainer
        titleMessage={"Loan Sureties details"}
        openDialogBox={openLoanSuretyDetailsInApplication}
        maxWidth="lg"
        handleCloseDialog={handleCloseLoanSuretyDetailsInApplication}
      >
        <LoanApplicationSuretiesDetailsViewer LoanApplicationId={SelectedLoanApplication.loanApplicationId}/>
      </DialogContainer>


      <DialogContainer
        titleMessage={"Loan Mark Application For Review"}
        openDialogBox={openMarkLoanApplicationForReview}
        maxWidth="sm"
        handleCloseDialog={handleCloseMarkLoanApplicationForReview}
      >
        <LoanApplicationsMarkForReview 
          api= {api}
          staffLoggedIn = {ruidLoggedIn}
          selectedLoanApplication = {SelectedLoanApplication}
          applicationPartForReview = {applicationPartForReview}
          setApplicationPartForReview = {setApplicationPartForReview}
          setOpenProgressBarDialog = {setOpenProgressBarDialog}
          setOpenMarkLoanApplicationForReview = {setOpenMarkLoanApplicationForReview}
          setUpdatedForReRender = {setUpdatedForReRender}
          reviewRemarks = {reviewRemarks}
          setReviewRemarks = {setReviewRemarks}
        />
      </DialogContainer>



      <DialogContainer
        titleMessage={"Loan Verification Dialog"}
        openDialogBox={openVerifyApplication}
        maxWidth="sm"
        handleCloseDialog={handleCloseVerifyApplication}
      >
        <LoanApplicationsVerifyOKSubmit 
         api ={api}
         staffLoggedIn = {ruidLoggedIn}
         selectedLoanApplication = {SelectedLoanApplication}
         setOpenProgressBarDialog = {setOpenProgressBarDialog}
         setUpdatedForReRender = {setUpdatedForReRender}
         setOpenVerifyApplication = {setOpenVerifyApplication}
        />
      </DialogContainer>


      {/* Dialog to implement the Progess bar ................ */}
      <ProgressorBarDisplayer
        titleMessage={"Please wait! Loading the Loan Applications ...."}
        openProgressBarDialog={openProgressBarDialog}
      />
    </>
  );
};

export default LoanApplicationsToBeVerified;
