import "./NewMembershipApplication.css";


import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import MembershipApplicationInstructions from "./MembershipApplicationInstructions/MembershipApplicationInstructions";
import MemberPersonalDetailsForm from "./MemberPersonalDetailsForm/MemberPersonalDetailsForm";
import MemberOfficialDetailsForm from "./MemberOfficialDetailsForm/MemberOfficialDetailsForm";
import MemberBankDetailsForm from "./MemberBankDetailsForm/MemberBankDetailsForm";
import MemberNomineesForm from "./MemberNomineesForm/MemberNomineesForm";
import MemberOtherSocietyDetailsForm from "./MemberOtherSocietyDetailsForm/MemberOtherSocietyDetailsForm";
import MembershipApplicationPreview from "./MembershipApplicationPreview/MembershipApplicationPreview";

import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import LinearProgress from "@mui/material/LinearProgress";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import  useMyAuthService from "../../../authenticationServiceProvider/useMyAuthService";
import { useGetXMLResources } from "../../../BackEndXMLDataServiceProvider/XMLResources";




export default function NewMembershipApplication() {


  //authenicated data and api header..............
  const {loggedInUserRUID, loggedInUserName, api} = useMyAuthService();

  //XML resource storage variables
  const {memberDesignations, Banks, rbBranches} = useGetXMLResources();
   
  

  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const activeprops = { activeStep, setActiveStep};

  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);
  //const [skipped, setSkipped] = React.useState(new Set());

  

  const steps = [ /////////Steps of the Form
    "Instructions",
    "Personal Info",
    "Official Info",
    "Nominee Info",
    "Other Society Info",
    "Bank Info",
    "Preview",
  ];
  
  const personalFormFields = { //////////Fields in teh Personal Form
    name: loggedInUserName,
    fatherName: " ",
    spouseName: " ",
    gender: " ",
    dateOfBirth: " ",
    panNo: " ",
    residentialAddress: " ",
    permanentAddress: " ",
    memberPhoto:"upload",
    memberSign:"upload",
    isOtherSociety: 0,
  };
  
  const officialFormFields = { ///////Fields in the official form
    rbMemberRUID: loggedInUserRUID,
    employeeId:" ",
    designation: " ",
    branch: " ",
    ccBranch: " ",
    srBranch: " ",
    payLevel: " ",
    railwayTelNo: " ",
    dateOfAppointment: " ",
    dateOfRetirement: " ",
    gradePay: " ",
    basicPay: " ",
  };
  
  const bankFormFields = { /////////////Fields for the bank details......
    salaryBankName: " ",
    salaryBankAccountNo: " ",
    salaryBankIFSC: " ",
    
  };
  
  
  
  const societyFormFields = {//////Fields for the Other Society Forms............
    societyName: " ",
    societyAddress: " ",
    societyMobile: " ",
    societyEmail: " ",
  };
  
  const nomineeFormFields = {// Fields in the Nominees form..................
    nomineeName: " ",
    nomineeDateOfBirth: " ",
    nomineeRelation: " ",
    nomineeMobile: " ",
    nomineeHeirName: " ",
    nomineeHeirDateOfBirth: " ",
    nomineeHeirRelation: "",
    amountPayableToNominee: " ",
    nomineeAddress: " ",
    nomineeEmail: " ",
    nomineePhoto:"upload",
    nomineeSign:"upload",
    nomineeHeirPhoto:"upload",
    nomineeHeirSign:"upload"
  };
  
  let societydata = [];
  
  let nomineedata = [];
  
  let emptyFields = [];
  

  const [personalForm, setFormFieldValues] = React.useState(personalFormFields);
  const personalformprops = { personalForm, setFormFieldValues };

  const [bankForm, setbankFormFieldValues] = React.useState(bankFormFields);
  const bankformprops = { bankForm, setbankFormFieldValues, Banks };

  const [officialForm, setofficialFormFieldValues] =
    React.useState(officialFormFields);

  const officialformprops = {
    officialForm,
    setofficialFormFieldValues,
    memberDesignations,
    rbBranches,
  };

  const [societyForm, setsocietyFormFieldValues] =
    React.useState(societyFormFields);
  const [society, setsocietydata] = React.useState(societydata);
  const [isOtherSocietyFieldsDisabled, setIsOtherSocietyFieldsDisabled] =
    React.useState(true);

  const societyformprops = {
    societyForm,
    setsocietyFormFieldValues,
    society,
    setsocietydata,
    isOtherSocietyFieldsDisabled,
    setIsOtherSocietyFieldsDisabled,
  };

  const [nomineeForm, setnomineeFormFieldValues] = React.useState(nomineeFormFields);
  const [nominee, setnomineedata] = React.useState(nomineedata);
  const nomineeformprops = {
    nomineeForm,
    setnomineeFormFieldValues,
    nominee,
    setnomineedata,
  };

  const handleNext = () => {
    
    //set the active step to the next step.................
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  

  const showStepContents = (step) => {

    //console.log(Banks);
    
    // get the contents as per the active state.
    switch (step) {
      case 0:
        return <MembershipApplicationInstructions />;
      case 1:
        return <MemberPersonalDetailsForm {...personalformprops} />;
      case 2:
        return <MemberOfficialDetailsForm {...officialformprops} />;
      case 3:
        return <MemberNomineesForm {...nomineeformprops} />;
      case 4:
        return <MemberOtherSocietyDetailsForm {...societyformprops}  {...personalformprops}/>;
      case 5:
        return <MemberBankDetailsForm {...bankformprops} />;
      // case 6:
      //   return <MembershipPaymentDetails {...paymentformprops} />;  
      case 6:
        return (
          <MembershipApplicationPreview
            {...personalformprops}
            {...officialformprops}
            {...bankformprops}
            // {...paymentformprops}
            {...societyformprops}
            {...nomineeformprops}
            {...activeprops}
          />
        );
   
      default:
        return null;
    }
  };

  //saving the details to the database.....
  async function handleSubmit (e) {
    //preventing the default behavior on submit
    e.preventDefault();


    //prepare the societyForm and NomineeForm objects.......................
    let societyForm = {
      societyArr : society
    }

    let nomineeForm = {
      nomineeArr : nominee
    }
    
    emptyFields = [];

    //check if any of the properties of the personalForm  is empty.......... and dont allow to move forward
    for (var key in personalForm) {
      if (personalForm[key] === null  || personalForm[key] === " "){
        //console.log("please fill in the complete details of the following : "+key)
        emptyFields.push("Personal Form: "+key);
       // return false;
      }
    }  

    //check if any of the properties of the officialForm  is empty.......... and dont allow to move forward
    for (var key in officialForm) {
      if (officialForm[key] === null || officialForm[key] === " ")
        emptyFields.push("Official Form: "+key);
    } 

    //check if any of the properties of the bankForm  is empty.......... and dont allow to move forward
    for (var key in bankForm) {
      if (bankForm[key] === null || bankForm[key] === " ")
        emptyFields.push("Bank Form: "+key);
    } 

    

    //check if any of the properties of the societyForm  is empty.......... and dont allow to move forward
    society.map((societyDetails)=>{
      for(var key in societyDetails){
        if (societyDetails[key] === null || societyDetails[key] === " ")
        emptyFields.push("Other Society Form: "+key);
      }
      return 0;
    });
      

    //check if any of the properties of the nomineeForm  is empty.......... and dont allow to move forward
   nominee.map((nomineeDetails)=>{
    for (var key in nomineeDetails) {
      if (nomineeDetails[key] === null || nomineeDetails[key] === " ")
        emptyFields.push("Nominee Form: "+key);
    } 
    return 0;
   })
    
    
    /////////sending the data to the server id all the data is present
    
    if(emptyFields.length === 0){
        // console.log("The lenght is "+ emptyFields.length)
        // console.log("The data being sent..................");
        // console.log({ ...personalForm, ...officialForm, ...bankForm, ...societyForm, ...nomineeForm});
        let config = {
            method: "POST",
            url: `/NewMembershipRegistrationRequest`,
            data:{ ...personalForm, ...officialForm, ...bankForm, ...societyForm, ...nomineeForm, applicationStatus:"MASDP"},
            headers: {
              Accept: "application/json",
              //  Authorization: varToken
            },
            responseType: "application/json",
          };
      
          setOpenProgressBarDialog(true);
          const response = await api(config);////////sending to the Server........
          setOpenProgressBarDialog(false);
          //   console.log("data sent from the server.............................");
          // console.log(config);
          //  console.log(response.data);
           navigate("../View-ManageStatus", {replace:true});
    }else{
           //form is not ready..................
               // console.log(emptyFields);
                alert("Please fill in all the details" +"\r\n"+ emptyFields.map((field, index)=>{
                          return field+"\r\n";
                }));
    }
      
  };




  return (
    <form onSubmit={(e) => {handleSubmit(e);}}>
      <Box sx={{ width: "96%" }} m={2}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};

            return (
              <Step key={label} {...stepProps}>
                <StepButton>{label}</StepButton>
              </Step>
            );
          })}
        </Stepper>

        {<div>{showStepContents(activeStep)}</div>}

        {activeStep === steps.length ? (
          <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />

              {activeStep === steps.length - 1 ? "": (
                <Button onClick={handleNext}>{"Next"}</Button>
              )}
              
            </Box>
          </React.Fragment>
        )}
      </Box>

       {/* Dialog to implement the Progess bar ................ */}
       <Dialog
        open={openProgressBarDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="scroll-dialog-title">
          <b>Please wait! work under process ....</b>
        </DialogTitle>
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      </Dialog>
    </form>
  );
}
