import "./MemberPersonalDetailsViewByStaffOrAdmin.css";
import React, { useEffect, useState } from "react";


import { useNavigate } from "react-router-dom";

import profileImage from "../../../../images/profile2.jpg";
import signImage from "../../../../images/sign.jpg";

import  useMyAuthService  from "../../../../authenticationServiceProvider/useMyAuthService";
import ProgressorBarDisplayer from "../../DialogComponents/ProgressorBarDisplayer/ProgressorBarDisplayer";
import RBMembersListDropDown from "../../RBMembersListDropDown/RBMembersListDropDown";

const personalFormFields = {
  name: " ",
  fatherName: " ",
  spouseName:" ",
  gender:" ",
  dateOfBirth: " ",
  email: " ",
  mobile: " ",
  panNo: " ",
  residentialAddress: " ",
  permanentAddress: " ",
 
};


const MemberPersonalDetailsViewByStaffOrAdmin = () => {
  
  const navigate = useNavigate();
 const {loggedInUserRUID, api} = useMyAuthService();

 const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

 const [selectedMemberRUID, setSelectedMemberRUID] = useState("");
  const [personalForm, setFormFieldValues] = useState(personalFormFields);
  
  const [memberPhoto, setMemberPhoto] = useState(profileImage);
  const [memberSign, setMemberSign] = useState(signImage);

 
 
  const getRBMemberPersonalDetails = () => {
    api
      .get(
        `/getRBMemberPersonalDetails/${selectedMemberRUID}`
      )
      .then((response) => {
        // console.log(response.data);
         setFormFieldValues({
          ...personalForm,
          name: response.data.name,
          fatherName: response.data.fatherName,
          mobile: response.data.users.mobile,
          email: response.data.users.email,
          residentialAddress: response.data.homeAddress,
          permanentAddress: response.data.permanentAddress,
          panNo: response.data.panNo,
          dateOfBirth: response.data.dateOfBirth,
          spouseName:response.data.spouseName,
          gender:response.data.gender,
        });
        //console.log(regData);
        
      });
  };


  const getRBMemberPhotoDetails = () => {

    api({
      url: `/GetMemberPhoto/${selectedMemberRUID}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
       const url = window.URL.createObjectURL(new Blob([response.data]));
       //console.log(url);
       setMemberPhoto(url);
      
    });
  
  };

  const getRBMemberSignatureDetails = () => {

    api({
      url: `/GetMemberSignature/${selectedMemberRUID}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
       const url = window.URL.createObjectURL(new Blob([response.data]));
       setMemberSign(url);
      
    });
  
  };


  const GetMemberPersonalDetails = ()=>{
    setOpenProgressBarDialog(true);
     getRBMemberPersonalDetails();
     getRBMemberPhotoDetails();
     getRBMemberSignatureDetails();
     setOpenProgressBarDialog(false);
  }

  

  return (
    <div className="MemberPersonalDetailsViewByStaffOrAdminMainDiv">
      <fieldset>
        <legend>🔍 View Member personal Details here</legend>
         
        
          <RBMembersListDropDown 
            setSelectedMemberRUID={setSelectedMemberRUID}
            actionButtonText={"Click to View Member Personal Details"}
            actionButtonFunction={GetMemberPersonalDetails}
          />

         <hr/>
          <>
            <div className="gridRow">
              <div className="gridCol">
                <table>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{personalForm.name}</td>
                    </tr>
                    <tr>
                      <td>Father Name</td>
                      <td>{personalForm.fatherName}</td>
                    </tr>
                    <tr>
                      <td>Spouse Name</td>
                      <td>{personalForm.spouseName}</td>
                    </tr>
                    <tr>
                      <td>Date of Birth</td>
                      <td>{personalForm.dateOfBirth}</td>
                    </tr>
                    <tr>
                      <td>Gender</td>
                      <td>{personalForm.gender}</td>
                    </tr>
                    <tr>
                      <td>Mobile No.</td>
                      <td>{personalForm.mobile}</td>
                    </tr>
                    <tr>
                      <td>PAN No.</td>
                      <td>{personalForm.panNo}</td>
                    </tr>
                    <tr>
                      <td>Email Id</td>
                      <td>{personalForm.email}</td>
                    </tr>
                    <tr>
                      <td>Mobile No.</td>
                      <td>{personalForm.mobile}</td>
                    </tr>
                    <tr>
                      <td>Residential Address</td>
                      <td>{personalForm.residentialAddress}</td>
                    </tr>
                    <tr>
                      <td>Permanent Address</td>
                      <td>{personalForm.permanentAddress}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="gridCol">
                <table>
                  <tbody>
                    <tr>
                      <td>Member Photo</td>
                      <td>Member Signature</td>
                    </tr>
                    <tr>
                      <td>
                        <img src={memberPhoto}></img>
                      </td>
                      <td>
                        <img src={memberSign}></img>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
           
          </>
          
          <ProgressorBarDisplayer 
             titleMessage = {'Loading Member Personal Details'} openProgressBarDialog = {openProgressBarDialog} />
      </fieldset>
    </div>
  );
};

export default MemberPersonalDetailsViewByStaffOrAdmin;
