import React, { useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const MenuLoanApplicationsVerification = ({
  handleClickOpenLoanDetailsInApplication,
  handleOpenPaySlipDisplayer,
  handleClickOpenMarkLoanApplicationForReview,
  handleClickOpenVerifyApplication,
  selectedLoanApplication,
  handleClickOpenLoanSuretyDetailsInApplication,
  anchorActionsMenu,
  openMenu,
  handleCloseMenu,
  applicationStatus,
}) => {
  
  return (
    <>
      <Menu
        anchorEl={anchorActionsMenu}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClickOpenLoanDetailsInApplication();
          }}
        >
          🔍 View Loan & Member Details
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenPaySlipDisplayer(selectedLoanApplication.rbmembers.rbMemberRUID);
          }}
        >
          🔍 View Member Salary Slip
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClickOpenLoanSuretyDetailsInApplication();
          }}
        >
          🔍 View Surety Details
        </MenuItem>

        {applicationStatus === "LAS" || applicationStatus === "LARS" ? (
          <MenuItem
            onClick = {() => {
              handleClickOpenMarkLoanApplicationForReview();
            }}
          >
            ✍️ Mark For Review
          </MenuItem>
        ) : (
          ""
        )}

        {applicationStatus === "LAS" || applicationStatus === "LARS" ? (
          <MenuItem
            onClick={() => {
              handleClickOpenVerifyApplication();
            }}
          >
            ✔️ Verify Details
          </MenuItem>
        ) : (
          ""
        )}
      </Menu>
    </>
  );
};

export default MenuLoanApplicationsVerification;
