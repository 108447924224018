import React, { useState } from "react";
import "./MemberPersonalDetailsForm.css";



const MemberPersonalDetailsForm = (props) => {
  
  const {personalForm, setFormFieldValues } = props;

  const [open, setOpen] = useState(false);
  
  return (
    <div className="personalDetailsMainDiv">
      <fieldset>
        <legend>🖥️ Fill Your Personal Details</legend>
        <div className="gridRow">
          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Name</label>
              </div>
              <div>
                <input
                  type={"text"}
                  value={personalForm.name}
                  
                 readOnly
                />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Father's Name</label>
              </div>
              <div>
                <input
                  type={"text"}
                  value={personalForm.fatherName}
                  onChange={(e) =>
                    setFormFieldValues({
                      ...personalForm,
                      fatherName: e.target.value,
                    })
                  }
                  placeholder="Enter your father's name"
                />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Spouse's Name</label>
              </div>
              <div>
                <input
                  type={"text"}
                  value={personalForm.spouseName}
                  onChange={(e) =>
                    setFormFieldValues({
                      ...personalForm,
                      spouseName: e.target.value,
                    })
                  }
                  placeholder="Enter your spouse's name"
                />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Residential Address</label>
              </div>
              <div>
                <textarea rows="5" cols="40" value={personalForm.residentialAddress}
                  onChange={(e) =>
                    setFormFieldValues({
                      ...personalForm,
                      residentialAddress: e.target.value,
                    })
                  }></textarea>
              </div>
            </div>
          </div>

          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Date of Birth</label>
              </div>
              <div>
                <input type={"date"} value={personalForm.dateOfBirth}
                  onChange={(e) =>
                    setFormFieldValues({
                      ...personalForm,
                      dateOfBirth: e.target.value,
                    })} 
                    placeholder="Enter your date of birth" />
              </div>
            </div>

            
            <div className="FormFieldDiv">
              <div>
                <label>Pan No.</label>
              </div>
              <div>
                <input type={"text"} value={personalForm.panNo}
                  onChange={(e) =>
                    setFormFieldValues({
                      ...personalForm,
                      panNo: e.target.value,
                    })} placeholder="Enter your pan no." />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Gender</label>
              </div>
              <div>
                <input list="genders" value={personalForm.gender}
                    onChange={(e) =>
                      setFormFieldValues({
                        ...personalForm,
                        gender: e.target.value,
                      })
                    }/>
                <datalist id="genders">
                  <option  value={'Male'}>Male</option>
                  <option  value={'Female'}>Female</option> 
                  <option  value={'Others'}>Others</option>
                </datalist>
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Permanent Address</label>
              </div>
              <div>
                <textarea rows="5" cols="40" value={personalForm.permanentAddress}
                  onChange={(e) =>
                    setFormFieldValues({
                      ...personalForm,
                      permanentAddress: e.target.value,
                    })} ></textarea>
              </div>
            </div>
            
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default MemberPersonalDetailsForm;
