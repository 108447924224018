import "./UpdateLogInPasswordComponent.css"

import React from "react";

import { useState } from "react";

import LinearProgress from "@mui/material/LinearProgress";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMyAuthService from "../../../authenticationServiceProvider/useMyAuthService";


const UpdateLogInPasswordComponent = () => {

 const {loggedInUserRUID, api} = useMyAuthService();
    /** The data elements of the form to be stored in these variables */
  const [OTP, setOTP] = useState();
  const [otpEntered, setOTPEntered] = useState();

  const [errorMessage, setErrorMessage] = useState();
  const [message, setMessage] = useState();

  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const [sendOTPStep, setSendOTPStep] = useState(true); /////step 1
  const [validateOTPStep, setValidateOTPStep] = useState(false); ////step 2
  
  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);


  //fucntion to handle submit for OTP validation..........................
  async function handleSubmitValidateOTP(e) {
    e.preventDefault();

    // //getting the value sent
    // if(dataSent.state !== null){
    //   setRUID(dataSent.state.ruid);
    //   setOTPSent(dataSent.state.OTP);
    // }else{
    //   console.log(dataSent.state);
    // }

    ///validating otp entered

    if (parseInt(otpEntered) !== parseInt(OTP)) {
      setErrorMessage("OTP entered is not valid");
      // console.log(otpEntered);
      // console.log(otpSent);
      setMessage("");
      return 0;
    }

    if (password !== confirmPassword) {
      setErrorMessage("Password and Confirm Password do not match");
      setMessage("");
      return 0;
    }

    const data = {
      ruid: loggedInUserRUID,
      password: password,
    };
    //console.log(data);
    let config = {
      method: "PUT",
      url: `/UpdatePasswordAfterOTPValidation`,
      data: { data },
      headers: {
        Accept: "application/json",

        //  Authorization: varToken
      },
      responseType: "json",
    };

    setOpenProgressBarDialog(true);
    const response = await api(config);
    //console.log(response.data);

    if (response.data === "success") {
      setMessage(
        "Password has been updated successfully. Please LOG IN with the New Password"
      );

      
      // navigate("/", {
      //   state: {
      //       message: "Password has been updated successfully. Please LOG IN with the New Password",
      //      }
      //   });

      setErrorMessage("");
      setSendOTPStep(false);
      setValidateOTPStep(false);
     //data.state.otp = undefined;
    } else {
      setMessage("");
      setErrorMessage(response.data);
    }

    setOpenProgressBarDialog(false);
  }


///////////////////handle submit sending OTP .............................................
  async function handleSubmitSendOTP(e) {
    e.preventDefault();

    const data = {
      ruid: loggedInUserRUID,
    };
    //console.log(data);
    let config = {
      method: "POST",
      url: `/UpdatePasswordConfirmUserSendOTP`,
      data: { data },
      headers: {
        Accept: "application/json",

        //  Authorization: varToken
      },
      responseType: "json",
    };

    setOpenProgressBarDialog(true);
    const response = await api(config);
     //console.log(typeof response.data);
     //console.log(response.data);
    
    if(Number.isInteger(response.data)){
        //user found and OTP generated......
        setOTP(response.data);
        setSendOTPStep(false);//step 1 over
        setValidateOTPStep(true);// step 2 begins
    }else{
        //user do not exist
        setErrorMessage("User not Found. Please register as a new User on the RBCTCS portal");
        setMessage(""); 
        setSendOTPStep(false);//step 1 over
    }
    
    setOpenProgressBarDialog(false);
   
  }

  return (
    <div className="UpdatePasswordMainDiv">
      <fieldset>
        <legend>Resetting: Change Password </legend>
        <div className="errorDiv">{errorMessage}</div>
        <div className="msgDiv">{message}</div>
        {!sendOTPStep ? (
          ""
        ) : (
          <form
            onSubmit={(e) => {
              handleSubmitSendOTP(e);
            }}
          >
            <div>
              <h2>Please Click to generate and send the One Time Password to your mail id for updating your Log In password</h2>
            </div>
            
            <div className="FormActionDiv">
              <input type={"submit"} value="Click to receive OTP" />
            </div>
          </form>
        )}

        {!validateOTPStep ? (
          ""
        ) : (
          <form
            onSubmit={(e) => {
              handleSubmitValidateOTP(e);
            }}
          >
            <div>
              <p>
                Please Enter the OTP received in your mail for PASSWORD
                RESETTING
              </p>
            </div>
            <div className="FormFieldDiv">
              <div>
                <input
                  type={"number"}
                  placeholder="🆔 Enter Your OTP"
                  min="1"
                  onChange={(e) => {
                    setOTPEntered(e.target.value);
                  }}
                  required
                />
              </div>
            </div>

            <div className="FormFieldDiv">
              <div>
                <input
                  type={"password"}
                  placeholder="🔑 Enter Your New Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <input
                  type={"text"}
                  placeholder="🔑 Confirm Your New Password"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="FormActionDiv">
              <input type={"reset"} value="Reset" />
              <input type={"submit"} value="Submit to Update New Password" />
            </div>
          </form>
        )}
      </fieldset>

      {/* Dialog to implement the Progess bar ................ */}
      <Dialog open={openProgressBarDialog} fullWidth maxWidth="sm">
        <DialogTitle id="scroll-dialog-title">
          <b>Please wait! work under process ....</b>
        </DialogTitle>
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UpdateLogInPasswordComponent