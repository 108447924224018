import React from "react";
import './LoanApplicationInstructions.css'


const LoanInstructions = () => {
  return (
    <div className="LoanInstructionMainDiv">
      <fieldset>
        <legend>🎯 Loan Instructions</legend>
        <div className="LoanInstructionContentDiv">
          Loan Instructions will be posted here
        </div>
      </fieldset>
    </div>
  );
};

export default LoanInstructions;
