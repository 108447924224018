import { useState } from "react";
import useMyAuthService  from "../../../../authenticationServiceProvider/useMyAuthService";

// This is the object that will serve as the mark the part for review
export const partForReview = {
  staffId: null,
  memberApplicationID: null,
  Personal_Details: false,
  Official_Details: false,
  Bank_Details: false,
  Document_Uploads: false,
  Other_Society_Details: false,
  Nominee_Details: false,
};

//The State Variables to be used in the Membership Applications for Verifications.....................................
export const useVerifyMembershipApplicationsState = () => {
  const {loggedInUserRUID, api} = useMyAuthService();

   const [memberNomineeDetailsData, setMemberNomineeDetailsData] = useState({});
  const [memberOtherSocietyDetailsData, setMemberOtherSocietyDetailsData] =
    useState({});
  // const [memberApplicationDetails, setMemberApplicationDetails] = useState([]);

  const [selectedMemberApplication, setSelectedMemberApplication] = useState(
    {}
  );
  const [applicationPartForReview, setApplicationPartForReview] =
    useState(partForReview);
  // const [isLoadingData, setIsLoadingData] = useState(true);
  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);
  const [ledgerNoAlloted, setLedgerNoAlloted] = useState(0);
  const [openAdminDisplayDialog, setOpenAdminDisplayDialog] = useState(false);
  const [openStaffDisplayDialog, setOpenStaffDisplayDialog] = useState(false);
  const [adminRBCTCSDetails, setAdminRBCTCSDetails] = useState({});
  const [staffRBCTCSDetails, setStaffRBCTCSDetails] = useState({});
  const [adminName, setAdminName] = useState();
  const [openPaySlipDisplayer, setOpenPaySlipDisplayer] = useState(false);
  const [openMembershipFormDocsDisplayer, setOpenMembershipFormDocsDisplayer] = useState(false);
  const [docFile, setDocFile] = useState();
  const [updated, setUpdated] = useState(true);

  const [reviewRemarks, setReviewRemarks] = useState("Data is correct");

  // State  varrriables for Dialogg  boxees.................................

  //function for open and Close of the Dialog Box....................................starts
  const [openMemberDetailsInApplication, setOpenMemberDetailsInApplication] =
    useState(false);
  // const [
  //   openMemberPaymentDetailsForMembership,
  //   setOpenMemberPaymentDetailsForMembership,
  // ] = useState(false);
  const [
    openOtherSocietyDetailsInApplication,
    setOpenOtherSocietyDetailsInApplication,
  ] = useState(false);
  const [openNomineeDetailsInApplication, setOpenNomineeDetailsInApplication] =
    useState(false);
  const [openMarkApplicationForReview, setOpenMarkApplicationForReview] =
    useState(false);
  const [openVerifyApplication, setOpenVerifyApplication] =
    useState(false);


    // select options for the application status ....................... 
    const optionsForApprovedMembershipApplications = [
      {
        value: "MAPDV",
        label: "Membership Application and Payment Details Verified",
      },
      {
        value: "MAA",
        label: "Member Application Approved",
      },
    ]; 


    const optionsForMembershipApplicationsPaymentVerification =  [
        {
          value: "MASWP",
          label: "Membership Application Submitted With Payment",
        },
        {
          value: "MASDP",
          label: "Membership Application Submitted & Documentation Pending",
        },
        {
          value: "MASPP",
          label: "Membership Application Submitted & Payment Pending",
        },
        {
          value: "MASPR",
          label: "Membership Application Submitted & Payment Rejected",
        },
        {
          value: "MASRP",
          label: "Membership Application Submitted with Revised Payment",
        },
       
    ]; 

    const optionsForMembershipApplicationsToBeVerified = [
      {
        value: "MASPV",
        label: "Membership Application Submitted & Payment Verified",
      },
      {
        value: "MAMR",
        label: "Membership Application Marked For Review",
      },
      {
        value: "MARS",
        label: "Membership Application Re-submitted",
      },
     
   
    ]; 

  return {
   loggedInUserRUID, api,
    memberNomineeDetailsData,
    setMemberNomineeDetailsData,
    memberOtherSocietyDetailsData,
    setMemberOtherSocietyDetailsData,
    // memberApplicationDetails,
    // setMemberApplicationDetails,
    selectedMemberApplication,
    setSelectedMemberApplication,
    applicationPartForReview,
    setApplicationPartForReview,
    // isLoadingData,
    // setIsLoadingData,
    openProgressBarDialog,
    setOpenProgressBarDialog,
    ledgerNoAlloted,
    setLedgerNoAlloted,
    openAdminDisplayDialog,
    setOpenAdminDisplayDialog,
    openStaffDisplayDialog,
    setOpenStaffDisplayDialog,
    adminRBCTCSDetails,
    setAdminRBCTCSDetails,
    staffRBCTCSDetails,
    setStaffRBCTCSDetails,
    adminName,
    setAdminName,
    openPaySlipDisplayer,
    setOpenPaySlipDisplayer,
    openMembershipFormDocsDisplayer, setOpenMembershipFormDocsDisplayer,
    docFile,
    setDocFile,
    updated,
    setUpdated,
    // anchorActionsMenu,
    // setAnchorActionsMenu,
    // openMenu

    openMarkApplicationForReview,
    setOpenMarkApplicationForReview,

    openMemberDetailsInApplication,
    setOpenMemberDetailsInApplication,

    openNomineeDetailsInApplication,
    setOpenNomineeDetailsInApplication,

    openOtherSocietyDetailsInApplication,
    setOpenOtherSocietyDetailsInApplication,

    openVerifyApplication,
    setOpenVerifyApplication,

    optionsForApprovedMembershipApplications,
    optionsForMembershipApplicationsPaymentVerification,
    optionsForMembershipApplicationsToBeVerified,

    reviewRemarks, setReviewRemarks
  };
};
