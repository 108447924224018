import React from "react";

import "./UpdateStaffDetails.css";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useMyAuthService  from "../../../authenticationServiceProvider/useMyAuthService";

import LinearProgress from "@mui/material/LinearProgress";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";



let UpdateStaffForm = {
  staffId: " ",
  name: " ",
  dateOfBirth: " ",
  panNo: " ",
  homeAddress: " ",
  permanentAddress: " ",
  dateOfAppointment: " ",
  dateOfRetirement: " ",
  designation: " ",
  branchRoomNo: " ",
  railwayTelNo: " ",
  basicPay: " ",
  salaryBankName: " ",
  salaryBankAccountNo: " ",
  salaryBankIFSC: " ",
  mobileNo: " ",
  emailId: " ",
};

const UpdateStaffDetails = () => {
  /** The data elements of the form to be stored in these variables */

  const {loggedInUserRUID, api} = useMyAuthService();
  
  const [UpdateStaffFormData, setUpdateStaffFormData] =
    useState(UpdateStaffForm);
  const [errorMessage, setErrorMessage] = useState();
  const [message, setMessage] = useState();

  const [isloadingData, setIsLoadingData] = useState(true);
  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getRBCTCSStaffDetails();
  }, []);

  const getRBCTCSStaffDetails = () => {
    api
      .get(
        `/ViewRBCTCSStaffDetails/${loggedInUserRUID}`// for the staff 
      )
      .then((response) => {
        //console.log(response.data);
        //setRBMemberPersonalDetails(response.data);
        setUpdateStaffFormData({
          ...UpdateStaffFormData,
          staffId: response.data[0].staffRBCTCSId,
          name: response.data[0].name,
          dateOfBirth: response.data[0].dateOfBirth,
          panNo: response.data[0].panNo,
          homeAddress: response.data[0].homeAddress,
          permanentAddress: response.data[0].permanentAddress,
          dateOfAppointment: response.data[0].dateOfAppointment,
          dateOfRetirement: response.data[0].dateOfRetirement,
          designation: response.data[0].designation,
          branchRoomNo: response.data[0].branchRoomNo,
          railwayTelNo: response.data[0].railwayTelNo,
          basicPay: response.data[0].basicPay,
          salaryBankName: response.data[0].salaryBankName,
          salaryBankAccountNo: response.data[0].salaryBankAccountNo,
          salaryBankIFSC: response.data[0].salaryBankIFSC,
          mobileNo: response.data[0].mobileNo,
          emailId: response.data[0].emailId,
        });
        //console.log(regData);
        setIsLoadingData(false);
      });
  };

  //function to submit ...............................
  async function handleSubmit(e) {
    e.preventDefault();
    //console.log("form has been submitted");
    // console.log(UpdateStaffFormData);

    const data = UpdateStaffFormData;
    //console.log(data);
    let config = {
      method: "PUT",
      url: "/UpdateRBCTCSStaffDetails",
      data: { data },
      headers: {
        Accept: "application/json",

        //  Authorization: varToken
      },
      responseType: "json",
    };

    setOpenProgressBarDialog(true);
    const response = await api(config);
    setOpenProgressBarDialog(false);

    //console.log(response.data);
    setMessage(response.data);
    navigate(-1);
  }

  return (
    <div className="StaffUpdationMainDiv">
      <fieldset>
        <legend>Update Staff Details for RBCTCS </legend>
        {isloadingData ? (
          <div className="isLoadingDiv">Loading Data .....</div>
        ) : (
          <>
            <div className="errorDiv">{errorMessage}</div>
            <div className="msgDiv">{message}</div>
            {/* {JSON.stringify(UpdateStaffFormData)} */}
            <form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <div className="gridRow">
                <div className="gridCol">
                  <div className="FormFieldDiv">
                    <div>
                      <label>Staff Name</label>
                    </div>
                    <div>
                      <input
                        type={"text"}
                        name="staffName"
                        placeholder="Enter the Staff Name "
                        value={UpdateStaffFormData.name}
                        onChange={(e) =>
                          setUpdateStaffFormData({
                            ...UpdateStaffFormData,
                            name: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="FormFieldDiv">
                    <div>
                      <label>Date Of Birth</label>
                    </div>
                    <div>
                      <input
                        type={"date"}
                        name="dobStaff"
                        placeholder="Enter Date of Birth "
                        value={UpdateStaffFormData.dateOfBirth}
                        onChange={(e) =>
                          setUpdateStaffFormData({
                            ...UpdateStaffFormData,
                            dateOfBirth: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="FormFieldDiv">
                    <div>
                      <label>PAN No.</label>
                    </div>
                    <div>
                      <input
                        type={"text"}
                        name="panNo"
                        placeholder="Enter the PAN No "
                        value={UpdateStaffFormData.panNo}
                        onChange={(e) =>
                          setUpdateStaffFormData({
                            ...UpdateStaffFormData,
                            panNo: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>

                  <div className="FormFieldDiv">
                    <div>
                      <label>Home Address</label>
                    </div>
                    <div>
                      <textarea
                        rows={4}
                        cols={32}
                        name="homeAddress"
                        placeholder="Enter the Home Address"
                        value={UpdateStaffFormData.homeAddress}
                        onChange={(e) =>
                          setUpdateStaffFormData({
                            ...UpdateStaffFormData,
                            homeAddress: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="gridCol">
                  <div className="FormFieldDiv">
                    <div>
                      <label>Designation</label>
                    </div>
                    <div>
                      <input
                        type={"text"}
                        name="designationStaff"
                        placeholder="Enter the Designation for Staff "
                        value={UpdateStaffFormData.designation}
                        onChange={(e) =>
                          setUpdateStaffFormData({
                            ...UpdateStaffFormData,
                            designation: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="FormFieldDiv">
                    <div>
                      <label>Date of Appointment</label>
                    </div>
                    <div>
                      <input
                        type={"date"}
                        name="doaStaff"
                        placeholder="Enter the DOA "
                        value={UpdateStaffFormData.dateOfAppointment}
                        onChange={(e) =>
                          setUpdateStaffFormData({
                            ...UpdateStaffFormData,
                            dateOfAppointment: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="FormFieldDiv">
                    <div>
                      <label>Date of Retirement</label>
                    </div>
                    <div>
                      <input
                        type={"date"}
                        name="dorStaff"
                        placeholder="Enter the DOR "
                        value={UpdateStaffFormData.dateOfRetirement}
                        onChange={(e) =>
                          setUpdateStaffFormData({
                            ...UpdateStaffFormData,
                            dateOfRetirement: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>

                  <div className="FormFieldDiv">
                    <div>
                      <label>Permanent Address</label>
                    </div>
                    <div>
                      <textarea
                        rows={4}
                        cols={32}
                        name="permanentAddress"
                        placeholder="Enter the Permanent Address"
                        value={UpdateStaffFormData.permanentAddress}
                        onChange={(e) =>
                          setUpdateStaffFormData({
                            ...UpdateStaffFormData,
                            permanentAddress: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="gridCol">
                  <div className="FormFieldDiv">
                    <div>
                      <label>Branch Room No</label>
                    </div>
                    <div>
                      <input
                        type={"number"}
                        name="branchRoom"
                        placeholder="Enter the Office Room No "
                        value={UpdateStaffFormData.branchRoomNo}
                        onChange={(e) =>
                          setUpdateStaffFormData({
                            ...UpdateStaffFormData,
                            branchRoomNo: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="FormFieldDiv">
                    <div>
                      <label>Railway Tel No.</label>
                    </div>
                    <div>
                      <input
                        type={"number"}
                        name="railwayTelNo"
                        placeholder="Enter the Railway Tel No "
                        value={UpdateStaffFormData.railwayTelNo}
                        onChange={(e) =>
                          setUpdateStaffFormData({
                            ...UpdateStaffFormData,
                            railwayTelNo: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="FormFieldDiv">
                    <div>
                      <label>Mobile No.</label>
                    </div>
                    <div>
                      <input
                        type={"number"}
                        name="mobile"
                        placeholder="Enter the mobile No. "
                        value={UpdateStaffFormData.mobileNo}
                        onChange={(e) =>
                          setUpdateStaffFormData({
                            ...UpdateStaffFormData,
                            mobileNo: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="FormFieldDiv">
                    <div>
                      <label>Email Id</label>
                    </div>
                    <div>
                      <input
                        type={"email"}
                        name="emailId"
                        placeholder="Enter the Email Id "
                        value={UpdateStaffFormData.emailId}
                        onChange={(e) =>
                          setUpdateStaffFormData({
                            ...UpdateStaffFormData,
                            emailId: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="gridCol">
                  <div className="FormFieldDiv">
                    <div>
                      <label>Salary/Pay</label>
                    </div>
                    <div>
                      <input
                        type={"number"}
                        name="salary"
                        placeholder="Enter the Salary/pay "
                        value={UpdateStaffFormData.basicPay}
                        onChange={(e) =>
                          setUpdateStaffFormData({
                            ...UpdateStaffFormData,
                            basicPay: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="FormFieldDiv">
                    <div>
                      <label>Salary Bank Name</label>
                    </div>
                    <div>
                      <input
                        type={"text"}
                        name="salaryBank"
                        placeholder="Enter the Salary Bank "
                        value={UpdateStaffFormData.salaryBankName}
                        onChange={(e) =>
                          setUpdateStaffFormData({
                            ...UpdateStaffFormData,
                            salaryBankName: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="FormFieldDiv">
                    <div>
                      <label>Salary Bank Account</label>
                    </div>
                    <div>
                      <input
                        type={"number"}
                        name="salBankAcc"
                        placeholder="Enter the Bank Account No. "
                        value={UpdateStaffFormData.salaryBankAccountNo}
                        onChange={(e) =>
                          setUpdateStaffFormData({
                            ...UpdateStaffFormData,
                            salaryBankAccountNo: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="FormFieldDiv">
                    <div>
                      <label>Salary Bank IFSC</label>
                    </div>
                    <div>
                      <input
                        type={"text"}
                        name="ifscCode"
                        placeholder="Enter the IFSC Code "
                        value={UpdateStaffFormData.salaryBankIFSC}
                        onChange={(e) =>
                          setUpdateStaffFormData({
                            ...UpdateStaffFormData,
                            salaryBankIFSC: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="FormActionDiv">
                <input type={"button"} value="Clear Form" />
                <input type={"submit"} value="Update Staff for RBCTCS" />
              </div>
            </form>

            {/* Dialog to implement the Progess bar ................ */}
            <Dialog open={openProgressBarDialog} fullWidth maxWidth="sm">
              <DialogTitle id="scroll-dialog-title">
                <b>Please wait! Work Under Progess ....</b>
              </DialogTitle>
              <DialogContent>
                <LinearProgress />
              </DialogContent>
            </Dialog>
          </>
        )}
      </fieldset>
    </div>
  );
};

export default UpdateStaffDetails;
