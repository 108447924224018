import "./ApplicationsReadyForDisbursal.css";

import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import useMyAuthService from "../../../../../authenticationServiceProvider/useMyAuthService";

const ApplicationsReadyForDisbursal = () => {

  const {api} = useMyAuthService();
  
  const [isLoanDisbursalListLoaded, setIsLoanDisbursalListLoaded] =
    useState(false);
  const [loanApplicationsForDisbursal, setLoanApplicationsForDisbursal] =
    useState([]);

  const [quckSearchText, setQuickSearchText] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    getApplicationStatus();
    //populateCheckList();
  }, []);

  const getApplicationStatus = () => {};

  //fucntion to handle the onchange event on the check box
  const handleChangeCheckBox = (e, index) => {
    // console.log(loanApplicationsForDisbursal[index]);
    let dataUpdated = [...loanApplicationsForDisbursal];
    dataUpdated[index].isChecked = e.target.checked;
    setLoanApplicationsForDisbursal(dataUpdated);
  };

  //fucntion to hhandle select all button onclick event
  const handleButtonSelectAllCheckBox = () => {
    let dataUpdated = [...loanApplicationsForDisbursal];

    dataUpdated.forEach((element) => {
      element.isChecked = !element.isChecked;
    });
    // dataUpdated[index].isChecked = e.target.checked;
    setLoanApplicationsForDisbursal(dataUpdated);
  };

  const handleClickGoToSelectedLoansForDisbursal = () => {
    // Assuming loansForDisbursal is your object of objects
  const filteredLoans = Object.keys(loanApplicationsForDisbursal)
  .filter(key => loanApplicationsForDisbursal[key].isChecked === true)
  .map(key => loanApplicationsForDisbursal[key]);

  //console.log("filtered loans are: "+filteredLoans);

    navigate("FillDetails", {
      state: {
        loanApplicationsForDisbursal: filteredLoans,
      },
    });
  };

  const handleClickViewLoanApplicationsForDisbursal = () => {
    api
      .get(`/GetLoanApplicationsReadyForDisbursal`)
      .then((response) => {
        // adding the ischecked propoerty to the response.data

        // let newData =  Object.entries(response.data).map(data =>  { return {...data, isChecked:false}});
        let newData = Object.entries(response.data).map(([key, value]) => {
          return {
            ...value,
            isChecked: false,
          };
        });

        // console.log(newData);
        setLoanApplicationsForDisbursal(newData);
        setIsLoanDisbursalListLoaded(true);
      });
  };

  return (
    <>
      {/* {JSON.stringify(loanApplicationsForDisbursal)} */}

      {!isLoanDisbursalListLoaded ? (
        <div className="LDRFiltersSearchDiv gridRow">
          <span>
            <h3>
              Please Click on the Go Button to View the Loan Applications For
              Disbursal
            </h3>
          </span>

          <input
            type={"button"}
            value={"Go 🔍"}
            onClick={handleClickViewLoanApplicationsForDisbursal}
          />
        </div>
      ) : (
        <div className="LDRFiltersSearchDiv gridRow">
             <input
                type={"button"}
                value={"👈 Back"}
                onClick={()=>{setIsLoanDisbursalListLoaded(false)}}
              />
          </div>
      )}

      <div>
        {isLoanDisbursalListLoaded ? (
          <div>
            <table className="LDRContentTable">
              <thead>
                <tr>
                  <td>Sl No.</td>
                  <td>Loan ID</td>
                  <td>Type</td>
                  <td>Status</td>
                  <td>Name/ RUID</td>
                  <td>Desgn/ Branch</td>
                  <td>L/F No.</td>
                  <td>Bank </td>
                  <td>Account No.</td>
                  <td>IFSC</td>
                  <td>Amount</td>
                  <td>
                    <input
                      type={"button"}
                      value={"🎲"}
                      onClick={handleButtonSelectAllCheckBox}
                    />
                  </td>
                </tr>
              </thead>
              <tbody>
                {Object.keys(loanApplicationsForDisbursal).map((key, index) => {
                  return (
                    <tr key={key}>
                      <td>{index + 1} </td>
                      <td>
                        {loanApplicationsForDisbursal[key].loanApplicationId}
                      </td>
                      <td>{loanApplicationsForDisbursal[key].loanType}</td>
                      <td>
                        {loanApplicationsForDisbursal[key].applicationStatus}
                      </td>
                      <td>
                        {loanApplicationsForDisbursal[key].rbmembers.name} (
                        {
                          loanApplicationsForDisbursal[key].rbmembers
                            .rbMemberRUID
                        }
                        )
                      </td>
                      <td>
                        {
                          loanApplicationsForDisbursal[key].rbmembers
                            .designation
                        }
                        /{loanApplicationsForDisbursal[key].rbmembers.branch}
                      </td>
                      <td>
                        {loanApplicationsForDisbursal[key].rbmembers.ledgerNo}
                      </td>
                      <td>
                        {
                          loanApplicationsForDisbursal[key].rbmembers
                            .salaryBankName
                        }
                      </td>
                      <td>
                        {
                          loanApplicationsForDisbursal[key].rbmembers
                            .salaryBankAccountNo
                        }
                      </td>
                      <td>
                        {
                          loanApplicationsForDisbursal[key].rbmembers
                            .salaryBankIFSC
                        }
                      </td>
                      <td>
                        {loanApplicationsForDisbursal[key].loanAmountSought}
                      </td>
                      <td className="actionCol">
                        <input
                          type={"checkbox"}
                          onChange={(e) => handleChangeCheckBox(e, index)}
                          checked={loanApplicationsForDisbursal[key].isChecked}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="FormActionDiv">
              <input
                type={"button"}
                value={"Proceed to Next Step 👉"}
                onClick={handleClickGoToSelectedLoansForDisbursal}
              />
            </div>
          </div>
        ) : (
            <div className="alternateReportViewText">
                <h1> Loan Disbursement Report </h1>
            </div>
        )}
      </div>
    </>
  );
};

export default ApplicationsReadyForDisbursal;
