import "./ReviewPaySlipUploader.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import LinearProgress from "@mui/material/LinearProgress";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import useMyAuthService from "../../../authenticationServiceProvider/useMyAuthService";
import { DialogActions } from "@mui/material";
import { Button } from "@mui/material";

const ReviewPaySlipUploader = () => {
  const navigate = useNavigate();

  const data = useLocation();
  //console.log(data);
  const loanApplicationId = data.state.loanApplicationId;

  const { loggedInUserRUID, api } = useMyAuthService();
  const [currentLoggedInRUID, setCurrentLoggedInRUID] =
    useState(loggedInUserRUID);

  const [openPaySlipDisplayer, setOpenPaySlipDisplayer] = useState(false);
  const [openPaySlipUpdator, setOpenPaySlipUpdator] = useState(false);
  const [paySlipFile, setPaySlipFile] = useState();

  const [paySlipFileDetailsForUpload, setPaySlipFileDetailsForUpload] =
    useState();

  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

  const handleOpenPaySlipDisplayer = (ruid) => {
    //console.log(orderId);
    api({
      url: `/GetPaySlipFile/${ruid}`,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      //console.log(response.data);
      setPaySlipFile(fileURL);
      setOpenPaySlipDisplayer(true);
    });
  };

  const handleOpenPaySlipUpdator = () => {
    setOpenPaySlipUpdator(true);
  };

  const handleClosePaySlipDisplayerDialog = () => {
    setOpenPaySlipDisplayer(false);
  };

  const handleClosePaySlipUpdatorDialog = () => {
    setOpenPaySlipUpdator(false);
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setPaySlipFileDetailsForUpload(e.target.files[0]);
    }
  };

  const handleSubmitUploadMemberPaySlip = async (e) => {
    e.preventDefault();
    //console.log("form has been submitted");

    const fd = new FormData();
    fd.append("paySlipFileName", paySlipFileDetailsForUpload.name);
    fd.append("paySlipFile", paySlipFileDetailsForUpload);
    fd.append("ruid", currentLoggedInRUID);

    //console.log(data);
    let config = {
      headers: {
        Accept: "multipart/FormData",

        //  Authorization: varToken
      },
      responseType: "json",
    };

    setOpenProgressBarDialog(true);

    api.post(`/UploadPaySlipFile`, fd, config).then((response) => {
      //console.log(response.data);
      //console.log(response.data);
      setOpenPaySlipUpdator(false);
      setOpenProgressBarDialog(false);
      handleOpenPaySlipDisplayer(currentLoggedInRUID);
    });
  };

  const handleReviewPaySlip = (loanAppId) => {

    setOpenProgressBarDialog(true);
    let config = {
      method: "POST",
      url: `/ReviewPaySlipForLoanApplication/${loanAppId}`,
      headers: {
        Accept: "multipart/FormData",

        //  Authorization: varToken
      },
      responseType: "json",
    };

    //console.log(config);

    api(config).then((response) => {
      //console.log(response.data);
      //console.log(response.data);
      navigate("../ViewYourLoanApplications", { replace: true });
      setOpenProgressBarDialog(false);
    });
  };

  return (
    <div className="ReveiwPaySlipMainDiv">
      <fieldset>
        <legend>Pay Slip uploader</legend>

        <div className="gridRow">
          <div className="FormActionDiv gridCol">
            <input
              type={"button"}
              onClick={() => handleOpenPaySlipUpdator()}
              value="💰 Update Pay Slip"
            />
          </div>
          <div className="FormActionDiv gridCol">
            <input
              type={"button"}
              onClick={() => handleOpenPaySlipDisplayer(currentLoggedInRUID)}
              value="💰 View Pay Slip"
            />
          </div>
        </div>

        <hr />
        <div>
          <h1>After uploading latest payslip click on the review Pay slip for Loan
          Application Button</h1>
        </div>
        <div className="gridRow">
          <br />
          <div className="FormActionDiv gridCol">
            <input
              type={"button"}
              onClick={() => handleReviewPaySlip(data.state.loanApplicationId)}
              value="💰Review Pay slip for Loan Application"
            />
          </div>
        </div>

        {/* Dialog to implement the Progess bar ................ */}
        <Dialog open={openPaySlipDisplayer} fullWidth maxWidth="lg">
          <DialogActions id="scroll-dialog-ActionDialogActions">
            <span className="titleDialogBox">Salary Slip Viewer</span>
            <Button
              variant="outlined"
              onClick={handleClosePaySlipDisplayerDialog}
            >
              ❌
            </Button>
          </DialogActions>
          <DialogContent>
            <div className="pdfDisplayerDialogContentDiv">
              <iframe src={paySlipFile}></iframe>
            </div>
          </DialogContent>
        </Dialog>

        {/* Dialog to implement the Progess bar ................ */}
        <Dialog open={openPaySlipUpdator} fullWidth maxWidth="sm">
          <DialogActions id="scroll-dialog-ActionDialogActions">
            <span className="titleDialogBox">Pay Slip Uploader Dialog</span>
            <Button
              variant="outlined"
              onClick={handleClosePaySlipUpdatorDialog}
            >
              ❌
            </Button>
          </DialogActions>
          <DialogContent>
            <form
              onSubmit={(e) => {
                handleSubmitUploadMemberPaySlip(e);
              }}
            >
              <div className="FormFieldDiv">
                <div>
                  <label>Pay Slip File</label>
                </div>
                <div>
                  <input
                    accept=".pdf"
                    type={"file"}
                    placeholder=" Enter Your Order Date"
                    onChange={(e) => {
                      handleFileChange(e);
                    }}
                    required
                  />
                </div>
              </div>
              <hr />
              <div className="FormActionDiv">
                <input type={"button"} value="Clear Form" />
                <input type={"submit"} value="💾 Save and Upload" />
              </div>
            </form>
          </DialogContent>
        </Dialog>

        {/* Dialog to implement the Progess bar ................ */}
        <Dialog open={openProgressBarDialog} fullWidth maxWidth="sm">
          <DialogTitle id="scroll-dialog-title">
            <b>Salary Slip Uploader </b>
          </DialogTitle>
          <DialogContent>
            <LinearProgress />
          </DialogContent>
        </Dialog>
      </fieldset>
    </div>
  );
};

export default ReviewPaySlipUploader;
