import React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import TimeLine from "../../../generalComponents/TimeLines/TimeLine";
import membershipApplicationVerificationSteps from "./verificationSteps";
import MembershipApplicationsToBeVerified from './MembershipApplicationsToBeVerified/MembershipApplicationsToBeVerified';

import {
  useVerifyMembershipApplicationsState,
} from "./StateForVerifyMembershipApplications";



const VerifyMembershipApplications = () => {

    
  const state = useVerifyMembershipApplicationsState();

  const {
    
    optionsForApprovedMembershipApplications,
    optionsForMembershipApplicationsPaymentVerification,
    optionsForMembershipApplicationsToBeVerified
  } = state;



  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
 

  return (
    <div style={{ display: "flex" }} className='VerifyMembershipApplicationsMainDiv'>
      <div style={{ flex: 1 }}>
        <fieldset className="scrollWithoutScrollBar">
          <legend>📑 Verification of Membership Applications</legend>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Manage Surety Tabs"
              indicatorColor="secondary"
            >
              <Tab
                label="STEP# 1 : Payment Verification"
                {...a11yProps(0)}
              />
              <Tab
                label="STEP# 2: Verify Application Details"
                {...a11yProps(1)}
              />
              <Tab label="STEP# 3: View Approved Members" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div>{/* Applications for Payment Verification component */}
              <MembershipApplicationsToBeVerified applicationStatusOptions ={optionsForMembershipApplicationsPaymentVerification} />
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div>
              {/* Membership Application To be Verified component */}
              <MembershipApplicationsToBeVerified applicationStatusOptions ={optionsForMembershipApplicationsToBeVerified} />
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div>{/* Approved Memberships component */}
              <MembershipApplicationsToBeVerified applicationStatusOptions ={optionsForApprovedMembershipApplications} />
            </div>
          </TabPanel>
        </fieldset>
      </div>
      <div style={{ flex: "0 0 200px", marginLeft: "10px" }}>
        <TimeLine steps={membershipApplicationVerificationSteps} />
      </div>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



export default VerifyMembershipApplications;
