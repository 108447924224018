import React, { useState } from "react";
import DialogContainer from "../../DialogComponents/DialogContainer/DialogContainer";
import ProgressorBarDisplayer from "../../DialogComponents/ProgressorBarDisplayer/ProgressorBarDisplayer";
import useMyAuthService from "../../../../authenticationServiceProvider/useMyAuthService";
import { useNavigate } from "react-router-dom";


const CombinedAuthenticatedPDFUploadModal = ({
  isOpen,
  closeModal,
  onUpload,
  updateType,
}) => {
  const { api, loggedInUserRUID} = useMyAuthService();
  const [selectedFile, setSelectedFile] = useState(null);
  const [paySlipFile, setPaySlipFile] = useState(null);

  const navigate = useNavigate();

  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handlePaySlipFileChange = (event) => {
    const file = event.target.files[0];
    setPaySlipFile(file);
  };

  const handleUpload = (e) => {
    // Implement the logic to upload the combined PDF
    e.preventDefault();
    //console.log("form has been submitted");
    if (selectedFile === null) {
      alert("Please select the file");
    } else {
      const fd = new FormData();
      fd.append("rbMemberRUID", loggedInUserRUID);
      fd.append("membershipFormDocsFileName", selectedFile.name);
      fd.append("membershipFormDocsFile", selectedFile);

      console.log("update type is "+updateType);
      if(updateType === "Review"){
        //this is for the reviewing...........................
        fd.append("updateType", "Review") //documentation over and payment pending
        fd.append("applicationStatus", "MAMR") //documentation over and payment pending
      }else{
        fd.append("updateType", "Original") //documentation over and payment pending
        fd.append("applicationStatus", "MASPP") //documentation over and payment pending

      }
      
      fd.append("paySlipFileName", paySlipFile.name);
      fd.append("paySlipFile", paySlipFile);
      fd.append("ruid", loggedInUserRUID);


      //console.log(data);
      let config = {
        headers: {
          Accept: "multipart/FormData",

          //  Authorization: varToken
        },
        responseType: "json",
      };

      // console.log(fd);
      setOpenProgressBarDialog(true);

     api.post(`/UploadAuthenticatedMembershipDocs`, fd, config).then((response) => {
        // console.log(response.data);
        // console.log(response.data);
        navigate("../View-ManageStatus");
        setOpenProgressBarDialog(false);

      });

      
      setOpenProgressBarDialog(true);

      //uploading the pay slip now........................................
      api
        .post(`/UploadPaySlipFile`, fd, config)
        .then((response) => {
          //console.log(response.data);
          //console.log(response.data);
          setOpenProgressBarDialog(false);
          
        });
    }

    // Call the onUpload callback to close the modal and trigger any further actions
    onUpload();

    navigate("../View-ManageStatus", {replace:true})
  };
  

  return (
    <div>
      <DialogContainer
        openDialogBox={isOpen}
        maxWidth={"sm"}
        handleCloseDialog={closeModal}
        titleMessage="Upload Modal"
      >
        <h2>Upload Combined PDF</h2>
        <p>After authentication and preparation, upload the combined PDF and Pay Slip PDF.</p>

        <table className="uploadFileTable">
          <tr>
            <td>Select Combined PDF (Max Size - 5 MB)</td>
            <td><input type="file" accept=".pdf" onChange={handleFileChange} /></td>
          </tr>
          <tr>
            <td>Select Pay slip file (Max Size - 2 MB)</td>
            <td><input type="file" accept=".pdf" onChange={handlePaySlipFileChange} /></td>
          </tr>

        </table>
        <p></p>
       
       <hr/>

        <input
          type="button"
          onClick={handleUpload}
          disabled={!selectedFile}
          value={"Upload PDF Files"}
        />

       
      </DialogContainer>



       {/* Dialog to implement the Progess bar ................ */}
      <ProgressorBarDisplayer
        titleMessage={"Please wait! work under process ...."}
        openProgressBarDialog={openProgressBarDialog}
      />

    </div>
  );
};

export default CombinedAuthenticatedPDFUploadModal;
