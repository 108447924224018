import React from "react";
import { useEffect } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { formatDate } from "../../../../ComonFunctionsProvider/CommonFunctionsProvider";

const NewMembershipFormTemplate = (memberApp) => {
  useEffect(() => {
    // console.log(
    //   "printing from NewMembershipFormTemplate  " + JSON.stringify(memberApp)
    // );
  }, []);

  const htmlString = renderToStaticMarkup(
    <>
      <div
        id="pdfContent"
        style={{
          fontFamily: "monospace",
          fontSize: "15px",
          border: "2px solid Black",
          padding: "5px",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <h2>
            <u>Railway Board Co-operative Thrift & Credit Society Ltd.</u>
          </h2>
          <h3>
            <u>APPLICATION FOR MEMBERSHIP</u>
          </h3>
        </div>
        <table border="0" style={{ width: "100%" }}>
          <tr>
            <td>
              <p className="address">
                The Hon'ble Secretary,
                <br />
                Railway Board Co-operative Thrift & Credit Society Ltd.,
                <br />
                Room No. 415-C, Rail Bhavan,
                <br />
                New Delhi.
              </p>
            </td>
            <td>
              <div
                style={{
                  border: "2px solid #333", // Set border width and color
                  padding: "5px", // Set padding around the image
                  width: "120px", // Set a fixed width for the container
                  height: "150px",
                }}
              >
                {/* Image to be pasted here by the User */}
              </div>
            </td>
          </tr>
        </table>

        <div className="section">
          <p>
            <strong>Dear Sir,</strong>{" "}
          </p>
          <p style={{ textAlign: "justify" }}>
            1. I, <u>{memberApp.rbmembers.name}</u>, hereby apply for admission
            as a member of the society. My date of appointment in Board's office
            is <u>{formatDate(memberApp.rbmembers.dateOfAppointment)}</u>.
          </p>
        </div>

        <div className="section">
          <p style={{ textAlign: "justify" }}>
            2. I have carefully read the rules and bye-laws of the Society and{" "}
            hereby agree to abide by them. I certify that I am not a member of
            any other Co-operative Thrift & Credit Society.
          </p>
        </div>

        <div className="section">
          <p style={{ textAlign: "justify" }}>
            3. I am confident that the Managing Committee will allot me 10
            shares of Rs. 1000 deposited by me in Cash in the Society office on
            approval of my membership.
          </p>
        </div>

        <div className="section">
          <p style={{ textAlign: "justify" }}>
            4. I also nominate persons, as listed in the Annexure attached with
            the Application Form, to whom my dues in the society may be paid in
            the event of my death.
          </p>
        </div>

        <div className="section">
          <p style={{ textAlign: "justify" }}>
            5. I agree to pay Rs.{" "}
            <u>{memberApp.rbmembers.gradePay >= 4600 ? 1500 : 1000}</u> of
            monthly compulsory deposit. per mensums or us decided by the society
            from time to time on account of monthly compulsory deposit.
          </p>
        </div>

        <div className="section">
          <p style={{ textAlign: "justify" }}>
            6. I authorise my Disbursing Officer, the Pay & Accounts Officer,{" "}
            Railway Board, New Delhi to deduct from my pay, monthly dues of the{" "}
            society and remit the same to you.
          </p>
        </div>

        <table border="0" style={{ width: "100%", padding: "2px" }}>
          <tr>
            <td>7. Date of Retirement:</td>
            <td>
              <u>{formatDate(memberApp.rbmembers.dateOfRetirement)}</u>
            </td>
            <td rowSpan="3" style={{ textAlign: "right" }}>
              <u>Your faithfully,</u>
            </td>
          </tr>
          <tr>
            <td>8. Pan No:</td>
            <td>
              <u>{memberApp.rbmembers.panNo}</u>
            </td>
          </tr>
          <tr>
            <td>9. RUID:</td>
            <td>
              <u>{memberApp.rbmembers.rbMemberRUID}</u>
            </td>
          </tr>
          <tr>
            <td>10. Date of Birth:</td>
            <td>
              <u>{formatDate(memberApp.rbmembers.dateOfBirth)}</u>
            </td>
            <td rowSpan="2" style={{ textAlign: "right" }}>
              <u>Signature of Applicant </u>
            </td>
          </tr>
          <tr>
            <td>11. Mob. No:</td>
            <td>
              <u>{memberApp.rbmembers.users.mobile}</u>
            </td>
          </tr>
        </table>
        <hr />

        <div className="section">
          <p style={{ textAlign: "justify" }}>
            Name in full <u>{memberApp.rbmembers.name}</u> Father's Name{" "}
            <u>{memberApp.rbmembers.fatherName}</u> Designation{" "}
            <u>{memberApp.rbmembers.designation}</u> Branch{" "}
            <u>{memberApp.rbmembers.branch}</u> Born on{" "}
            <u>{formatDate(memberApp.rbmembers.dateOfBirth)}</u> Home Address in
            office <u>{memberApp.rbmembers.homeAddress}</u>{" "}
          </p>
        </div>

        <div className="section">
          <p style={{ textAlign: "justify" }}>
            <strong>
              Certify that Shri/Ms/Mrs. <u>{memberApp.rbmembers.name}</u> is a
              permanent/temporary employee in the office of the Ministry of
              Railways (Railway Board), New Delhi. His/her date of appointment
              in this office is{" "}
              <u>{formatDate(memberApp.rbmembers.dateOfAppointment)}</u>.
            </strong>
          </p>
        </div>
        <br />
        <br />
        <table border="0" style={{ width: "100%" }}>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ textAlign: "right" }}>Section Officer</td>
          </tr>
          <tr>
            <td>Dated:</td>
            <td>
              <u>{formatDate(memberApp.dateOfMembershipApplication)}</u>
            </td>

            <td></td>
            <td style={{ textAlign: "right" }}>ERB-I/II/V Railway Board</td>
          </tr>
        </table>
      </div>
      <br />
      <div
        className="section"
        id="page-break"
        style={{
          fontFamily: "monospace",
          fontSize: "15px",
          //border: "2px solid Black",
          padding: "5px",
        }}
      >
        <p style={{ textAlign: "right" }}>Annexure- Nominees List</p>

        <div style={{ textAlign: "center" }}>
          <h3>
            <u>
              Nomination for Railway Board Co-operative Thrift & Credit Society
              Ltd.
            </u>
          </h3>
        </div>

        <div className="section">
          <p style={{ textAlign: "justify" }}>
            I do nominate persons, as listed under, to whom my dues in the society may be paid in
            the event of my death.
          </p>
        </div>

        <table
          border="1"
          style={{
            borderCollapse: "collapse",
            width: "100%",
            textAlign: "center",
          }}
        >
          <tr>
            <th>Sl.</th>
            <th>Nomminee Name</th>
            <th>Relation with Member</th>
            <th>Nominee DOB</th>
            <th>Amount payable (%)</th>
            <th>Nominee Heir Details</th>
          </tr>
          {memberApp.rbmembers.nominees.map((nominee, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{nominee.name}</td>
                <td>{nominee.relationWithMember}</td>
                <td>{formatDate(nominee.dateOfBirth)}</td>
                <td>{nominee.amountPayable}</td>
                <td>
                  {nominee.nomineeHeirName},{" "}
                  {nominee.nomgitineeHeirRelationWithMember} of {memberApp.rbmembers.name} and
                  Born on {formatDate(nominee.nomineeHeirDOB)}
                </td>
              </tr>
            );
          })}
        </table>

        <br /><br />
        <div>
          <table border="0" style={{ width: "100%" }}>
            <tr>
              <td>
                Dated {formatDate(memberApp.dateOfMembershipApplication)}{" "}
                at RailBhawan
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td><br/></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                {'Witness Signatures'}
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td><br/></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                {'Witness #1'}
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td><br/></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td colSpan={2}><strong>{'Signature of Member'}</strong><br/></td>
              <td></td>
            </tr>
            <tr>
              <td>
                {'Witness #2'}
              </td>
              <td></td>
              <td>{'Name'}</td>
              <td><u>{memberApp.rbmembers.name}</u></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>{'Designation'}</td>
              <td><u>{memberApp.rbmembers.designation}</u></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>{'RUID No.'}</td>
              <td><u>{memberApp.rbmembers.rbMemberRUID}</u></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>{'Home Address'}</td>
              <td><u>{memberApp.rbmembers.homeAddress}</u></td>
            </tr>
            
          </table>
        </div>
      </div>
    </>
  );

  return htmlString;
};

export default NewMembershipFormTemplate;
