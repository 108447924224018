import React from "react";
import "./CreateNewAdminDetails.css";

import { useState, useEffect } from "react";

import LinearProgress from "@mui/material/LinearProgress";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMyAuthService from "../../../../authenticationServiceProvider/useMyAuthService";



let createAdminForm = {
  adminId:" ",
  ruid: " ",
  designation: " ",
  approvalType: " ",
  electedDate: " ",
  tenureInYears: " ",
  branchRoomNo: " ",
  railwayTelNo: " ",
  currentStatus: " ",
};

function CreateNewAdminDetails() {

  const {loggedInUserRUID, api} = useMyAuthService();// logged in user is the staff........

  /** The data elements of the form to be stored in these variables */
  const [createAdminFormData, setCreateAdminFormData] =
    useState(createAdminForm);


  const [errorMessage, setErrorMessage] = useState();
  const [message, setMessage] = useState();
  const [usersList, setUsersList] = useState({});

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);


  useEffect(() => {
    getUsersList();
  }, []);


  const getUsersList = () => {


  api.get(`/getUsersList`)
      .then((response) => {
        //console.log(response.data);
        setUsersList(response.data);
        setIsLoadingData(false);
        //console.log(regData);
      });
  };


  const setAdminRUIDForCreatingAdmin = () =>{
    let ruid = createAdminFormData.ruid;
    let year = createAdminFormData.electedDate.split("-");
    let adminType = createAdminFormData.approvalType.split("-");
    let adminId = adminType[1]+year[0]+ruid;
    
    //console.log(adminId);
    createAdminFormData.adminId = adminId;
   

  } 


  async function handleSubmitCreateAdmin(e) {
    e.preventDefault();
    //console.log("form has been submitted");
    // console.log(createAdminFormData);

    if (
      createAdminFormData.ruid === " " ||
      createAdminFormData.electedDate === " " ||
      createAdminFormData.railwayTelNo === " " ||
      createAdminFormData.designation === " " ||
      createAdminFormData.approvalType === " " ||
      createAdminFormData.branchRoomNo === " " ||
      createAdminFormData.tenureInYears === " "
    ) {
      setErrorMessage("Please Enter all the details for proceeding further");
      setMessage("");
    } else {

      
      //function for setting the admin id to generate the custom id..... 
      setAdminRUIDForCreatingAdmin();

      // the user has filled all the details correctly............

      createAdminFormData.currentStatus = "Active";
      
      const data = createAdminFormData;

     // console.log(data);

      let config = {
        method: "POST",
        url: "/CreateNewAdminDetails",
        data: { data },
        headers: {
          Accept: "application/json",

          //  Authorization: varToken
        },
        responseType: "json",
      };

      setOpenProgressBarDialog(true);
      const response = await api(config);
      //console.log(response.data);
      setMessage(response.data);
      setErrorMessage("");
      setOpenProgressBarDialog(false);
    }
  }

  return (
    <div className="AdminCreationMainDiv">
      <fieldset>
        <legend>Create a ADMIN Details for RBCTCS </legend>
        {isLoadingData ? (
          <div className="isLoadingDiv">Loading Data .....</div>
        ) : (
          <>
            <div className="errorDiv">{errorMessage}</div>
            <div className="msgDiv">{message}</div>
            <form
              onSubmit={(e) => {
                handleSubmitCreateAdmin(e);
              }}
            >
              <div className="gridRow">
                <div className="gridCol">
                  <div className="FormFieldDiv">
                    <div>
                      <label>RUIDs</label>
                    </div>
                    <input
                      list="rbMembers"
                      name="rbMemberList"
                      id="rbMemberList"
                      onChangeCapture={(e) =>
                        setCreateAdminFormData({
                          ...createAdminFormData,
                          ruid: e.target.value,
                        })
                      }
                      placeholder="Select a RUID"
                    />
                    <datalist id="rbMembers">
                      {usersList.map((member, index) => {
                        return (
                          <option key={index} value={member.rbMemberRUID}>
                            {member.name}
                          </option>
                        );
                      })}
                    </datalist>
                  </div>

                  <div className="FormFieldDiv">
                    <div>
                      <label>Railway Tel No.</label>
                    </div>
                    <div>
                      <input
                        type={"number"}
                        name="railwayTelNo"
                        placeholder="Enter the Railway Tel No "
                        value={createAdminFormData.railwayTelNo}
                        onChange={(e) =>
                          setCreateAdminFormData({
                            ...createAdminFormData,
                            railwayTelNo: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="FormFieldDiv">
                    <div>
                      <label>Date When Elected</label>
                    </div>
                    <div>
                      <input
                        type={"date"}
                        name="doaAdmin"
                        placeholder="Enter the DOA "
                        value={createAdminFormData.electedDate}
                        onChange={(e) =>
                          setCreateAdminFormData({
                            ...createAdminFormData,
                            electedDate: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="gridCol">
                  <div className="FormFieldDiv">
                    <div>
                      <label>Designation</label>
                    </div>
                    <div>
                      <select
                        onChange={(e) => {
                          setCreateAdminFormData({
                            ...createAdminFormData,
                            designation: e.target.value,
                          });
                        }}
                      >
                        <option value=" ">Select Designation</option>
                        <option value="President">President</option>
                        <option value="Vice-President">Vice-President</option>
                        <option value="Hony. Secretary">Hony. Secretary</option>
                        <option value="Joint Secretary">Joint Secretary</option>
                        <option value="Treasurer">Treasurer</option>
                        <option value="Executive Member">
                          Executive Member
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="FormFieldDiv">
                    <div>
                      <label>Branch Room No</label>
                    </div>
                    <div>
                      <input
                        type={"number"}
                        name="branchRoom"
                        placeholder="Enter the Office Room No "
                        value={createAdminFormData.branchRoomNo}
                        onChange={(e) =>
                          setCreateAdminFormData({
                            ...createAdminFormData,
                            branchRoomNo: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="gridCol">
                  <div className="FormFieldDiv">
                    <div>
                      <label>Approval Type</label>
                    </div>
                    <div>
                      <select
                        name="approvalType"
                        placeholder="Enter Approval Type "
                        value={createAdminFormData.approvalType}
                        onChange={(e) =>
                          setCreateAdminFormData({
                            ...createAdminFormData,
                            approvalType: e.target.value,
                          })
                        }
                        required
                      >
                        <option value=" ">Select Approval Type</option>
                        <option value="Admin-AA">Admin - Approval Authority</option>
                        <option value="Admin-OB">
                          Admin - Office Bearer
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="FormFieldDiv">
                    <div>
                      <label>Tenure In Years</label>
                    </div>
                    <div>
                      <input
                        type={"number"}
                        name="salaryBank"
                        placeholder="Enter the Salary Bank "
                        value={createAdminFormData.tenureInYears}
                        onChange={(e) =>
                          setCreateAdminFormData({
                            ...createAdminFormData,
                            tenureInYears: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="gridCol"></div>
              </div>

              <div className="FormActionDiv">
                <input type={"button"} value="Clear Form" />
                <input type={"submit"} value="Create Admin for RBCTCS" />
              </div>
            </form>

            {/* Dialog to implement the Progess bar ................ */}
            <Dialog open={openProgressBarDialog} fullWidth maxWidth="sm">
              <DialogTitle id="scroll-dialog-title">
                <b>Please wait! work under process ....</b>
              </DialogTitle>
              <DialogContent>
                <LinearProgress />
              </DialogContent>
            </Dialog>
          </>
        )}
      </fieldset>
    </div>
  );
}

export default CreateNewAdminDetails;
