import React from 'react'

const LoanPrePaymentPostingForm = ({ selectedItem }) => {
    return (
      <form className="submitPaymentRequestMainDiv">
        <div className="gridRow">
          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Request Payment Id:</label>
              </div>
              <div>
                <input type="text" value={selectedItem.paymentRequestId} readOnly />
              </div>
            </div>
          </div>
  
          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Payment Mode:</label>
              </div>
              <div>
                <input type="text" value={selectedItem.paymentMode} readOnly />
              </div>
            </div>
          </div>
          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Transaction No./Cheque No.</label>
              </div>
              <div>
                <input type="text" value={selectedItem.tranRefNoOrChequeNo} readOnly />
              </div>
            </div>
          </div>
        </div>
  
        <hr />
        <div className="FormActionDiv">
          <input type="button" value="Clear Form" />
          <input type="submit" value="💾 Save and Upload" />
        </div>
      </form>
    );
  };

export default LoanPrePaymentPostingForm