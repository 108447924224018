import "./FillChequeDetailsForDisbursal.css";

import React from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import useMyAuthService from "../../../../../authenticationServiceProvider/useMyAuthService";
import ProgressorBarDisplayer from "../../../DialogComponents/ProgressorBarDisplayer/ProgressorBarDisplayer";

const disbursementChequeForm = {
  chequeNo: 0,
  chequeDate: "",
  chequeAmount: 0,
  chequeAccountNo: "10211841032",
};

const FillChequeDetailsForDisbursal = () => {
  const { loggedInUserRUID, api } = useMyAuthService();
  const [ruidLoggedIn, setRUIDLoggedIn] = useState(loggedInUserRUID);

  const data = useLocation();
  const loansForDisbursal = data.state.loanApplicationsForDisbursal;
  //console.log(loansForDisbursal)

  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);
  const [chequeForm, setChequeForm] = useState(disbursementChequeForm);

  const navigate = useNavigate();

  const handleClickSaveAndSubmitLDR = async () => {
    // Object.keys(chequeForm).map(([key, value])
    //     (key === null || key === " ")?alert("Please Fill the Cheque Details"):("")
    // )
    // Code to make the loans as active loans from the date of disbusrsal...........

    //console.log(selectedMember.membership_applications.applicationId);
    var today = new Date(),
      approvalDate =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();

    //console.log(data);
    let config = {
      method: "PUT",
      url: `/UpdateLoanApplicationOnDisbursal`,
      data: {
        staffId: ruidLoggedIn,
        approvalStatus: "LAAD",
        dateOfApproval: today,
        loansForDisbursal: loansForDisbursal,
        chequeForm: chequeForm,
      },
      headers: {
        Accept: "application/json",

        //  Authorization: varToken
      },
      responseType: "json",
    };
    //console.log(config);
    setOpenProgressBarDialog(true);
    const response = await api(config);
    setOpenProgressBarDialog(false);
    //  console.log(response.data);

    navigate("/StaffRBCTCSDashBoard/LoanDisbursementReport/LDRFinal", {
      state: {
        loanApplicationsForDisbursal: loansForDisbursal,
        chequeForm: chequeForm,
      },
    });
  };

  return (
    <>
      {/* {JSON.stringify(loansForDisbursal)} */}
      <div className="LDRDirections">
        <p>
          Please review the selected loans and Click on the Submit button to
          save the disburment details and view the Loan Disbursement Report.
        </p>
      </div>
      <div>
        <table className="LDRContentTable">
          <thead>
            <tr>
              <td>Sl No.</td>
              <td>Loan ID</td>
              <td>Type</td>
              <td>Status</td>
              <td>Name/ RUID</td>
              <td>Desgn/ Branch</td>
              <td>L/F No.</td>
              <td>Bank </td>
              <td>Account No.</td>
              <td>IFSC</td>
              <td>Amount</td>
            </tr>
          </thead>
          <tbody>
            {Object.keys(loansForDisbursal).map((key, index) => {
              return loansForDisbursal[key].isChecked === true ? (
                <tr key={key}>
                  <td>{index + 1} </td>
                  <td>{loansForDisbursal[key].loanApplicationId}</td>
                  <td>{loansForDisbursal[key].loanType}</td>
                  <td>{loansForDisbursal[key].applicationStatus}</td>
                  <td>
                    {loansForDisbursal[key].rbmembers.name} (
                    {loansForDisbursal[key].rbmembers.rbMemberRUID})
                  </td>
                  <td>
                    {loansForDisbursal[key].rbmembers.designation}/
                    {loansForDisbursal[key].rbmembers.branch}
                  </td>
                  <td>{loansForDisbursal[key].rbmembers.ledgerNo}</td>
                  <td>{loansForDisbursal[key].rbmembers.salaryBankName}</td>
                  <td>
                    {loansForDisbursal[key].rbmembers.salaryBankAccountNo}
                  </td>
                  <td>{loansForDisbursal[key].rbmembers.salaryBankIFSC}</td>
                  <td>{loansForDisbursal[key].loanAmountSought}</td>
                </tr>
              ) : (
                ""
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="fillChequeDetailsDiv">
        <div className="gridCol">
          <div className="gridRow">
            <div className="FormFieldDiv">
              <div>
                <label>Account No</label>
              </div>
              <div>
                <input
                  type={"text"}
                  value={chequeForm.chequeAccountNo}
                  readOnly
                />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Cheque No.</label>
              </div>
              <div>
                <input
                  type={"text"}
                  value={chequeForm.chequeNo}
                  onChange={(e) =>
                    setChequeForm({
                      ...chequeForm,
                      chequeNo: e.target.value,
                    })
                  }
                  placeholder=""
                />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Cheque Date</label>
              </div>
              <div>
                <input
                  type={"date"}
                  value={chequeForm.chequeDate}
                  onChange={(e) =>
                    setChequeForm({
                      ...chequeForm,
                      chequeDate: e.target.value,
                    })
                  }
                  placeholder=""
                />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Cheque Amount</label>
              </div>
              <div>
                <input
                  type={"text"}
                  value={chequeForm.chequeAmount}
                  onChange={(e) =>
                    setChequeForm({
                      ...chequeForm,
                      chequeAmount: e.target.value,
                    })
                  }
                  placeholder=""
                />
              </div>
            </div>
            <div className="FormActionDiv">
              <button type="Submit" onClick={handleClickSaveAndSubmitLDR}>
                {" "}
                💾 Save & Submit
              </button>
            </div>
          </div>
          <div className="gridRow"></div>
        </div>
      </div>

      {/* Dialog to implement the Progess bar ................ */}
      <ProgressorBarDisplayer
        titleMessage={"Please wait! Loan Disbursals under process...."}
        openProgressBarDialog={openProgressBarDialog}
      />
    </>
  );
};

export default FillChequeDetailsForDisbursal;
