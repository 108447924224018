import React, { useState } from "react";
import ProgressorBarDisplayer from "../../ProgressorBarDisplayer/ProgressorBarDisplayer";
import useMyAuthService from "../../../../../authenticationServiceProvider/useMyAuthService";

const SharesPurchasePaymentPostingForm = ({
  selectedItem,
  handleDialogClose,
  setDataUpdateRender,
  staffId
}) => {


  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

  const [formData, setFormData] = useState({
    // Initialize form fields and their default values
    // Example: name: ''
    ...selectedItem,
   staffRUID:staffId
  });

 
  const {api} =useMyAuthService();

  const handleChange = (e) => {//////////handling the onChange event on all the inputs
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission logic here
    // Example: Submit the form data to an API endpoint

    // Check if the Payment Status is set or not
    if (formData.status === "Payment Submitted") {
      alert("Please Select the Payment Status");
      return 0;
    }

   
    console.log("Form submitted:", formData);

    //Since the form data is set start progrress bar for posting the payment details on the server

    // Close the dialog after submission
    handleDialogClose();

    const url = `/PostSharesPurchasePaymentDetails`;

    let config = {
      headers: {
        Accept: "multipart/FormData",
        //  Authorization: varToken
      },
      responseType: "json",
    };

    setOpenProgressBarDialog(true);

   await api.post(url, formData, config).then((response) => {
      // console.log(response);
      // console.log(response.data);
      setDataUpdateRender((data)=>!data)
      setOpenProgressBarDialog(false);
    });
  };

  

  return (
    <div className="submitPaymentRequestMainDiv">
      <form onSubmit={handleSubmit}>
        <div className="gridRow">
          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Request Payment Id:</label>
              </div>
              <div>
                <input type="text" value={formData.paymentRequestId} readOnly />
              </div>
            </div>
          </div>

          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Payment Mode:</label>
              </div>
              <div>
                <input type="text" value={formData.paymentMode} readOnly />
              </div>
            </div>
          </div>

          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Transaction No./Cheque No.</label>
              </div>
              <div>
                <input
                  type="text"
                  value={formData.tranRefNoOrChequeNo}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>

        <div className="gridRow">
          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>RB Member RUID</label>
              </div>
              <div>
                <input type="text" value={formData.rbMemberRUID} readOnly />
              </div>
            </div>
          </div>

          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Request Type</label>
              </div>
              <div>
                <input type="text" value={formData.requestType} readOnly />
              </div>
            </div>
          </div>

          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Request Date</label>
              </div>
              <div>
                <input type="text" value={formData.requestDate} readOnly />
              </div>
            </div>
          </div>
        </div>

        <div className="gridRow">
          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Payment Amount</label>
              </div>
              <div>
                <input type="text" value={formData.paymentAmount} readOnly />
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="gridRow">
          {/* <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Addmission Fees</label>
              </div>
              <div>
                <input name="admissionFees" type="text" onChange={(e) => handleChange(e)} required />
              </div>
            </div>
          </div>

          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Benevolent Fund</label>
              </div>
              <div>
                <input name="benevolentFund" type="text" onChange={(e) => handleChange(e)} required />
              </div>
            </div>
          </div> */}

          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Payment Status</label>
              </div>
              <div>
                <select
                  id="status-select"
                  name="status"
                  onChange={(e) => handleChange(e)}
                >
                  <option value="Payment Accepted">
                    Select Payment Status
                  </option>
                  <option value="Payment Accepted">Payment Accepted</option>
                  <option value="Payment Rejected">Payment Rejected</option>
                </select>
              </div>
            </div>
          </div>

          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Shares Amount</label>
              </div>
              <div>
                <input
                  name="sharesAmount"
                  type="text"
                  onChange={(e) => handleChange(e)}
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <div className="gridRow">
          {/* <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Compulsory Deposit</label>
              </div>
              <div>
                <select
                  name="cdAmount"
                  //   value={formData.compulsoryDeposit}
                  onChange={(e) => handleChange(e)}
                  required
                >
                  <option>Select CD Amount</option>
                  <option value={"1000"}>
                    1000, if Grade-Pay less than 4600
                  </option>
                  <option value={"1500"}>
                    1500, if Grade-Pay equal to 4600
                  </option>
                  <option value={"1500"}>
                    1500, if Grade-Pay more than 4600
                  </option>
                </select>
              </div>
            </div>
          </div> */}
        </div>

        <hr />
        <div className="FormActionDiv">
          <input type="button" value="Clear Form" />
          <input type="submit" value="💾 Save and Upload" />
        </div>
      </form>

      {/* Dialog to display to the Payment Receipt/Cheque Uploaded */}
      <ProgressorBarDisplayer
        titleMessage={"Saving the Payment Details"}
        openProgressBarDialog={openProgressBarDialog}
        // setOpenProgressBarDialog={setOpenProgressBarDialog}
      />
    </div>
  );
};

export default SharesPurchasePaymentPostingForm