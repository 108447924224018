import React, { useEffect, useState } from "react";
import "./PaymentDetailsUpdator.css";


import LinearProgress from "@mui/material/LinearProgress";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { DialogActions } from "@mui/material";
import { Button } from "@mui/material";

import useMyAuthService from "../../../../authenticationServiceProvider/useMyAuthService";

import OpenDocumentDisplayer from "../../DialogComponents/OpenDocumentDisplayer/OpenDocumentDisplayer";
import ChequeOnlineReceiptUploader from "../../DialogComponents/ChequeOnlineReceiptUploader/ChequeOnlineReceiptUploader";

import { useLocation } from 'react-router-dom';

const paymentFormFields = {
  noOfShares: 10,
  shareAmount: 1000,
  admissionFees: 50,
  benevolentFund: 60,
  compulsoryDeposit: " ",
  beneficieryName: "RBCTCS",
  beneficieryAccountNo: "10211841032",
  beneficieryIFSCCode: "SBIN00003771",
  totalAmount: 0,
};

//let  = " ";

const PaymentDetailsUpdator = () => {

    const location = useLocation();
    const memberApp = location.state; // Access the passed props

    const [chequeOrTransactionRefNo, setChequeOrTransactionRefNo] =
    useState(null);

  const [paymentForm, setPaymentFormFieldValues] =
    React.useState(paymentFormFields);

   const {loggedInUserRUID, api} = useMyAuthService();
  const [currentLoggedInRUID, setCurrentLoggedInRUID] = useState(
    loggedInUserRUID
  );

  const [openPaySlipDisplayer, setOpenPaySlipDisplayer] = useState(false);
  const [
    openChequeOnlineReceiptDisplayer,
    setOpenChequeOnlineReceiptDisplayer,
  ] = useState(false);
  const [docFile, setDocFile] = useState();

  const [openPaySlipUpdator, setOpenPaySlipUpdator] = useState(false);
  const [paySlipFileDetailsForUpload, setPaySlipFileDetailsForUpload] =
    useState();

  const [openChequeOnlineReceiptUpdator, setOpenChequeOnlineReceiptUpdator] =
    useState(false);

  const [
    isMembershipApplicationSubmitted,
    setIsMembershipApplicationSubmitted,
  ] = useState(false);
  const [
    isPaymentRequestForMemmbershipMade,
    setIsPaymentRequestForMembershipMade,
  ] = useState(false);
  const [paymentReqData, setPaymentReqData] = useState([]);

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);
  const [isLoadingCheque, setIsLoadingCheque] = useState(false);

 //getting the XML resources..........................
 useEffect(() => {
  doesMembershipApplicationExist();
  getPaymentRequestForNewMembership();
}, [isLoadingCheque]);


const doesMembershipApplicationExist = () => {
  const RUID = currentLoggedInRUID;
  api.get(`/DoesMembershipApplicationExist/${RUID}`)
  .then((response) => {
    //console.log(response.data);
    if(response.data === 1){
      setIsMembershipApplicationSubmitted(true);
    }
    if(response.data === 0){
      setIsMembershipApplicationSubmitted(false);
    }

  });
}

const getPaymentRequestForNewMembership = () => {
    const RUID = currentLoggedInRUID;
    api.get(`/GetPaymentRequestForNewMembership/${RUID}`)
    .then((response) => {
      //console.log("Payyment Reqeust data: "+response.data.paymentRequestId);
      setPaymentReqData(response.data);
    });
};

  const setCDAmountAndChequeAmount = (cdAmount) => {
    // console.log(cdAmount);
    let Amount =
      parseInt(paymentForm.shareAmount) +
      parseInt(paymentForm.admissionFees) +
      parseInt(paymentForm.benevolentFund) +
      parseInt(cdAmount);

    //console.log(typeof parseInt(Amount));
    //setting the CD amount and total amount to be paid vide cheque...........
    setPaymentFormFieldValues({
      ...paymentForm,
      compulsoryDeposit: cdAmount,
      totalAmount: Amount,
    });
    // console.log(paymentForm.compulsoryDeposit);
  };

  const handleOpenPaySlipDisplayer = () => {
    const RUID = currentLoggedInRUID; 
    api({
      url: `/GetPaySlipFile/${RUID}`,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      //console.log(response.data);
      setDocFile(fileURL);
      setOpenChequeOnlineReceiptDisplayer(false);
      setOpenPaySlipDisplayer(true);
    });
  };

  const handleOpenChequeOnlineReceiptDisplayer = () => {
    let paymentRequestId = paymentReqData.paymentRequestId;
    //console.log(paymentReqData);
    api({
      url: `/GetChequeOnlineReceiptFile`, 
        params: {
         paymentRequestId: paymentRequestId,
         // requestType: "NMFP",
        },
      method: "GET",
      responseType: "blob", // important
      // data:{
      //   RUID:currentLoggedInRUID,
      //   refNo:
      // }
    }).then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      //console.log(response.data);
      setDocFile(fileURL);
      setOpenPaySlipDisplayer(false);
      setOpenChequeOnlineReceiptDisplayer(true);
    });
  };

  const handleOpenPaySlipUpdator = () => {
    setOpenPaySlipUpdator(true);
  };

  const handleClosePaySlipUpdatorDialog = () => {
    setOpenPaySlipUpdator(false);
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setPaySlipFileDetailsForUpload(e.target.files[0]);
    }
  };

  const handleSubmitUploadSalarySlip = async (e) => {
    e.preventDefault();
    //console.log("form has been submitted");

    const fd = new FormData();
    fd.append("paySlipFileName", paySlipFileDetailsForUpload.name);
    fd.append("paySlipFile", paySlipFileDetailsForUpload);
    fd.append("ruid", currentLoggedInRUID);

    //console.log(data);
    let config = {
      headers: {
        Accept: "multipart/FormData",

        //  Authorization: varToken
      },
      responseType: "json",
    };

    setOpenProgressBarDialog(true);

    api
      .post(`/UploadPaySlipFile`, fd, config)
      .then((response) => {
        //console.log(response.data);
        //console.log(response.data);
        setOpenPaySlipUpdator(false);
        setOpenProgressBarDialog(false);
        // handleOpenPaySlipDisplayer(currentLoggedInRUID);
      });
  };

  return (
    <div className="paymentDetailsMainDiv">
      <fieldset>
        <legend>
          🖥️ Upload Your Salary-Slip and Membership Fees Payment Details
        </legend>
        <div className="gridRow"></div>

        {/* row 2 starts............. */}
        <div className="gridRow">
          <div className="FormFieldDiv">
            <div>
              <label>Beneficiery Name </label>
            </div>
            <div>
              <input
                type={"text"}
                value={paymentForm.beneficieryName}
                readOnly
              />
            </div>
          </div>

          <div className="FormFieldDiv">
            <div>
              <label>Beneficiery Account No.</label>
            </div>
            <div>
              <input
                type={"text"}
                value={paymentForm.beneficieryAccountNo}
                readOnly
              />
            </div>
          </div>

          <div className="FormFieldDiv">
            <div>
              <label>Beneficiery IFSC Code</label>
            </div>
            <div>
              <input
                type={"text"}
                value={paymentForm.beneficieryIFSCCode}
                readOnly
              />
            </div>
          </div>
        </div>

        {/* row 3 starts............. */}
        <div className="gridRow">
          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>No of Shares</label>
              </div>
              <div>
                <input
                  type={"text"}
                  value={paymentForm.noOfShares}
                  placeholder="Shares to be purchased"
                  readOnly
                />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>CD Amount</label>
              </div>
              <div>
                <select
                  name="CDAmounts"
                  value={paymentForm.compulsoryDeposit}
                  onChange={(e) => setCDAmountAndChequeAmount(e.target.value)}
                  required
                >
                  <option>Select CD Amount</option>
                  <option value={"1000"}>
                    1000, if Grade-Pay less than 4600
                  </option>
                  <option value={"1500"}>
                    1500, if Grade-Pay equal to 4600
                  </option>
                  <option value={"1500"}>
                    1500, if Grade-Pay more than 4600
                  </option>
                </select>
              </div>
            </div>
            <div>
              {chequeOrTransactionRefNo !== null
                ? "Cheque/Online Receipt with the Reference No./Cheque No. " +
                  chequeOrTransactionRefNo +
                  " saved successfully"
                : ""}
              {/* 'Cheque/Online Receipt couldn\'t be saved successfully' */}
            </div>
          </div>
          <div className="gridCol"></div>
          <div className="gridCol">
            <table className="paymentTable">
              <thead>
                <tr>
                  <td>Sl.</td>
                  <td>Head</td>
                  <td>Amount</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Addmission Fees</td>
                  <td>{paymentForm.admissionFees}</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Benevolent Fund</td>
                  <td>{paymentForm.benevolentFund}</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Shares Amount</td>
                  <td>{paymentForm.shareAmount}</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>compulsoryDeposit</td>
                  <td>{paymentForm.compulsoryDeposit}</td>
                </tr>
                <tr>
                  <td colSpan={2}>Total Amount</td>
                  <td>{paymentForm.totalAmount}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* row 3 ends................. */}
        <hr></hr>
        {isMembershipApplicationSubmitted ? (
          <>
            <div className="gridRow">
              <div className="FormActionDiv gridCol">
                <ChequeOnlineReceiptUploader
                  openChequeOnlineReceiptUpdator={
                    openChequeOnlineReceiptUpdator
                  }
                  setOpenChequeOnlineReceiptUpdator={
                    setOpenChequeOnlineReceiptUpdator
                  }
                  RUID={currentLoggedInRUID}
                  setChequeOrTransactionRefNo={setChequeOrTransactionRefNo}
                  setOpenProgressBarDialog={setOpenProgressBarDialog}
                  requestType={"NMFP"}
                  setIsLoadingCheque = {setIsLoadingCheque}
                  originalOrUpdate = {'Update'}
                  applicationId = {memberApp.applicationId}
                  applicationStatus = {'MASRP'} //membership Application submitted with revised payment
                />
                
              </div>
              <div className="FormActionDiv gridCol">
                <input
                  type={"button"}
                  onClick={() => handleOpenChequeOnlineReceiptDisplayer()}
                  value={"💰 View Uploaded Cheque/Online Receipt"}
                />
                <OpenDocumentDisplayer
                  docTitle={"View Uploaded Cheque/Online Receipt"}
                  docFile={docFile}
                  openDocDisplayer={openChequeOnlineReceiptDisplayer}
                  setOpenDocDisplayer={setOpenChequeOnlineReceiptDisplayer}
                />
              </div>
            </div>
            <div className="gridRow">
              <div className="FormActionDiv gridCol">
                <input
                  type={"button"}
                  onClick={() => handleOpenPaySlipUpdator()}
                  value="💰 Upload Pay Slip"
                />
              </div>
              <div className="FormActionDiv gridCol">
                <input
                  type={"button"}
                  onClick={() => handleOpenPaySlipDisplayer()}
                  value="💰 View Pay Slip"
                />
                <OpenDocumentDisplayer
                  docTitle={"View Pay Slip"}
                  docFile={docFile}
                  openDocDisplayer={openPaySlipDisplayer}
                  setOpenDocDisplayer={setOpenPaySlipDisplayer}
                />
              </div>
            </div>
          </>
        ) : (
          <h3 align="justify">
            Please submit the Membership Application before Uploading the following documents:-
             <ul>
              <li>Payment Cheque/Online Receipt Details of the amount as in the table above </li>
              <li>Latest Salary Slip</li>
            </ul>
           </h3>
        )}

        {/* Dialog to implement the Pay slip Uploader ................ */}
        <Dialog open={openPaySlipUpdator} fullWidth maxWidth="sm">
          <DialogActions id="scroll-dialog-ActionDialogActions">
            <span className="titleDialogBox">Pay Slip Uploader Dialog</span>
            <Button
              variant="outlined"
              onClick={handleClosePaySlipUpdatorDialog}
            >
              ❌
            </Button>
          </DialogActions>
          <DialogContent>
            <form
              onSubmit={(e) => {
                handleSubmitUploadSalarySlip(e);
              }}
            >
              <div className="FormFieldDiv">
                <div>
                  <label>Pay Slip File</label>
                </div>
                <div>
                  <input
                    accept=".pdf"
                    type={"file"}
                    placeholder=" Enter Your Order Date"
                    onChange={(e) => {
                      handleFileChange(e);
                    }}
                    required
                  />
                </div>
              </div>
              <hr />
              <div className="FormActionDiv">
                <input type={"button"} value="Clear Form" />
                <input type={"submit"} value="💾 Save and Upload" />
              </div>
            </form>
          </DialogContent>
        </Dialog>

        {/* Dialog to implement the Progess bar ................ */}
        <Dialog open={openProgressBarDialog} fullWidth maxWidth="sm">
          <DialogTitle id="scroll-dialog-title">
            <b>Document being uploaded ......... </b>
          </DialogTitle>
          <DialogContent>
            <LinearProgress />
          </DialogContent>
        </Dialog>
      </fieldset>
    </div>
  );
};


export default PaymentDetailsUpdator