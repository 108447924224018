import React from 'react';
import { Outlet } from "react-router-dom";



const MiscPaymentsManager = () => {

  return (
    <div className='ledgerReportMemberWiseMainDiv'>
      <fieldset className="scrollWithoutScrollBar">
        <legend>📑 Misc. Payments Manager</legend>
        <Outlet></Outlet>
      </fieldset>
    </div> 
    )
}

export default MiscPaymentsManager


