import "./ManageLoanSureties.css";
import React from 'react';
import { Outlet } from "react-router-dom";

const ManageLoanSureties = () => {

  return (
    <div className="tabPanelMainDiv">
      <fieldset className="scrollWithoutScrollBar">
        <legend>📑 Loan Sureties Manager</legend>
        <Outlet></Outlet>
      </fieldset>
    </div>
  );
}

export default ManageLoanSureties

