import { useContext } from 'react';
import { AuthContext } from './authServiceProvider';

export const useTokenRevocation = () => {
  const { revokeToken } = useContext(AuthContext);

  const handleTokenRevocation = () => {
    return revokeToken()
      .then(() => {
        // Token revocation successful, perform necessary actions
         return "Success";
      })
      .catch(error => {
        // Handle token revocation error
        console.error("Token Revocation failed:", error);
        return "Failed";
      });
  };

  return handleTokenRevocation;
};


