import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import useMyAuthService from "../../../../authenticationServiceProvider/useMyAuthService";
import DialogContainer from "../../../generalComponents/DialogComponents/DialogContainer/DialogContainer";
import ProgressorBarDisplayer from "../../../generalComponents/DialogComponents/ProgressorBarDisplayer/ProgressorBarDisplayer";

function LoanApprovals() {
  const { api, loggedInUserRUID } = useMyAuthService();

  const [ruidLoggedIn, setRUIDLoggedIn] = useState(loggedInUserRUID);
  const [LoanSuretyDetailsData, setLoanSuretyDetailsData] = useState({});
  const [LoanDetails, setLoanDetails] = useState([]);
  const [SelectedLoanApplication, setSelectedLoanApplication] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [approvalType, setApprovalType] = useState("");
  const [applicationStatus, setApplicationStatus] = useState("LADV");
  const [approvalStatus, setApprovalStatus] = useState("LADV");

  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [updatedForReRender, setUpdatedForReRender] = useState(false);

  const [adminRBCTCSDetails, setAdminRBCTCSDetails] = useState({});
  const [staffRBCTCSDetails, setStaffRBCTCSDetails] = useState({});
  const [adminName, setAdminName] = useState();

  const [openAdminDisplayDialog, setOpenAdminDisplayDialog] = useState(false);
  const [openStaffDisplayDialog, setOpenStaffDisplayDialog] = useState(false);

  const markForReviewMsg = "Select For Correction";

  useEffect(() => {
    getApplicationStatus();
  }, [updatedForReRender, applicationStatus]);

  const getApplicationStatus = () => {
    api
      .get(`/ViewLoanApplicationsForApproval/${applicationStatus}`)
      .then((response) => {
        setLoanDetails(response.data);
        setIsLoadingData(false);
      });
  };

  ////////////  Surety details/..................................
  let SuretyTableRow = null;
  let suretiesDataArray = Object.values(LoanSuretyDetailsData);
  //console.log(LoanSuretyDetailsData);
  if (suretiesDataArray !== undefined) {
    SuretyTableRow = suretiesDataArray.map((Surety, index) => {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{Surety.rbmembers.rbMemberRUID} </td>
          <td>{Surety.rbmembers.name} </td>
          <td>{Surety.rbmembers.designation} </td>
          <td>{Surety.rbmembers.branch} </td>
          {/* <td>{Surety.rbmembers.email} </td> */}
          <td>{Surety.rbmembers.railwayTelNo} </td>
          <td>{Surety.suretyRequestStatus} </td>
          {/* <td>{suretiesDataArray.length} </td> */}
        </tr>
      );
    });
  }

  //action menu for the Approval............................of the applications................start.....
  const [anchorActionsMenu, setAnchorActionsMenu] = useState(null);
  const open = Boolean(anchorActionsMenu);
  const handleClick = (event, Loan) => {
    // console.log(Loan);p
    setSelectedLoanApplication(Loan);
    setLoanSuretyDetailsData(Loan.loan_sureties_by_loan_id);
    //console.log(SelectedLoanApplication);
    setAnchorActionsMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorActionsMenu(null);
  };
  //action menu for the Approval of the applications............................................end

  //function for open and close of the Dialog Box....................................starts
  const [openLoanDetailsInApplication, setOpenLoanDetailsInApplication] =
    React.useState(false);
  const [
    openLoanSuretyDetailsInApplication,
    setOpenLoanSuretyDetailsInApplication,
  ] = React.useState(false);
  const [openApplicationApproval, setOpenApplicationApproval] =
    React.useState(false);

  const handleClickOpenLoanDetailsInApplication = (rbMemberRUID) => () => {
    //console.log(rbMemberRUID);
    setAnchorActionsMenu(null);
    setOpenLoanDetailsInApplication(true);
  };

  const handleClickOpenLoanSuretyDetailsInApplication = () => {
    setAnchorActionsMenu(null);
    setOpenLoanSuretyDetailsInApplication(true);
  };

  const handleClickOpenApplicationApproval =
    (applicationId, approvalType) => () => {
      if (
        SelectedLoanApplication.approverAdminID != null ||
        SelectedLoanApplication.approverAdmin2ID != null
      ) {
        if (
          SelectedLoanApplication.approverAdminID ===
          SelectedLoanApplication.approverAdmin2ID
        ) {
          //console.log(SelectedLoanApplication.approverAdminID);
          //console.log(SelectedLoanApplication.approverAdmin2ID);
          alert("Approver and Counter Approver cannot be the same");
          return;
        }
      }

      switch (approvalType) {
        case "approval":
          setApprovalType("Approval");

          SelectedLoanApplication.approverAdmin2ID !== null
            ? setApprovalStatus("LAA")
            : setApprovalStatus("LAPA");

          break;
        case "counter approval":
          setApprovalType("Counter Approval");

          SelectedLoanApplication.approverAdminID !== null
            ? setApprovalStatus("LAA")
            : setApprovalStatus("LAPA");
          break;
        case "unapproval":
          setApprovalType("Withdraw Your Approval");
          SelectedLoanApplication.applicationStatus === "LAA"
            ? setApprovalStatus("LAPA")
            : setApprovalStatus("LAS");
          break;
        case "counter unapproval":
          setApprovalType("Withdraw Your Counter Approval");
          SelectedLoanApplication.applicationStatus === "LAA"
            ? setApprovalStatus("LAPA")
            : setApprovalStatus("LAS");
          break;

        default:
          break;
      }

      setAnchorActionsMenu(null);
      setOpenApplicationApproval(true);
    };

  const handleCloseLoanDetailsInApplication = () => {
    setOpenLoanDetailsInApplication(false);
  };

  const handleCloseLoanSuretyDetailsInApplication = () => {
    setOpenLoanSuretyDetailsInApplication(false);
  };

  const handleCloseApplicationApproval = () => {
    setOpenApplicationApproval(false);
  };

  const handleCloseAdminDisplayDialog = () => {
    setOpenAdminDisplayDialog(false);
  };

  const handleCloseStaffDisplayDialog = () => {
    setOpenStaffDisplayDialog(false);
  };

  //function for open and close of the Dialog Box....................................ends

  const handleSubmitLoanApplicationApproval = async () => {
    //console.log(selectedMember.membership_applications.applicationId);
    const urlForApproval =
      SelectedLoanApplication.loanType === "ML" ||
      SelectedLoanApplication.loanType === "EL"
        ? `/LoanApplicationApprovalOk`
        : `/TopUpLoanApplicationApprovalOk`;

    const data = {
      LoanApplication: SelectedLoanApplication,
      //oldLoanAppId:,
      adminRUID: ruidLoggedIn,
      applicationStatus: approvalStatus,
      approvalType: approvalType,
      loanType: SelectedLoanApplication.loanType,
      rbMemberLedgerNo: SelectedLoanApplication.rbMemberLedgerNo,
    };

    // console.log(data);
    if (ruidLoggedIn === SelectedLoanApplication.rbMemberRUID) {
      alert("Sorry! You cannot Approve Your Own Loan Application.");
      return 0;
    }

    let config = {
      method: "PUT",
      url: urlForApproval,
      data: { data },
      headers: {
        Accept: "application/json",

        //  Authorization: varToken
      },
      responseType: "json",
    };
    // console.log(config);
    setOpenProgressBarDialog(true);
    const response = await api(config);
   // console.log(response.data);
    setOpenApplicationApproval(false);
    setOpenProgressBarDialog(false);
    setUpdatedForReRender((updatedForReRender) => !updatedForReRender);
  };

  //fucntions for the accordian..................
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const displayAdminDetails = (e, adminId) => {
    api.get(`/getAdminRBCTCSDetailsByAdminId/${adminId}`).then((response) => {
      // console.log(response.data)
      setAdminRBCTCSDetails(response.data);
      setAdminName(response.data.users.name);
      setOpenAdminDisplayDialog(true);
    });
  };

  const displayStaffDetails = (e, staffId) => {
    api.get(`/ViewRBCTCSStaffDetails/${staffId}`).then((response) => {
      //console.log(response.data);
      setStaffRBCTCSDetails(response.data[0]);
      setOpenStaffDisplayDialog(true);
    });
  };

  const handleChangeApplicationsStatusForApproval = (status) => {
    // console.log(status);
    setApplicationStatus(status);
  };

  return (
    // Div to show the applications table for the incoming Approval request of the Loans................
    <div className="VerifyLoanApplicationsMainDiv">
      <fieldset>
        <legend>📜 New Loan Applications For Approval by the ADMINs</legend>
        {/* {LoanDetails.map((Loan) => {
          return JSON.stringify(Loan);
        })} */}
        <div className="FilterApplicationsDiv">
          <select
            onChange={(e) =>
              handleChangeApplicationsStatusForApproval(e.target.value)
            }
          >
            <option value="LADV">Loan Application Details Verified</option>
            <option value="LAPA">Loan Application Partially Approved </option>
            <option value="LAA">Loan Application Approved</option>
            <option value="LAS">Loan Application Submitted</option>
            <option value="LAMR">Loan Application Marked For Review</option>
            <option value="LARS">Loan Application Re-Submitted</option>
          </select>
        </div>
        {isLoadingData ? (
          <div className="isLoadingDiv">Loading Data ..... </div>
        ) : (
          <table className="LoanApplicationStatusTable centerContent">
            <thead>
              <tr>
                <td>Sl No.</td>
                <td>Ledger No</td>
                <td>Name</td>
                <td>Desgn.</td>
                <td>Loan Type</td>
                <td>Application Date</td>
                <td>Amount Sought</td>
                <td>Application status</td>
                <td>Verified By</td>
                <td>Approved By</td>
                <td>Counter Approved By</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {LoanDetails.map((Loan, index) => {
                return (
                  <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>{Loan.rbMemberLedgerNo}</td>
                    <td className="justifyContent">{Loan.rbmembers.name}</td>
                    <td>{Loan.rbmembers.designation}</td>
                    <td>{Loan.loanType}</td>
                    <td>{Loan.dateOfApplication}</td>
                    <td>{Loan.loanAmountSought}</td>
                    <td>{Loan.applicationStatus}</td>
                    <td>
                      {Loan.verifierStaffID ? (
                        <Button
                          variant="outlined"
                          onClick={(e) => {
                            displayStaffDetails(e, Loan.verifierStaffID);
                          }}
                        >
                          {Loan.verifierStaffID}
                        </Button>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {Loan.approverAdminID ? (
                        <Button
                          variant="outlined"
                          onClick={(e) => {
                            displayAdminDetails(e, Loan.approverAdminID);
                          }}
                        >
                          {Loan.approverAdminID}
                        </Button>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {Loan.approverAdmin2ID ? (
                        <Button
                          variant="outlined"
                          onClick={(e) => {
                            displayAdminDetails(e, Loan.approverAdmin2ID);
                          }}
                        >
                          {Loan.approverAdmin2ID}
                        </Button>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {
                        <Button
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={(e) => handleClick(e, Loan)}
                        >
                          ⚙️
                        </Button>
                      }{" "}
                      {/* this is the actions menu activated on the button click to take actions on a particular selected Loan */}
                      <Menu
                        anchorEl={anchorActionsMenu}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          onClick={handleClickOpenLoanDetailsInApplication(
                            SelectedLoanApplication.rbMemberRUID
                          )}
                        >
                          🔍 View Loan & Member Details
                        </MenuItem>
                        <MenuItem
                          onClick={
                            handleClickOpenLoanSuretyDetailsInApplication
                          }
                        >
                          🔍 View Surety Details
                        </MenuItem>
                        {SelectedLoanApplication.approverAdminID === null ? (
                          <MenuItem
                            onClick={handleClickOpenApplicationApproval(
                              SelectedLoanApplication.loanApplicationId,
                              "approval"
                            )}
                          >
                            ✔️ Approve Details
                          </MenuItem>
                        ) : (
                          ""
                        )}
                        {SelectedLoanApplication.approverAdmin2ID === null ? (
                          <MenuItem
                            onClick={handleClickOpenApplicationApproval(
                              SelectedLoanApplication.loanApplicationId,
                              "counter approval"
                            )}
                          >
                            ✔️ Counter Approve Details
                          </MenuItem>
                        ) : (
                          ""
                        )}
                        {/* {
                         (SelectedLoanApplication.approverAdminID !== null) 
                         ?(
                          <MenuItem onClick={handleClickOpenApplicationApproval(SelectedLoanApplication.loanApplicationId, "unapproval")}>
                            🔙 Withdraw-Approval
                          </MenuItem>
                         ):("")
                      }
                      {
                         (SelectedLoanApplication.approverAdmin2ID !== null) 
                         ?(
                          <MenuItem onClick={handleClickOpenApplicationApproval(SelectedLoanApplication.loanApplicationId, "counter unapproval")}>
                            🔙 Withdraw-Counter Approval
                          </MenuItem>
                         ):("")
                      } */}
                      </Menu>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </fieldset>

      {/* Dialog to display Staff Details ................ */}
      <DialogContainer
        titleMessage={"Staff RBCTCS Details Dialog"}
        openDialogBox={openStaffDisplayDialog}
        maxWidth="sm"
        handleCloseDialog={handleCloseStaffDisplayDialog}
      >
        <div className="adminDisplayDiv">
          <table>
            <tbody>
              <tr>
                <td>Staff ID:</td>
                <td>{staffRBCTCSDetails.staffRBCTCSId}</td>
              </tr>
              <tr>
                <td>Shri/Smt./Ms</td>
                <td>{staffRBCTCSDetails.name}</td>
              </tr>
              <tr>
                <td>Designation</td>
                <td>{staffRBCTCSDetails.designation}/RBCTCS</td>
              </tr>
            </tbody>
          </table>
        </div>
      </DialogContainer>

      {/* Dialog to display Admin Details ................ */}
      <DialogContainer
        titleMessage={"ADMIN RBCTCS Details Dialog"}
        openDialogBox={openAdminDisplayDialog}
        maxWidth="sm"
        handleCloseDialog={handleCloseAdminDisplayDialog}
      >
        <div className="adminDisplayDiv">
          <table>
            <tbody>
              <tr>
                <td>RUID:</td>
                <td>{adminRBCTCSDetails.adminRBCTCSRUID}</td>
              </tr>
              <tr>
                <td>Shri/Smt./Ms</td>
                <td>{adminName}</td>
              </tr>
              <tr>
                <td>Designation</td>
                <td>{adminRBCTCSDetails.designationRBCTCS}/RBCTCS</td>
              </tr>
            </tbody>
          </table>
        </div>
      </DialogContainer>

      {/* Dialog to implement the Progess bar ................ */}
      <ProgressorBarDisplayer
        titleMessage={"Please wait! Approving the Loan Applications ...."}
        openProgressBarDialog={openProgressBarDialog}
      />

      {/* Dialog to implement the Approval logic for the submitted application ................ */}
      <DialogContainer
        titleMessage={"Approval of Loan Application"}
        openDialogBox={openApplicationApproval}
        maxWidth="sm"
        handleCloseDialog={handleCloseApplicationApproval}
      >
        <div className="finalApprovalSubmitDialogDiv">
          <p>
            If the details of the application are succesfully verified, please
            click on apprval ok button.
          </p>
          <div className="FormActionDiv">
            <button onClick={handleSubmitLoanApplicationApproval}>
              👌 {approvalType} OK
            </button>
          </div>
        </div>
      </DialogContainer>

      {/* Dialog to view the other society details of the Loans............................ */}
      <DialogContainer
        titleMessage={"Loan Application Surety Details"}
        openDialogBox={openLoanSuretyDetailsInApplication}
        maxWidth="md"
        handleCloseDialog={handleCloseLoanSuretyDetailsInApplication}
      >
        <div className="societyDetailsTableDiv">
          <table className="societyDetailsTable" border="1">
            <thead>
              <tr>
                <td>Sl.</td>
                <td>Surety RUID</td>
                <td>Surety Name</td>
                <td>Designation</td>
                <td>Branch</td>
                <td>Railway Tel No.</td>
                <td>Surety Status</td>
                {/* <td>Mobile No.</td>
                  <td>No of Sureties Given</td> */}
              </tr>
            </thead>
            <tbody>{SuretyTableRow}</tbody>
          </table>
        </div>
      </DialogContainer>

      {/* Dialog to view the details of the Loan............................. */}
      <DialogContainer
        titleMessage={"Loan Application Details"}
        openDialogBox={openLoanDetailsInApplication}
        maxWidth="sm"
        handleCloseDialog={handleCloseLoanDetailsInApplication}
      >
        <fieldset>
          <legend>🪟 Loan Application Preview</legend>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <span> Member Details </span>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                {/* Personal Details here */}
                {SelectedLoanApplication.rbmembers !== undefined ? (
                  <table className="LoanApplicationStatusTable">
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td>Shri {SelectedLoanApplication.rbmembers.name}</td>
                      </tr>
                      <tr>
                        <td>Ledger No</td>
                        <td>{SelectedLoanApplication.rbmembers.ledgerNo}</td>
                      </tr>
                      <tr>
                        <td>Date: Appointment/Retirement</td>
                        <td>
                          {SelectedLoanApplication.rbmembers.dateOfAppointment}/
                          {SelectedLoanApplication.rbmembers.dateOfRetirement}
                        </td>
                      </tr>
                      <tr>
                        <td>Designation/Branch</td>
                        <td>
                          {SelectedLoanApplication.rbmembers.designation}/
                          {SelectedLoanApplication.rbmembers.branch}
                        </td>
                      </tr>
                      <tr>
                        <td>Grade Pay/Pay Level/ Basic Pay</td>
                        <td>
                          {SelectedLoanApplication.rbmembers.gradePay}/
                          {SelectedLoanApplication.rbmembers.payLevel}/
                          {SelectedLoanApplication.rbmembers.basicPay}
                        </td>
                      </tr>
                      <tr>
                        <td>Contact No</td>
                        <td>
                          {SelectedLoanApplication.rbmembers.railwayTelNo}
                        </td>
                      </tr>
                      <tr>
                        <td>Current Outstanding</td>
                        <td>
                          {SelectedLoanApplication.rbmembers.currentOutstanding}
                        </td>
                      </tr>
                      <tr>
                        <td>Total Shares / Compulsory Deposit</td>
                        <td>
                          {SelectedLoanApplication.rbmembers.totalShareAmount}/
                          {SelectedLoanApplication.rbmembers.compulsoryDeposit}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  ""
                )}
              </div>
              <div className="detailseditbuttondiv"></div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <span>Loan Details</span>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                {/* {official details here)} */}
                <table className="LoanApplicationStatusTable">
                  <tbody>
                    <tr>
                      <td>Loan Type</td>
                      <td>
                        {SelectedLoanApplication.loanType === "ML"
                          ? "Main Loan"
                          : "Emergent Loan"}
                      </td>
                    </tr>
                    <tr>
                      <td>Loan Amount Sought</td>
                      <td>{SelectedLoanApplication.loanAmountSought}</td>
                    </tr>
                    <tr>
                      <td>Loan Purpose</td>
                      <td>{SelectedLoanApplication.loanPurpose}</td>
                    </tr>
                    <tr>
                      <td>No Of Installments</td>
                      <td>{SelectedLoanApplication.noOfInstallments}</td>
                    </tr>
                    <tr>
                      <td>Date of First Installment</td>
                      <td>{SelectedLoanApplication.dateOfFirstInstallment}</td>
                    </tr>
                    <tr>
                      <td>Rate Of Interest</td>
                      <td>{SelectedLoanApplication.branch}</td>
                    </tr>
                    <tr>
                      <td>Bond #1 is signed</td>
                      <td>
                        {SelectedLoanApplication.isBondOneSigned === 1
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>
                    <tr>
                      <td>Bond #2 is signed</td>
                      <td>
                        {SelectedLoanApplication.isBondTwoSigned === 1
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="detailseditbuttondiv">
                {/* {personalForm.map} */}
              </div>
            </AccordionDetails>
          </Accordion>
        </fieldset>
      </DialogContainer>
    </div>
  );
}

export default LoanApprovals;
