import React, {useState} from "react";
import Button from "@mui/material/Button";

import { formatDate } from "../../../../ComonFunctionsProvider/CommonFunctionsProvider";

import "../TableDataDisplayer.css"
import PaginationNumberRendering from "../PaginationNumberRendering/PaginationNumberRendering";


const LoanApplicationsTable = ({
  loanApplicationDetails, //details of all the membership applications
  displayStaffDetails,
  displayAdminDetails,
  handleClickOpenMenu,
  openMenu,
}) => {

  const toCamelCase = (str) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+|-|_/g, "");
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPage = 15; // Number of items to display per page

  const maxPageNumbersToShow = 5; // Adjust the number of page numbers to show

  // Calculate the total number of pages
  const totalPages = Math.ceil(loanApplicationDetails.length / itemsPerPage);

  // Get the current page's items based on the currentPage and itemsPerPage
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = loanApplicationDetails
    .filter((loanApp) =>
      Object.values(loanApp)
        .join(" ")
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    )
    .slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle search query change
  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page when search query changes
  };

  


  return (
    <div className="table-data-displayer">
      <div className="quickSearchBoxDiv">
        <input
          type="text"
          placeholder="Search RUID..."
          value={searchQuery}
          onChange={handleSearchQueryChange}
        />
      </div>

      <table className="dataDisplayerTable centerContent">
        <thead>
          <tr>
            <td>Sl.</td>
            <td>Ledger No</td>
            <td>Name, Desgn.</td>
            <td>CD Amount</td>
            <td>Appl. Date</td>
            <td>Loan Type</td>
            <td>Appl. status</td>
            <td>Verifier</td>
            <td>Approver</td>
            <td>Counter Approver</td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((Loan, index) => {
            return (
              <tr key={index + 1}>
                <td>{index + 1}</td>
                <td>{Loan.rbMemberLedgerNo}</td>
                <td className="justifyContent">
                  {Loan.rbmembers.name}, {Loan.rbmembers.designation}
                </td>
                <td>{Loan.rbmembers.compulsoryDeposit}</td>
                <td>{formatDate(Loan.dateOfApplication)}</td>
                <td>{Loan.loanType ==="ML" || Loan.loanType ==="AML"?("Main Loan"):("Emergent Loan")}</td>
                <td>{Loan.applicationStatus}</td>
                <td>
                  {Loan.verifierStaffID ? (
                    <Button
                      variant="outlined"
                      onClick={(e) => {
                        displayStaffDetails(e, Loan.verifierStaffID);
                      }}
                    >
                      {Loan.verifierStaffID}
                    </Button>
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  {Loan.approverAdminID ? (
                    <Button
                      variant="outlined"
                      onClick={(e) => {
                        displayAdminDetails(e, Loan.approverAdminID);
                      }}
                    >
                      {Loan.approverAdminID}
                    </Button>
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  {Loan.approverAdmin2ID ? (
                    <Button
                      variant="outlined"
                      onClick={(e) => {
                        displayAdminDetails(e, Loan.approverAdmin2ID);
                      }}
                    >
                      {Loan.approverAdmin2ID}
                    </Button>
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  <Button
                    id="basic-button"
                    aria-controls={openMenu ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? "true" : undefined}
                    onClick={(e) => handleClickOpenMenu(e, Loan)}
                  >
                    ⚙️
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Pagination */}
      <PaginationNumberRendering 
          maxPageNumbersToShow={maxPageNumbersToShow}
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default LoanApplicationsTable;
