import "./MemberOtherSocietyDetailsViewer.css";

import React, { useEffect, useState } from "react";


import useMyAuthService from "../../../../authenticationServiceProvider/useMyAuthService";


function MemberOtherSocietyDetailsViewer() {

  const {loggedInUserRUID, api} = useMyAuthService();
  const [currentLoggedInRUID, setCurrentLoggedInRUID] = useState(loggedInUserRUID);
  const [rbMemberOtherSocietyData, setRBMemberOtherSocietyDetails] = useState({});

  const [isLoadingData, setIsLoadingData] = useState(true);

  useEffect(() => {
    getRBMemberBankDetails();
  }, []);

  const getRBMemberBankDetails = () => {
    api
      .get(`/getRBMemberOtherSocietyDetails/${currentLoggedInRUID}`
      )
      .then((response) => {
        //setIsLoadingData(true);
        setRBMemberOtherSocietyDetails(response.data.other_societies);
        //console.log(regData);
        setIsLoadingData(false)
      });
  };


  return (
    <div className='OtherSocietyDetailsViewerMainDiv'>
        <fieldset>
          <legend>🔍 View Your OtherSociety Details</legend>
          {isLoadingData?(<div className="isLoadingDiv">Loading Data .....</div>):(
            <div className="gridRow">
            <div className="gridCol">
              {
                (rbMemberOtherSocietyData.isOtherSocietyMember === 0)?("You are not Member of any other Society"):
                (
                  <table>
                    <thead>
                      <tr>
                        <td>Sl. No.</td>
                        <td>Society Name</td>
                        <td>Society Mobile No.</td>
                        <td>Society Email Id</td>
                        <td>Society Address</td>
                        <td>Your Indebtedness</td>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        Object.keys(rbMemberOtherSocietyData).map((key)=>{
                           return (
                             <tr key={key}>
                               <td>{key+1}</td>
                               <td>
                                 {rbMemberOtherSocietyData[key].societyName}
                               </td>
                               <td>
                                 {
                                   rbMemberOtherSocietyData[key].societyMobileNo
                                 }
                               </td>
                               <td>
                                 {
                                   rbMemberOtherSocietyData[key].societyEmailId
                                 }
                               </td>
                               <td>
                                 {
                                   rbMemberOtherSocietyData[key].societyAddress
                                 }
                               </td>
                               <td>
                                 {
                                   (rbMemberOtherSocietyData[key].pivot.memberIndebtednessAmount === null)?(0):(rbMemberOtherSocietyData[key].pivot.memberIndebtednessAmount)
                                 }
                               </td>
                             </tr>
                           );
                        })
                      }
                      
                    </tbody>
                  </table>
                )
              }
              
            </div>
            
          </div>
          )}
          
        </fieldset>
    </div>
  )
}

export default MemberOtherSocietyDetailsViewer