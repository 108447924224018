import { useState, createContext, useEffect } from "react";
import axios from "axios";
import { useGetAPIServer } from "../BackEndServerServiceProvider/BackEndServerServiceProvider";

// Create a context for authentication-related data and methods
export const AuthContext = createContext(null);

// Authentication Service Provider component to manage authentication state
export const AuthServiceProvider = ({ children }) => {
  
  const apiServer = useGetAPIServer();

  // State variables to store authentication-related data
  const [authToken, setAuthToken] = useState(localStorage.getItem("token"));
  const [loggedInUserRUID, setLoggedInUserRUID] = useState(null);
  const [loggedInUserRole, setLoggedInUserRole] = useState(null);
  const [loggedInUserName, setLoggedInUserName] = useState(null);

  
  // Create an axios instance with the base URL and authorization header
  const api = axios.create({
    baseURL: apiServer.serverBaseRoute,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

  // Function to create a new token and set user data on successful login
  const createToken =  async(userId, userPassword, userRole) => {

    return  await axios
      .post(`${apiServer.serverBaseRoute}/loginToRBCTCS`, { userId, userPassword, userRole })
      .then((response) => {

        //console.log("This has been received from the Server:-  ");
       // console.log(response);

        const { token, refresh_token, user_Id, user_RUID, user_role, user_name } = response.data;

        //console.log("token not received......."+token);

        if(token === undefined){
           return "token is not being received from the server"; // means error at server...
        }

        if(response.status !== 200){
            return response; // Invalid Credentials..
        }

        // Store the token and user data in local storage and state variables
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refresh_token);

        setAuthToken(token);
        setLoggedInUserRUID(user_RUID);
        setLoggedInUserRole(user_role);
        setLoggedInUserName(user_name);

        //return "Logged In Successfully";
      })
      .catch((error) => {
        //console.error("Error creating token:", error);
           // Handle specific error status codes
          
        throw error; // Re-throw the error to reject the Promise
       
           
      });
  };

  // Function to revoke the token and log the user out
  const revokeToken = async () => {
    try {
      await api.post(`/logoutFromRBCTCS`);// thsi goes to the authenticated route 

      // Clear the token and user data from local storage and state variables
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      setAuthToken(null);
      setLoggedInUserRUID(null);
      setLoggedInUserRole(null);
      setLoggedInUserName(null);
      
    } catch (error) {
      console.error("Error revoking token:", error);
    }
  };

  // Function to refresh the access token when it expires
  const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem("refreshToken");

    try {
      const response = await axios.post(`${apiServer.serverBaseRoute}/refreshTokenForRBCTCS`, {
        refresh_token: refreshToken,
      });

      const newAccessToken = response.data.access_token;

      // Update the token in local storage and state variable
      localStorage.setItem("token", newAccessToken);
      setAuthToken(newAccessToken);

      return newAccessToken;
    } catch (error) {
      console.error("Error refreshing token:", error);
      throw error;
    }
  };

  // Update the authorization header when the token changes
  useEffect(() => {
    api.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
  }, [ authToken]);

  // Return the authentication context provider with authentication-related data and methods
  return (
    <AuthContext.Provider
      value={{
        loggedInUserRUID,
        loggedInUserRole,
        loggedInUserName,
        api,
        createToken,
        revokeToken,
        refreshAccessToken,
        
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
