import React from 'react';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';

import "./authServiceProvider";
import useMyAuthService  from './useMyAuthService';

const RouteProtector = ({children}) => {

  const navigate = useNavigate();
  const myAuthService = useMyAuthService();
  //const location = useLocation();

  if(myAuthService.loggedInUserRUID === null){
    // navigate("/", { replace: true });
      return <Navigate to='/'></Navigate>
  }

  return children;
}

export default RouteProtector