import React from "react";
import "./AccountsHeadCreation.css";

import { useState } from "react";
import axios from "axios";
import { useGetAPIServer } from "../../../../BackEndServerServiceProvider/BackEndServerServiceProvider";

let createAccountHeadForm = {
  headName: " ",
  headCode: " ",
  headType: " ",
  headNature: " ",
  headOpeningBalance: " ",
  headClosingBalance: " ",
  headDescription: " ",
};

const AccountsHeadCreation = () => {
 
  const apiServer = useGetAPIServer();

  /** The data elements of the form to be stored in these variables */
  const [createAccountHeadFormData, setCreateAccountHeadFormData] = useState(
    createAccountHeadForm
  );
  const [errorMessage, setErrorMessage] = useState();
  const [message, setMessage] = useState();

  async function handleSubmit(e) {
    e.preventDefault();
    //console.log("form has been submitted");
    // console.log(createStaffFormData);

    //  if(createStaffFormData.staffSign ==="" && createStaffFormData.staffPhoto ==="" ){
    //     setErrorMessage("Please Upload the Photo and Signatures for proceeding further" );
    //  }else{

    const data = createAccountHeadFormData;
    //console.log(data);
    let config = {
      method: "POST",
      url: `${apiServer.serverBaseRoute}/CreateNewAccountHead`,
      data: { data },
      headers: {
        Accept: "application/json",

        //  Authorization: varToken
      },
      responseType: "json",
    };

    const response = await axios(config);
    //console.log(response.data);
    setMessage(response.data);

    // }
  }

  return (
    <div className="AccountsHeadCreationMainDiv">
      <fieldset>
        <legend>Create a Accounts Head </legend>
        <div className="errorDiv">{errorMessage}</div>
        <div className="msgDiv">{message}</div>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="gridRow">
            <div className="gridCol">
              <div className="FormFieldDiv">
                <div>
                  <label>Head Name</label>
                </div>
                <div>
                  <input
                    type={"text"}
                    name="headName"
                    placeholder="Enter the Head Name "
                    value={createAccountHeadFormData.headName}
                    onChange={(e) =>
                      setCreateAccountHeadFormData({
                        ...createAccountHeadFormData,
                        headName: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
              <div className="FormFieldDiv">
                <div>
                  <label>Head Code</label>
                </div>
                <div>
                  <input
                    type={"text"}
                    name="headCode"
                    placeholder="Enter the Head Code "
                    value={createAccountHeadFormData.headCode}
                    onChange={(e) =>
                      setCreateAccountHeadFormData({
                        ...createAccountHeadFormData,
                        headCode: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
              <div className="FormFieldDiv">
                <div>
                  <label>Head Type</label>
                </div>
                <div>
                  <select
                    type={"text"}
                    name="headType"
                    placeholder="Enter the Head Type "
                    value={createAccountHeadFormData.headType}
                    onChange={(e) =>
                      setCreateAccountHeadFormData({
                        ...createAccountHeadFormData,
                        headType: e.target.value,
                      })
                    }
                    required
                  >
                    <option>Debit</option>
                    <option>Credit</option>
                  </select>
                </div>
              </div>
              <div className="FormFieldDiv">
                <div>
                  <label>Head Nature</label>
                </div>
                <div>
                  <select
                    type={"text"}
                    name="headNature"
                    placeholder="Enter the Head Nature "
                    value={createAccountHeadFormData.headNature}
                    onChange={(e) =>
                      setCreateAccountHeadFormData({
                        ...createAccountHeadFormData,
                        headNature: e.target.value,
                      })
                    }
                    required
                  >
                    <option>Asset</option>
                    <option>Liability</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="gridCol">
              <div className="FormFieldDiv">
                <div>
                  <label>Opening Balance</label>
                </div>
                <div>
                  <input
                    type={"number"}
                    name="openingBalance"
                    placeholder="Enter the Opening Balance "
                    value={createAccountHeadFormData.headOpeningBalance}
                    onChange={(e) =>
                      setCreateAccountHeadFormData({
                        ...createAccountHeadFormData,
                        headOpeningBalance: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
              <div className="FormFieldDiv">
                <div>
                  <label>Closing Balance</label>
                </div>
                <div>
                  <input
                    type={"number"}
                    name="closingBalance"
                    placeholder="Enter the Closing Balance "
                    value={createAccountHeadFormData.headClosingBalance}
                    onChange={(e) =>
                      setCreateAccountHeadFormData({
                        ...createAccountHeadFormData,
                        headClosingBalance: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
              <div className="FormFieldDiv">
                <div>
                  <label>Head Description</label>
                </div>
                <div>
                  <textarea
                    rows={7}
                    cols={32}
                    name="headDescription"
                    placeholder="Enter the Head Description"
                    value={createAccountHeadFormData.headDescription}
                    onChange={(e) =>
                      setCreateAccountHeadFormData({
                        ...createAccountHeadFormData,
                        headDescription: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="FormActionDiv">
            <input type={"button"} value="Clear Form" />
            <input type={"submit"} value="Create Head" />
          </div>
        </form>
      </fieldset>
    </div>
  );
};

export default AccountsHeadCreation;
