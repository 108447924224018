import "./MemberBankDetailsViewer.css";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useMyAuthService  from "../../../../authenticationServiceProvider/useMyAuthService";

function MemberBankDetailsViewer() {

  const navigate = useNavigate();
  const {loggedInUserRUID, api} = useMyAuthService();
  
  const [currentLoggedInRUID, setCurrentLoggedInRUID] = useState(loggedInUserRUID);
  const [rbMemberBankData, setRBMemberBankDetails] = useState({});

  const [isLoadingData, setIsLoadingData] = useState(true);

  useEffect(() => {
    getRBMemberBankDetails();
  }, []);

  const getRBMemberBankDetails = () => {
    api.get(
        `/getRBMemberBankDetails/${currentLoggedInRUID}`
      )
      .then((response) => {
        // console.log(response.data);
        setRBMemberBankDetails(response.data);
        //console.log(regData);
        setIsLoadingData(false);
      });
  };

  const handleOpenBankDetailsUpdaterComponent = () =>{
    navigate("../UpdateBankDetails");
  }

  return (
    <div className="BankDetailsViewerMainDiv">
      <fieldset>
        <legend>🔍 View Your Bank Details</legend>
        {isLoadingData ? (
          <div className="isLoadingDiv">Loading Data .....</div>
        ) : (
          <>
            <div className="gridRow">
              <div className="gridCol">
                <table>
                  <tbody>
                    <tr>
                      <td>Salary Bank Name</td>
                      <td>{rbMemberBankData.salaryBankName}</td>
                    </tr>
                    <tr>
                      <td>Salary Bank Account No.</td>
                      <td>{rbMemberBankData.salaryBankAccountNo}</td>
                    </tr>
                    <tr>
                      <td>Salary Bank IFSC Code</td>
                      <td>{rbMemberBankData.salaryBankIFSC}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr></hr>
            <div className="gridRow">
              <div className="FormActionDiv gridCol">
                <input
                  type={"button"}
                  onClick={() => handleOpenBankDetailsUpdaterComponent()}
                  value="🖆 Update Bank Details"
                />
              </div>
            </div>
          </>
        )}
      </fieldset>
    </div>
  );
}

export default MemberBankDetailsViewer;
