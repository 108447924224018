import "../TableDataDisplayer.css";

import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import PaginationNumberRendering from "../PaginationNumberRendering/PaginationNumberRendering";

const TableDataDisplayer = ({
  tableHeaders,
  displayColumns,
  data,
  isLoading,
  itemsPerPage,
  currentPage,
  handlePageChange,
  handleMenuOptionClick,
  setSelectedItem,
  paymentStatus,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const toCamelCase = (str) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+|-|_/g, "");
  };

  const filteredData = data.filter((item) => {
    const searchString = displayColumns
      .map((column) => item[toCamelCase(column)])
      .join(" ")
      .toLowerCase();
    return searchString.includes(searchTerm.toLowerCase());
  });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = filteredData.slice(startIndex, endIndex);

  const maxPageNumbersToShow = 5; // Adjust the number of page numbers to show

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    handlePageChange(1);
  };

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // setSelectedItem(null);
  };

  //   const handleMenuOptionClick = (option) => {
  //     console.log('Selected option:', option);
  //     handleClose();
  //   };

  return (
    <div className="table-data-displayer">
      <div className="quickSearchBoxDiv">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="🔍 Quick Search"
        />
      </div>

      {isLoading ? (
        <div>Loading Data...</div>
      ) : (
        <>
          <table className="dataDisplayerTable">
            <thead>
              <tr>
                {tableHeaders.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
                <th title="Select From Actions Menu Below">🛠️</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index}>
                  {displayColumns.map((column, colIndex) => (
                    <td key={colIndex}>{item[toCamelCase(column)]}</td>
                  ))}
                  <td>
                    <Button
                      aria-controls="menu-options"
                      aria-haspopup="true"
                      onClick={(event) => handleClick(event, item)}
                      title="Actions Menu"
                    >
                      🔗
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <PaginationNumberRendering 
               maxPageNumbersToShow={maxPageNumbersToShow}
               totalPages={totalPages}
               currentPage={currentPage}
               handlePageChange={handlePageChange}
          />
        </>
      )}

      {paymentStatus === "Payment Accepted" ? (
        <Menu
          id="menu-options"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => handleMenuOptionClick("view-payment-details")}
          >
            View Payment Details
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          id="menu-options"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => handleMenuOptionClick("view-payment-details")}
          >
            View Payment Details
          </MenuItem>
          <MenuItem
            onClick={() => handleMenuOptionClick("post-payment-details")}
          >
            Post Payment Details
          </MenuItem>
        </Menu>
      )}
    </div>
  );
};

export default TableDataDisplayer;
