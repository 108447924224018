import "./LedgerReportMemberWise.css"
import React from 'react';
import { Outlet } from "react-router-dom";


function LedgerReportMemberWise() {
  return (
    <div className='ledgerReportMemberWiseMainDiv'>
      <fieldset className="scrollWithoutScrollBar">
        <legend>📑 Ledger Memberwise Report</legend>
        <Outlet></Outlet>
      </fieldset>
    </div> 
    
  ) 
}

export default LedgerReportMemberWise