import React, {useState} from "react";
import OpenDocumentDisplayer from "../../DialogComponents/OpenDocumentDisplayer/OpenDocumentDisplayer";
import html2pdf from 'html2pdf.js';
import jsPDF from "jspdf";

const PDFGeneration = ({docTitle, htmlString }) => {

  // const [pdfContent, setPdfContent] = useState(null);
  // const [openDocDisplayer, setOpenDocDisplayer] = useState(false);


  const generatePDF = async() => {
    //console.log(htmlString);
    // Convert the HTML content to PDF using html2pdf library
    ;
    if (htmlString) {
      // const pdf = new jsPDF();
      // pdf.text(htmlString, 10, 10);
      // // Get htmlString URL for the PDF content
      // const htmlStringUrl = pdf.output('htmlStringuristring');
      // setPdfContent(htmlStringUrl);
      // setOpenDocDisplayer(true);
      const pdfBlob = await html2pdf(htmlString, {
        margin: 10,
        filename: 'newMembershipApplicationForm.pdf',
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        pagebreak: { mode: ['avoid-all'], avoid: '#page-break' }, // Add this option
      });
  
      // Create a URL for the PDF Blob
      // const pdfUrl = URL.createObjectURL(pdfBlob);
      // setPdfContent(pdfUrl);
      // setOpenDocDisplayer(true);
      // Now you can use pdfUrl as needed, for example, opening it in a new tab
      //window.open(pdfUrl, '_blank');
    }
    
  };

  return (
    <div>
      <div className="FormFieldDiv">
        <input
          type="button"
          onClick={generatePDF}
          value={"Generation PDF of Application Form"}
        />
      </div>

      {/* <OpenDocumentDisplayer 
           docTitle = {docTitle}
           docFile={pdfContent}
           openDocDisplayer = {openDocDisplayer}
           setOpenDocDisplayer = {setOpenDocDisplayer}
      /> */}
    </div>
  );
};

export default PDFGeneration;
