import React, {useState} from "react";

import "../TableDataDisplayer.css"
import PaginationNumberRendering from "../PaginationNumberRendering/PaginationNumberRendering";


const EDRTableData = ({ className, membersDeductionList }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPage = 1581; // Number of items to display per page

  const maxPageNumbersToShow = 5;

  // Calculate the total number of pages
  const totalPages = Math.ceil(membersDeductionList.length / itemsPerPage);

  // Get the current page's items based on the currentPage and itemsPerPage
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = membersDeductionList
    .filter((loanApp) =>
      Object.values(loanApp)
        .join(" ")
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    )
    .slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle search query change
  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page when search query changes
  };

  

  return (
    <div className="table-data-displayer">
      <div className="quickSearchBoxDiv">
        <input
          type="text"
          placeholder="Search RUID..."
          value={searchQuery}
          onChange={handleSearchQueryChange}
        />
      </div>
      <table className={className}>
        <thead>
          <tr>
            <td>Sl.</td>
            <td>Employee Id</td>
            <td>Employee Name</td>
            <td>Ledger No.</td>
            <td>Compulsory Deposit</td>
            <td>Main Loan O/S</td>
            <td>ML Int.</td>
            <td>Emergent Loan O/S</td>
            <td>EL Int.</td>
            <td>Misc.</td>
            <td>Total</td>
            {/* <td></td> */}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((member, index) => {
          
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{member.EmployeeId}</td>
                <td className="justifyContent">{member.EmployeeName}</td>
                <td>{member.LedgerNo}</td>
                <td>{member.CompulsoryDeposit}</td>
                <td>{member.MainLoanDeductionAmt}</td>
                <td>{member.MainLoanInterest}</td>
                <td>{member.EmergentLoanDeductionAmt}</td>
                <td>{member.EmergentLoanInterest}</td>
                <td>{member.MiscAmt}</td>
                <td>{member.emiAmount}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Pagination */}
      <PaginationNumberRendering 
           maxPageNumbersToShow={maxPageNumbersToShow}
           totalPages={totalPages}
           currentPage={currentPage}
           handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default EDRTableData;
