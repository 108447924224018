import "./DashBoardFooter.css";
import React from 'react'

const DashBoardFooter = () => {
  return (
    <div className="dashBoardFooterDiv">
      System designed, developed and maintained @ Krishana Meena and Team
    </div>
  )
}

export default DashBoardFooter