import React from "react";

import "./LoanBond1.css";

import { convertToWords } from "../../../../ComonFunctionsProvider/CommonFunctionsProvider";

const LondBond1 = (props) => {

  const {loanBond1, setLoanBond1Values, loanForm, setLoanFormValues, loggedInMemberDetails} = props;

  const signBond1CheckHandler = (e) => {
       setLoanBond1Values({... loanBond1, isBond1Signed: e.target.checked});
        //console.log(e.target.checked+'---'+loanBond1.isBondSigned);
  } 

  return (
    <div className="loanBond1MainDiv">
      <fieldset>
        <legend>🗞️ Loan Bond</legend>
        <div className="loanBond1HeaderDiv">
          <h3>RAILWAY BOARD CO-OPERATIVE THRIFT & CREDIT SOCIETY LTD.</h3>
          <hr />
          <div>Room No. 415-C, Rail Bhawan, New Delhi</div>
          <div>(Registered under the Co-Op. Societies Act. 11 of 1912)</div>
          <div>(Registration No. 374U(A) Dated 01-07-1931)</div> 
          <h2><u>BOND</u></h2>
        </div>
        
        <div className="loanBond1ContentDiv">
          <p>
            I, <u>{loggedInMemberDetails.name}</u>, Member of the above named Society have this Day of Received
            subject to the bylaws of the Society a LOAN of Rs. <u>{loanForm.loanAmount}</u> (<u>{convertToWords(loanForm.loanAmount)}</u> Rupees Only) (in
            words) bearing interest at a rate to be decided by the Society from
            time to time (Present rate of interest is <u>{loanForm.rateOfInterest}%</u> per annum) which I,
            hereby, undertake to repay in full by <u>{loanForm.noOfInstallments}</u> monthly installments commencing
            from the month of <u>{loanForm.datefirstIntallment}</u> with the interest due there each installment being
            payable on or before the 7th of each month. If I fail to pay the
            interest or any installment of the principal loan on the due date,
            the whole loan shall become immediately due and payable with
            interest.
          </p>
          <p>
            2. I also agree to furnish sureties in respect of this loan or fresh
            sureties in addition to or in place of those furnished below, should
            the Managing Committee of the Society require to do so.
          </p>
          <p>
            3. I further agree that the loan will be expended for <u>{loanForm.loanPurpose}</u> within one
            month from the date of its receipt. If it is not applied, I
            undertake to repay the amount of loan with interest thereon in
            accordance with the bylaws of the Society.
          </p>
          <p>
            4. Under the provisions of section 44 of the Delhi Co-operative
            Societies 1972, I authorise my Disbursing Officer, the Pay &
            Accounts Officer, Railway Board, New Delhi to deduct from my Salary
            or other dues payable to me, such amount on account of installments
            due on this loan as may be furnished to him by the Hony. Secretary
            for the said society from time to time and pay the same direct to
            the said Society in satisfaction of the loan.
          </p>
          <p>
            5. Under the above conditions, I execute this Bond after having duly
            received the full sum of Rs. <u>{loanForm.loanAmount}</u> only. (<u>{convertToWords(loanForm.loanAmount)}</u> Rupees only )
          </p>
          <p>
            6. In case of my death, the outstanding dues towards loan account to
            the society may be recovered from my gratuity / final settlement
            amount.
          </p>
          <p>
            7. I hereby also authorize DDO (PAO) Railway Board to recover the
            total outstanding dues towards my loan account from my gratuity /
            Final settlement for payment to the RBCTCS in case of my death.
          </p>
          <p>
            NEW DELHI, Dated {loanBond1.bond1SignDate}	
          </p>
          <p>
            <input id='signBond1' type={'checkbox'} checked={loanBond1.isBond1Signed} onChange={(e) => signBond1CheckHandler(e)} /> <label htmlFor="signBond1"> please accept the terms and conditions of the bond. you will be deemed to have signed the bond on accepting the same.</label>
          </p>
        </div>
      </fieldset>
    </div>
  );
};

export default LondBond1;
