import "./ShareLedgerReport.css";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";

import useMyAuthService from "../../../../../../authenticationServiceProvider/useMyAuthService";
import LRMWTableSharesLedgerData from "../../../../TableDataDisplayer/LRMWTableSharesLedgerData/LRMWTableSharesLedgerData";

const ShareLedgerReport = (props) => {

  const {api, loggedInUserRUID} = useMyAuthService();
  //getting the list to populate the drop down list in the filters menu..................
  const rbMemberListForFilters = props.rbMemberListForFilters;

  //filter fileds that have been selected for getting data from the server........
  const filters = {
    // selectedRBMemberRUID: " ",
    // selectedLoanType: " ",
  };

  const [filterFields, setFilterFields] = useState(filters);

  const [memberSharesDetails, setMemberSharesDetails] = useState([]);
  const [isMemberSharesDataLoaded, setIsMemberSharesDataLoaded] = useState(false);

  const [sharesLedgerData, setSharesLedgerData] = useState([]);
  const [isSharesLedgerDataLoaded, setIsSharesLedgerDataLoaded] = useState(false);

  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

  const navigate = useNavigate();

  const handleClickSearchFilterToGenerateTheLRMW = async () => {
    //console.log(filterFields);
    let config = {
      method: "POST",
      url: "/GetMemberShareDetailsForSharesLRMW",
      data: { filterFields },
      headers: {
        Accept: "application/json",
        //  Authorization: varToken
      },
      responseType: "json",
    };

    setOpenProgressBarDialog(true);
    const response = await api(config);
   // console.log(response.data);
    setMemberSharesDetails(response.data);
    setIsMemberSharesDataLoaded(true);
    setOpenProgressBarDialog(false);
  };


  const handleClickGetSharesLedgerData = async (selectedMemberRUID) => {
    //console.log(filterFields);
    let config = {
      method: "POST",
      url: `/GetShareTransactionsForLRMW`,
      data: { selectedMemberRUID },
      headers: {
        Accept: "application/json",

        //  Authorization: varToken
      },
      responseType: "json",
    };

    setOpenProgressBarDialog(true);
    const response = await api(config);
    // console.log(response.data);
    setSharesLedgerData(response.data);
    setIsMemberSharesDataLoaded(false);
    setIsSharesLedgerDataLoaded(true);

    setOpenProgressBarDialog(false);
  };


  const goBackToStepOneFilters = () =>{
    //alert("cle")
    setIsMemberSharesDataLoaded(false);
    setIsSharesLedgerDataLoaded(false);
  }

  return (
    <>
      {!isMemberSharesDataLoaded && !isSharesLedgerDataLoaded ? (
        <div className="LRMWFiltersSearchDiv gridRow">
          {/* <input type={"text"} value={"Go"} onChange={setQuickSearchText} /> */}
          {/* <span>
            <h5>Select the RUID to view the active Loans of the selected Member</h5>
          </span> */}

          <div className="FormFieldDiv">
            <div>
              <input
                list="members"
                name="memberList"
                id="memberList"
                onChangeCapture={(e) =>
                  setFilterFields({
                    ...filterFields,
                    selectedRBMemberRUID: e.target.value,
                  })
                }
                placeholder="Select a Member"
              />
              <datalist id="members">
                {rbMemberListForFilters
                  ? rbMemberListForFilters.map((rbMember, index) => {
                      return (
                        <option key={index} value={rbMember.rbMemberRUID}>
                          {rbMember.name} --- {rbMember.ledgerNo}
                        </option>
                      );
                    })
                  : ""}
              </datalist>
            </div>
          </div>

          <div className="FormActionDiv">
            <input
              type={"button"}
              value={"Go"}
              onClick={handleClickSearchFilterToGenerateTheLRMW}
            />
          </div>
        </div>
      ) : (
        <div className="LRMWFiltersSearchDiv gridRow">
          <div className="FormActionDiv">
            <input
              type={"button"}
              value={"👈 Back"}
              onClick={goBackToStepOneFilters}
            />
          </div>
        </div>
      )}

      <hr />

      <div>
        {!isMemberSharesDataLoaded && !isSharesLedgerDataLoaded ? (
          <div className="alternateReportViewText">
            <h1>
              Shares Ledger to Generate here.
            </h1>
          </div>
        ) : isMemberSharesDataLoaded && !isSharesLedgerDataLoaded ? (
          <div>
            <table className="LRMWFiltersSearchDivTable centerContent">
              <thead>
                <tr>
                  <td>Sl.</td>
                  <td>RUID </td>
                  <td>Member </td>
                  <td>Ledger No.</td>
                  <td>No of Shares</td>
                  <td>Total Share Amount</td>
                  <td>View Statement</td>
                </tr>
              </thead>
              <tbody>
                {memberSharesDetails.map((share, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{share.rbMemberRUID}</td>
                      <td>{share.name} ({share.designation})</td>
                      <td>{share.ledgerNo}</td>
                      <td>{share.noOfShares}</td>
                      <td>{share.totalShareAmount}</td>
                      <td>
                        <Button
                          variant="outlined"
                          onClick={()=>handleClickGetSharesLedgerData(share.rbMemberRUID)}
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div>
            <LRMWTableSharesLedgerData className={"LRMWFiltersSearchDivTable centerContent"} 
              sharesTransactionsList={sharesLedgerData}
            />
            
          </div>
        )}
      </div>

      {/* Dialog to implement the Progess bar ................ */}
      <Dialog open={openProgressBarDialog} fullWidth maxWidth="sm">
        <DialogTitle id="scroll-dialog-title">
          <b>Please wait! work under process ....</b>
        </DialogTitle>
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ShareLedgerReport