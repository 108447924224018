import React from "react";
import PropTypes from "prop-types";
import "./AboutUsWriteUp.css"

const AboutUsWriteUp = () => (
  <div className="AboutUsWriteUpMainDiv">
    <div className="AboutUsHeaderDiv">
      <h4>
        <p>
          RBCTCS : A Registered Society under the 'Delhi Cooperative Societies
          Act, 1972'
        </p>
      </h4>
    </div>
    <div className="AboutUsContentDivWrapper">
      <div className="AboutUsContentDiv">
        <p>
          Railway Board Co-operative Thrift & Credit Society Limited is a
          registered Society under the "Delhi Cooperative Societies Act, 1972"
          (Registration No. 374(UA) and dated: 01-07-1931). Railway Board
          Co-operative Thrift & Credit Society strictly abides by the principles
          of Cooperative Societies Act, 1973 and its Rules.
        </p>
        <p>
          The vision of Railway Board Co-operative Thrift & Credit Society is
          "Public prosperity" by offering financial services to all its
          registered members and offering equal opportunity to all, to live an
          improved life by Financial Enablement and empowerment. The mission of
          the society is to create self sufficient sustainable ecosystem of
          members through co-operation. This society is to enable financial
          inclusion by providing affordable financial services to all its
          members.
        </p>
        <p>
          The society is authorized by its laws to accept Monthly deposits from
          its members. The deposit schemes are designed to deliver maximum value
          to its members, offering attractive rate of interest along-with other
          benefits. The Society's rate of interest is among one the best rates
          offered in comparison to Nationalized/Co-operative Banks, Financial
          Institutions and other co-operative societies, making it the preferred
          choice of investment by members.
        </p>
        <p>
          The deposits accepted by the society are mobilized by the means of
          loans to members, making it a profitable institution to enable further
          expansion and delivering value to its members. The society has loans
          available to all members for all sorts of purposes.
        </p>
        <p>
          The society operations are fully computerized using latest
          state-of-art technology for accurate and robust financial
          transactions.
        </p>
      </div>
    </div>
  </div>
);

AboutUsWriteUp.propTypes = {};

AboutUsWriteUp.defaultProps = {};

export default AboutUsWriteUp;
