import "./ManageLoanSuretiesTabs.css";

import React, { useEffect, useState } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import useMyAuthService from "../../../../authenticationServiceProvider/useMyAuthService";
import DialogContainer from "../../DialogComponents/DialogContainer/DialogContainer";

const ManageLoanSuretiesTabs = () => {
  const { loggedInUserRUID, api } = useMyAuthService();
  const [ruidLoggedIn, setRUIDLoggedIn] = useState(loggedInUserRUID);
  const [suretyRequests, setSuretyRequests] = useState([]);
  const [suretyRequestsRejected, setSuretyRequestsRejected] = useState([]);
  const [suretyRequestsAccepted, setSuretyRequestsAccepted] = useState([]);
  const [suretyRequestsClosed, setSuretyRequestsClosed] = useState([]);

  const [updateForReRender, setUpdateForReRender] = useState(false);

  const [isLoadingData, setIsLoadingData] = useState(true);

  //for tabs
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //for tabs

  useEffect(() => {
    GetLoanApplicationsSeekingSurety("Pending");
    GetLoanApplicationsSeekingSurety("Accepted", "Verified", "Approved");
    //GetLoanApplicationsSeekingSurety("Verified");
    GetLoanApplicationsSeekingSurety("Rejected", "Disapproved");
    //GetLoanApplicationsSeekingSurety("Disapproved");
    GetLoanApplicationsSeekingSurety("Closed");
  }, [updateForReRender]);

  async function GetLoanApplicationsSeekingSurety(
    requestStatus,
    requestStatus2 = undefined,
    requestStatus3 = undefined
  ) {
   

    let config = {
      method: "POST",
      url: `/GetLoanApplicationsSeekingSurety`,
      data: {
        requestStatus: requestStatus,
        requestStatus2: requestStatus2, // Use requestStatus2 if provided, otherwise default to undefined
        requestStatus3: requestStatus3, // Use requestStatus2 if provided, otherwise default to undefined
        ruidLoggedIn: ruidLoggedIn,
      },
      headers: {
        Accept: "application/json",
        // Authorization: varToken
      },
      responseType: "json",
    };

    const response = await api(config);

   // console.log(response.data);

    switch (requestStatus) {
      case "Pending":
        setSuretyRequests(response.data);
        break;
      case "Accepted":
        setSuretyRequestsAccepted(response.data);
        break;
      case "Rejected":
        setSuretyRequestsRejected(response.data);
        break;
      case "Closed":
        setSuretyRequestsClosed(response.data);
        break;
      default:
        break;
    }

    setIsLoadingData(false);
  }

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Manage Surety Tabs"
          indicatorColor="secondary"
        >
          <Tab label="Manage Surety Requests" {...a11yProps(0)} />
          <Tab label="Active Sureties Furnished" {...a11yProps(1)} />
          <Tab label="Surety Requests Rejected" {...a11yProps(2)} />
          <Tab label="Sureties To Loans Closed" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className="tabPanelMainDiv">
          {isLoadingData ? (
            <div className="isLoadingDiv">Loading Data ....</div>
          ) : (
            <LoanSuretyReqTable
              suretyRequests={suretyRequests}
              tableIndex={0}
              updateForReRender={updateForReRender}
              setUpdateForReRender={setUpdateForReRender}
              api={api}
            />
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="tabPanelMainDiv">
          {isLoadingData ? (
            <div className="isLoadingDiv">Loading Data ....</div>
          ) : (
            <LoanSuretyReqTable
              suretyRequests={suretyRequestsAccepted}
              tableIndex={1}
              updateForReRender={updateForReRender}
              setUpdateForReRender={setUpdateForReRender}
              api={api}
            />
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="tabPanelMainDiv">
          {isLoadingData ? (
            <div className="isLoadingDiv">Loading Data ....</div>
          ) : (
            <LoanSuretyReqTable
              suretyRequests={suretyRequestsRejected}
              tableIndex={2}
              updateForReRender={updateForReRender}
              setUpdateForReRender={setUpdateForReRender}
              api={api}
            />
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div className="tabPanelMainDiv">
          {isLoadingData ? (
            <div className="isLoadingDiv">Loading Data ....</div>
          ) : (
            <LoanSuretyReqTable
              suretyRequests={suretyRequestsClosed}
              tableIndex={3}
              updateForReRender={updateForReRender}
              setUpdateForReRender={setUpdateForReRender}
              api={api}
            />
          )}
        </div>
      </TabPanel>
    </div>
  );
};

export default ManageLoanSuretiesTabs;

///function for the data table implementation

function LoanSuretyReqTable(props) {
  const {
    suretyRequests,
    tableIndex,
    updateForReRender,
    setUpdateForReRender,
    api,
  } = props;

  const [anchorActionsMenu, setAnchorActionsMenu] = React.useState(null);
  const open = Boolean(anchorActionsMenu);

  const [openManageSuretyDialog, setOpenManageSuretyDialog] =
    React.useState(false);

  const [selectedSuretyRequestForSubmit, setSelectedSuretyRequestForSubmit] =
    useState({});

  const [suretyProvider, setSuretyProvider] = useState({});
  const [openSuretyProviderDialog, setOpenSuretyProviderDialog] =
    React.useState(false);

  const handleActionMenuOpen = (event, suretyReq) => {
    setSelectedSuretyRequestForSubmit(suretyReq);
    setAnchorActionsMenu(event.currentTarget);
  };

  const handleActionMenuClose = () => {
    setAnchorActionsMenu(null);
  };

  const handleMenuAcceptRejectSurety = () => {
    setAnchorActionsMenu(null);
    setOpenManageSuretyDialog(true);
  };

  const handleCloseManageSuretyRequestDialog = () => {
    setOpenManageSuretyDialog(false);
  };

  const handleCloseSuretyProviderDialog = () => {
    setOpenSuretyProviderDialog(false);
  };

  const handleSubmitManageSuretyRequest = async (requestStatus) => {
    const data = {
      requestStatus: requestStatus,
      loanSuretyTableId: selectedSuretyRequestForSubmit.id,
    };
    //console.log(data);
    let config = {
      method: "PUT",
      url: `/UpdateSuretyRequestStatus`,
      data: { data },
      headers: {
        Accept: "application/json",
      },
      responseType: "json",
    };

    const response = await api(config);
    //console.log(response);
    setUpdateForReRender(!updateForReRender);
    setOpenManageSuretyDialog(false);
    // console.log(response.data);
  };

  const getSuretyProviderDetails = async (ledgerNo) => {
    try {
      // Encode the ledger number so as to handle the slash in the ledger no
      const formattedLedgerNo = ledgerNo.replace("/", "_");

      let config = {
        method: "GET",
        url: `/GetRBMemberDetailsByLedgerNo/${formattedLedgerNo}`,
        headers: {
          Accept: "application/json",
        },
        responseType: "json",
      };

      const response = await api(config);
      //console.log("API Response:", response.data);

      setSuretyProvider(response.data);
      setOpenSuretyProviderDialog(true);
    } catch (error) {
      console.error("API Error:", error);
      // Handle the error, e.g., show an error message to the user
    }
  };

  return (
    <>
      <table>
        <thead>
          <tr>
            <td>Sl.</td>
            <td>Surety Seeker Name/LedgerNo</td>
            <td>Surety Provider </td>
            <td>Loan Id</td>
            <td>Loan Type</td>
            <td>Loan Amount</td>
            <td>Loan Purpose</td>
            <td>Request Status</td>
            {tableIndex === 0 ? <td>Actions</td> : ""}
          </tr>
        </thead>
        <tbody>
          {Object.keys(suretyRequests).map((key, index) => {
            return (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>
                  {suretyRequests[key].loan_applications.rbmembers.name} (
                  {suretyRequests[key].rbMemberLedgerNo})
                </td>
                <td>
                  <input
                    type="button"
                    value={suretyRequests[key].suretyLedgerNo}
                    onClick={() =>
                      getSuretyProviderDetails(
                        suretyRequests[key].suretyLedgerNo
                      )
                    }
                  />
                </td>
                <td>
                  {suretyRequests[key].loan_applications.loanApplicationId}
                </td>
                <td>{suretyRequests[key].loan_applications.loanType}</td>
                <td>
                  {suretyRequests[key].loan_applications.loanAmountSought}
                </td>
                <td>{suretyRequests[key].loan_applications.loanPurpose}</td>
                <td>{suretyRequests[key].suretyRequestStatus}</td>
                {tableIndex === 0 ? (
                  <td>
                    {
                      <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={(e) =>
                          handleActionMenuOpen(e, suretyRequests[key])
                        }
                      >
                        ⚙️
                      </Button>
                    }{" "}
                    {/* this is the actions menu activated on the button click to take actions on a particular selected Loan */}
                    <Menu
                      anchorEl={anchorActionsMenu}
                      open={open}
                      onClose={handleActionMenuClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={handleMenuAcceptRejectSurety}>
                        💡 Accept/Reject Surety Requests
                      </MenuItem>
                    </Menu>
                  </td>
                ) : (
                  ""
                )}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Dialog to implement the verification logic for the submitted application ................ */}
      <Dialog
        open={openManageSuretyDialog}
        onClose={handleCloseManageSuretyRequestDialog}
        //scroll={body}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="scroll-dialog-title">
          <div className="dialogTitle">
            Submit Acceptance/Rejection for Surety Request
          </div>
        </DialogTitle>
        <DialogContent>
          {/* get the nominee table here */}
          <div className="finalVerificationSubmitDialogDiv">
            <p>
              To Accept the Surety Request, Please Click "Accepted" button
              otherwise click "Rejected" button to Reject the Surety Request.
            </p>
            <div className="FormActionDiv">
              <button
                onClick={() => handleSubmitManageSuretyRequest("Rejected")}
              >
                ❌ Rejected
              </button>
              <button
                onClick={() => handleSubmitManageSuretyRequest("Accepted")}
              >
                ✔️ Accepted
              </button>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseManageSuretyRequestDialog}>
            ❌ Close
          </Button>
        </DialogActions>
      </Dialog>

      <DialogContainer
        titleMessage={"Surety Provider Details"}
        maxWidth={"sm"}
        openDialogBox={openSuretyProviderDialog}
        handleCloseDialog={handleCloseSuretyProviderDialog}
      >
        <div className="suretyProviderDisplayDiv">
          <table className="suretyProviderDisplayTable">
            <tr>
              <td>Name :- </td>
              <td>{suretyProvider.name}</td>
            </tr>
            <tr>
              <td>RUID :- </td>
              <td>{suretyProvider.rbMemberRUID}</td>
            </tr>
          </table>
        </div>
      </DialogContainer>
    </>
  );
}

////////functions for tab implementation.......................

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
