import React, {useState} from "react";
import Button from "@mui/material/Button";

import "../TableDataDisplayer.css"

import {formatDate} from "../../../../ComonFunctionsProvider/CommonFunctionsProvider"
import PaginationNumberRendering from "../PaginationNumberRendering/PaginationNumberRendering";


const LRMWTableLoanLedgerData = ({ className, loanTransactionsList, moveBacktoLoanApps }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const itemsPerPage = 15; // Number of items to display per page
  
    const maxPageNumbersToShow = 5; // Adjust the number of page numbers to show
    
    // Calculate the total number of pages
    const totalPages = Math.ceil(loanTransactionsList.length / itemsPerPage);
  
    // Get the current page's items based on the currentPage and itemsPerPage
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = loanTransactionsList
      .filter((loanApp) =>
        Object.values(loanApp)
          .join(" ")
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
      .slice(indexOfFirstItem, indexOfLastItem);
  
    // Handle page change
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
  
    // Handle search query change
    const handleSearchQueryChange = (e) => {
      setSearchQuery(e.target.value);
      setCurrentPage(1); // Reset to the first page when search query changes
    };
  
    return (
      <div className="table-data-displayer">
        <div className="quickSearchBoxDiv">
          <Button  onClick = {()=> moveBacktoLoanApps()} >👈 Back </Button>
          <input
            type="text"
            placeholder="Search RUID..."
            value={searchQuery}
            onChange={handleSearchQueryChange}
          />
        </div>
       
        <table className={className}>
          <thead>
            <tr>
              <td>Sl.</td>
              <td>loan Id</td>
              <td>Member</td>
              <td>Reference No</td>
              <td>Mode</td>
              <td>Account Head</td>
              <td>Open Balance</td>
              <td>Transaction Amount</td>
              <td>Close Balance</td>
              <td>Transaction Date</td>
              <td>Dr/Cr</td>
              <td>Status</td>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((loanHeadtrans, index) => {
           
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{loanHeadtrans.loanApplicationId}</td>
                  <td>{loanHeadtrans.rbMemberLedgerNo}</td>
                  <td>{loanHeadtrans.transaction.transactionReferenceNo}</td>
                  <td className="justifyContent">{loanHeadtrans.transaction.transactionModeCode}</td>
                  <td>{loanHeadtrans.transaction.transactionHeadCode}</td>
                  <td>{loanHeadtrans.openingOutstanding}</td>
                  <td>{loanHeadtrans.transaction.transactionAmount}</td>
                  <td>{loanHeadtrans.closingOutstanding}</td>
                  <td>{formatDate(loanHeadtrans.transaction.transactionDate)}</td>
                  <td>{loanHeadtrans.transaction.transactionDrOrCr}</td>
                  <td>{loanHeadtrans.transaction.transactionStatus}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* Pagination */}
        <PaginationNumberRendering 
           maxPageNumbersToShow={maxPageNumbersToShow}
           totalPages={totalPages}
           currentPage={currentPage}
           handlePageChange={handlePageChange}
        />
      </div>
    );
  };

export default LRMWTableLoanLedgerData