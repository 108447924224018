import React, { useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const MembershipApprovalsMenu = ({
  handleClickOpenMemberDetailsInApplication,
  handleClickOpenMemberPaymentDetailsForMembership,
  handleClickOpenMembershipApprovalDialog,
  selectedMember,
  handleClickOpenNomineeDetailsInApplication,
  handleClickOpenOtherSocietyDetailsInApplication,
  anchorActionsMenu,
  openMenu,
  handleCloseMenu,
  applicationStatus,
}) => {
  return (
    <div>
      <Menu
        anchorEl={anchorActionsMenu}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={handleClickOpenMemberDetailsInApplication(
            selectedMember.rbMemberRUID
          )}
        >
          🔍 View Member Details
        </MenuItem>
        <MenuItem
          onClick={(e) =>
            handleClickOpenMemberPaymentDetailsForMembership(
              selectedMember.rbMemberRUID
            )
          }
        >
          🔍 View Payment Details
        </MenuItem>
        <MenuItem onClick={handleClickOpenOtherSocietyDetailsInApplication}>
          🔍 View Other Society Details
        </MenuItem>
        <MenuItem onClick={handleClickOpenNomineeDetailsInApplication}>
          🔍 View Nominee Details
        </MenuItem>
        {applicationStatus === "MAPDV" ? (
          <MenuItem onClick={handleClickOpenMembershipApprovalDialog}>
            ✔️ Approve Membership
          </MenuItem>
        ) : (
          ""
        )}
      </Menu>
    </div>
  );
};

export default MembershipApprovalsMenu;
