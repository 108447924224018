import React from 'react'

const UpdateAdminDetails = () => {
  return (
    <div>UpdateAdminDetail
      
      </div>
  )
}

export default UpdateAdminDetails