import "./LoanLedgerReport.css";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import useMyAuthService from "../../../../../../authenticationServiceProvider/useMyAuthService";
import ProgressorBarDisplayer from "../../../../DialogComponents/ProgressorBarDisplayer/ProgressorBarDisplayer";
import LRMWTableLoanLedgerData from "../../../../TableDataDisplayer/LRMWTableLoanLedgerData/LRMWTableLoanLedgerData";



const LoanLedgerReport = (props) => {

  const {api} = useMyAuthService();
  //getting the list to populate the drop down list in the filters menu..................
  const rbMemberListForFilters = props.rbMemberListForFilters;

  //filter fileds that have been selected for getting data from the server........
  const filters = {
  //  to be filled on filter selection
    selectedRBMemberRUID:"",
    loanApplicationStatus:"",

  };

  const [filterFields, setFilterFields] = useState(filters);

  const [memberDisbursedLoanApps, setMemberDisbursedLoanApps] = useState([]);
  const [isMemberLoansDataLoaded, setIsMemberLoansDataLoaded] = useState(false);

  // const [selectedLoanAppId, setSelectedLoanAppId] = useState();
  const [loanLedgerData, setLoanLedgerData] = useState([]);
  const [isLoanLedgerDataLoaded, setIsLoanLedgerDataLoaded] = useState(false);

  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

  const navigate = useNavigate();

  const handleClickSearchFilterToGenerateTheLRMW = async () => {
    //console.log(filterFields);
    
    let config = {
      method: "POST",
      url: `/GetMemberLoansForLoanLRMW`,
      data: { filterFields },
      headers: {
        Accept: "application/json",

      },
      responseType: "json",
    };

    setOpenProgressBarDialog(true);
    const response = await api(config);
    //console.log(response.data);
    setMemberDisbursedLoanApps(response.data);
    setIsMemberLoansDataLoaded(true);
    setOpenProgressBarDialog(false);
  };

  const handleClickGetLoanLedgerData = async (selectedLoanAppId) => {
    //console.log(filterFields);
    let config = {
      method: "GET",
      url: `/GetLoanTransactionsForLRMW/${selectedLoanAppId}`,
      headers: {
        Accept: "application/json",

        //  Authorization: varToken
      },
      responseType: "json",
    };

    setOpenProgressBarDialog(true);
    const response = await api(config);
   // console.log(response.data);
    setLoanLedgerData(response.data);
    setIsMemberLoansDataLoaded(false);
    setIsLoanLedgerDataLoaded(true);

    setOpenProgressBarDialog(false);
  };


  const goBackToStepOneFilters = () =>{
    //alert("cle")
    setIsMemberLoansDataLoaded(false);
    setIsLoanLedgerDataLoaded(false);
  }



  return (
    <>
      {!isMemberLoansDataLoaded && !isLoanLedgerDataLoaded ? (
        <div className="LRMWFiltersSearchDiv gridRow">
          {/* <input type={"text"} value={"Go"} onChange={setQuickSearchText} /> */}
          {/* <span>
            <h5>Select the RUID to view the active Loans of the selected Member</h5>
          </span> */}

          <div className="FormFieldDiv">
            <div>
              <input
                list="members"
                name="memberList"
                id="memberList"
                onChangeCapture={(e) =>
                  setFilterFields({
                    ...filterFields,
                    selectedRBMemberRUID: e.target.value,
                    //loanApplicationStatus:"LAAD"
                  })
                }
                placeholder="Select a Member"
              />
              <datalist id="members">
                {rbMemberListForFilters
                  ? rbMemberListForFilters.map((rbMember, index) => {
                      return (
                        <option key={index} value={rbMember.rbMemberRUID}>
                          {rbMember.name} --- {rbMember.ledgerNo}
                        </option>
                      );
                    })
                  : ""}
              </datalist>
            </div>
          </div>

          <div className="FormActionDiv">
            <input
              type={"button"}
              value={"Go"}
              onClick={handleClickSearchFilterToGenerateTheLRMW}
            />
          </div>
        </div>
      ) : (
        <div className="LRMWFiltersSearchDiv gridRow">
          <div className="FormActionDiv">
            <input
              type={"button"}
              value={"👈 Back"}
              onClick={goBackToStepOneFilters}
            />
          </div>
        </div>
      )}

      <hr />

      <div>
        {!isMemberLoansDataLoaded && !isLoanLedgerDataLoaded ? (
          <div className="alternateReportViewText">
            <h1>
               Loan Ledger to Generate here.
            </h1>
          </div>
        ) : isMemberLoansDataLoaded && !isLoanLedgerDataLoaded ? (
          <div>
            <table className="LRMWFiltersSearchDivTable centerContent">
              <thead>
                <tr>
                  <td>Sl.</td>
                  <td>Loan Id</td>
                  <td>Loan Type</td>
                  <td>Loan Status</td>
                  <td>Loan Amount</td>
                  <td>Rate Of Interest</td>
                  <td>Loan Purpose</td>
                  <td>View Statement</td>
                </tr>
              </thead>
              <tbody>
                {memberDisbursedLoanApps.map((loan, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{loan.loanApplicationId}</td>
                      <td>{loan.loanType}</td>
                      <td>{loan.applicationStatus}</td>
                      <td>{loan.loanAmountSanctioned}</td>
                      <td>{loan.rateOfInterest}</td>
                      <td>{loan.loanPurpose}</td>
                      <td>
                        <Button
                          variant="outlined"
                          onClick={()=>handleClickGetLoanLedgerData(loan.loanApplicationId)}
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div>
            <LRMWTableLoanLedgerData className={"LRMWFiltersSearchDivTable centerContent"}
                  loanTransactionsList={loanLedgerData}
             />
            
          </div>
        )}
      </div>

      {/* Dialog to implement the Progess bar ................ */}
      <ProgressorBarDisplayer   
          titleMessage={"Please Wait! Loading the Loan Data"}
          openProgressBarDialog={openProgressBarDialog}
      />
    
    </>
  );
};

export default LoanLedgerReport;
