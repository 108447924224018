import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const DialogContainer = ({
  titleMessage,
  openDialogBox,
  maxWidth,
  handleCloseDialog,
  children,
}) => {
  return (
    <div>
      {/* Dialog to implement the Progess bar ................ */}
      <Dialog open={openDialogBox} fullWidth maxWidth={maxWidth}>
      <DialogActions id="scroll-dialog-ActionDialogActions">
            <span className="titleDialogBox">
              {titleMessage} 
            </span>
            <Button
              variant="outlined"
              onClick={handleCloseDialog}
            >
              ❌
            </Button>
          </DialogActions>
       
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </div>
  );
};

export default DialogContainer;
