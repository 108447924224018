import React from "react";
import useMyAuthService from "../../../../../authenticationServiceProvider/useMyAuthService";

const MembershipApplicationMarkForReview = ({
  applicationPartForReview,
  setApplicationPartForReview,
  ruidLoggedIn,
  setOpenProgressBarDialog,
  setUpdated,
  setOpenMarkApplicationForReview,
  reviewRemarks,
  setReviewRemarks,
}) => {
  const { api } = useMyAuthService();

  //function to hanlde the logic for mark for review of the memberdhip application.............
  const handleCheckBoxMarkForReview = (e, part) => {
    switch (part) {
      case "Personal_Details":
        setApplicationPartForReview({
          ...applicationPartForReview,
          Personal_Details: e.target.checked,
        });
        break;
      case "Official_Details":
        setApplicationPartForReview({
          ...applicationPartForReview,
          Official_Details: e.target.checked,
        });
        break;
      case "Bank_Details":
        setApplicationPartForReview({
          ...applicationPartForReview,
          Bank_Details: e.target.checked,
        });
        break;
      // case "Payment_Details":
      //   setApplicationPartForReview({
      //     ...applicationPartForReview,
      //     Payment_Details: e.target.checked,
      //   });
      //   break;
      case "Other_Society_Details":
        setApplicationPartForReview({
          ...applicationPartForReview,
          Other_Society_Details: e.target.checked,
        });
        break;

      case "Nominee_Details":
        setApplicationPartForReview({
          ...applicationPartForReview,
          Nominee_Details: e.target.checked,
        });
       break;
      case "Document_Uploads":
        setApplicationPartForReview({
          ...applicationPartForReview,
          Document_Uploads: e.target.checked,
        });
        break;
    } //end switch
  };

  const handleSubmitMarkApplicationForReview = async () => {
    // const data = applicationPartForReview;
    const data = {
      // memberApplicationID: applicationPartForReview.memberApplicationID,
      applicationPartForReview: applicationPartForReview,
      // selectedMemberRUID:selectedMember.rbMemberRUID,
      // staffId: ruidLoggedIn,
      reviewRemarks: reviewRemarks,
      approvalStatus: "MAMR",
    };

    //console.log(data);
    let config = {
      method: "PUT",
      url: `/MarkMembershipApplicationForReview`,
      data: { data },
      headers: {
        Accept: "application/json",

        //  Authorization: varToken
      },
      responseType: "json",
    };

    setOpenProgressBarDialog(true);
    const response = await api(config);
    setOpenMarkApplicationForReview(false);
    setUpdated((updated) => !updated);
    setOpenProgressBarDialog(false);
    //console.log(response.data);
  };

  return (
    <div className="markForReviewDialogDiv">
      <p>please select the part of the application which is incorrect.</p>
      <table>
        <tbody>
          <tr>
            <td>
              <input
                type={"checkbox"}
                checked={applicationPartForReview.Personal_Details}
                onChange={(e) =>
                  handleCheckBoxMarkForReview(e, "Personal_Details")
                }
              />
              Personal Details
            </td>
          </tr>
          <tr>
            <td>
              <input
                type={"checkbox"}
                checked={applicationPartForReview.Official_Details}
                onChange={(e) =>
                  handleCheckBoxMarkForReview(e, "Official_Details")
                }
              />
              Official Details
            </td>
          </tr>
          <tr>
            <td>
              <input
                type={"checkbox"}
                checked={applicationPartForReview.Bank_Details}
                onChange={(e) => handleCheckBoxMarkForReview(e, "Bank_Details")}
              />
              Bank Details
            </td>
            {/* <td>
                    <input
                      type={"checkbox"}
                      checked={applicationPartForReview.Payment_Details}
                      onChange={(e) =>
                        handleCheckBoxMarkForReview(e, "Payment_Details")
                      }
                    />
                    Payment Details
                  </td> */}
          </tr>
          
          <tr>
            <td>
              <input
                type={"checkbox"}
                checked={applicationPartForReview.Nominee_Details}
                onChange={(e) =>
                  handleCheckBoxMarkForReview(e, "Nominee_Details")
                }
              />
              Nominee Details
            </td>
          </tr>
          <tr>
            <td>
              <input
                type={"checkbox"}
                checked={applicationPartForReview.Document_Uploads}
                onChange={(e) =>
                  handleCheckBoxMarkForReview(e, "Document_Uploads")
                }
              />
              Documents/Pay Slip Uploads
            </td>
          </tr>
          <tr>
            <td>
              <hr/>
            </td>
          </tr>
          <tr>
            <td>
              <textarea
                rows="4"
                cols="60"
                value={reviewRemarks}
                onChange={(e) => {
                  setReviewRemarks(e.target.value);
                }}
                placeholder="enter any remarks"
              ></textarea>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <div className="FormActionDiv">
        <button onClick={handleSubmitMarkApplicationForReview}>
          Submit for Review ✍️
        </button>
      </div>
    </div>
  );
};

export default MembershipApplicationMarkForReview;
