import { useContext } from 'react';
import { AuthContext } from './authServiceProvider';

export const useTokenCreation = () => {
  const { createToken } = useContext(AuthContext);

  const handleTokenCreation =  (userId, userPassword, userRole) => {
    return  createToken(userId, userPassword, userRole)
      .then((response) => {
        // Token creation successful
        //console.log("From the useTokenCreation :- "+JSON.stringify(response));
        return response;
      })
      // .catch(error => {
      //   // Handle token creation error
      //   console.error("useTokenCreation says :", error);
      //   return error;
      // });
  };
  
  return handleTokenCreation;
};

