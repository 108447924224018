import "./NewOrdinaryLoanApplicationForm.css";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import LoanApplicationInstructions from "../NewLoanApplicationComponents/LoanApplicationInstructions/LoanApplicationInstructions";
import LoanFormDetails from "../NewLoanApplicationComponents/LoanFormDetails/LoanFormDetails";
import LoanSuretyFormDetails from "../NewLoanApplicationComponents/LoanSuretyFormDetails/LoanSuretyFormDetails";
import LoanBond1 from "../NewLoanApplicationComponents/LoanBond1/LondBond1";
import LoanBond2 from "../NewLoanApplicationComponents/LoanBond2/LondBond2";

import React, { useEffect, useState } from "react";

import LoanApplicationPreview from "../NewLoanApplicationComponents/LoanApplicationPreview/LoanApplicationPreview";

import { useNavigate } from "react-router-dom";

import ProgressorBarDisplayer from "../DialogComponents/ProgressorBarDisplayer/ProgressorBarDisplayer";

import useMyAuthService from "../../../authenticationServiceProvider/useMyAuthService";

const steps = [
  "Instructions",
  "Loan Details",
  // "Payment Details",
  "Surety Details",
  "Loan Bond-1",
  "Loan Bond-2",
  "Preview",
];

const loanFormFields = {
  loanType: "ML",
  loanCategory: "Original",
  loanAmount: 0,
  loanPurpose: "",
  rateOfInterest: "9",
  noOfIntallments: " ",
  dateFirstInstallment: " ",
  isSharesPayRequired: true,
  totalSuretyWeightRequired:0,
};

const loanSuretyFormFields = {
  ruid: " ",
  Name: " ",
  Mobile: " ",
  suretyStatus: "Pending",
};

let loanSuretyData = [];

const loanBond1FormFields = {
  isBond1Signed: false,
  bond1SignDate: new Date().toLocaleDateString("en-GB"),
  bond1SignPlace: " ",
};

const loanBond2FormFields = {
  isBond2Signed: false,
  bond2SignDate: new Date().toLocaleDateString("en-GB"),
  bond2SignPlace: " ",
};

//array to help in checking the empty fields on the form submission...................
let emptyFields = [];

export default function NewLoanApplicationForm() {

  const { api, loggedInUserRUID } = useMyAuthService();

  const navigate = useNavigate();

  const [activeStep, setActiveStep] = React.useState(0);
  const activeprops = { activeStep, setActiveStep };

  const [ruidLoggedIn, setRUIDLoggedIn] = useState(loggedInUserRUID);
  const [loggedInMemberDetails, setLoggedInMemberDetails] = useState({});
  const [loanLimitDetails, setLoanLimitDetails] = useState({});

  const [loanAmountInWords, setLoanAmountInWord] = useState(" ");

  const [loggedInMemberSuretyWeight, setLoggedInMemberSuretyWeight] = useState(0);
 // const [totalSuretyWeightRequired, setTotalSuretyWeightRequired] = useState(0);
  const [selectedSuretiesWeight, setSelectedSuretiesWeight] = useState(0);

  const [loanType, setLoanType] = useState("ML");
  
  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

  const [openProgressBarDialog2, setOpenProgressBarDialog2] = useState(false);

  const [dialog2Message, setDialog2Message] = useState("Please Wait! Loading the Module..");


  //varaibles contatining the loan form details......................................

  const [loanForm, setLoanFormValues] = React.useState(loanFormFields);
  

  const loanformprops = {
    loanForm,
    setLoanFormValues,
    loggedInMemberDetails,
    loanType,
    loanLimitDetails,
    loggedInMemberSuretyWeight, 
    loanAmountInWords, setLoanAmountInWord
  };

  const [loanSuretyForm, setLoanSuretyFormFieldValues] =
    React.useState(loanSuretyFormFields);
  const [loanSurety, setLoanSuretydata] = React.useState(loanSuretyData);
  const loanSuretyFormProps = {
    loanForm,
    loanLimitDetails,
    loanSuretyForm,
    setLoanSuretyFormFieldValues,
    loanSurety,
    setLoanSuretydata,
    loggedInMemberDetails,
    loggedInMemberSuretyWeight,
    setSelectedSuretiesWeight,
    loanType,
  };

  
  const [loanBond1, setLoanBond1Values] = React.useState(loanBond1FormFields);
  const loanBond1props = {
    loanBond1,
    setLoanBond1Values,
    loanForm,
    setLoanFormValues,
    loggedInMemberDetails,
  };

  const [loanBond2, setLoanBond2Values] = React.useState(loanBond2FormFields);
  const loanBond2props = {
    loanBond2,
    setLoanBond2Values,
    loanForm,
    setLoanFormValues,
    loggedInMemberDetails,
  };


   //getting the details of the member logged in currently from the DB...........
   useEffect(() => {
    
    getLoggedInRBMemberDetails();
  
  }, []);

  const getLoggedInRBMemberDetails = () => {

    const data = {
      memberRUID: ruidLoggedIn,
      loanType:loanType,
    };


    let config = {
      method: "POST",
      url: `/GetRBMemberDetailsForLoanApplication`,
      data: { data },
      headers: {
        Accept: "application/json",

      },
      responseType: "json",
    };

    api(config)
      .then((response) => {
       //  console.log(response.data);
        setLoggedInMemberDetails(response.data.rbMember);
        setLoanLimitDetails(response.data.loanLimitDetails);
        setLoanFormValues({...loanForm, loanCategory:response.data.loanCategory});

        let currentLoggedInMember = response.data.rbMember;
        setLoggedInMemberSuretyWeight(
          currentLoggedInMember.compulsoryDeposit >= 50000 &&
            currentLoggedInMember.compulsoryDeposit <= 100000
            ? 0.5
            : currentLoggedInMember.compulsoryDeposit > 100000
            ? 1
            : 0
        );
        //console.log(response.data.rbMember);
      });
  };

  

  

  //functions to take care of the multi step form navigations.............................
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const showStepContents = (step) => {
    // get the contents as per the active state.
    switch (step) {
      case 0:
        return <LoanApplicationInstructions />;
      case 1:
        return <LoanFormDetails {...loanformprops} />;
      // case 2:
      //   return <PaymentsForLoan {...sharesFormprops}/>
      case 2:
        return <LoanSuretyFormDetails {...loanSuretyFormProps} />;
      case 3:
        return <LoanBond1 {...loanBond1props} />;
      case 4:
        return <LoanBond2 {...loanBond2props} />;
      case 5:
        return (
          <LoanApplicationPreview
            {...loanformprops}
            //  {...sharesFormprops}
            {...loanSuretyFormProps}
            {...loanBond1props}
            {...loanBond2props}
            {...activeprops}
          />
        );
      default:
        return null;
    }
  };

  async function handleSubmit(e) {
    //preventing the default behavior on submit
    e.preventDefault();

    //prepare the loanSuretyForm and NomineeForm objects.......................
    let loanSuretyForm = {
      loanSuretyArr: loanSurety,
    };

    emptyFields = [];

    //check if any of the properties of the loanForm  is empty.......... and dont allow to move forward
    for (var key in loanForm) {
      if (loanForm[key] === null || loanForm[key] === " ") {
        //console.log("please fill in the complete details of the following : "+key)
        emptyFields.push("Loan Form: " + key);
        // return false;
      }
    }

    const amountAvailable =
      parseInt(loanLimitDetails.loanLimits) -
      parseInt(loggedInMemberDetails.currentMLOutstanding);
    if (loanForm.loanAmount > amountAvailable) {
      alert(
        "Loan Amount sought is greater than the Loan Amount Available. Cannot be submitted"
      );
      return 0;
    }
   
    // const noOfSuretiesRequired =
    //   parseInt(loanForm.loanAmount / 100000) -
    //   parseInt(loggedInMemberDetails.compulsoryDeposit / 100000);
   
     if (loanForm.totalSuretyWeightRequired !== selectedSuretiesWeight) {
      alert(
        "The No of Sureties You have selected ("+selectedSuretiesWeight+") is inappropriate. Please select Sureties whose total surety weight is " +
        loanForm.totalSuretyWeightRequired +
          "  to proceed"
      );
      return 0;
    }

    //check if any of the properties of the societyForm  is empty.......... and dont allow to move forward
    loanSurety.map((loanSuretyDetails) => {
      for (var key in loanSuretyDetails) {
        if (loanSuretyDetails[key] === null || loanSuretyDetails[key] === " ")
          emptyFields.push("Other loanSurety Form: " + key);
      }
      return 0;
    });

    //check if any of the properties of the loanbonds  is empty.......... and dont allow to move forward
    if (!loanBond1.isBond1Signed) {
      emptyFields.push("Bond 1 is not signed/checked");
    }
    if (!loanBond2.isBond2Signed) {
      emptyFields.push("Bonds 2 is not signed/checked");
    }

    /////////sending the data to the server id all the data is present

    if (emptyFields.length === 0) {
      // console.log("The lenght is "+ emptyFields.length)
      //console.log("The data being sent..................");
      //console.log({ ...loanForm, ...sharesForm, ...loanSuretyForm, ...loanBond1, ...loanBond2 });
      let config = {
        method: "POST",
        url: `/NewOrdinaryLoanApplication/${ruidLoggedIn}`,
        data: {
          ...loanForm,
          ...loanSuretyForm,
          ...loanBond1,
          ...loanBond2,
          applicationStatus: "LAS",
        },
        headers: {
          Accept: "application/json",
          //  Authorization: varToken
        },
        responseType: "application/json",
      };

      setOpenProgressBarDialog(true);

      const response = await api(config);
      //console.log("data sent from the server.............................");
      //console.log(response.data);
      setOpenProgressBarDialog(false);
      navigate("../ViewYourLoanApplications", {replace:true});
    } else {
      //form is not ready..................
      // console.log(emptyFields);
      alert(
        "Please fill in all the details" +
          "\r\n" +
          emptyFields.map((field, index) => {
            return field + "\r\n";
          })
      );
    }
  }



  return (
    <>
      <Box sx={{ width: "96%" }} m={2}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};

            return (
              <Step key={label} {...stepProps}>
                <StepButton>{label}</StepButton>
              </Step>
            );
          })}
        </Stepper>

       
          <div>
            <form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              {showStepContents(activeStep)}
            </form>
          </div>
       

        {activeStep === steps.length ? (
          <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />

              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </React.Fragment>
        )}

        {/* Dialog to implement the Progess bar ................ */}
        <ProgressorBarDisplayer
          titleMessage={"Please Wait! Processing your request....."}
          openProgressBarDialog={openProgressBarDialog}
        />

        <ProgressorBarDisplayer
          titleMessage={dialog2Message}
          openProgressBarDialog={openProgressBarDialog2}
        />
      </Box>
    </>
  );
}
