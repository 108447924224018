import "./MemberOfficialDetailsViewer.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import LinearProgress from "@mui/material/LinearProgress";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import useMyAuthService from "../../../../authenticationServiceProvider/useMyAuthService";
import { DialogActions } from "@mui/material";
import { Button } from "@mui/material";

const OfficialDetailsViewer = () => {
  const navigate = useNavigate();

  const {loggedInUserRUID, api} = useMyAuthService();
  const [currentLoggedInRUID, setCurrentLoggedInRUID] = useState(
   loggedInUserRUID
  );

  const [rbMemberOfficialData, setRBMemberOfficialDetails] = useState({});

  const [openPaySlipDisplayer, setOpenPaySlipDisplayer] = useState(false);
  const [openPaySlipUpdator, setOpenPaySlipUpdator] = useState(false);
  const [paySlipFile, setPaySlipFile] = useState();

  const [paySlipFileDetailsForUpload, setPaySlipFileDetailsForUpload] =
    useState();

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

  useEffect(() => {
    getRBMemberOfficialDetails();
  }, []);

  const getRBMemberOfficialDetails = () => {
    api
      .get(
        `/getRBMemberOfficialDetails/${currentLoggedInRUID}`
      )
      .then((response) => {
        //console.log(response.data);
        setRBMemberOfficialDetails(response.data);
        //console.log(regData);
        setIsLoadingData(false);
      });
  };

  const handleOpenOfficialDetailsUpdaterComponent = () => {
    navigate("../UpdateOfficialDetails");
  };

  const handleOpenPaySlipDisplayer = (ruid) => {
    //console.log(orderId);
    api({
      url: `/GetPaySlipFile/${ruid}`,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      //console.log(response.data);
      setPaySlipFile(fileURL);
      setOpenPaySlipDisplayer(true);
    });
  };

  const handleOpenPaySlipUpdator = () => {
    setOpenPaySlipUpdator(true);
  };

  const handleClosePaySlipDisplayerDialog = () => {
    setOpenPaySlipDisplayer(false);
  };

  const handleClosePaySlipUpdatorDialog = () => {
    setOpenPaySlipUpdator(false);
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setPaySlipFileDetailsForUpload(e.target.files[0]);
    }
  };

  const handleSubmitUploadMemberPaySlip = async (e) => {
    e.preventDefault();
    //console.log("form has been submitted");

    const fd = new FormData();
    fd.append("paySlipFileName", paySlipFileDetailsForUpload.name);
    fd.append("paySlipFile", paySlipFileDetailsForUpload);
    fd.append("ruid", currentLoggedInRUID);

    //console.log(data);
    let config = {
      headers: {
        Accept: "multipart/FormData",

        //  Authorization: varToken
      },
      responseType: "json",
    };

    setOpenProgressBarDialog(true);

    api
      .post(`/UploadPaySlipFile`, fd, config)
      .then((response) => {
        //console.log(response.data);
        //console.log(response.data);
        setOpenPaySlipUpdator(false);
        setOpenProgressBarDialog(false);
        handleOpenPaySlipDisplayer(currentLoggedInRUID);
      });
  };

  return (
    <div className="OfficialDetailsViewerMainDiv">
      <fieldset>
        <legend>🔍 View Your Official Details</legend>
        {isLoadingData ? (
          <div className="isLoadingDiv">Loading Data ..... </div>
        ) : (
          <>
            <div className="gridRow">
              <div className="gridCol">
                <table>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{rbMemberOfficialData.name}</td>
                    </tr>
                    <tr>
                      <td>RUID</td>
                      <td>{rbMemberOfficialData.rbMemberRUID}</td>
                    </tr>
                    <tr>
                      <td>Designation</td>
                      <td>{rbMemberOfficialData.designation}</td>
                    </tr>
                    <tr>
                      <td>Branch</td>
                      <td>{rbMemberOfficialData.branch}</td>
                    </tr>
                    <tr>
                      <td>Employee Id</td>
                      <td>{rbMemberOfficialData.employeeId}</td>
                    </tr>
                    <tr>
                      <td>Railway Tel No.</td>
                      <td>{rbMemberOfficialData.railwayTelNo}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="gridCol">
                <table>
                  <tbody>
                    <tr>
                      <td>Date Of Appointment</td>
                      <td>{rbMemberOfficialData.dateOfAppointment}</td>
                    </tr>
                    <tr>
                      <td>Date Of Retirement</td>
                      <td>{rbMemberOfficialData.dateOfRetirement}</td>
                    </tr>
                    <tr>
                      <td>Grade Pay (PayLevel)</td>
                      <td>
                        {rbMemberOfficialData.gradePay} (Level:{" "}
                        {rbMemberOfficialData.payLevel})
                      </td>
                    </tr>
                    <tr>
                      <td>Basic Pay</td>
                      <td>{rbMemberOfficialData.basicPay}</td>
                    </tr>
                    <tr>
                      <td>Cadre Branch</td>
                      <td>{rbMemberOfficialData.cadreControllingBranch}</td>
                    </tr>
                    <tr>
                      <td>Salary Branch</td>
                      <td>{rbMemberOfficialData.salaryReportingBranch}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <hr></hr>
            <div className="gridRow">
              <div className="FormActionDiv gridCol">
                <input
                  type={"button"}
                  onClick={() => handleOpenOfficialDetailsUpdaterComponent()}
                  value="🖆 Update Official Details"
                />
              </div>
              <div className="FormActionDiv gridCol">
                <input
                  type={"button"}
                  onClick={() => handleOpenPaySlipUpdator()}
                  value="💰 Update Pay Slip"
                />
              </div>
              <div className="FormActionDiv gridCol">
                <input
                  type={"button"}
                  onClick={() => handleOpenPaySlipDisplayer(currentLoggedInRUID)}
                  value="💰 View Pay Slip"
                />
              </div>
            </div>
          </>
        )}

        {/* Dialog to implement the Progess bar ................ */}
        <Dialog open={openPaySlipDisplayer} fullWidth maxWidth="lg">
          <DialogActions id="scroll-dialog-ActionDialogActions">
            <span className="titleDialogBox">
              Salary Slip Viewer
            </span>
            <Button
              variant="outlined"
              onClick={handleClosePaySlipDisplayerDialog}
            >
              ❌
            </Button>
          </DialogActions>
          <DialogContent>
            <div className="pdfDisplayerDialogContentDiv">
              <iframe src={paySlipFile}></iframe>
            </div>
          </DialogContent>
        </Dialog>

        {/* Dialog to implement the Progess bar ................ */}
        <Dialog open={openPaySlipUpdator} fullWidth maxWidth="sm">
          <DialogActions id="scroll-dialog-ActionDialogActions">
            <span className="titleDialogBox">Pay Slip Uploader Dialog</span>
            <Button
              variant="outlined"
              onClick={handleClosePaySlipUpdatorDialog}
            >
              ❌
            </Button>
          </DialogActions>
          <DialogContent>
            <form
              onSubmit={(e) => {
                handleSubmitUploadMemberPaySlip(e);
              }}
            >
              <div className="FormFieldDiv">
                <div>
                  <label>Pay Slip File</label>
                </div>
                <div>
                  <input
                    accept=".pdf"
                    type={"file"}
                    placeholder=" Enter Your Order Date"
                    onChange={(e) => {
                      handleFileChange(e);
                    }}
                    required
                  />
                </div>
              </div>
              <hr />
              <div className="FormActionDiv">
                <input type={"button"} value="Clear Form" />
                <input type={"submit"} value="💾 Save and Upload" />
              </div>
            </form>
          </DialogContent>
        </Dialog>

        {/* Dialog to implement the Progess bar ................ */}
        <Dialog open={openProgressBarDialog} fullWidth maxWidth="sm">
          <DialogTitle id="scroll-dialog-title">
            <b>Salary Slip Uploader </b>
          </DialogTitle>
          <DialogContent>
            <LinearProgress />
          </DialogContent>
        </Dialog>
      </fieldset>
    </div>
  );
};

export default OfficialDetailsViewer;
