import React from "react";
import "./CreateNewRBCTCSStaff.css";

import { useState } from "react";

import useMyAuthService from "../../../../authenticationServiceProvider/useMyAuthService";

let createStaffForm = {
  staffId: "",
  name: "",
  dateOfBirth: "",
  panNo: "",
  homeAddress: "",
  permanentAddress: "",
  staffPhoto: "upload",
  staffSign: "upload",
  dateOfAppointment: "",
  dateOfRetirement: "",
  designation: "",
  branchRoomNo: "",
  railwayTelNo: "",
  basicPay: "",
  salaryBankName: "",
  salaryBankAccountNo: "",
  salaryBankIFSC: "",
  mobileNo: "",
  emailId: "",
};

function CreateNewRBCTCSStaff() {
  const { api } = useMyAuthService();

  /** The data elements of the form to be stored in these variables */
  const [createStaffFormData, setCreateStaffFormData] =
    useState(createStaffForm);
  const [errorMessage, setErrorMessage] = useState();
  const [message, setMessage] = useState();

  async function handleSubmit(e) {
    e.preventDefault();
    //console.log("form has been submitted");
    // console.log(createStaffFormData);

    const data = createStaffFormData;
    //console.log(data);
    let config = {
      method: "POST",
      url: `/CreateNewRBCTCSStaffDetails`,
      data: { data },
      headers: {
        Accept: "application/json",

        //  Authorization: varToken
      },
      responseType: "json",
    };

    const response = await api(config);
    console.log(response.data);
    //setMessage(response.data);
  }

  const setMobileMobileNoAndStaffId = (mobileNo) => {
    // Calculate the Staff Id
    const staffId = "ST" + mobileNo.slice(-5);

    // Update the mobile No and staff Id together
    setCreateStaffFormData({
      ...createStaffFormData,
      mobileNo: mobileNo,
      staffId: staffId,
    });
  };

  return (
    <div className="StaffCreationMainDiv">
      <fieldset>
        <legend>Create a Staff for RBCTCS </legend>
        {/* {errorMessage && <div className="errorDiv">{errorMessage}</div>}
        {message && <div className="msgDiv">{message}</div>} */}
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="gridRow">
            <div className="gridCol">
              <div className="FormFieldDiv">
                <div>
                  <label>Staff Name</label>
                </div>
                <div>
                  <input
                    type={"text"}
                    name="staffName"
                    placeholder="Enter the Staff Name "
                    value={createStaffFormData.name}
                    onChange={(e) =>
                      setCreateStaffFormData({
                        ...createStaffFormData,
                        name: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
              <div className="FormFieldDiv">
                <div>
                  <label>Date Of Birth</label>
                </div>
                <div>
                  <input
                    type={"date"}
                    name="dobStaff"
                    placeholder="Enter Date of Birth "
                    value={createStaffFormData.dateOfBirth}
                    onChange={(e) =>
                      setCreateStaffFormData({
                        ...createStaffFormData,
                        dateOfBirth: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
              <div className="FormFieldDiv">
                <div>
                  <label>PAN No.</label>
                </div>
                <div>
                  <input
                    type={"text"}
                    name="panNo"
                    placeholder="Enter the PAN No "
                    value={createStaffFormData.panNo}
                    onChange={(e) =>
                      setCreateStaffFormData({
                        ...createStaffFormData,
                        panNo: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>

              <div className="FormFieldDiv">
                <div>
                  <label>Home Address</label>
                </div>
                <div>
                  <textarea
                    rows={4}
                    cols={32}
                    name="homeAddress"
                    placeholder="Enter the Home Address"
                    value={createStaffFormData.homeAddress}
                    onChange={(e) =>
                      setCreateStaffFormData({
                        ...createStaffFormData,
                        homeAddress: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
            </div>
            <div className="gridCol">
              <div className="FormFieldDiv">
                <div>
                  <label>Designation</label>
                </div>
                <div>
                  <input
                    type={"text"}
                    name="designationStaff"
                    placeholder="Enter the Designation for Staff "
                    value={createStaffFormData.designation}
                    onChange={(e) =>
                      setCreateStaffFormData({
                        ...createStaffFormData,
                        designation: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
              <div className="FormFieldDiv">
                <div>
                  <label>Date of Appointment</label>
                </div>
                <div>
                  <input
                    type={"date"}
                    name="doaStaff"
                    placeholder="Enter the DOA "
                    value={createStaffFormData.dateOfAppointment}
                    onChange={(e) =>
                      setCreateStaffFormData({
                        ...createStaffFormData,
                        dateOfAppointment: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
              <div className="FormFieldDiv">
                <div>
                  <label>Date of Retirement</label>
                </div>
                <div>
                  <input
                    type={"date"}
                    name="dorStaff"
                    placeholder="Enter the DOR "
                    value={createStaffFormData.dateOfRetirement}
                    onChange={(e) =>
                      setCreateStaffFormData({
                        ...createStaffFormData,
                        dateOfRetirement: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>

              <div className="FormFieldDiv">
                <div>
                  <label>Permanent Address</label>
                </div>
                <div>
                  <textarea
                    rows={4}
                    cols={32}
                    name="permanentAddress"
                    placeholder="Enter the Permanent Address"
                    value={createStaffFormData.permanentAddress}
                    onChange={(e) =>
                      setCreateStaffFormData({
                        ...createStaffFormData,
                        permanentAddress: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
            </div>
            <div className="gridCol">
              <div className="FormFieldDiv">
                <div>
                  <label>Branch Room No</label>
                </div>
                <div>
                  <input
                    type={"number"}
                    name="branchRoom"
                    placeholder="Enter the Office Room No "
                    value={createStaffFormData.branchRoomNo}
                    onChange={(e) =>
                      setCreateStaffFormData({
                        ...createStaffFormData,
                        branchRoomNo: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
              <div className="FormFieldDiv">
                <div>
                  <label>Railway Tel No.</label>
                </div>
                <div>
                  <input
                    type={"number"}
                    name="railwayTelNo"
                    placeholder="Enter the Railway Tel No "
                    value={createStaffFormData.railwayTelNo}
                    onChange={(e) =>
                      setCreateStaffFormData({
                        ...createStaffFormData,
                        railwayTelNo: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
              <div className="FormFieldDiv">
                <div>
                  <label>Mobile No.</label>
                </div>
                <div>
                  <input
                    type={"number"}
                    name="mobile"
                    placeholder="Enter the mobile No. "
                    value={createStaffFormData.mobileNo}
                    onChange={(e) =>
                      setMobileMobileNoAndStaffId(e.target.value)
                    }
                    required
                  />
                </div>
              </div>
              <div className="FormFieldDiv">
                <div>
                  <label>Email Id</label>
                </div>
                <div>
                  <input
                    type={"email"}
                    name="emailId"
                    placeholder="Enter the Email Id "
                    value={createStaffFormData.emailId}
                    onChange={(e) =>
                      setCreateStaffFormData({
                        ...createStaffFormData,
                        emailId: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
            </div>
            <div className="gridCol">
              <div className="FormFieldDiv">
                <div>
                  <label>Salary/Pay</label>
                </div>
                <div>
                  <input
                    type={"number"}
                    name="salary"
                    placeholder="Enter the Salary/pay "
                    value={createStaffFormData.basicPay}
                    onChange={(e) =>
                      setCreateStaffFormData({
                        ...createStaffFormData,
                        basicPay: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
              <div className="FormFieldDiv">
                <div>
                  <label>Salary Bank Name</label>
                </div>
                <div>
                  <input
                    type={"text"}
                    name="salaryBank"
                    placeholder="Enter the Salary Bank "
                    value={createStaffFormData.salaryBankName}
                    onChange={(e) =>
                      setCreateStaffFormData({
                        ...createStaffFormData,
                        salaryBankName: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
              <div className="FormFieldDiv">
                <div>
                  <label>Salary Bank Account</label>
                </div>
                <div>
                  <input
                    type={"number"}
                    name="salBankAcc"
                    placeholder="Enter the Bank Account No. "
                    value={createStaffFormData.salaryBankAccountNo}
                    onChange={(e) =>
                      setCreateStaffFormData({
                        ...createStaffFormData,
                        salaryBankAccountNo: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
              <div className="FormFieldDiv">
                <div>
                  <label>Salary Bank IFSC</label>
                </div>
                <div>
                  <input
                    type={"text"}
                    name="ifscCode"
                    placeholder="Enter the IFSC Code "
                    value={createStaffFormData.salaryBankIFSC}
                    onChange={(e) =>
                      setCreateStaffFormData({
                        ...createStaffFormData,
                        salaryBankIFSC: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="FormActionDiv">
            <input type={"button"} value="Clear Form" />
            <input type={"submit"} value="Create Staff for RBCTCS" />
          </div>
        </form>
      </fieldset>
    </div>
  );
}

export default CreateNewRBCTCSStaff;
