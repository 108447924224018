import React, { useEffect, useState } from "react";
import "./VerifyLoanApplications.css";

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import TimeLine from "../../../generalComponents/TimeLines/TimeLine";
import LoanApplicationVerificationSteps from './VerificationSteps';

import { useVerifyLoanApplicationsState } from "./StateForVerifyLoanApplications";


import LoanApplicationsToBeVerified from "./LoanApplicationsToBeVerified/LoanApplicationsToBeVerified";


function VerifyLoanApplications() {

  const state = useVerifyLoanApplicationsState();

  const {
    
    optionsForApprovedLoanApplications,
    optionsForLoanApplicationsToBeVerified
  } = state;


  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  

 
  return (
    // Div to show the applications table for the incoming verification request of the Loans................
    <div style={{ display: "flex" }} className='VerifyLoanApplicationsMainDiv'>
      <div style={{ flex: 1 }}>
        <fieldset className="scrollWithoutScrollBar">
          <legend>📑 Verification of Loan Applications</legend>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Manage Surety Tabs"
              indicatorColor="secondary"
            >
             <Tab
                label="STEP# 1: Verify Application Details"
                {...a11yProps(0)}
              />
              <Tab label="STEP# 2: View Approved Loans " {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {/* <div> */}
              {/* Loan Application To be Verified component */}
              <LoanApplicationsToBeVerified applicationStatusOptions ={optionsForLoanApplicationsToBeVerified} />
            {/* </div> */}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {/* <div>Approved Loans component */}
              <LoanApplicationsToBeVerified applicationStatusOptions ={optionsForApprovedLoanApplications} />
            {/* </div> */}
          </TabPanel>
        </fieldset>
      </div>
      <div style={{ flex: "0 0 200px", marginLeft: "10px" }}>
        <TimeLine steps={LoanApplicationVerificationSteps} />
      </div>
    </div>
  );
  
}



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



export default VerifyLoanApplications;
