import "./FinalPrintReportForDisbursal.css";

import React, { useRef }  from 'react'
import { useLocation } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print';



const FinalPrintReportForDisbursal = () => {
    const data = useLocation();
    const loansForDisbursal = data.state.loanApplicationsForDisbursal;
    const chequeForm = data.state.chequeForm;
    
    //console.log(loansForDisbursal)
   
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    return (
      <>
        {/* {JSON.stringify(loansForDisbursal)} */}
        <div className="LDRActionsDiv">
          <input type={'button'} value={"🖨 Print Report"} onClick={handlePrint}/>
        </div>
        <ComponentToPrint ref={componentRef}  loansForDisbursal={loansForDisbursal} chequeForm={chequeForm}/>
      </>
    );
}

export default FinalPrintReportForDisbursal

//componenet to print ...............
export const ComponentToPrint = React.forwardRef((props, ref) => {
  const {loansForDisbursal, chequeForm} = props;
  let sn = 1, total =0;
  return (
    <div ref={ref} className="reportMainDiv" id="printablediv">
        <div className="loanBond1HeaderDiv">
          <h3>RAILWAY BOARD CO-OPERATIVE THRIFT & CREDIT SOCIETY LTD.</h3>
          <hr />
          <div>Room No. 415-C, Rail Bhawan, New Delhi. Contact No.23303596</div>
          <div>(Registered under the Co-Op. Societies Act. 11 of 1912)</div>
          <div>(Registration No. 374U(A) Dated 01-07-1931)</div>
        </div>
        <div className="divAddressee">
          The Manager, <br />
          State Bank of India, <br />
          Rail Bhavan, New Delhi <br />
        </div>
        <div className="divSalutation">Dear Sir/Madam,</div>
        <div className="divPara">
          <p>
            We enclose a cheque bearing no.- {chequeForm.chequeNo} dated.- {chequeForm.chequeDate} for
            Rs-{chequeForm.chequeAmount} /-(Rs- {convertNumberToWords(chequeForm.chequeAmount)}Only.) and authorize
            you to debit the above A/C and request for transfer the amount as
            per list below...
          </p>
        </div>
        <div>
          <table className="LDRContentTable2">
            <thead>
              <tr>
                <td>Sl No.</td>
                <td>Name</td>
                <td>L/F No.</td>
                <td>Bank </td>
                <td>S/A Account No.</td>
                <td>IFSC</td>
                <td>Amount</td>
                <td>Amount (In words)</td>
              </tr>
            </thead>
            <tbody>
              {Object.keys(loansForDisbursal).map((key, index) => {
                //calculating the total amount
                (loansForDisbursal[key].isChecked === true)?total+=loansForDisbursal[key].loanAmountSought:total=total;
                //returning the table rows to be  printed......
                return loansForDisbursal[key].isChecked === true ? (
                  <tr key={key}>
                    <td>{sn++} </td>
                    <td>{loansForDisbursal[key].rbmembers.name}</td>
                    <td>{loansForDisbursal[key].rbmembers.ledgerNo}</td>
                    <td>{loansForDisbursal[key].rbmembers.salaryBankName}</td>
                    <td>
                      {loansForDisbursal[key].rbmembers.salaryBankAccountNo}
                    </td>
                    <td>{loansForDisbursal[key].rbmembers.salaryBankIFSC}</td>
                    <td>{loansForDisbursal[key].loanAmountSought}</td>
                    <td>
                      {convertNumberToWords(
                        loansForDisbursal[key].loanAmountSought
                      )}
                    </td>
                  </tr>
                ) : (
                  ""
                );
              })}
              <tr>
                <td colSpan={6}>Total</td>
                <td>{total}</td>
                <td>{convertNumberToWords(total)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="divSign">
          Signature by <br />
          DDO/Authorized Signatory <br />
          with Official Seal And date:<br />
        </div>
        <div className="divOfficeUse">
          <u>Office Use:</u>
        </div>
        <div className="divPara">
          <p>
          The signature of DDO/Authorized Signatory have been verified both on letter and list (if applicable) and Transfer have been effected as per the aforesaid mandate.					

          </p>
         
        </div>
        <div className="divSign">
          Signature of authorized official of Bank	  <br/>				
          with seal and date.	

        </div>
      </div>
  );
}); 



function convertNumberToWords(amount) {
  var words = new Array();
  words[0] = '';
  words[1] = 'One';
  words[2] = 'Two';
  words[3] = 'Three';
  words[4] = 'Four';
  words[5] = 'Five';
  words[6] = 'Six';
  words[7] = 'Seven';
  words[8] = 'Eight';
  words[9] = 'Nine';
  words[10] = 'Ten';
  words[11] = 'Eleven';
  words[12] = 'Twelve';
  words[13] = 'Thirteen';
  words[14] = 'Fourteen';
  words[15] = 'Fifteen';
  words[16] = 'Sixteen';
  words[17] = 'Seventeen';
  words[18] = 'Eighteen';
  words[19] = 'Nineteen';
  words[20] = 'Twenty';
  words[30] = 'Thirty';
  words[40] = 'Forty';
  words[50] = 'Fifty';
  words[60] = 'Sixty';
  words[70] = 'Seventy';
  words[80] = 'Eighty';
  words[90] = 'Ninety';
  amount = amount.toString();
  var atemp = amount.split(".");
  var number = atemp[0].split(",").join("");
  var n_length = number.length;
  var words_string = "";
  if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (var i = 0; i < n_length; i++) {
          received_n_array[i] = number.substr(i, 1);
      }
      for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
          n_array[i] = received_n_array[j];
      }
      for (var i = 0, j = 1; i < 9; i++, j++) {
          if (i == 0 || i == 2 || i == 4 || i == 7) {
              if (n_array[i] == 1) {
                  n_array[j] = 10 + parseInt(n_array[j]);
                  n_array[i] = 0;
              }
          }
      }
      let value = "";
      for (var i = 0; i < 9; i++) {
          if (i == 0 || i == 2 || i == 4 || i == 7) {
              value = n_array[i] * 10;
          } else {
              value = n_array[i];
          }
          if (value != 0) {
              words_string += words[value] + " ";
          }
          if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
              words_string += "Crores ";
          }
          if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
              words_string += "Lakhs ";
          }
          if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
              words_string += "Thousand ";
          }
          if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
              words_string += "Hundred and ";
          } else if (i == 6 && value != 0) {
              words_string += "Hundred ";
          }
      }
      words_string = words_string.split("  ").join(" ");
  }
  return words_string;
}