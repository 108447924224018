import React, { useState } from "react";
import Button from "@mui/material/Button";

import "../TableDataDisplayer.css";
import PaginationNumberRendering from "../PaginationNumberRendering/PaginationNumberRendering";

const LRHWTableData = ({ className, tranactionsList }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPage = 20; // Number of items to display per page

  // Calculate the total number of pages
  const totalPages = Math.ceil(tranactionsList.length / itemsPerPage);

  // Get the current page's items based on the currentPage and itemsPerPage
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tranactionsList
    .filter((loanApp) =>
      Object.values(loanApp)
        .join(" ")
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    )
    .slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle search query change
  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page when search query changes
  };

  const maxPageNumbersToShow = 5; // Adjust the number of page numbers to show

  
  return (
    <div className="table-data-displayer">
      <div className="quickSearchBoxDiv">
        <input
          type="text"
          placeholder="Search RUID..."
          value={searchQuery}
          onChange={handleSearchQueryChange}
        />
      </div>

      <table className={className}>
        <thead>
          <tr>
            <td>Sl.</td>
            <td>Reference No</td>
            <td>Mode</td>
            <td>Member</td>
            <td>Account Head</td>
            <td>Transaction Amount</td>
            <td>Transaction Date</td>
            <td>Dr/Cr</td>
            <td>Status</td>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((transaction, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{transaction.transactionReferenceNo}</td>
                <td className="justifyContent">
                  {transaction.transactionModeCode}
                </td>
                <td>{transaction.rbMemberLedgerNo}</td>
                <td>{transaction.transactionHeadCode}</td>
                <td>{transaction.transactionAmount}</td>
                <td>{transaction.transactionDate}</td>
                <td>{transaction.transactionDrOrCr}</td>
                <td>{transaction.transactionStatus}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Pagination */}
      <PaginationNumberRendering 
          maxPageNumbersToShow={maxPageNumbersToShow}
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default LRHWTableData;
