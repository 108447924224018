import React from 'react';
import { useNavigate } from 'react-router-dom';
import DashBoardFooter from '../../generalComponents/DashBoardFooter/DashBoardFooter';
import DashBoardHeader from '../../generalComponents/DashBoardHeader/DashBoardHeader';
import DashBoardSideNav from '../../generalComponents/DashBoardSideNav/DashBoardSideNav';
import DashWorkSpace from '../../generalComponents/DashWorkSpace/DashWorkSpace';
import "./NewUserDashHome.css";


const menuTree = [
  {
    id: 1,
    head: "Manage Registration  Details",
    subMenu: ["Manage Registration Details", "Update LOG In Password" ],
  },
  {
    id: 2,
    head: "Manage Your Application Status",
    subMenu: ["View-Manage Status"],
  },
  {
    id: 3,
    head: "Apply For New Membership",
    subMenu: ["Apply & Submit Your Details"
    // , "Upload Payment Details & Salary Slip "
             ],
  }
];

const NewUserDashHome = () => {
  const navigate = useNavigate();
  const selectedMenuItem =item=>{
      navigate(item.replace(/ /g,''));
  }

  return (
    <div className='NewUserDashMainDiv'>
      <DashBoardHeader></DashBoardHeader>
      <div className='NewUserDashBoardBody'>
        <DashBoardSideNav menuTree={menuTree} selectedMenuItem={selectedMenuItem}></DashBoardSideNav>
        <DashWorkSpace></DashWorkSpace>
      </div>
      <DashBoardFooter></DashBoardFooter>
    </div>
  )
}

export default NewUserDashHome