import React from 'react'

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import LinearProgress from "@mui/material/LinearProgress";


const ProgressorBarDisplayer = ({titleMessage, openProgressBarDialog}) => {

  return (
    <div>
        {/* Dialog to implement the Progess bar ................ */}
      <Dialog
        open={openProgressBarDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="scroll-dialog-title">
          <b>{titleMessage}</b>
        </DialogTitle>
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default ProgressorBarDisplayer