import "./DashBoardSideNav.css";

import React from "react";
import MenuListItems from "../../generalComponents/MenuList/MenuListItems";



function MemberSideNav(Props) {
  const {menuTree, selectedMenuItem} = Props;
  const MenuTree = menuTree;
  const MenuHeaderList = MenuTree.map((menu, id) => (
    <ul key={menu.id}>
      <b>{menu.head}</b>
      <MenuListItems menuList={menu.subMenu} selectedMenuItem={selectedMenuItem}/>
    </ul>
  ));

  return <div className="sideNavBar">{MenuHeaderList}</div>;
}

export default MemberSideNav;
