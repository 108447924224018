import "./MemberBankDetailsViewByStaffOrAdmin.css";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useMyAuthService  from "../../../../authenticationServiceProvider/useMyAuthService";
import ProgressorBarDisplayer from "../../DialogComponents/ProgressorBarDisplayer/ProgressorBarDisplayer";
import RBMembersListDropDown from "../../RBMembersListDropDown/RBMembersListDropDown";

function MemberBankDetailsViewByStaffOrAdmin() {

  const navigate = useNavigate();
  const {loggedInUserRUID, api} = useMyAuthService();
  
  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

 const [selectedMemberRUID, setSelectedMemberRUID] = useState("");

  const [rbMemberBankData, setRBMemberBankDetails] = useState({});

  const [isLoadingData, setIsLoadingData] = useState(true);

  

  const getRBMemberBankDetails = () => {
    api.get(
        `/getRBMemberBankDetails/${selectedMemberRUID}`
      )
      .then((response) => {
        // console.log(response.data);
        setRBMemberBankDetails(response.data);
        //console.log(regData);
        setIsLoadingData(false);
      });
  };

  const GetMemberBankingDetails = ()=>{
    setOpenProgressBarDialog(true);
    getRBMemberBankDetails();
     setOpenProgressBarDialog(false);
  }

  return (
    <div className="MemberBankDetailsViewByStaffOrAdminMainDiv">
      <fieldset>
        <legend>🔍 View Member Bank Details</legend>
        
        <RBMembersListDropDown 
            setSelectedMemberRUID={setSelectedMemberRUID}
            actionButtonText={"Click to View Member Bank Details"}
            actionButtonFunction={GetMemberBankingDetails}
          />
         <hr/>
          <>
            <div className="gridRow">
              <div className="gridCol">
                <table>
                  <tbody>
                    <tr>
                      <td>Salary Bank Name</td>
                      <td>{rbMemberBankData.salaryBankName}</td>
                    </tr>
                    <tr>
                      <td>Salary Bank Account No.</td>
                      <td>{rbMemberBankData.salaryBankAccountNo}</td>
                    </tr>
                    <tr>
                      <td>Salary Bank IFSC Code</td>
                      <td>{rbMemberBankData.salaryBankIFSC}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
          </>
          <ProgressorBarDisplayer
             titleMessage = {'Loading Member Personal Details'} openProgressBarDialog = {openProgressBarDialog} />

      </fieldset>
    </div>
  );
}

export default MemberBankDetailsViewByStaffOrAdmin;
