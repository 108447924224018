
import React, { Component } from 'react';
import { Outlet } from "react-router-dom";
function BankBookReport() {
  return (
    <div className='loanDisbursmentReportMainDiv'>
      <fieldset>
        <legend>📑 Bank Book Report</legend>
        <Outlet></Outlet>
      </fieldset>
    </div> 
    
  ) 
}

export default BankBookReport