const loanApplicationVerificationSteps = [
    {
      title: "#1: Verify Application",
      description: "Check the Application Details Submitted/Re-Submitted by the RB Member. Mark the Application for Review, if any errors. Otherwise, if found correct, Submit the Application for approval by alloting a ledger No. to the RB Member.",
    },
    {
      title: "#2: View Approvals",
      description: "See/Check the Status of Approval of the Member Loan Applications ",
    },
  ];
  
export default loanApplicationVerificationSteps;
  