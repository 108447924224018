import React, { useState, useEffect } from "react";

import "./NewMembershipDocumentationUpload.css"


import PDFGeneration from "./PDFGeneration/PDFGeneration";
import CombinedPDFUploadModal from "./CombinedAuthenticatedPDFUploadModal/CombinedAuthenticatedPDFUploadModal";

import { useLocation } from "react-router-dom";
import useMyAuthService from "../../../authenticationServiceProvider/useMyAuthService";
import { renderToStaticMarkup } from 'react-dom/server';

import NewMembershipFormTemplate from "./NewMembershipFormTemplate/NewMembershipFormTemplate";


const NewMembershipDocumentationUpload = (props) => {

  const location = useLocation();
  const memberApp = location.state; // Access the passed props

  const updateType = memberApp.updateType;
  
  const [modalIsOpen, setModalIsOpen] = useState(false);
  
  const htmlString =  NewMembershipFormTemplate(memberApp.memberDetails)

  const handleUpload = () => {
    // Implement any further logic needed after the combined PDF is uploaded
    // ...

    // Close the modal
    setModalIsOpen(false);
  };

  return (
    <div className="membershipDocsUploadMainDiv">
      <fieldset>
        <legend>
          🖥️ Get the Application Form Authenticated and Re-Upload along with
          Documents
        </legend>
        {/* {htmlString} */}
        <div>
          <h2>New Membership Documentation Upload</h2>
          <p>
            Please follow the steps below to complete the document upload
            process:
          </p>
          <ol>
            <li>Download the application form by clicking the button below:</li>
          </ol>
          
          {/* Use PDFGenerator to generate PDF using the filled template */}
          <PDFGeneration docTitle = {'New Membership Application Form'}  htmlString = {htmlString} />
          
          <ol start={2}>
            <li>Get the form authenticated from ERB-IV branch.</li>
            <li>Compile the following documents into a single PDF:</li>
            <ul>
              <li>Authenticated Application Form</li>
              <li>Aadhar Card</li>
              <li>PAN Card</li>
              <li>Confirmation Letter</li>
              <li>Identity Card</li>
              <li>Salary Slip</li>
              <li>First Page of Bank Passbook</li>
            </ul>
            <li>Click below to upload the Combined PDF of the documents</li>
          </ol>

          <div className="FormFieldDiv">
            <input
              type="button"
              onClick={() => setModalIsOpen(true)}
              value={"Upload the Authenticated Form with Documents in one PDF"}
            />
          </div>

          <CombinedPDFUploadModal
            isOpen={modalIsOpen}
            closeModal={() => setModalIsOpen(false)}
            onUpload={handleUpload}
            updateType={updateType}
          />
          {/* Form Generation Component */}
          {/* <FormGeneration responseData={pdfBlob} /> */}
          {/* PDF Generation Component */}
        </div>
      </fieldset>
    </div>
  );
};

export default NewMembershipDocumentationUpload;
