import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import PortalWithoutLogin from "./components/PortalWithoutLogin/PortalWithoutLogin";
import AboutUsWriteUp from "./components/AboutUsWriteUp/AboutUsWriteUp";
import BulletinBoard from "./components/BulletinBoard/BulletinBoard";
import Login from "./components/LoginBox/LoginBox";
import WriteToRBCTCS from "./components/WriteToRBCTCS/WriteToRBCTCS";
import MemberDashHome from "./components/MembersDashComponents/MemberDashHome/MemberDashHome";
import NewUserDashHome from "./components/NewUserDashComponents/NewUserDashHome/NewUserDashHome";
import StaffRBCTCSDashHome from "./components/StaffRBCTCSDashComponents/StaffRBCTCSDashHome/StaffRBCTCSDashHome";
import AdminDashHome from "./components/AdminDashComponents/AdminDashHome/AdminDashHome";
import DashWorkSpace from "./components/generalComponents/DashWorkSpace/DashWorkSpace";
import NewUserPortalRegistration from "./components/NewUserPortalRegistration/NewUserPortalRegistration";
import RegistrationDetailsViewer from "./components/generalComponents/RegistrationDetailsViewer/RegistrationDetailsViewer";
import NewMembershipApplication from "./components/generalComponents/NewMembershipApplicationForm/NewMembershipApplication";
import ViewMembershipApplicationStatus from "./components/generalComponents/ViewMembershipApplicationStatus/ViewMembershipApplicationStatus";
import NewOrdinaryLoanApplicationForm from "./components/generalComponents/NewOrdinaryLoanApplicationForm/NewOrdinaryLoanApplicationForm";
import NewEmergentLoanApplicationForm from "./components/generalComponents/NewEmergentLoanApplicationForm/NewEmergentLoanApplicationForm";
import AccountsHeadCreation from "./components/StaffRBCTCSDashComponents/SettingFunctions/AccountsHeadCreation/AccountsHeadCreation";
import LoanDisbursementReport from "./components/generalComponents/ReportsComponents/LoanDisbursementReport/LoanDisbursementReport";
import LedgerReportHeadWise from "./components/generalComponents/ReportsComponents/LedgerReportHeadWise/LedgerReportHeadWise";
import LedgerReportMemberWise from "./components/generalComponents/ReportsComponents/LedgerReportMemberWise/LedgerReportMemberWise";
import BankBookReport from "./components/generalComponents/ReportsComponents/BankBookReport/BankBookReport";
import TrialBalanceReport from "./components/generalComponents/ReportsComponents/TrialBalanceReport/TrialBalanceReport";
import EmiDeductionsReport from "./components/generalComponents/ReportsComponents/EmiDeductionsReport/EmiDeductionsReport";
import SettingFeesforNewMembership from "./components/AdminDashComponents/SettingsFunctions/SettingFeesforNewMembership/SettingFeesforNewMembership";
import SettingOtherLimits from "./components/AdminDashComponents/SettingsFunctions/SettingOtherLimits/SettingOtherLimits";
import SettingLoanLimits from "./components/AdminDashComponents/SettingsFunctions/SettingLoanLimits/SettingLoanLimits";
import CreateNewRBCTCSStaff from "./components/StaffRBCTCSDashComponents/SettingFunctions/CreateNewRBCTCSStaff/CreateNewRBCTCSStaff";
import LoanApprovals from "./components/AdminDashComponents/ApprovalsByAdmin/LoanApprovals/LoanApprovals";
import MembershipApprovals from "./components/AdminDashComponents/ApprovalsByAdmin/MembershipApprovals/MembershipApprovals";
import VerifyMembershipApplications from "./components/StaffRBCTCSDashComponents/Verifications/VerifyMembershipApplications/VerifyMembershipApplications";
import VerifyLoanApplications from "./components/StaffRBCTCSDashComponents/Verifications/VerifyLoanApplications/VerifyLoanApplications";
import ViewStaffDetails from "./components/generalComponents/ViewStaffDetails/ViewStaffDetails";
import SettingForCompulsoryDeposit from "./components/AdminDashComponents/SettingsFunctions/SettingForCompulsoryDeposit/SettingForCompulsoryDeposit";
import SaveMembershipApplicationToServer from "./components/generalComponents/NewMembershipApplicationForm/SaveMembershipApplicationToServer/SaveMembershipApplicationToServer";

import PersonalDetailsViewer from "./components/generalComponents/ViewNewMembershipApplicationDetails/MemberPersonalDetailViewer/MemberPersonalDetailsViewer";
import OfficialDetailsViewer from "./components/generalComponents/ViewNewMembershipApplicationDetails/MemberOfficialDetailsViewer/MemberOfficialDetailsViewer";
import MemberBankDetailsViewer from "./components/generalComponents/ViewNewMembershipApplicationDetails/MemberBankDetailsViewer/MemberBankDetailsViewer";
import MemberOtherSocietyDetailsViewer from "./components/generalComponents/ViewNewMembershipApplicationDetails/MemberOtherSocietyDetailsViewer/MemberOtherSocietyDetailsViewer";
import MemberNomineesDetailsViewer from "./components/generalComponents/ViewNewMembershipApplicationDetails/MemberNomineesDetailsViewer/MemberNomineesDetailsViewer";
import RBCTCSMembershipDetailsViewer from "./components/generalComponents/ViewNewMembershipApplicationDetails/RBCTCSMembershipDetailsViewer/RBCTCSMembershipDetailsViewer";

import ViewMemberExistingLoans from "./components/generalComponents/ViewMemberExistingLoans/ViewMemberExistingLoans";
import ViewMemberLoanApplications from "./components/generalComponents/ViewMemberLoanApplications/ViewMemberLoanApplications";

import MemberPersonalDetailsUpdater from "./components/generalComponents/UpdateNewMembershipApplicationDetails/MemberPersonalDetailsUpdater/MemberPersonalDetailsUpdater";
import MemberOfficialDetailsUpdater from "./components/generalComponents/UpdateNewMembershipApplicationDetails/MemberOfficialDetailsUpdater/MemberOfficialDetailsUpdater";
import MemberBankPaymentDetailsUpdater from "./components/generalComponents/UpdateNewMembershipApplicationDetails/MemberBankPaymentDetailsUpdater/MemberBankPaymentDetailsUpdater";
import MemberBankDetailsUpdater from "./components/generalComponents/UpdateNewMembershipApplicationDetails/MemberBankDetailsUpdater/MemberBankDetailsUpdater";
import MemberOtherSocietyDetailsUpdater from "./components/generalComponents/UpdateNewMembershipApplicationDetails/MemberOtherSocietyDetailsUpdater/MemberOtherSocietyDetailsUpdater";
import MemberNomineeDetailsUpdater from "./components/generalComponents/UpdateNewMembershipApplicationDetails/MemberNomineeDetailsUpdater/MemberNomineeDetailsUpdater";

import ApplicationsReadyForDisbursal from "./components/generalComponents/ReportsComponents/LoanDisbursementReport/ApplicationsReadyForDisbursal/ApplicationsReadyForDisbursal";
import ManageLoanSureties from "./components/generalComponents/ManageLoanSureties/ManageLoanSureties";
import FillChequeDetailsForDisbursal from "./components/generalComponents/ReportsComponents/LoanDisbursementReport/FillChequeDetailsForDisbursal/FillChequeDetailsForDisbursal";
import FinalPrintReportForDisbursal from "./components/generalComponents/ReportsComponents/LoanDisbursementReport/FinalPrintReportForDisbursal/FinalPrintReportForDisbursal";
import EDRFilterInputsForReportGeneration from "./components/generalComponents/ReportsComponents/EmiDeductionsReport/EDRFilterInputsForReportGeneration/EDRFilterInputsForReportGeneration";
import FinalPrintReportForDeduction from "./components/generalComponents/ReportsComponents/EmiDeductionsReport/FinalPrintReportForDeduction/FinalPrintReportForDeduction";
import { AuthServiceProvider } from "./authenticationServiceProvider/authServiceProvider";
import RouteProtector from "./authenticationServiceProvider/RouteProtector";
import MiscPaymentsManager from "./components/generalComponents/MiscPaymentsManager/MiscPaymentsManager";
import PhotoSignatureUploader from "./components/generalComponents/PhotoSignatureUploader/PhotoSignatureUploader";
import CreateNewAdminDetails from "./components/StaffRBCTCSDashComponents/SettingFunctions/CreateNewAdminDetails/CreateNewAdminDetails";
import UpdateStaffDetails from "./components/generalComponents/UpdateStaffDetails/UpdateStaffDetails";
import ViewAdminDetails from "./components/generalComponents/ViewAdminDetails/ViewAdminDetails";
import UpdateAdminDetails from "./components/generalComponents/UpdateAdminDetails/UpdateAdminDetails";
import OfficeBearerDashHome from "./components/OfficeBearerDashComponents/OfficerBearerDashHome/OfficerBearerDashHome";
import ForgotPassword from "./components/ForgotPasswordComponent/ForgotPassword";
import UploadRBCTCSOrdersCirculars from "./components/generalComponents/UploadRBCTCSOrdersCirculars/UploadRBCTCSOrdersCirculars";
import ManageMessagesToRBCTCS from "./components/generalComponents/ManageMessagesToRBCTCS/ManageMessagesToRBCTCS";
import LRMWFilterInputsForReportGeneration from "./components/generalComponents/ReportsComponents/LedgerReportMemberWise/LRMWFilterInputsForReportGeneration/LRMWFilterInputsForReportGeneration";
import FinalPrintLRMW from "./components/generalComponents/ReportsComponents/LedgerReportMemberWise/FinalPrintLRMW/FinalPrintLRMW";
import MiscPaymentsTabView from "./components/generalComponents/MiscPaymentsManager/MiscPaymentsTabView.js/MiscPaymentsTabView";
import LRHWFilterInputsForReportGeneration from "./components/generalComponents/ReportsComponents/LedgerReportHeadWise/LRHWFilterInputsForReportGeneration/LRHWFilterInputsForReportGeneration";
import ManageLoanSuretiesTabs from "./components/generalComponents/ManageLoanSureties/ManageLoanSuretiesTabs/ManageLoanSuretiesTabs";
import NewMembershipPaymentDocSubmission from "./components/generalComponents/NewMembershipPaymentDocSubmission/NewMembershipPaymentDocSubmission";
import ManageSharePurchaseRequests from "./components/generalComponents/ManagePaymentRequests/ManageSharePurchaseRequests/ManageSharePurchaseRequests";
import NewOrdinaryLoanTopUpComponent from "./components/generalComponents/NewOrdinaryLoanTopUpComponent/NewOrdinaryLoanTopUpComponent";
import ViewAndDownloadLoanStatement from "./components/generalComponents/ViewAndDownloanLoanStatement/ViewAndDownloadLoanStatement";
import BackEndServerServiceProvider from "./BackEndServerServiceProvider/BackEndServerServiceProvider";
import BackEndXMLDataServiceProvider from "./BackEndXMLDataServiceProvider/BackEndXMLDataServiceProvider";
import PaymentDetailsUpdator from "./components/generalComponents/NewMembershipPaymentDocSubmission/PaymentDetailsUpdator/PaymentDetailsUpdator";
import NewEmergentLoanTopUpComponent from "./components/generalComponents/NewEmergentLoanTopUpComponent/NewEmergentLoanTopUpComponent";
import ViewAndDownloadCDStatement from "./components/generalComponents/ViewAndDownloadCDStatement/ViewAndDownloadCDStatement";
import ViewAndDownloadSharePurchaseStatement from "./components/generalComponents/ViewAndDownloadSharePurchaseStatement/ViewAndDownloadSharePurchaseStatement";
import UpdateLogInPasswordComponent from "./components/generalComponents/UpdateLogInPasswordComponent/UpdateLogInPasswordComponent";
import AddMemberNomineeComponent from "./components/generalComponents/AddMemberNomineeComponent/AddMemberNomineeComponent";
import ViewSharesPurchaseRequestsStatus from "./components/generalComponents/ManagePaymentRequests/ViewSharesPurchaseRequests/ViewSharesPurchaseRequestsStatus";
import BBRFilterInputsForReportGeneration from "./components/generalComponents/ReportsComponents/BankBookReport/BBRFilterInputsForReportGeneration/BBRFilterInputsForReportGeneration";
import TBRFilterInputsForReportGeneration from "./components/generalComponents/ReportsComponents/TrialBalanceReport/TBRFilterInputsForReportGeneration/TBRFilterInputsForReportGeneration";
import NewMembershipDocumentationUpload from "./components/generalComponents/NewMembershipDocumentationUpload/NewMembershipDocumentationUpload";
import ViewSuretiesReceived from "./components/generalComponents/ManageLoanSureties/ViewSuretiesReceived";
import ViewSuretiesGivenByMember from "./components/generalComponents/ManageLoanSureties/ViewSuretiesGivenByMember";
import ViewSuretiesGivenToMember from "./components/generalComponents/ManageLoanSureties/ViewSuretiesGivenToMember";
import MemberPersonalDetailsViewByStaffOrAdmin from "./components/generalComponents/ViewMemberDetailsByStaffORAdmin/MemberPersonalDetailViewer/MemberPersonalDetailsViewByStaffOrAdmin";
import MemberOfficialDetailsViewByStaffOrAdmin from "./components/generalComponents/ViewMemberDetailsByStaffORAdmin/MemberOfficialDetailsViewer/MemberOfficialDetailsViewByStaffOrAdmin";
import RBCTCSMembershipDetailsViewByStaffOrAdmin from "./components/generalComponents/ViewMemberDetailsByStaffORAdmin/RBCTCSMembershipDetailsViewer/RBCTCSMembershipDetailsViewByStaffOrAdmin";
import MemberNomineesDetailsViewByStaffOrAdmin from "./components/generalComponents/ViewMemberDetailsByStaffORAdmin/MemberNomineesDetailsViewer/MemberNomineesDetailsViewByStaffOrAdmin";
import MemberBankDetailsViewByStaffOrAdmin from "./components/generalComponents/ViewMemberDetailsByStaffORAdmin/MemberBankDetailsViewer/MemberBankDetailsViewByStaffOrAdmin";
import ReviewPaySlipUploader from "./components/generalComponents/ReviewLoanApplication/ReviewPaySlipUploader";
import ReviewLoanApplicationForm from "./components/generalComponents/ReviewLoanApplication/ReviewLoanApplicationForm";
import ReviewLoanSureties from "./components/generalComponents/ReviewLoanApplication/ReviewLoanSureties";
import ViewMemberPreviousLoans from "./components/generalComponents/ViewMemberPreviousLoans/ViewMemberPreviousLoans";
import ManageLoanDisbursalReports from "./components/generalComponents/ManageLoanDisbursalReports/ManageLoanDisbursalReports";
import EMIDeductionReport from "./components/generalComponents/ReportsComponents/EMIDeductionReport/EMIDeductionReport";

function App() {
  return (
    <BackEndServerServiceProvider>
      <AuthServiceProvider>
        {/* <BackEndXMLDataServiceProvider> */}
          <BrowserRouter>
            <Routes>
              {/* Routes to the modules withot login */}
              <Route
                path="/"
                element={<PortalWithoutLogin></PortalWithoutLogin>}
              >
                <Route index element={<BulletinBoard></BulletinBoard>} />
                <Route path="/LogIn" element={<Login></Login>} />
                <Route
                  path="/Bulletins"
                  element={<BulletinBoard></BulletinBoard>}
                />
                <Route
                  path="/About-Us"
                  element={<AboutUsWriteUp></AboutUsWriteUp>}
                />
                <Route
                  path="/WriteToUs"
                  element={<WriteToRBCTCS></WriteToRBCTCS>}
                />
                <Route
                  path="/RegisterNewUser"
                  element={<NewUserPortalRegistration />}
                />
                <Route path="/ForgotPassword" element={<ForgotPassword />} />
                <Route path="/Forms" />
              </Route>

              {/* 
          
          
                  routes to the portal new user's Dashboard 
          
          
          */}
              <Route
                path="/NewUserDashBoard"
                element={
                  <RouteProtector>
                    <NewUserDashHome />
                  </RouteProtector>
                }
              >
                <Route index element={<DashWorkSpace />} />

                <Route
                  path="UpdateLOGInPassword"
                  element={<UpdateLogInPasswordComponent/>}
                />

                <Route
                  path="ManageRegistrationDetails"
                  element={<RegistrationDetailsViewer />}
                />
                <Route
                  path="UpdateRegistrationDetails"
                  element={<MemberPersonalDetailsUpdater />}
                />
                <Route
                  path="Apply&SubmitYourDetails"
                  element={<NewMembershipApplication />}
                />
                <Route
                  path="View-ManageStatus"
                  element={<ViewMembershipApplicationStatus />}
                />
                <Route
                  path="UploadMembershipDocumentation"
                  element={<NewMembershipDocumentationUpload />}
                />
                <Route
                  path="UploadPaymentDetails&SalarySlip"
                  element={<NewMembershipPaymentDocSubmission />}
                />
                <Route
                  path="UpdatePaymentDetails&SalarySlip"
                  element={<PaymentDetailsUpdator />}
                />
                <Route
                  path="MembershipFormSubmission"
                  element={<SaveMembershipApplicationToServer />}
                />
                <Route
                  path="ReviewPersonalDetails"
                  element={
                    <MemberPersonalDetailsUpdater
                      updateType={"Review"}
                      npx
                      sb
                      init
                    />
                  }
                />
                <Route
                  path="ReviewOfficialDetails"
                  element={
                    <MemberOfficialDetailsUpdater updateType={"Review"} />
                  }
                />
                <Route
                  path="ReviewBankDetails"
                  element={<MemberBankDetailsUpdater updateType={"Review"} />}
                />
                <Route
                  path="ReviewPaymentDetails"
                  element={
                    <MemberBankPaymentDetailsUpdater updateType={"Review"} />
                  }
                />
                <Route
                  path="ReviewOtherSocietyDetails"
                  element={
                    <MemberOtherSocietyDetailsUpdater updateType={"Review"} />
                  }
                />
                <Route
                  path="ReviewNomineeDetails"
                  element={
                    <MemberNomineesDetailsViewer updateType={"Review"} />
                  }
                />
                <Route
                  path="ReviewDocumentsUpload"
                  element={
                    <NewMembershipDocumentationUpload updateType={"Review"} />
                  }
                />
                <Route
                  path="AddNomineesDetails"
                  element={
                    <AddMemberNomineeComponent  />
                  }
                />
                <Route
                  path="UpdateNomineesDetails"
                  element={
                    <MemberNomineeDetailsUpdater updateType={"Update"} />
                  }
                />

                
                <Route path="*" element={<RegistrationDetailsViewer />} />
              </Route>

              {/*
          
          
                  routes to the member's Dashboard 
          
          
          */}
              <Route
                path="/MemberDashBoard"
                element={
                  <RouteProtector>
                    <MemberDashHome></MemberDashHome>
                  </RouteProtector>
                }
              >
                <Route index element={<DashWorkSpace />} />
                <Route
                  path="ManagePersonalDetails"
                  element={<PersonalDetailsViewer />}
                />
                <Route
                  path="UploadPhotoSign"
                  element={<PhotoSignatureUploader />}
                />
                <Route
                  path="UpdateLOGInPassword"
                  element={<UpdateLogInPasswordComponent/>}
                />
                <Route
                  path="UpdatePersonalDetails"
                  element={
                    <MemberPersonalDetailsUpdater updateType={"Update"} />
                  }
                />
                <Route
                  path="ManageOfficialDetails"
                  element={<OfficialDetailsViewer />}
                />
                <Route
                  path="UpdateOfficialDetails"
                  element={
                    <MemberOfficialDetailsUpdater updateType={"Update"} />
                  }
                />
                <Route
                  path="ManageBankDetails"
                  element={<MemberBankDetailsViewer />}
                />
                <Route
                  path="UpdateBankDetails"
                  element={<MemberBankDetailsUpdater updateType={"Update"} />}
                />
                <Route
                  path="ManageOtherSocietyDetails"
                  element={<MemberOtherSocietyDetailsViewer />}
                />
                <Route
                  path="UpdateOtherSocietyDetails"
                  element={
                    <MemberPersonalDetailsUpdater updateType={"Update"} />
                  }
                />
                <Route
                  path="ManageNomineesDetails"
                  element={<MemberNomineesDetailsViewer />}
                />
                <Route
                  path="AddNomineesDetails"
                  element={
                    <AddMemberNomineeComponent  />
                  }
                />
                <Route
                  path="UpdateNomineesDetails"
                  element={
                    <MemberNomineeDetailsUpdater updateType={"Update"} />
                  }
                />
                
                <Route
                  path="ManageMembershipDetails"
                  element={<RBCTCSMembershipDetailsViewer />}
                />
                <Route
                  path="UpdateMembershipDetails"
                  element={
                    <MemberPersonalDetailsUpdater updateType={"Update"} />
                  }
                />
                <Route
                  path="ManageYourExistingLoans"
                  element={<ViewMemberExistingLoans />}
                />
                <Route
                  path="ManageYourPreviousLoans"
                  element={<ViewMemberPreviousLoans />}
                />
                <Route
                  path="TopUpExistingMainLoan"
                  element={<NewOrdinaryLoanTopUpComponent />}
                />
                <Route
                  path="TopUpExistingEmergentLoan"
                  element={<NewEmergentLoanTopUpComponent />}
                />
                <Route
                  path="ViewSharesPurchaseRequestsStatus"
                  element={<ViewSharesPurchaseRequestsStatus />}
                />
                <Route
                  path="View&DownloadLoanStatement"
                  element={<ViewAndDownloadLoanStatement />}
                />
                <Route
                  path="View&DownloadCDStatement"
                  element={<ViewAndDownloadCDStatement />}
                />
                <Route
                  path="View&DownloadSharesPurchaseStatement"
                  element={<ViewAndDownloadSharePurchaseStatement />}
                />
                <Route
                  path="ViewYourLoanApplications"
                  element={<ViewMemberLoanApplications />}
                />
                <Route
                  path="ApplyForNewOrdinaryLoan"
                  element={<NewOrdinaryLoanApplicationForm />}
                />
                <Route
                  path="ApplyForNewEmergentLoan"
                  element={<NewEmergentLoanApplicationForm />}
                />
                <Route
                  path="ReviewLoanApplicationForm"
                  element={<ReviewLoanApplicationForm />}
                />
                <Route
                  path="ReviewLoanSuretyFormDetails"
                  element={<ReviewLoanSureties />}
                />
                
                <Route
                  path="ReviewPaySlipUpload"
                  element={
                    <ReviewPaySlipUploader updateType={"Review"} />
                  }
                />
                <Route
                  path="ManageSuretiesYouReceived"
                  element={<ViewSuretiesReceived />}
                />
                <Route
                  path="ManageSuretiesYouGave"
                  element={<ManageLoanSureties />}
                >
                  <Route index element={<ManageLoanSuretiesTabs />} />
                </Route>
                <Route
                  path="SharePurchaseRequests"
                  element={<ManageSharePurchaseRequests />}
                ></Route>
              </Route>

              {/*
          
          
                  routes to the RBCTCS staff's Dashboard 
          
          
          */}
              <Route
                path="/StaffRBCTCSDashBoard"
                element={
                  <RouteProtector>
                    <StaffRBCTCSDashHome />
                  </RouteProtector>
                }
              >
                <Route index element={<DashWorkSpace />} />

                <Route
                  path="UpdateLOGInPassword"
                  element={<UpdateLogInPasswordComponent/>}
                />
                <Route
                  path="ManagePersonalDetails"
                  element={<PersonalDetailsViewer />}
                />
                <Route
                  path="UpdatePersonalDetails"
                  element={<MemberPersonalDetailsUpdater />}
                />
                <Route
                  path="ManageOfficialDetails"
                  element={<MemberPersonalDetailsUpdater />}
                />
                <Route
                  path="UpdateOfficialDetails"
                  element={<MemberPersonalDetailsUpdater />}
                />
                <Route
                  path="Apply&SubmitYourDetails"
                  element={<NewMembershipApplication />}
                />
                <Route
                  path="UploadPaymentDetails&SalarySlip"
                  element={<NewMembershipPaymentDocSubmission />}
                />
                <Route
                  path="ApplyForNewOrdinaryLoan"
                  element={<NewOrdinaryLoanApplicationForm />}
                />
                <Route
                  path="ApplyForNewEmergentLoan"
                  element={<NewEmergentLoanApplicationForm />}
                />
                <Route
                  path="CreateAccountsHead"
                  element={<AccountsHeadCreation />}
                />
                <Route
                  path="CreateNewStaffMember"
                  element={<CreateNewRBCTCSStaff />}
                />
                <Route
                  path="CreateNewAdmin"
                  element={<CreateNewAdminDetails />}
                />
                <Route
                  path="LoanDisbursementReport"
                  element={<LoanDisbursementReport />}
                >
                  <Route
                    index
                    element={<ApplicationsReadyForDisbursal />}
                  ></Route>
                  <Route
                    path="FillDetails"
                    element={<FillChequeDetailsForDisbursal />}
                  ></Route>
                  <Route
                    path="LDRFinal"
                    element={<FinalPrintReportForDisbursal />}
                  ></Route>
                </Route>
                <Route
                  path="LedgerReportHeadWise"
                  element={<LedgerReportHeadWise />}
                >
                  <Route
                    index
                    element={<LRHWFilterInputsForReportGeneration />}
                  />
                  <Route path="LRMWFinal" element={<FinalPrintLRMW />} />
                </Route>
                <Route
                  path="LedgerReportMemberWise"
                  element={<LedgerReportMemberWise />}
                >
                  <Route
                    index
                    element={<LRMWFilterInputsForReportGeneration />}
                  />
                  <Route path="LRMWFinal" element={<FinalPrintLRMW />} />
                </Route>
                <Route path="BankBook" element={<BankBookReport />}>
                  <Route
                    index
                    element={<BBRFilterInputsForReportGeneration />}
                  />
                  <Route path="LRMWFinal" element={<FinalPrintLRMW />} />
                </Route>
                <Route path="TrialBalance" element={<TrialBalanceReport />}>
                  <Route
                    index
                    element={<TBRFilterInputsForReportGeneration />}
                  />
                  <Route path="LRMWFinal" element={<FinalPrintLRMW />} />
                </Route>
                <Route
                  path="EMIdeductionsReportForPAO"
                  element={<EMIDeductionReport />}
                >
                  <Route
                    index
                    element={<EDRFilterInputsForReportGeneration />}
                  />
                  <Route
                    path="EDRFinal"
                    element={<FinalPrintReportForDeduction />}
                  />
                </Route>
                <Route
                  path="VerifyNewMembershipApplication"
                  element={<VerifyMembershipApplications />}
                />
                <Route
                  path="VerifyNewLoanApplication"
                  element={<VerifyLoanApplications />}
                />
                <Route
                  path="ManageMiscPayments"
                  element={<MiscPaymentsManager />}
                >
                  <Route index element={<MiscPaymentsTabView />} />
                </Route>
                <Route
                  path="ManageYourDetails"
                  element={<ViewStaffDetails />}
                />
                <Route
                  path="UpdateStaffDetails"
                  element={<UpdateStaffDetails />}
                />
                <Route
                  path="UploadPhotoSign"
                  element={<PhotoSignatureUploader />}
                />
                <Route
                  path="UploadRBCTCSOrders&Circulars"
                  element={<UploadRBCTCSOrdersCirculars />}
                />
                <Route
                  path="SaveRepliestotheMessages"
                  element={<ManageMessagesToRBCTCS />}
                />
                <Route
                  path="ViewSuretiesGivenByMember"
                  element={<ViewSuretiesGivenByMember/>}
                />
                <Route
                  path="ViewSuretiesGivenToMember"
                  element={<ViewSuretiesGivenToMember />}
                />
                <Route
                  path="ViewMemberPersonalDetails"
                  element={<MemberPersonalDetailsViewByStaffOrAdmin />}
                />
                <Route
                  path="ViewMemberOfficialDetails"
                  element={<MemberOfficialDetailsViewByStaffOrAdmin />}
                />
                <Route
                  path="ViewMemberNomineeDetails"
                  element={<MemberNomineesDetailsViewByStaffOrAdmin />}
                />
                <Route
                  path="ViewMemberBankDetails"
                  element={<MemberBankDetailsViewByStaffOrAdmin />}
                />
                <Route
                  path="ViewMemberRBCTCSDetails"
                  element={<RBCTCSMembershipDetailsViewByStaffOrAdmin />}
                />
              </Route>

              {/*



          
                            routes to the ADMIN - Approval Authority's Dashboard 
          
          
          */}
              <Route
                path="/AdminDashBoard"
                element={
                  <RouteProtector>
                    <AdminDashHome />
                  </RouteProtector>
                }
              >
                <Route index element={<DashWorkSpace />} />
                <Route
                  path="UpdateLOGInPassword"
                  element={<UpdateLogInPasswordComponent/>}
                />
                <Route
                  path="ManagePersonalDetails"
                  element={<PersonalDetailsViewer />}
                />
                <Route
                  path="UploadPhotoSign"
                  element={<PhotoSignatureUploader />}
                />
                <Route
                  path="UpdatePersonalDetails"
                  element={<MemberPersonalDetailsUpdater />}
                />
                <Route
                  path="ManageAdminOfficialDetails"
                  element={<ViewAdminDetails />}
                />
                <Route
                  path="UpdateAdminOfficialDetails"
                  element={<UpdateAdminDetails />}
                />
                <Route
                  path="ApplyForNewLoan"
                  element={<NewOrdinaryLoanApplicationForm />}
                />
                <Route
                  path="SetFeesforNewMembership"
                  element={<SettingFeesforNewMembership />}
                />
                <Route path="SetOtherLimits" element={<SettingOtherLimits />} />
                <Route path="SetLoanLimits" element={<SettingLoanLimits />} />
                <Route
                  path="SetCompulsoryDepositLimits"
                  element={<SettingForCompulsoryDeposit />}
                />
                <Route
                  path="ManageLoanDisbursementReports"
                  element={<ManageLoanDisbursalReports />}
                >
                  <Route
                    index
                    element={<ApplicationsReadyForDisbursal />}
                  ></Route>
                  <Route
                    path="FillDetails"
                    element={<FillChequeDetailsForDisbursal />}
                  ></Route>
                  <Route
                    path="LDRFinal"
                    element={<FinalPrintReportForDisbursal />}
                  ></Route>
                </Route>
                <Route
                  path="LedgerReportHeadWise"
                  element={<LedgerReportHeadWise />}
                >
                  <Route
                    index
                    element={<LRHWFilterInputsForReportGeneration />}
                  />
                  <Route path="LRMWFinal" element={<FinalPrintLRMW />} />
                </Route>
                <Route
                  path="LedgerReportMemberWise"
                  element={<LedgerReportMemberWise />}
                >
                  <Route
                    index
                    element={<LRMWFilterInputsForReportGeneration />}
                  />
                  <Route path="LRMWFinal" element={<FinalPrintLRMW />} />
                </Route>
                <Route path="BankBook" element={<BankBookReport />}>
                  <Route
                    index
                    element={<BBRFilterInputsForReportGeneration />}
                  />
                  <Route path="LRMWFinal" element={<FinalPrintLRMW />} />
                </Route>
                <Route path="TrialBalance" element={<TrialBalanceReport />}>
                  <Route
                    index
                    element={<TBRFilterInputsForReportGeneration />}
                  />
                  <Route path="LRMWFinal" element={<FinalPrintLRMW />} />
                </Route>
                <Route
                  path="EMIdeductionsReportForPAO"
                  element={<EmiDeductionsReport />}
                >
                  <Route
                    index
                    element={<EDRFilterInputsForReportGeneration />}
                  />
                  <Route
                    path="EDRFinal"
                    element={<FinalPrintReportForDeduction />}
                  />
                </Route>
                <Route
                  path="ManageStaffDetails"
                  element={<ViewStaffDetails staffIdProp={'ST11477'} />}
                />
                <Route path="ApproveLoans" element={<LoanApprovals />} />
                <Route
                  path="ApproveNewMemberships"
                  element={<MembershipApprovals />}
                />
                <Route
                  path="MessagesWrittenToRBCTCS"
                  element={<ManageMessagesToRBCTCS />}
                />
                <Route
                  path="ViewSuretiesGivenByMember"
                  element={<ViewSuretiesGivenByMember/>}
                />
                <Route
                  path="ViewSuretiesGivenToMember"
                  element={<ViewSuretiesGivenToMember />}
                />
                <Route
                  path="ViewMemberPersonalDetails"
                  element={<MemberPersonalDetailsViewByStaffOrAdmin />}
                />
                <Route
                  path="ViewMemberOfficialDetails"
                  element={<MemberOfficialDetailsViewByStaffOrAdmin />}
                />
                <Route
                  path="ViewMemberNomineeDetails"
                  element={<MemberNomineesDetailsViewByStaffOrAdmin />}
                />
                <Route
                  path="ViewMemberBankDetails"
                  element={<MemberBankDetailsViewByStaffOrAdmin />}
                />
                <Route
                  path="ViewMemberRBCTCSDetails"
                  element={<RBCTCSMembershipDetailsViewByStaffOrAdmin />}
                />
              </Route>

              {/*
          
                            routes to the ADMIN - Officer Bearer's Dashboard 
          
          
          */}
              <Route
                path="/OfficeBearerDashBoard"
                element={
                  <RouteProtector>
                    <OfficeBearerDashHome />
                  </RouteProtector>
                }
              >
                <Route index element={<DashWorkSpace />} />
                <Route
                  path="UpdateLOGInPassword"
                  element={<UpdateLogInPasswordComponent/>}
                />
                <Route
                  path="ManagePersonalDetails"
                  element={<PersonalDetailsViewer />}
                />
                <Route
                  path="UploadPhotoSign"
                  element={<PhotoSignatureUploader />}
                />
                <Route
                  path="UpdatePersonalDetails"
                  element={<MemberPersonalDetailsUpdater />}
                />
                <Route
                  path="ManageAdminOfficialDetails"
                  element={<ViewAdminDetails />}
                />
                <Route
                  path="UpdateAdminOfficialDetails"
                  element={<UpdateAdminDetails />}
                />
                <Route
                  path="ApplyForNewLoan"
                  element={<NewOrdinaryLoanApplicationForm />}
                />
                <Route
                  path="SetFeesforNewMembership"
                  element={<SettingFeesforNewMembership />}
                />
                <Route path="SetOtherLimits" element={<SettingOtherLimits />} />
                <Route path="SetLoanLimits" element={<SettingLoanLimits />} />
                <Route
                  path="SetCompulsoryDepositLimits"
                  element={<SettingForCompulsoryDeposit />}
                />
                <Route
                  path="LoanDisbursementReport"
                  element={<LoanDisbursementReport />}
                >
                  <Route
                    index
                    element={<ApplicationsReadyForDisbursal />}
                  ></Route>
                  <Route
                    path="FillDetails"
                    element={<FillChequeDetailsForDisbursal />}
                  ></Route>
                  <Route
                    path="LDRFinal"
                    element={<FinalPrintReportForDisbursal />}
                  ></Route>
                </Route>
                <Route
                  path="LedgerReportHeadWise"
                  element={<LedgerReportHeadWise />}
                >
                  <Route
                    index
                    element={<LRHWFilterInputsForReportGeneration />}
                  />
                  <Route path="LRMWFinal" element={<FinalPrintLRMW />} />
                </Route>
                <Route
                  path="LedgerReportMemberWise"
                  element={<LedgerReportMemberWise />}
                >
                  <Route
                    index
                    element={<LRMWFilterInputsForReportGeneration />}
                  />
                  <Route path="LRMWFinal" element={<FinalPrintLRMW />} />
                </Route>
                <Route path="BankBook" element={<BankBookReport />}>
                  <Route
                    index
                    element={<BBRFilterInputsForReportGeneration />}
                  />
                  <Route path="LRMWFinal" element={<FinalPrintLRMW />} />
                </Route>
                <Route path="TrialBalance" element={<TrialBalanceReport />}>
                  <Route
                    index
                    element={<TBRFilterInputsForReportGeneration />}
                  />
                  <Route path="LRMWFinal" element={<FinalPrintLRMW />} />
                </Route>
                <Route
                  path="EMIdeductionsReportForPAO"
                  element={<EmiDeductionsReport />}
                >
                  <Route
                    index
                    element={<EDRFilterInputsForReportGeneration />}
                  />
                  <Route
                    path="EDRFinal"
                    element={<FinalPrintReportForDeduction />}
                  />
                </Route>
                <Route
                  path="ManageStaffDetails"
                  element={<ViewStaffDetails staffIdProp={4375} />}
                />
                <Route path="ApproveLoans" element={<LoanApprovals />} />
                <Route
                  path="ApproveNewMemberships"
                  element={<MembershipApprovals />}
                />
                <Route
                  path="MessagesWrittenToRBCTCS"
                  element={<ManageMessagesToRBCTCS />}
                />
              </Route>
              <Route
                  path="ViewSuretiesGivenByMember"
                  element={<ViewSuretiesGivenByMember/>}
                />
                <Route
                  path="ViewSuretiesGivenToMember"
                  element={<ViewSuretiesGivenToMember />}
                />
                <Route
                  path="ViewMemberPersonalDetails"
                  element={<MemberPersonalDetailsViewByStaffOrAdmin />}
                />
                <Route
                  path="ViewMemberOfficialDetails"
                  element={<MemberOfficialDetailsViewByStaffOrAdmin />}
                />
                <Route
                  path="ViewMemberNomineeDetails"
                  element={<MemberNomineesDetailsViewByStaffOrAdmin />}
                />
                <Route
                  path="ViewMemberBankDetails"
                  element={<MemberBankDetailsViewByStaffOrAdmin />}
                />
                <Route
                  path="ViewMemberRBCTCSDetails"
                  element={<RBCTCSMembershipDetailsViewByStaffOrAdmin />}
                />
            </Routes>
          </BrowserRouter>
        {/* </BackEndXMLDataServiceProvider> */}
      </AuthServiceProvider>
    </BackEndServerServiceProvider>
  );
}

export default App;
