import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './AvtarProfileWidget.css';

import { Divider } from '@mui/material';
import defaultImage from '../../images/memberpics/defaultMemberPic.png';
import axios from 'axios';
import { useGetAPIServer } from '../../BackEndServerServiceProvider/BackEndServerServiceProvider';


const AvtarProfileWidget = (Props) => {

 const apiServer = useGetAPIServer();
  const [memberPhoto, setMemberPhoto] =  useState(defaultImage);

  useEffect(() => {
     //console.log(Props)
    if(Props.RUID!="") {getRBMemberPhotoDetails(Props.RUID)}
  }, []);

  const getRBMemberPhotoDetails = (RUID) => {
    axios
      .get(`${apiServer.serverBaseRoute}/GetMemberPhotoForAdminProfiles/${RUID}`, {
        responseType: 'blob', // important
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        //console.log(url);
        setMemberPhoto(url);
        // setIsLoadingPhoto(false);
      });
  };
  
 return (
  <div className="AvtarProfileWidget">
    <div className='AvtarImageDiv'>
      <img src={memberPhoto} />
    </div>
    <Divider></Divider>
    <div className='AvtarInfoDiv'>
        <div className='AvtarInfoTextDiv'>{Props.name}</div>
        <div className='AvtarInfoTextDiv'>{Props.designation}</div>
        <div className='AvtarInfoTextDiv'>Mobile: {Props.mobileNo}</div>
        <div className='AvtarInfoTextDiv'>Room: {Props.roomNo}</div>
    </div>
  </div>
);
}

AvtarProfileWidget.propTypes = {};

AvtarProfileWidget.defaultProps = {};

export default AvtarProfileWidget;
