import React, {useState, useEffect} from "react";
import  useMyAuthService from "../authenticationServiceProvider/useMyAuthService";

export const useGetXMLResources = () => {
  const { api } = useMyAuthService();

  const [memberDesignations, setMemberDesignations] = useState([]);
  const [rbBranches, setRBBranches] = useState([]);
  const [Banks, setBanks] = useState([]);

  //getting the XML resources..........................
  useEffect(() => {
    getXMLResources();
  }, []);

  const getXMLResources = async() => {
    await api.get(`/getXMLResources/memberDesignations`).then((response) => {
      //console.log(response);
      setMemberDesignations(response.data.Designation);
    });

    await api.get(`/getXMLResources/rbBranches`).then((response) => {
      setRBBranches(response.data.Branch);
    });

    await api.get(`/getXMLResources/Banks`).then((response) => {
      setBanks(response.data.Bank);
    });
  };

  return {
    memberDesignations,
    rbBranches,
    Banks,
  };
};
