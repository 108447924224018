import "./ViewMemberPreviousLoans.css";

import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'

import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useMyAuthService  from "../../../authenticationServiceProvider/useMyAuthService";


function ViewMemberPreviousLoans() {

  const data = useLocation();
  const selectedLoanAppId = data.state.loanAppId;
  const selectedLoanType = data.state.loanType;

  const navigate = useNavigate();

  const {loggedInUserRUID, api} = useMyAuthService();

  const [ruidLoggedIn, setRUIDLoggedIn] = useState(
    loggedInUserRUID
  );

 
  const [LoanApplicationDetails, setloanApplicationDetails] = useState([]);

  const [isLoadingData, setIsLoadingData] = useState(true);

  const [noDataFound, setNoDataFound] = useState(false);

  const [adminRBCTCSDetails, setAdminRBCTCSDetails] = useState({});
  const [staffRBCTCSDetails, setStaffRBCTCSDetails] = useState({});
  const [adminName, setAdminName] = useState();

  const [selectedLoan, setSelectedLoan] = useState({});

  const [openAdminDisplayDialog, setOpenAdminDisplayDialog] =
    React.useState(false);
  const [openStaffDisplayDialog, setOpenStaffDisplayDialog] =
    React.useState(false);
  const [openLoanPrePaymentDialog, setOpenLoanPrePaymentDialog] =
    React.useState(false);


  useEffect(() => {
    getApplicationStatus();
  }, []);


  const getApplicationStatus = () => {

    const config = {
      params: {
        rbMemberRUID: ruidLoggedIn,
        selectedLoanAppId:selectedLoanAppId,
        selectedLoanType:selectedLoanType,
        applicationStatus: "LCLONFP",
        applicationStatus2:"LCLONTP",
      },
    };

    api
      .get(
        `/ViewPreviousLoansStatusForParticularMember`, config
      )
      .then((response) => {
        //console.log(response.data);
        if (response.data.length === 0) {
          setNoDataFound(true);
        } else {
          setloanApplicationDetails(response.data);
        }

        //console.log(partMarkedForReview);
        setIsLoadingData(false);
      });
  };

  const displayAdmin2Details = () => {
    setAnchorActionsMenu(null);
    api
      .get(`/getAdminRBCTCSDetailsByAdminId/${selectedLoan.approverAdmin2ID}`)
      .then((response) => {
        setAdminRBCTCSDetails(response.data);
        setAdminName(response.data.users.name);
        setOpenAdminDisplayDialog(true);
      });
  };

  const displayAdminDetails = () => {
    setAnchorActionsMenu(null);
    api
      .get(`/getAdminRBCTCSDetailsByAdminId/${selectedLoan.approverAdminID}`)
      .then((response) => {
        setAdminRBCTCSDetails(response.data);
        setAdminName(response.data.users.name);
        setOpenAdminDisplayDialog(true);
      });
  };

  const displayStaffDetails = () => {
    setAnchorActionsMenu(null);
    api
      .get(`/ViewRBCTCSStaffDetails/${selectedLoan.verifierStaffID}`)
      .then((response) => {
        //console.log(response.data);
        setStaffRBCTCSDetails(response.data[0]);
        setOpenStaffDisplayDialog(true);
      });
  };

 
  const handleCloseAdminDisplayDialog = () => {
    setOpenAdminDisplayDialog(false);
  };

  const handleCloseStaffDisplayDialog = () => {
    setOpenStaffDisplayDialog(false);
  };

    

  //action menu for the verification............................of the applications................start.....
  const [anchorActionsMenu, setAnchorActionsMenu] = React.useState(null);
  const open = Boolean(anchorActionsMenu);
  const handleClick = (event, loan) => {
    setAnchorActionsMenu(event.currentTarget);
    setSelectedLoan(loan); // Store the current loan when the button is clicked
  };
  const handleClose = () => {
    setAnchorActionsMenu(null);
  };
  //action menu for the verification of the applications............................................end

   
  const moveBackToMemberExistingLoans = ()=>{
    navigate('/MemberDashBoard/ManageYourExistingLoans', {
      replace:true
    });
  }
  

  const displayViewAndDownloadLoanStatment = () =>{
    //alert(selectedLoan.loanApplicationId);
    navigate('/MemberDashBoard/View&DownloadLoanStatement', {
      state: {
        loanAppId: selectedLoan.loanApplicationId,
       }
    });
  }

  return (
    <div className="memberPreviousActiveLoansMainDiv">
      <fieldset>
        <legend>📜 Your Previous Loans</legend>
        {isLoadingData ? (
          <div className="isLoadingDiv">Loading Data .....</div>
        ) : noDataFound ? (
          <div className="noDataFoundTextDiv">You have No Previous Loans</div>
        ) : (
          <table className="membershipApplicationStatusTable centerContent">
            <thead>
              <tr>
                <td>Sl No.</td>
                <td>Loan Id</td>
                <td>Ledger No</td>
                <td className="justifyContent">Name</td>
                <td>Application Date</td>
                <td>Application status</td>
                <td>Loan Type</td>
                <td>Loan Amount</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {LoanApplicationDetails.map((Loan, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{Loan.loanApplicationId}</td>
                    <td>{Loan.rbmembers.ledgerNo}</td>
                    <td>{Loan.rbmembers.name}</td>
                    <td>{Loan.dateOfApplication}</td>
                    <td>{Loan.applicationStatus}</td>
                    <td>{(Loan.loanType === 'ML' ||Loan.loanType === 'AML')?"Main Loan":"Emergent Loan"}</td>
                    <td>{Loan.loanAmountSanctioned}</td>
                    <td>
                      {
                        <Button
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={(e) => handleClick(e,Loan)}
                        >
                          ⚙️
                        </Button>
                      }{" "}
                      {/* this is the actions menu activated on the button click to take actions on a particular selected Loan */}
                      <Menu
                        anchorEl={anchorActionsMenu}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          onClick={(e) => {
                            moveBackToMemberExistingLoans();
                          }}
                        >
                          🔍 Move Back to Existing Loans
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            displayStaffDetails();
                          }}
                        >
                          🔍 View Staff Details
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            displayAdminDetails();
                          }}
                        >
                          🔍 View Approver Admin
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            displayAdmin2Details();
                          }}
                        >
                          🔍 View Approver Admin 2
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            displayViewAndDownloadLoanStatment();
                          }}
                        >
                          🔍 View & Download Statement
                        </MenuItem>
                        
                      </Menu>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        {/* Dialog to display Staff Details ................ */}
        <Dialog open={openStaffDisplayDialog} fullWidth maxWidth="sm">
          <DialogActions>
            <Button variant="outlined" onClick={handleCloseStaffDisplayDialog}>
              ❌ Close
            </Button>
          </DialogActions>
          <DialogContent>
            <div className="adminDisplayDiv">
              <table>
                <tbody>
                  <tr>
                    <td>Staff ID:</td>
                    <td>{staffRBCTCSDetails.staffRBCTCSId}</td>
                  </tr>
                  <tr>
                    <td>Shri/Smt./Ms</td>
                    <td>{staffRBCTCSDetails.name}</td>
                  </tr>
                  <tr>
                    <td>Designation</td>
                    <td>{staffRBCTCSDetails.designation}/RBCTCS</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </DialogContent>
        </Dialog>

        {/* Dialog to display Admin Details ................ */}
        <Dialog open={openAdminDisplayDialog} fullWidth maxWidth="sm">
          <DialogActions>
            <Button variant="outlined" onClick={handleCloseAdminDisplayDialog}>
              ❌ Close
            </Button>
          </DialogActions>
          <DialogContent>
            <div className="adminDisplayDiv">
              <table>
                <tbody>
                  <tr>
                    <td>RUID:</td>
                    <td>{adminRBCTCSDetails.adminRBCTCSRUID}</td>
                  </tr>
                  <tr>
                    <td>Shri/Smt./Ms</td>
                    <td>{adminName}</td>
                  </tr>
                  <tr>
                    <td>Designation</td>
                    <td>{adminRBCTCSDetails.designationRBCTCS}/RBCTCS</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </DialogContent>
        </Dialog>

      
      </fieldset>
    </div>
  );
}

export default ViewMemberPreviousLoans;
