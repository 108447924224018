import "./EDRFilterInputsForReportGeneration.css";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import useMyAuthService from "../../../../../authenticationServiceProvider/useMyAuthService";
import EDRTableData from "../../../TableDataDisplayer/EDRTableData/EDRTableData";
import ProgressorBarDisplayer from "../../../DialogComponents/ProgressorBarDisplayer/ProgressorBarDisplayer";

const filters = {
  dateOfDeductionReport: "",
};

const EDRFilterInputsForReportGeneration = () => {
  const { api, loggedInUserRUID } = useMyAuthService();

  let totalDeductionAmount = 0,
    totalInterest = 0;
  const [filterFields, setFilterFields] = useState(filters);
  const [isMemberListLoaded, setIsMemberListLoaded] = useState(false);
  const [membersDeductionList, setMembersDeductionList] = useState({});
  const [
    memberSelectedForEditInDeductionList,
    setMemberSelectedForEditInDeductionList,
  ] = useState({});

  const [newMainLoanDeductionAmount, setNewMainLoanDeductionAmount] =
    useState(null);
  const [newEmergentLoanDeductionAmount, setNewEmergentLoanDeductionAmount] =
    useState(null);

  const [openEditLoanDetailsDialog, setOpenEditLoanDetailsDialog] =
    useState(false);
  
  const [areEMIDeductionDetailsSavedInDB, setAreEMIDeductionDetailsSavedInDB] = useState(false);  
  const [openProgressBarDataLoad, setOpenProgressBarDataLoad] = useState(false);
  const [openProgressBarSaveDetails, setOpenProgressBarSaveDetails] = useState(false);


  const navigate = useNavigate();
  // useEffect(() => {
  //  setIsMemberListLoaded(false);
  // }, []);

  const handleClickFilterGenerateTheDeductionReport = () => {
    api.get(`/GetMemberListForEMIDeductions`).then((response) => {
       console.log(response.data);
      setOpenProgressBarDataLoad(true);
      setMembersDeductionList(response.data);
      setIsMemberListLoaded(true);
      setOpenProgressBarDataLoad(false);
    });
  };

  const handleClickGoToFinalPrintReportForDeduction = () => {
    navigate("EDRFinal", {
      state: {
        membersDeductionList: membersDeductionList,
      },
    });
  };

  const handleClickSaveEMIDeductionDetailsToDB = () => {
    
    let staffId = loggedInUserRUID;

    let config = {
      method: "PUT",
      url: `/SaveEMIDeductionsDetailsToDB`,
      data: { staffId },
      headers: {
        Accept: "application/json",

        //  Authorization: varToken
      },
      responseType: "json",
    };

    //we send the emi deduction details to be saved to the Data base.................
    api(config).then((response) => {
      //console.log(response.data);
      setOpenProgressBarDataLoad(true);
      //setMembersDeductionList(response.data);
      //setIsMemberListLoaded(true);
      setAreEMIDeductionDetailsSavedInDB(true);
      setOpenProgressBarDataLoad(false);
    });
  }; 
   
  

  const goBackFunction = () =>{
    setIsMemberListLoaded(false);
    setAreEMIDeductionDetailsSavedInDB(false);
  }

  return (
    <>
      {!isMemberListLoaded ? (
        <div className="EDRFiltersSearchDiv gridRow">
          {/* <input type={"text"} value={"Go"} onChange={setQuickSearchText} /> */}
          <span>
            <h3>
              Please Select the 10th of the month for which deduction report is
              to be generated
            </h3>
          </span>
          <div className="FormFieldDiv">
            <input
              type={"date"}
              value={filterFields.dateOfDeductionReport}
              onChange={(e) =>
                setFilterFields({
                  ...filterFields,
                  dateOfDeductionReport: e.target.value,
                })
              }
              placeholder="Date of Birth"
              required
            />
          </div>

          <div>
            <input
              type={"button"}
              value={"Go"}
              onClick={handleClickFilterGenerateTheDeductionReport}
            />
          </div>
        </div>
      ) : (
        <div className="EDRFiltersSearchDiv gridRow">
          <input
            type={"button"}
            value={"👈 Back"}
            onClick={() => goBackFunction()}
          />
        </div>
      )}

      <div>
        {!isMemberListLoaded ? (
          <div className="alternateReportViewText">
            <h1>EMI Deduction Report to Generate here.</h1>
          </div>
        ) : (
          <div>
            <EDRTableData
              className={"EDRFiltersSearchDivTable centerContent"}
              membersDeductionList={membersDeductionList}
            />
            {areEMIDeductionDetailsSavedInDB ? (
              <div className="FormActionDiv">
                <input
                  type={"button"}
                  value={"Proceed to Next Step 👉"}
                  onClick={handleClickGoToFinalPrintReportForDeduction}
                />
              </div>
            ) : (
              <div className="FormActionDiv">
                <input
                  type={"button"}
                  value={"💾 Save EMI Deduction Details To Data Base"}
                  onClick={handleClickSaveEMIDeductionDetailsToDB}
                />
              </div>
            )}
          </div>
        )}
      </div>

      {/* Progress bas displayer */}
      <ProgressorBarDisplayer 
         titleMessage ={'Please wait! Loading EMI Deduction data for the Members'}
         openProgressBarDialog ={openProgressBarDataLoad}
      />
         
      {/* Progress bas displayer */}
      <ProgressorBarDisplayer 
         titleMessage ={'Please wait! Saving the EMI Deduction Details to the Data Base.'}
         openProgressBarDialog ={openProgressBarSaveDetails}
      />

    </>
  );
};

export default EDRFilterInputsForReportGeneration;
