import "./CompulsoryDepositLedgerReport.css";

import React, { useState } from "react";

import Button from "@mui/material/Button";

import useMyAuthService from "../../../../../../authenticationServiceProvider/useMyAuthService";
import ProgressorBarDisplayer from "../../../../DialogComponents/ProgressorBarDisplayer/ProgressorBarDisplayer";
import LRMWTableCDLedgerData from "../../../../TableDataDisplayer/LRMWTableCDLedgerData/LRMWTableCDLedgerData";


const CompulsoryDepositLedgerReport = (props) => {

   const {api, loggedInUserRUID} = useMyAuthService();
   //getting the list to populate the drop down list in the filters menu..................
   const rbMemberListForFilters = props.rbMemberListForFilters;

   //filter fileds that have been selected for getting data from the server........
   const filters = {
     //
   };
 
   const [filterFields, setFilterFields] = useState(filters);
 
   const [memberCompulsoryDeposit, setMemberCompulsoryDeposit] = useState([]);
   const [isMemberCompulsoryDepositDataLoaded, setIsMemberCompulsoryDepositDataLoaded] = useState(false);
 
   const [CompulsoryDepositLedgerData, setCompulsoryDepositLedgerData] = useState([]);
   const [isCompulsoryDepositLedgerDataLoaded, setIsCompulsoryDepositLedgerDataLoaded] = useState(false);
 
   const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);
 
   

   const handleClickSearchFilterToGenerateTheLRMW = async () => {
     //console.log(filterFields);
     let config = {
       method: "POST",
       url: `/GetMemberCompulsoryDepositForCDLRMW`,
       data: { filterFields },
       headers: {
         Accept: "application/json",
 
       },
       responseType: "json",
     };
 
     setOpenProgressBarDialog(true);
     const response = await api(config);
     //console.log(response.data);
     setMemberCompulsoryDeposit(response.data);
     setIsMemberCompulsoryDepositDataLoaded(true);
     setOpenProgressBarDialog(false);
   };

 
   const handleClickGetCompulsoryDepositLedgerData = async (selectedMemberRUID) => {
     //console.log(filterFields);
     let config = {
       method: "POST",
       url: `/GetCompulsoryDepositTransactionsForCDLRMW`,
       data: { selectedMemberRUID },
       headers: {
         Accept: "application/json",
 
         //  Authorization: varToken
       },
       responseType: "json",
     };
 
     setOpenProgressBarDialog(true);
     const response = await api(config);
    //console.log(response.data);
     setCompulsoryDepositLedgerData(response.data);
     setIsMemberCompulsoryDepositDataLoaded(false);
     setIsCompulsoryDepositLedgerDataLoaded(true);
 
     setOpenProgressBarDialog(false);
   };
 
 
   const goBackToStepOneFilters = () =>{
     //alert("cle")
     setIsMemberCompulsoryDepositDataLoaded(false);
     setIsCompulsoryDepositLedgerDataLoaded(false);
   }
 
   return (
     <>
       {!isMemberCompulsoryDepositDataLoaded && !isCompulsoryDepositLedgerDataLoaded ? (
         <div className="LRMWFiltersSearchDiv gridRow">
           {/* <input type={"text"} value={"Go"} onChange={setQuickSearchText} /> */}
           {/* <span>
             <h5>Select the RUID to view the active CompulsoryDeposit of the selected Member</h5>
           </span> */}
 
           <div className="FormFieldDiv">
             <div>
               <input
                 list="members"
                 name="memberList"
                 id="memberList"
                 onChangeCapture={(e) =>
                   setFilterFields({
                     ...filterFields,
                     selectedRBMemberRUID: e.target.value,
                   })
                 }
                 placeholder="Select a Member"
               />
               <datalist id="members">
                 {rbMemberListForFilters
                   ? rbMemberListForFilters.map((rbMember, index) => {
                       return (
                         <option key={index} value={rbMember.rbMemberRUID}>
                           {rbMember.name} --- {rbMember.ledgerNo}
                         </option>
                       );
                     })
                   : ""}
               </datalist>
             </div>
           </div>
 
           <div className="FormActionDiv">
             <input
               type={"button"}
               value={"Go"}
               onClick={handleClickSearchFilterToGenerateTheLRMW}
             />
           </div>
         </div>
       ) : (
         <div className="LRMWFiltersSearchDiv gridRow">
           <div className="FormActionDiv">
             <input
               type={"button"}
               value={"👈 Back"}
               onClick={goBackToStepOneFilters}
             />
           </div>
         </div>
       )}
 
       <hr />
 
       <div>
         {!isMemberCompulsoryDepositDataLoaded && !isCompulsoryDepositLedgerDataLoaded ? (
           <div className="alternateReportViewText">
             <h1>
                Compulsory
               Deposit Ledger to Generate here.
             </h1>
           </div>
         ) : isMemberCompulsoryDepositDataLoaded && !isCompulsoryDepositLedgerDataLoaded ? (
           <div>
             <table className="LRMWFiltersSearchDivTable centerContent">
               <thead>
                 <tr>
                  <td>Sl.</td>
                  <td>RUID </td>
                  <td>Member </td>
                  <td>Ledger No.</td>
                  <td>Grade Pay</td>
                  <td>Compulsory Deposit</td>
                  <td>View Statement</td>
                 </tr>
               </thead>
               <tbody>
                 {memberCompulsoryDeposit.map((cd, index) => {
                   return (
                     <tr key={index}>
                       <td>{index + 1}</td>
                       <td>{cd.rbMemberRUID}</td>
                       <td>{cd.name} ({cd.designation})</td>
                       <td>{cd.ledgerNo}</td>
                       <td>{cd.gradePay}</td>
                       <td>{cd.compulsoryDeposit}</td>
                       <td>
                         <Button
                           variant="outlined"
                           onClick={()=>handleClickGetCompulsoryDepositLedgerData(cd.rbMemberRUID)}
                         >
                           View
                         </Button>
                       </td>
                     </tr>
                   );
                 })}
               </tbody>
             </table>
           </div>
         ) : (
           <div>
             <LRMWTableCDLedgerData className={"LRMWFiltersSearchDivTable centerContent"}
                      cdTransactionsList={CompulsoryDepositLedgerData}
             />
            
           </div>
         )}
       </div>
 
       {/* Dialog to implement the Progess bar ................ */}
       <ProgressorBarDisplayer 
            titleMessage={"Please Wait! Loading CD transactions data....."}
            openProgressBarDialog={openProgressBarDialog}
       />

     </>
   );
}

export default CompulsoryDepositLedgerReport