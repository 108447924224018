import "./LRHWFilterInputsForReportGeneration.css"

import React, { useState, useEffect} from 'react'

import { useNavigate } from "react-router-dom";


import useMyAuthService from "../../../../../authenticationServiceProvider/useMyAuthService";
import LRHWTableData from "../../../TableDataDisplayer/LRHWTableData/LRHWTableData";



const LRHWFilterInputsForReportGeneration = () => {

  const {api} = useMyAuthService();
  
   //filter fileds that have been selected for getting data from the server........
  const filters = {
    
  };
 
    const [filterFields, setFilterFields] = useState(filters);
    const [isLoadingData, setIsLoadingData] = useState(true);
    
    // const [rbMemberListForFilters, setRBMemberListForFilters] = useState();
    const [accountHeads, setAccountHeads] = useState();
    const [transactionsData, setTransactionsData] = useState([]);
       
    const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

    
    useEffect(() => {
      // getAllRBMembersListForFilters();
      getAccountHeads();
    }, []);

    // const getAllRBMembersListForFilters = () => {
    //   api
    //     .get(`/getRBMembersList`)
    //     .then((response) => {
    //       // console.log(response.data);
  
    //       setRBMemberListForFilters(response.data);
          
    //     });
    // };

    const getAccountHeads = () => {
      api
        .get(`/GetAccountHeads`)
        .then((response) => {
           //console.log(response.data);
  
           setAccountHeads(response.data);
         
        });
    };

  
    const handleClickSearchFilterToGenerateTheLRHW = async()=>{
         //console.log(filterFields);
         let config = {
          method: "POST",
          url: "/GetDataForLedgerReportHeadWise",
          data:{filterFields},
          headers: {
            Accept: "application/json",
           
            //  Authorization: varToken
          },
          responseType: "json",
        };
         
        setOpenProgressBarDialog(true);
        const response = await api(config);
        setTransactionsData(response.data);
        setIsLoadingData(false);
       // console.log(response.data);
        setOpenProgressBarDialog(false);
        
    }


  
    return (
      <>
        {isLoadingData ? (
          <div className="LRHWFiltersSearchDiv gridRow">
            {/* <input type={"text"} value={"Go"} onChange={setQuickSearchText} /> */}
          
           
            <div className="FormFieldDiv">
              <div>
                <input
                  list="accountHeads"
                  name="accountHeadList"
                  id="accountHeadList"
                  onChangeCapture={(e) =>
                    setFilterFields({...filterFields, selectedAccountHead:e.target.value})
                  }
                  placeholder="Select a Head"
                />
                <datalist id="accountHeads">
                    {accountHeads? accountHeads.map((head, index) => {
                       return (
                          <option key={index} value={head.headCode}>
                            {head.headName}
                          </option>
                        );
                      })
                    : " "}
                    
                </datalist>
              </div>
            </div>

            <div className="FormFieldDiv">
                From Date &nbsp;
                <input
                 type={'date'}
                  onChangeCapture = {(e) =>
                      setFilterFields({...filterFields, selectedFromDate:e.target.value})
                  }
                  placeholder="Select a Head"
                />
               
            </div>

            <div className="FormFieldDiv">
                To Date &nbsp;
                <input
                 type={'date'}
                  onChangeCapture = {(e) =>
                      setFilterFields({...filterFields, selectedToDate:e.target.value})
                  }
                  placeholder="Select a Head"
                />
               
            </div>

            <div className="FormActionDiv">
              <input
                type={"button"}
                value={"Go"}
                onClick={handleClickSearchFilterToGenerateTheLRHW}
              />
            </div>
          </div>
        ) : (
          <div className="EDRFiltersSearchDiv gridRow">
            <input
              type={"button"}
              value={"👈 Back"}
              onClick={() => {
                setIsLoadingData(true);
              }}
            />
          </div>
        )}

        <hr />

        <div>
          {isLoadingData ? (
            <div className="alternateReportViewText">
              <h1>
                Member-wise Ledger Report including Loan Ledger, Compulsory
                Deposit Ledger & Shares Ledgers to Generate here.
              </h1>
            </div>
          ) : (
            <div>
              <LRHWTableData className={'LDRContentTable centerContent'} tranactionsList={transactionsData} />
              
              {/* <div className="FormActionDiv">
                <input type={"button"} value={"Proceed to Next Step 👉"} />
              </div> */}
            </div>
          )}
        </div>
      </>
    );
}

export default LRHWFilterInputsForReportGeneration