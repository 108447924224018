import "./RBCTCSMembershipDetailsViewer.css";

import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import { useNavigate } from "react-router-dom";

import useMyAuthService  from "../../../../authenticationServiceProvider/useMyAuthService";

function RBCTCSMembershipDetailsViewer() {

  const sharesPurchasePaymentObj = {
    rbMemberRUID: "",
    loanApplicationId: "",
    prePaymentAmount: "",
    fileOnlineReceiptOrCheque:"",
    loanPrePaymentMode:""
  };


  const navigate = useNavigate();

  const {loggedInUserRUID, api} = useMyAuthService();
  const [currentLoggedInRUID, setCurrentLoggedInRUID] = useState(loggedInUserRUID);

  //const [sharesPurchasePaymentForm, setSharesPurchasePaymentForm] = useState(sharesPurchasePaymentObj);
  const [RBCTCSMembershipData, setRBCTCSMembershipDetails] = useState({});

  const [isLoadingData, setIsLoadingData] = useState(true);

  // const [openSharesPurchasePaymentDialog, setOpenSharesPurchasePaymentDialog] =
  //   React.useState(false);


  useEffect(() => {
    getRBCTCSMembershipDetails();
  }, []);

  const getRBCTCSMembershipDetails = () => {
    api
      .get(
        `/getRBCTCSMembershipDetails/${currentLoggedInRUID}`
      )
      .then((response) => {
        // console.log(response.data);
        setRBCTCSMembershipDetails(response.data);
        //console.log(regData);
        setIsLoadingData(false);
      });
  };


  const handleOpenSharesPurchasePaymentDialog =()=>{
    // setSharesPurchasePaymentForm({
    //   ...sharesPurchasePaymentForm,
    //   rbMemberRUID: currentLoggedInRUID,
    // });
    // setOpenSharesPurchasePaymentDialog(true);
    navigate("../SharePurchaseRequests", { replace: true })
  }
  
 

  return (
    <div className="RBCTCSMembershipDetailsViewerMainDiv">
      <fieldset>
        <legend>🔍 View Your Membership Details</legend>
        {isLoadingData ? (
          <div className="isLoadingDiv">Loading Data .....</div>
        ) : (
          <>
            <div className="gridRow">
              <div className="gridCol">
                <table>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{RBCTCSMembershipData.name}</td>
                    </tr>
                    <tr>
                      <td>RUID</td>
                      <td>{RBCTCSMembershipData.rbMemberRUID}</td>
                    </tr>
                    <tr>
                      <td>Ledger No.</td>
                      <td>{RBCTCSMembershipData.ledgerNo}</td>
                    </tr>
                    <tr>
                      <td>No of shares</td>
                      <td>{RBCTCSMembershipData.noOfShares}</td>
                    </tr>
                    <tr>
                      <td>Total Share Amount</td>
                      <td>{RBCTCSMembershipData.totalShareAmount}</td>
                    </tr>
                    <tr>
                      <td>Compulsory Deposit Amount</td>
                      <td>{RBCTCSMembershipData.compulsoryDeposit}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="gridCol">
                <table>
                  <tbody>
                    <tr>
                      <td>Date of Membership Application</td>
                      <td>
                        {RBCTCSMembershipData.dateOfMembershipApplication}
                      </td>
                    </tr>
                    <tr>
                      <td>Date of Membership Approval</td>
                      <td>{RBCTCSMembershipData.dateOfMembershipApproval}</td>
                    </tr>
                    <tr>
                      <td>Application Verified By</td>
                      <td>{RBCTCSMembershipData.MVSRUID}</td>
                    </tr>
                    <tr>
                      <td>Membership Approved By</td>
                      <td>{RBCTCSMembershipData.MAAID}</td>
                    </tr>
                    <tr>
                      <td>Main Loan Outstanding</td>
                      <td>{RBCTCSMembershipData.currentMLOutstanding}</td>
                    </tr>
                    <tr>
                      <td>Emergent Loan Outstanding</td>
                      <td>{RBCTCSMembershipData.currentELOutstanding}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr></hr>
            <div className="gridRow">
              <div className="FormActionDiv gridCol">
                <input
                  type={"button"}
                  onClick={() => handleOpenSharesPurchasePaymentDialog()}
                  value="🖆 Shares Purchase Request"
                />
              </div>
            </div>
          </>
        )}


        {/* Dialog to display Admin Details ................ */}
        {/* <Dialog open={openSharesPurchasePaymentDialog} fullWidth maxWidth="sm">
          <DialogActions>
            <Button
              variant="outlined"
              onClick={handleCloseSharesPurchasePaymentDialog}
            >
              ❌ Close
            </Button>
          </DialogActions>
          <DialogContent>
            <div className="memberExistingLoanPrePaymentDialogDiv">
              <fieldset>
                <legend>Shares Purchase Payment Details</legend>
                <form
                  onSubmit={(e) => {
                    handleSubmitSharesPurchasePaymentRequest(e);
                  }}
                >
                  <div className="gridRow">
                    <div className="FormFieldDiv">
                      <div>
                        <input
                          type={"text"}
                          placeholder="👤 RUID"
                          value={sharesPurchasePaymentForm.rbMemberRUID}
                          required
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="FormFieldDiv">
                      <div>
                        <input
                          type={"text"}
                          placeholder=" Enter Share Purchase Amount"
                          onChange={(e) => {
                            setSharesPurchasePaymentForm({
                              ...sharesPurchasePaymentForm,
                              loanPrePaymentAmount: e.target.value,
                            });
                          }}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="gridRow">
                    
                    <div className="FormFieldDiv">
                      <div>
                        <select
                        onChange={(e) =>{
                          setSharesPurchasePaymentForm({
                            ...sharesPurchasePaymentForm,
                            loanPrePaymentMode: e.target.value,
                          });
                        }
                       } 
                        >
                          <option value={"cheque"}>Payment By Cheque Mode</option>
                          <option value={"online"}>Payment By Online Mode</option>
                        </select>
                      </div>
                    </div>
                    <div className="FormFieldDiv">
                      <div>Upload Cheque/Online Pay Receipt</div>
                      <div>
                        <input
                          type={"file"}
                          onChangeCapture={(e) => {
                            setSharesPurchasePaymentForm({
                              ...sharesPurchasePaymentForm,
                              fileOnlineReceiptOrCheque: e.target.file,
                            });
                          }}
                          required
                        />
                      </div>
                    </div>

                  </div>
                  <div className="gridRow">
                  
                  </div>
                  <hr />
                  <div className="gridRow">
                    <div className="FormActionDiv">
                      <input type={'submit'} value="Submit Pre-Payment Form" />
                    </div>
                  </div>
                </form>
              </fieldset>
            </div>
          </DialogContent>
        </Dialog> */}
      </fieldset>
    </div>
  );
}

export default RBCTCSMembershipDetailsViewer;
