import React from 'react'

const LoanApplicationsVerifyOKSubmit = ({
  api,
  staffLoggedIn,
  selectedLoanApplication,
  setOpenProgressBarDialog,
  setUpdatedForReRender,
  setOpenVerifyApplication
}) => {

  const handleSubmitVerifyOkLoanApplication = async () => {
    const data = {
      LoanApplicationID: selectedLoanApplication.loanApplicationId,
      rbMemberLedgerNo: selectedLoanApplication.rbMemberLedgerNo,
      staffId: staffLoggedIn,
      applicationStatus: "LADV",
    };
    //console.log(data);
    let config = {
      method: "PUT",
      url: `/LoanApplicationVerifiedOk`,
      data: { data },
      headers: {
        Accept: "application/json",

        //  Authorization: varToken
      },
      responseType: "json",
    };

    //console.log(config);
    setOpenProgressBarDialog(true);
    const response = await api(config);

    setUpdatedForReRender((updatedForReRender) => !updatedForReRender);
    setOpenVerifyApplication(false);
    setOpenProgressBarDialog(false);
    //console.log(response.data);
  };

  
  return (
    <>
      {/* Dialog to implement the verification logic for the submitted application ................ */}
      
          <div className="finalVerificationSubmitDialogDiv">
            <p>
              If the details of the application are succesfully verified, please
              click on verify ok button.
            </p>
            <div className="FormActionDiv">
              <button onClick={handleSubmitVerifyOkLoanApplication}>
                👌 Verify OK
              </button>
            </div>
          </div>
       
    </>
  )
}

export default LoanApplicationsVerifyOKSubmit