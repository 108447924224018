import "./ManageMessagesToRBCTCS.css";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";


import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import LinearProgress from "@mui/material/LinearProgress";

import useMyAuthService from "../../../authenticationServiceProvider/useMyAuthService";



const ManageMessagesToRBCTCS = () => {

 // const [role ]= props.role;

  const {loggedInUserRUID, loggedInUserRole, api} = useMyAuthService();

  const [messagesToRBCTCS, setMessagesToRBCTCS] = useState();
  const [noDataFound, setNoDataFound] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const [selectedMessage, setSelectedMessage] = useState();

  const [anchorActionsMenu, setAnchorActionsMenu] = React.useState(null);
  const open = Boolean(anchorActionsMenu);

  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

  const [openViewMessageSendReplyDialog, setOpenViewMessageSendReplyDialog] =
    useState(false);

  const [replyMessageContent, setReplyMessageContent] = useState();

  const [isContentUpdated, setIsContentUpdated] = useState(false);

  const handleClick = (event, msg) => {
    // console.log(member);
    setSelectedMessage(msg);
    //console.log(selectedMember);
    setAnchorActionsMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorActionsMenu(null);
  };

  useEffect(() => {
    GetMessagesToRBCTCS();
  }, [isContentUpdated]);

  const GetMessagesToRBCTCS = () => {
    api
      .get(`/GetMessagesToRBCTCS`)
      .then((response) => {
        //]);
        if (response.data.length === 0) {
          setNoDataFound(true);
        } else {
          setMessagesToRBCTCS(response.data);
        }

        setIsLoadingData(false);
      });
  };

  const handleClickOpenViewMessageSendReplyDialog = () => {
    // console.log(selectedMessage);
    setReplyMessageContent(selectedMessage.replyByRBCTCS);
    setAnchorActionsMenu(null);
    setOpenViewMessageSendReplyDialog(true);
  };

  const handleCloseViewMessageSendReplyDialog = () => {
    setOpenViewMessageSendReplyDialog(false);
  };

  const handleSubmitReplyMessagePosting = async (e) => {
    e.preventDefault();

    // const fd = new FormData();
    // fd.append("replyText", replyMessageContent);
    // fd.append("staffId", loggedInUserRUID);
    // fd.append("messageId", selectedMessage.id)

    const data = {
      replyText: replyMessageContent,
      staffId: loggedInUserRUID,
      messageId: selectedMessage.id
    }

    //console.log(Array.from(fd.entries()));
    let config = {
      headers: {
        Accept: "multipart/form-data",
       // data:fd.entries()
        //  Authorization: varToken
      },
      responseType: "json",
    };

    //console.log("This is the config: "+config);
    setOpenProgressBarDialog(true);

    api.put(`/postReplyMessageByRBCTCSStaff`, data, config ).then((response) => {
       //  console.log(response.data);
        
         setIsContentUpdated(oldvalue=>!oldvalue);
        setOpenViewMessageSendReplyDialog(false);
        setOpenProgressBarDialog(false);

      }) .catch((error) => {
        console.error("Error posting reply:", error);
        setOpenProgressBarDialog(false);
      });
  };


  const handleSubmitReplyMessageSending = async (e) => {
    e.preventDefault();

    // const fd = new FormData();
    // fd.append("replyText", replyMessageContent);
    // fd.append("replierAdmin", loggedInUserRUID);// this function is executed on the Admin Role only
    // fd.append("messageId", selectedMessage.id)

    const data ={
      replyText: replyMessageContent,
      replierAdmin: loggedInUserRUID,
      messageId: selectedMessage.id
    }
    //console.log(replyMessageContent);
    let config = {
      headers: {
        Accept: "multipart/form-data",
        //  Authorization: varToken
      },
      responseType: "json",
    };

    setOpenProgressBarDialog(true);

    api
      .put(
        `/sendReplyMessageByRBCTCSAdmin`,
        data,
        config
      )
      .then((response) => {
        // console.log(response.data);
        // console.log(response.data);
        setOpenViewMessageSendReplyDialog(false);
        setIsContentUpdated(oldvalue=>!oldvalue);
         setOpenProgressBarDialog(false);
      });
  };

   // Choose the appropriate submit function based on user role
   //const handleSubmit = ;


  return (
    <div className="ManageMessagesToRBCTCSMainDiv">
      <fieldset>
        <legend>Messages written to RBCTCS</legend>
        {isLoadingData ? (
          <div className="isLoadingDiv">Loading Data .....</div>
        ) : noDataFound ? (
          <div className="noDataFoundTextDiv">
            No Messages Currently available
          </div>
        ) : (
          <table>
            <thead>
              <tr>
                <td>Sl.</td>
                <td>Sender Name (Email Id)</td>
                <td>Subject</td>
                <td>Message Content</td>
                <td>Reply Sent</td>
                <td>Dealing Staff</td>
                <td>Reply By</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {messagesToRBCTCS.map((msg, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="justifyContent">
                      {msg.fromName} <br></br>({msg.email})
                    </td>
                    <td className="justifyContent">{msg.subject}</td>
                    <td className="justifyContent">{msg.content}</td>
                    <td className="justifyContent">
                      {msg.replyByRBCTCS}
                    </td>
                    <td className="justifyContent">{msg.dealingStaff}</td>
                    <td className="justifyContent">{msg.replierAdmin}</td>
                    <td>
                      <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={(e) => handleClick(e, msg)}
                      >
                        ⚙️
                      </Button>
                      {/* this is the actions menu activated on the button click to take actions on a particular selected member */}
                      <Menu
                        anchorEl={anchorActionsMenu}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {loggedInUserRole === "Staff" ? (
                          <MenuItem
                            onClick={handleClickOpenViewMessageSendReplyDialog}
                          >
                            🔍 View Content & Save Reply
                          </MenuItem>
                        ) : (
                          <MenuItem
                            onClick={handleClickOpenViewMessageSendReplyDialog}
                          >
                            🔍 View Content & Send Reply
                          </MenuItem>
                        )}
                      </Menu>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </fieldset>

      {/* Dialog to implement the Progess bar ................ */}
      <Dialog open={openProgressBarDialog} fullWidth maxWidth="sm">
        <DialogTitle id="scroll-dialog-title">
          <b>Please wait! work under process ....</b>
        </DialogTitle>
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      </Dialog>

      {/* Dialog to implement the verification logic for the submitted application ................ */}
      <Dialog
        open={openViewMessageSendReplyDialog}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogActions>
          <span className="titleDialogBox">
            Replying to {selectedMessage?(selectedMessage.email):("Message Sender")}
          </span>
          <Button onClick={handleCloseViewMessageSendReplyDialog}>❌</Button>
        </DialogActions>
        <DialogContent>
          {selectedMessage ? (
            <div className="ViewMessageSendReplyDialogDiv">
              <form onSubmit={loggedInUserRole === 'Staff' ? (e)=>handleSubmitReplyMessagePosting(e) : (e)=>handleSubmitReplyMessageSending(e)}>
              
                <div className="FormFieldDiv">
                  <div>
                    <label>Message Received</label>
                  </div>
                  <div>
                    <textarea
                      rows={8}
                      cols={60}
                      name="homeAddress"
                      defaultValue={selectedMessage.content}
                      //onChange={''}
                      readOnly
                      //suppressContentEditableWarning={true}
                    />
                  </div>
                </div>
                
                <div className="FormFieldDiv">
                  <div>
                    <label>Reply Text to be sent</label>
                  </div>
                  <div>
                    <textarea
                      rows={8}
                      cols={60}
                      name="homeAddress"
                      value={replyMessageContent}
                      onChange={(e) => {
                        setReplyMessageContent(e.target.value);
                      }}
                      required
                      
                    />
                  </div>
                </div>
                <hr />
                <div className="FormActionDiv">
                  <input type={"button"} value="Clear Form" />
                  {loggedInUserRole === "Staff"?<input type={"submit"} value="📧 Save Reply" />:<input type={"submit"} value="📧 Send Reply" />}
                </div>
              </form>
            </div>
          ) : (
            ""
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ManageMessagesToRBCTCS;
