import React from "react";
import "./RegistrationDetailsViewer.css";

import { useState, useEffect } from "react";
import useMyAuthService from "../../../authenticationServiceProvider/useMyAuthService";


function RegistrationDetailsViewer() {
  
  const { loggedInUserRUID, api } = useMyAuthService();

  // const [ruid, setRuid] = useState(myAuthService.loggedInUserRUID);
  const [regData, setRegData] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  
  useEffect(() => {
    const viewRegistrationDetails = () => {
      //console.log(api.get(`/ViewNewUserRegistrationDetails/${loggedInUserRUID}`));

      api.get(`/ViewNewUserRegistrationDetails/${loggedInUserRUID}`)
      .then((response) => {
          // console.log(response.data[0]);
          setRegData(response.data[0]);
          //console.log(regData);
          setIsLoading(false);
        })
        .catch((error) => {
          // Handle error
          console.log(error);
        });
    };

    viewRegistrationDetails();
  }, []);

  return (
    <div className="RegistrationDetailsViewerMainDiv">
      {/* {JSON.stringify(regData)} */}
      <fieldset>
        <legend>✍️ Registration Details</legend>
        {isLoading ? (
          <div className="isLoadingDiv">Loading Data...........</div>
        ) : (
          <div className="gridRow">
            <div className="gridCol">
              <table>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>{regData.name}</td>
                  </tr>
                  <tr>
                    <td>RUID</td>
                    <td>{regData.rbMemberRUID}</td>
                  </tr>
                  <tr>
                    <td>User Id</td>
                    <td>{regData.userId}</td>
                  </tr>
                  <tr>
                    <td>Email Id</td>
                    <td>{regData.email}</td>
                  </tr>
                  <tr>
                    <td>Mobile No.</td>
                    <td>{regData.mobile}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </fieldset>
    </div>
  );
}

export default RegistrationDetailsViewer;
