import React, {useState, useEffect} from "react";
import "./LoanApplicationPreview.css";


import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import EditRoundedIcon from "@mui/icons-material/EditRounded";

import useMyAuthService from "../../../../authenticationServiceProvider/useMyAuthService";

function LoanApplicationPreview(props) {

  const {loggedInUserRUID, api} = useMyAuthService();

  const [ruidLoggedIn, setRUIDLoggedIn] = useState(loggedInUserRUID);

  const [expanded, setExpanded] = React.useState(false);
 
  const {
    loanForm,
    loanSuretyForm,
    loanSurety,
    loanBond1,   
    loanBond2,
    activeStep,
    setActiveStep,
    loggedInMemberDetails
  } = props;

  //console.log(loanBond1.isBondSigned);

  const [isSharesPayRequired, setIsSharesPayRequired] = useState(true);
  const [shareAmount, setShareAmount] = useState(0);
  const [existingShareAmount, setExistingShareAmount] = useState(0);

  useEffect(() => {
    getRBCTCSMembershipDetails();
 }, [])
 
 const getRBCTCSMembershipDetails = () => {
   api
     .get(
       `/getRBCTCSMembershipDetails/${ruidLoggedIn}`
     )
     .then((response) => {
       //console.log(response.data);
      // setRBCTCSMembershipDetails(response.data);
      setSharePurchaseRequirements(response.data);
     });
 };

 const setSharePurchaseRequirements = (membershipData) =>{
   //getRBCTCSMembershipDetails();
   let noOfShares = 0; 
    //console.log(membershipData);
    if (membershipData !== undefined || membershipData != null) {
      if (loanForm.loanAmount !== 0 || loanForm.loanAmount !== null || loanForm.loanAmount !== " ") 
      {
        // console.log(parseInt(loanForm.loanAmount));
        noOfShares = Math.ceil(parseInt(loggedInMemberDetails.currentMLOutstanding) / 3000)+Math.ceil(parseInt(loanForm.loanAmount) / 3000) - parseInt(membershipData.noOfShares);
        (noOfShares <= 0)?noOfShares = 0:setShareAmount(noOfShares * 100);
        

        if(noOfShares === 0){
         setIsSharesPayRequired(isSharesPayRequired => isSharesPayRequired=false);
        }else{
         setIsSharesPayRequired(isSharesPayRequired => isSharesPayRequired=true);
         setExistingShareAmount(membershipData.totalShareAmount);
        }
       
      }
    }
   
}
   

  let loanSuretytablerow = null;

  if (loanSurety !== undefined) {
    loanSuretytablerow = loanSurety.map((loanSurety, index) => {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{loanSurety.name}</td>
          <td>{loanSurety.RUID}</td>
          <td>{loanSurety.mobile}</td>
        </tr>
      );
    });
  }

 

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const movetoeditdetails = (active) => {
    setActiveStep((activeStep) => active);
  };

  

  return (
    <div className="loanApplicationPreviewMainDiv">
      <fieldset>
        <legend>🪟 Loan Application Preview</legend>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <span> Loan Form Details </span>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {/* {console.log(bankForm)} */}
              <ul>
                {Object.entries(loanForm).map(([key, value]) => {
                  // Pretty straightforward - use key for the key and value for the value.
                  // Just to clarify: unlike object destructuring, the parameter names don't matter here.
                  return (
                    <li key={key}>
                      {key} {} {loanForm[key]}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="detailseditbuttondiv">
              <EditRoundedIcon onClick={() => movetoeditdetails(1)} />
            </div>
          </AccordionDetails>
        </Accordion>
        {/* {sharesForm.noOfShares === 0 ? (
          " "
        ) : (
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <span>Shares Payment Details</span>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                
                <ul>
                  {Object.entries(sharesForm).map(([key, value]) => {
                    // Pretty straightforward - use key for the key and value for the value.
                    // Just to clarify: unlike object destructuring, the parameter names don't matter here.
                    return (
                      <li key={key}>
                        {key} {} {sharesForm[key]}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="detailseditbuttondiv">
                
                <EditRoundedIcon onClick={() => movetoeditdetails(2)} />
              </div>
            </AccordionDetails>
          </Accordion>
        )} */}
        {loanSuretyForm === undefined ? (
          ""
        ) : (
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <span>Loan Surety Details</span>
            </AccordionSummary>
            <AccordionDetails>
              <div className="loanSuretyDetailsTableDiv">
                <table className="loanSuretyDetailsTable" border="1">
                  <thead>
                    <tr>
                      <td>Sl.</td>
                      <td>Surety Name</td>
                      <td>RUID</td>
                      <td>Mobile No.</td>
                    </tr>
                  </thead>
                  <tbody>{loanSuretytablerow}</tbody>
                </table>
              </div>
              <div className="detailseditbuttondiv">
                {/* {loanForm.map} */}
                <EditRoundedIcon onClick={() => movetoeditdetails(3)} />
              </div>
            </AccordionDetails>
          </Accordion>
        )}

        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <span>Loan Bonds Details</span>
          </AccordionSummary>
          <AccordionDetails>
            <div className="loanBondDetailsPreviewDiv">
              <div>
                {loanBond1.isBond1Signed
                  ? "Bond 1 is signed"
                  : "Bond 1 is not signed"}
              </div>
              <div>
                {loanBond2.isBond2Signed
                  ? "Bond 2 is signed"
                  : "Bond 2 is not signed"}
              </div>
            </div>
            <div className="detailseditbuttondiv">
              {/* {loanForm.map} */}
              <EditRoundedIcon onClick={() => movetoeditdetails(4)} />
            </div>
          </AccordionDetails>
        </Accordion>

        {isSharesPayRequired ? (
          <div className="gridRow">
            <h2>
            <p align="justify"> 
              
              Your Existing Shares of Rs. {existingShareAmount} are not enough for the required Loan Amount of Rs. {parseInt(loanForm.loanAmount) + parseInt(loggedInMemberDetails.currentMLOutstanding)}. Share Payment of {shareAmount} is required. Please Make the Payment, contact the admin and then apply 
              
            </p>
            </h2>
          </div>
        ) : (
          <div className="FormActionDiv">
            <button type="Submit"> Submit Your Loan Application</button>
            {/* {
            (emptyFields.length>0)?
            (emptyFields.map((field, index)=>{
                 <p key={index}>{field}</p>
            })):()
          } */}
          </div>
        )}
      </fieldset>
    </div>
  );
}

export default LoanApplicationPreview;
// onClick={() => moveToAfterSubmitScreen()}