import React, { useState, useEffect } from 'react';
import CustomDatePicker from '../../../CustomDatePicker/CustomDatePicker';
import "./TBRFilterInputsForReportGeneration.css"

const TBRFilterInputsForReportGeneration = () => {

  const [trialBalanceData, setTrialBalanceData] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      // Simulate fetching data. Replace with actual data fetching logic.
      setIsLoading(true);

      // In a real application, fetch trial balance data from your API here
      // For this example, we're using dummy data
      setTimeout(() => {
        const dummyData = [
          { id: 1, accountName: 'Cash', openingDebit: 5000, openingCredit: 0, debit: 5500, credit: 500 },
          { id: 2, accountName: 'Savings', openingDebit: 0, openingCredit: 3000, debit: 1000, credit: 4000 },
          { id: 3, accountName: 'Loans', openingDebit: 0, openingCredit: 10000, debit: 2000, credit: 12000 },
          // Add more dummy data as needed
        ];

        setTrialBalanceData(dummyData);
        setIsLoading(false);
      }, 1000);
    }
  }, [selectedStartDate, selectedEndDate]); // Fetch data whenever the selected dates change

  const handleStartDateSelect = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateSelect = (date) => {
    setSelectedEndDate(date);
  };

  return (
    <div className="trial-balance-container">
      <h2 className="trial-balance-title">RBCTCS Trial Balance</h2>

      {/* Custom Date Pickers */}
      <CustomDatePicker label="Select Start Date" onSelectDate={handleStartDateSelect} />
      <CustomDatePicker label="Select End Date" onSelectDate={handleEndDateSelect} />

      {isLoading && <p className="loading-message">Loading data...</p>}

      {!isLoading && !trialBalanceData.length && (
        <p className="instruction-message">Select start and end dates to fetch trial balance data.</p>
      )}

      {trialBalanceData.length > 0 && (
        <table className="trial-balance-table">
          <thead>
            <tr>
              <th>Account</th>
              <th>Opening Debit</th>
              <th>Opening Credit</th>
              <th>Debit</th>
              <th>Credit</th>
              <th>Closing Debit</th>
              <th>Closing Credit</th>
            </tr>
          </thead>
          <tbody>
            {trialBalanceData.map((entry) => (
              <tr key={entry.id}>
                <td>{entry.accountName}</td>
                <td>{entry.openingDebit}</td>
                <td>{entry.openingCredit}</td>
                <td>{entry.debit}</td>
                <td>{entry.credit}</td>
                <td>{entry.openingDebit + entry.debit}</td>
                <td>{entry.openingCredit + entry.credit}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};


export default TBRFilterInputsForReportGeneration