import "../NewEmergentLoanApplicationForm/NewEmergentLoanApplicationForm.css";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import LoanApplicationInstructions from "../NewLoanApplicationComponents/LoanApplicationInstructions/LoanApplicationInstructions";
import LoanFormDetails from "../NewLoanApplicationComponents/LoanFormDetails/LoanFormDetails";
import LoanBond1 from "../NewLoanApplicationComponents/LoanBond1/LondBond1";
import LoanBond2 from "../NewLoanApplicationComponents/LoanBond2/LondBond2";

import React, { useEffect, useState } from "react";

import PaymentsForLoan from "../NewLoanApplicationComponents/PaymentsForLoan/PaymentsForLoan";
import LoanApplicationPreview from "../NewLoanApplicationComponents/LoanApplicationPreview/LoanApplicationPreview";

import { useNavigate } from "react-router-dom";

import useMyAuthService from "../../../authenticationServiceProvider/useMyAuthService";


const steps = [
  "Instructions",
  "Loan Details",
  // "Payment Details",
  "Loan Bond-1",
  "Loan Bond-2",
  "Preview"
];

const  loanFormFields = {
  loanType:"Emergent Loan TopUp",
  loanAmount:0,
  loanPurpose:"",
  rateOfInterest:"9",
  noOfIntallments:"",
  firstIntallmentMonth:"",
  isSharesPayRequired:true
};


// const sharesFormFields = {
//   noOfShares: 0,
//   shareAmount: 0,
//   chequeNo: " ",
//   chequeDate: " ",
//   chequeBank: " ",
//   chequeAmount:0,
//   chequeBearer: "RBCTCS",
//   chequeBearerAccountNo: "10211841032",
// };

const  loanBond1FormFields = {
  isBond1Signed: false,
  bond1SignDate: new Date().toLocaleDateString('en-GB'),
  bond1SignPlace:" ",
  
};

const  loanBond2FormFields = {
  isBond2Signed: false,
  bond2SignDate: new Date().toLocaleDateString('en-GB'),
  bond2SignPlace:" ",
  
};

//array to help in checking the empty fields on the form submission...................
let emptyFields = [];


const NewEmergentLoanTopUpComponent = () => {

    const {loggedInUserRUID, api} = useMyAuthService();
  
    const navigate = useNavigate();
  
    //active step of the multi step form.......................
    const [activeStep, setActiveStep] = React.useState(0);
    const activeprops = { activeStep, setActiveStep};
  
    const [loanType, setLoanType] = useState("Emergent Loan TopUp");
  
    const [ruidLoggedIn, setRUIDLoggedIn] = useState(loggedInUserRUID);
    const [loggedInMemberDetails, setLoggedInMemberDetails] = useState({});
  
  
    //getting the details of the member logged in currently from the DB...........
    useEffect(() => {
      getLoggedInRBMemberDetails();
    
    }, []);
  
    const getLoggedInRBMemberDetails = () => {
      api.get(`/getRBMemberDetails/${ruidLoggedIn}`)
        .then((response) => {
          // console.log(response.data);
          setLoggedInMemberDetails(response.data);
          //console.log(regData);
        });
    };
  
   //XML resource storage variables
   const [memberDesignations, setMemberDesignations] = useState([]);
   const [rbBranches, setRBBranches] = useState([]);
   const [Banks, setBanks] = useState([]);
  
  //getting the XML resources..........................
  useEffect(() => {
    const getXMLResources = () => {
      api.get(`/getXMLResources/memberDesignations`)
        .then((response) => {
          setMemberDesignations(response.data.Designation);
        });
  
      api.get(`/getXMLResources/rbBranches`)
        .then((response) => {
          setRBBranches(response.data.Branch);
        });
  
      api.get(`/getXMLResources/Banks`)
        .then((response) => {
          setBanks(response.data.Bank);
        });
    };
    getXMLResources();
  }, []);
  
  
    const [loanForm, setLoanFormValues]= React.useState(loanFormFields);
    const loanformprops = {loanForm, setLoanFormValues, loggedInMemberDetails, loanType};
  
    // const [sharesForm, setsharesFormFieldValues] = React.useState(sharesFormFields);
    // const sharesFormprops = { sharesForm, setsharesFormFieldValues, Banks, loanForm,setLoanFormValues};
  
    const [loanBond1, setLoanBond1Values]= React.useState(loanBond1FormFields);
    const loanBond1props = {loanBond1, setLoanBond1Values, loanForm, setLoanFormValues, loggedInMemberDetails};
  
    const [loanBond2, setLoanBond2Values]= React.useState(loanBond2FormFields);
    const loanBond2props = {loanBond2, setLoanBond2Values, loanForm, setLoanFormValues, loggedInMemberDetails};
  
  
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };
    
    const showStepContents = (step) =>{
          // get the contents as per the active state.
        switch (step) {
          case 0:
            return <LoanApplicationInstructions />;
          case 1:
            return <LoanFormDetails { ... loanformprops} />;
          // case 2:
          //   return <PaymentsForLoan {...sharesFormprops}/>
          case 2:
            return <LoanBond1 { ... loanBond1props}/>;
          case 3:
            return <LoanBond2 { ... loanBond2props}/>;
          case 4:
            return <LoanApplicationPreview   {... loanformprops} 
                                            //  {...sharesFormprops} 
                                             { ... loanBond1props}
                                             { ... loanBond2props}
                                             {...activeprops}/>;
          default:
            return null;
        }
    };
  
  //saving the details to the database.....
    async function handleSubmit (e) {
        //preventing the default behavior on submit
        e.preventDefault();
    
        //initialize the array................      
        emptyFields = [];
    
        //check if any of the properties of the loanForm  is empty.......... and dont allow to move forward
        for (var key in loanForm) {
          if (loanForm[key] === null  || loanForm[key] === " "){
            //console.log("please fill in the complete details of the following : "+key)
            emptyFields.push("Loan Form: "+key);
           // return false;
          }
        }  
    
        // //check if any of the properties of the sharesForm  is empty.......... and dont allow to move forward
        // if(sharesForm.noOfShares !== 0){
        //   for (var key in sharesForm) {
        //     if (sharesForm[key] === null || sharesForm[key] === " ")
        //       emptyFields.push("Shares Form: "+key);
        //   } 
        // }else{
        //     for (var key in sharesForm) {
        //      sharesForm[key]="not required"
        //      } 
        //      sharesForm.noOfShares = 0;
        // }
        
    
        //check if any of the properties of the loanbonds  is empty.......... and dont allow to move forward
        if(!loanBond1.isBond1Signed){
            emptyFields.push("Bond 1 is not signed/checked" )
        }
        if(!loanBond2.isBond2Signed){
          emptyFields.push("Bonds 2 is not signed/checked")
        }
      
        /////////sending the data to the server id all the data is present
        
        if(emptyFields.length === 0){
            // console.log("The lenght is "+ emptyFields.length)
            //console.log("The data being sent..................");
            //console.log({ loanForm, sharesForm, loanBond1, loanBond2});
            let config = {
                method: "POST",
                url: `/NewEmergentLoanApplicationTopUp/${ruidLoggedIn}`,
                data:{ ...loanForm, ...loanBond1, ...loanBond2, appplicationStatus:"LAS"},
                headers: {
                  Accept: "application/json",
                  //  Authorization: varToken
                },
                responseType: "application/json",
              };
          
             const response = await api(config);
              console.log("data sent from the server.............................");
              console.log(response.data);
              //navigate("../MembershipFormSubmission", {replace:true}, { state: response.data });
        }else{
               //form is not ready..................
                   // console.log(emptyFields);
                    alert("Please fill in all the details" +"\r\n"+ emptyFields.map((field, index)=>{
                              return field+"\r\n";
                    }));
        }
          
      };
    
  
  
    return (
      <Box sx={{ width: "96%" }} m={2}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
  
            return (
              <Step key={label} {...stepProps}>
                <StepButton>{label}</StepButton>
              </Step>
            );
          })}
        </Stepper>
      
        {<div>
            <form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
            {showStepContents(activeStep)}
            </form> 
          </div>  
        }
            
        {activeStep === steps.length ? (
          <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
  
              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Box>
    );
  }

export default NewEmergentLoanTopUpComponent