import React, { useState } from 'react'

const MemberBankPaymentDetailsUpdater = (props) => {
  const [ bankForm, setbankFormFieldValues] = useState({});
  const [Banks, setBankNames] = useState([]);

  

  
  return (
    <div className="bankDetailsMainDiv">
      <fieldset>
        <legend>✍️ Update Your Bank Details</legend>
        Salary Bank Details
        <hr />
        <div className="gridRow">
          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Bank Name</label>
              </div>
              <div>
                <input
                  list="bankNames"
                  value={bankForm.salaryBankName}
                  onChange={(e) =>
                    setbankFormFieldValues({
                      ...bankForm,
                      salaryBankName: e.target.value,
                    })
                  }
                />
                <datalist id="bankNames">
                  {Banks.map((bank, index) => {
                    return (
                      <option key={index} value={bank.Code}>
                        {bank.FullForm}
                      </option>
                    );
                  })}
                </datalist>
              </div>
            </div>
          </div>
          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Account No.</label>
              </div>
              <div>
                <input
                  type={"number"}
                  value={bankForm.salaryBankAccountNo}
                  onChange={(e) =>
                    setbankFormFieldValues({
                      ...bankForm,
                      salaryBankAccountNo: e.target.value,
                    })
                  }
                  placeholder="Enter your Bank Account No."
                />
              </div>
            </div>
          </div>
          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>IFSC Code</label>
              </div>
              <div>
                <input
                  type={"text"}
                  value={bankForm.salaryBankIFSC}
                  onChange={(e) =>
                    setbankFormFieldValues({
                      ...bankForm,
                      salaryBankIFSC: e.target.value,
                    })
                  }
                  placeholder="Enter Bank IFSC Code"
                />
              </div>
            </div>
          </div>
        </div>
        
      </fieldset>
    </div>
  );
}

export default MemberBankPaymentDetailsUpdater