import React, { useState, useEffect } from "react";
import "./LoanApplicationSuretiesDetailsViewer.css"
import useMyAuthService from "../../../../../authenticationServiceProvider/useMyAuthService";

const LoanApplicationSuretiesDetailsViewer = ({
  LoanApplicationId
}) => {

  const {api} = useMyAuthService();
  const [suretyData, setSuretyData] = useState([]);

  useEffect(() => {
    getSuretiesOfLoanApplication();
   
  }, [suretyData]);

  const getSuretiesOfLoanApplication = () => {
    let config = {
      method: "GET",
      url: `/GetSuretiesOfLoanApplication/${LoanApplicationId}`, // Corrected the URL, remove the extra backtick
      headers: {
        Accept: "multipart/FormData",
        // Authorization: varToken
      },
      responseType: "json",
    };
  
    api(config)
      .then((response) => {
        setSuretyData(response.data);
      })
      .catch((error) => {
        console.error("Error while fetching sureties:", error);
        // Handle errors if any
      });
  };
  

  ////////////  Surety details/..................................
  let SuretyTableRow = null;
  if (suretyData.length > 0) {
    SuretyTableRow = suretyData.map((Surety, index) => {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{Surety.rbmembers.rbMemberRUID} </td>
          <td>{Surety.rbmembers.ledgerNo} </td>
          <td>{Surety.rbmembers.name} </td>
          <td>{Surety.rbmembers.designation} </td>
          <td>{Surety.rbmembers.branch} </td>
          <td>{Surety.rbmembers.compulsoryDeposit} </td>
          <td>{Surety.rbmembers.railwayTelNo} </td>
          <td>{Surety.suretyRequestStatus} </td>
          <td>
            <input
              type="button"
              value={"✅"}
              disabled = {Surety.suretyRequestStatus !== "Accepted"}
              onClick={() => handleSubmitVerifySurety("Verified", Surety.id)}
            />
          </td>
          <td>
            <input
              type="button"
              disabled = {Surety.suretyRequestStatus !== "Accepted"}
              value={"❌"}
              onClick={() => handleSubmitVerifySurety("Disapproved", Surety.id)}
            />
          </td>
        </tr>
      );
    });
  }


  const handleSubmitVerifySurety = (suretyRequestStatus, loanSuretyId) => {
    let config = {
      method: "POST",
      data: { suretyRequestStatus: suretyRequestStatus, loanSuretyId: loanSuretyId },
      url: `/VerifySuretyForLoanApplication`, // Corrected the URL, remove the extra backtick
      headers: {
        Accept: "multipart/FormData",
        // Authorization: varToken
      },
      responseType: "json",
    };
  
    api(config)
      .then((response) => {
        //console.log(response.data);
        // Perform any necessary actions after successful API call
      })
      .catch((error) => {
        console.error("Error while verifying surety:", error);
        // Handle errors if any
      });
  };
  

  return (
    <div className="suretyDetailsTableDiv">
        <table className="suretyDetailsTable" border="1">
          <thead>
            <tr>
              <td>Sl.</td>
              <td>Surety RUID</td>
              <td>Surety Ledger No.</td>
              <td>Surety Name</td>
              <td>Designation</td>
              <td>Branch</td>
              <td>Compulsory Deposit</td>
              <td>Railway Tel No.</td>
              <td>Surety Status</td>
              <td colSpan={2}>Actions</td>
              {/* <td>Mobile No.</td>
                  <td>No of Sureties Given</td> */}
            </tr>
          </thead>
          <tbody>{SuretyTableRow}</tbody>
        </table>
      </div>
  );
};

export default LoanApplicationSuretiesDetailsViewer;
