import "./MemberOfficialDetailsViewByStaffOrAdmin.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import LinearProgress from "@mui/material/LinearProgress";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import useMyAuthService from "../../../../authenticationServiceProvider/useMyAuthService";
import { DialogActions } from "@mui/material";
import { Button } from "@mui/material";
import ProgressorBarDisplayer from "../../DialogComponents/ProgressorBarDisplayer/ProgressorBarDisplayer";
import RBMembersListDropDown from "../../RBMembersListDropDown/RBMembersListDropDown";

const MemberOfficialDetailsViewByStaffOrAdmin = () => {
  const navigate = useNavigate();

  const { loggedInUserRUID, api } = useMyAuthService();

  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

 
  const [selectedMemberRUID, setSelectedMemberRUID] = useState(null);

  const [rbMemberOfficialData, setRBMemberOfficialDetails] = useState({});

  const [openPaySlipDisplayer, setOpenPaySlipDisplayer] = useState(false);
  const [paySlipFile, setPaySlipFile] = useState();

  
 
  const getRBMemberOfficialDetails = () => {
    api
      .get(`/getRBMemberOfficialDetails/${selectedMemberRUID}`)
      .then((response) => {
        //console.log(response.data);
        setRBMemberOfficialDetails(response.data);
        //console.log(regData);
       
      });
  };

  const GetMemberOfficialDetails = () => {
    setOpenProgressBarDialog(true);
    getRBMemberOfficialDetails();

    setOpenProgressBarDialog(false);
  };

  const handleOpenPaySlipDisplayer = () => {
    //console.log(orderId);
    api({
      url: `/GetPaySlipFile/${selectedMemberRUID}`,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      //console.log(response.data);
      setPaySlipFile(fileURL);
      setOpenPaySlipDisplayer(true);
    });
  };

  const handleClosePaySlipDisplayerDialog = () => {
    setOpenPaySlipDisplayer(false);
  };

  return (
    <div className="MemberOfficialDetailsViewByStaffOrAdminMainDiv">
      <fieldset>
        <legend>🔍 View Your Official Details</legend>

        <RBMembersListDropDown 
            setSelectedMemberRUID={setSelectedMemberRUID}
            actionButtonText={"Click to View Member Official Details"}
            actionButtonFunction={GetMemberOfficialDetails}
          />
        <hr />
        <>
          <div className="gridRow">
            <div className="gridCol">
              <table>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>{rbMemberOfficialData.name}</td>
                  </tr>
                  <tr>
                    <td>RUID</td>
                    <td>{rbMemberOfficialData.rbMemberRUID}</td>
                  </tr>
                  <tr>
                    <td>Designation</td>
                    <td>{rbMemberOfficialData.designation}</td>
                  </tr>
                  <tr>
                    <td>Branch</td>
                    <td>{rbMemberOfficialData.branch}</td>
                  </tr>
                  <tr>
                    <td>Employee Id</td>
                    <td>{rbMemberOfficialData.employeeId}</td>
                  </tr>
                  <tr>
                    <td>Railway Tel No.</td>
                    <td>{rbMemberOfficialData.railwayTelNo}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="gridCol">
              <table>
                <tbody>
                  <tr>
                    <td>Date Of Appointment</td>
                    <td>{rbMemberOfficialData.dateOfAppointment}</td>
                  </tr>
                  <tr>
                    <td>Date Of Retirement</td>
                    <td>{rbMemberOfficialData.dateOfRetirement}</td>
                  </tr>
                  <tr>
                    <td>Grade Pay (PayLevel)</td>
                    <td>
                      {rbMemberOfficialData.gradePay} (Level:{" "}
                      {rbMemberOfficialData.payLevel})
                    </td>
                  </tr>
                  <tr>
                    <td>Basic Pay</td>
                    <td>{rbMemberOfficialData.basicPay}</td>
                  </tr>
                  <tr>
                    <td>Cadre Branch</td>
                    <td>{rbMemberOfficialData.cadreControllingBranch}</td>
                  </tr>
                  <tr>
                    <td>Salary Branch</td>
                    <td>{rbMemberOfficialData.salaryReportingBranch}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <hr></hr>
          {selectedMemberRUID === null ? (
            ""
          ) : (
            <div className="gridRow">
              <div className="FormActionDiv gridCol">
                <input
                  type={"button"}
                  onClick={() => handleOpenPaySlipDisplayer()}
                  value="💰 View Pay Slip"
                />
              </div>
            </div>
          )}
        </>

        {/* Dialog to implement the Progess bar ................ */}
        <Dialog open={openPaySlipDisplayer} fullWidth maxWidth="lg">
          <DialogActions id="scroll-dialog-ActionDialogActions">
            <span className="titleDialogBox">Salary Slip Viewer</span>
            <Button
              variant="outlined"
              onClick={handleClosePaySlipDisplayerDialog}
            >
              ❌
            </Button>
          </DialogActions>
          <DialogContent>
            <div className="pdfDisplayerDialogContentDiv">
              <iframe src={paySlipFile}></iframe>
            </div>
          </DialogContent>
        </Dialog>

        <ProgressorBarDisplayer
          titleMessage={"Loading Member Official Details"}
          openProgressBarDialog={openProgressBarDialog}
        />
      </fieldset>
    </div>
  );
};

export default MemberOfficialDetailsViewByStaffOrAdmin;
