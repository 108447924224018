import "./BulletinBoard.css";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import axios from "axios";
import { Button } from "@mui/material";
import { Download } from "@mui/icons-material";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import { useGetAPIServer } from "../../BackEndServerServiceProvider/BackEndServerServiceProvider";

const BulletinBoard = () => {

  const [orderCircularDetails, setOrderCircularDetails] = useState();
  const [noDataFound, setNoDataFound] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [openPDFShowDialog, setOpenPDFShowDialog] = useState(false);
  const [filePDF, setFilePDF] = useState();
  const [selectedOrderFileTitle, setSelectedOrderFileTitle] = useState();

  const apiServer = useGetAPIServer();

  useEffect(() => {
    GetOrderCircularDetails();
  }, []);

  const GetOrderCircularDetails = () => {
    axios
      .get(`${apiServer.serverBaseRoute}/GetOrderCircularDetails`)
      .then((response) => {
        // console.log(response.data);
        if (response.data.length === 0) {
          setNoDataFound(true);
        } else {
          setOrderCircularDetails(response.data);
        }

        setIsLoadingData(false);
      });
  };



  const handleDownloadOrderCircularFile = (orderId, orderTitle) =>{

    //console.log(orderId);
    axios({
      url: `${apiServer.serverBaseRoute}/GetOrderCircularFile/${orderId}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
       //console.log(response.data);
       setFilePDF(fileURL);
       setSelectedOrderFileTitle(orderTitle);
       setOpenPDFShowDialog(true);

    });
    
  }

  const handleClosePDFDisplayDialog = () =>{
     setOpenPDFShowDialog(false);
  }

  return (
    <div className="BulletinBoard">
      <fieldset>
        <legend>📖 RBCTCS : Bulletin Board</legend>

        {isLoadingData ? (
          <div className="isLoadingDiv">Loading Data .....</div>
        ) : noDataFound ? (
          <div className="noDataFoundTextDiv">
            No Orders/Circulars Currently available
          </div>
        ) : (
          <table className="centerContent">
            <thead>
              <tr>
                <td>Sl.</td>
                <td>Order Title</td>
                <td>Date</td>
                <td>Download</td>
              </tr>
            </thead>
            <tbody>
              {orderCircularDetails.map((order, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="justifyContent">{order.orderTitle}</td>
                    <td>{order.orderDate}</td>
                    <td>
                      <Button
                        variant="contained"
                        onClick={() => {
                          handleDownloadOrderCircularFile(order.id, order.orderTitle);
                        }}
                      >
                        <Download />
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </fieldset>
      {/* Dialog to implement the Progess bar ................ */}
      <Dialog open={openPDFShowDialog} fullWidth maxWidth="lg">
        <DialogActions>
          {/* <span className="titleDialogBox">{selectedOrderFileTitle}</span> */}
          <Button color='info' variant="outlined" onClick={handleClosePDFDisplayDialog}>❌</Button>
        </DialogActions>
        <DialogContent>
          <div className="pdfDisplayerDialogContentDiv">
            <iframe src={filePDF}></iframe>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BulletinBoard;
