import { useState } from "react";
import useMyAuthService from "../../../../authenticationServiceProvider/useMyAuthService";

export const partForReview = {
  // Loan_Details: false,
  Surety_Details: false,
  // Bonds_Details: false,
  Pay_Slip:false,
};

export const useVerifyLoanApplicationsState = () => {
  const { loggedInUserRUID, api } = useMyAuthService();
  const [ruidLoggedIn, setRUIDLoggedIn] = useState(loggedInUserRUID);
  const [LoanSuretyDetailsData, setLoanSuretyDetailsData] = useState({});
  const [LoanDetails, setLoanDetails] = useState([]);
  const [SelectedLoanApplication, setSelectedLoanApplication] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [applicationPartForReview, setApplicationPartForReview] =
    useState(partForReview);

  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

  const [isLoadingData, setIsLoadingData] = useState(true);

  const [updatedForReRender, setUpdatedForReRender] = useState(false);

  const [adminRBCTCSDetails, setAdminRBCTCSDetails] = useState({});
  const [staffRBCTCSDetails, setStaffRBCTCSDetails] = useState({});
  const [adminName, setAdminName] = useState();

  const [openAdminDisplayDialog, setOpenAdminDisplayDialog] = useState(false);
  const [openStaffDisplayDialog, setOpenStaffDisplayDialog] = useState(false);

  const [paySlipFile, setPaySlipFile] = useState();
  const [openPaySlipDisplayer, setOpenPaySlipDisplayer] = useState(false);

  const markForReviewMsg = "Select For Correction";

  const [openLoanDetailsInApplication, setOpenLoanDetailsInApplication] =
    useState(false);
  const [
    openLoanSuretyDetailsInApplication,
    setOpenLoanSuretyDetailsInApplication,
  ] = useState(false);
  const [
    openMarkLoanApplicationForReview,
    setOpenMarkLoanApplicationForReview,
  ] = useState(false);
  const [openVerifyApplication, setOpenVerifyApplication] = useState(false);

  const [reviewRemarks, setReviewRemarks] = useState("Data is correct");

  // select options for the application status .......................
  const optionsForApprovedLoanApplications = [
    {
      value: "LADV",
      label: "Loan Application Details Verified",
    },
    {
      value: "LAPA",
      label: "Loan Application Partially Approved",
    },
    {
      value: "LAA",
      label: "Loan Application Approved",
    },
    
  ];

  const optionsForLoanApplicationsToBeVerified = [
    {
      value: "LAS",
      label: "Loan Application Submitted",
    },
    {
      value: "LAMR",
      label: "Loan Application Marked For Review",
    },
    {
      value: "LARS",
      label: "Loan Application Re-submitted",
    },
  ];

  return {
    api,
    loggedInUserRUID,
    ruidLoggedIn,
    setRUIDLoggedIn,
    LoanDetails,
    LoanSuretyDetailsData,
    // LoanApplicationID,
    setLoanDetails,
    setLoanSuretyDetailsData,
    adminName,
    setAdminName,
    adminRBCTCSDetails,
    setAdminRBCTCSDetails,
    applicationPartForReview,
    setApplicationPartForReview,
    expanded,
    setExpanded,
    isLoadingData,
    setIsLoadingData,
    openAdminDisplayDialog,
    setOpenAdminDisplayDialog,
    openLoanDetailsInApplication,
    setOpenLoanDetailsInApplication,
    openLoanSuretyDetailsInApplication,
    setOpenLoanSuretyDetailsInApplication,
    openMarkLoanApplicationForReview,
    setOpenMarkLoanApplicationForReview,
    openPaySlipDisplayer,
    setOpenPaySlipDisplayer,
    openVerifyApplication,
    setOpenVerifyApplication,
    updatedForReRender,
    setUpdatedForReRender,
    SelectedLoanApplication,
    setSelectedLoanApplication,
    openStaffDisplayDialog,
    setOpenStaffDisplayDialog,
    staffRBCTCSDetails,
    setStaffRBCTCSDetails,
    paySlipFile,
    setPaySlipFile,
    openProgressBarDialog,
    setOpenProgressBarDialog,

    reviewRemarks,
    setReviewRemarks,

    optionsForApprovedLoanApplications,
    optionsForLoanApplicationsToBeVerified,
  };
};
