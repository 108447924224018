import React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Tooltip } from "@mui/material";

const LoanApplicationDetailsForPreview = ({ SelectedLoanApplication }) => {
  const [expanded, setExpanded] = React.useState(false);

  //fucntions for the accordian..................
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {/* {JSON.stringify({ Loan })} */}
      <fieldset>
        <legend>🪟 Loan Application and Member Details</legend>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <span> Member Details </span>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {/* Personal Details here */}
              {SelectedLoanApplication.rbmembers !== undefined ? (
                <table className="LoanApplicationStatusTable">
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>Shri {SelectedLoanApplication.rbmembers.name}</td>
                    </tr>
                    <tr>
                      <td>Ledger No</td>
                      <td>{SelectedLoanApplication.rbmembers.ledgerNo}</td>
                    </tr>
                    <tr>
                      <td>Date: Appointment/Retirement</td>
                      <td>
                        {SelectedLoanApplication.rbmembers.dateOfAppointment}/
                        {SelectedLoanApplication.rbmembers.dateOfRetirement}
                      </td>
                    </tr>
                    <tr>
                      <td>Designation/Branch</td>
                      <td>
                        {SelectedLoanApplication.rbmembers.designation}/
                        {SelectedLoanApplication.rbmembers.branch}
                      </td>
                    </tr>
                    <tr>
                      <td>Grade Pay/Pay Level/ Basic Pay</td>
                      <td>
                        {SelectedLoanApplication.rbmembers.gradePay}/
                        {SelectedLoanApplication.rbmembers.payLevel}/
                        {SelectedLoanApplication.rbmembers.basicPay}
                      </td>
                    </tr>
                    <tr>
                      <td>Contact No</td>
                      <td>{SelectedLoanApplication.rbmembers.railwayTelNo}</td>
                    </tr>
                    <tr>
                      <td>Current Outstanding</td>
                      <td>
                        {SelectedLoanApplication.loanType === "ML"
                          ? SelectedLoanApplication.rbmembers
                              .currentMLOutstanding
                          : SelectedLoanApplication.rbmembers
                              .currentELOutstanding}
                      </td>
                    </tr>
                    <tr>
                      <td>Total Shares / Compulsory Deposit</td>
                      <td>
                        {SelectedLoanApplication.rbmembers.totalShareAmount}/
                        {SelectedLoanApplication.rbmembers.compulsoryDeposit}
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                ""
              )}
            </div>
            <div className="detailseditbuttondiv"></div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <span>Loan Details</span>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {/* {official details here)} */}
              <table className="LoanApplicationStatusTable">
                <tbody>
                  <tr>
                    <td>Loan Type</td>
                    <td>
                      {SelectedLoanApplication.loanType === "ML"
                        ? "Main Loan"
                        : "Emergent Loan"}
                    </td>
                  </tr>
                  <tr>
                    <td>Loan Amount Sought</td>
                    <td>{SelectedLoanApplication.loanAmountSought}</td>
                  </tr>
                  <tr>
                    <td>Loan Purpose</td>
                    <td>{SelectedLoanApplication.loanPurpose}</td>
                  </tr>
                  <tr>
                    <td>No Of Installments</td>
                    <td>{SelectedLoanApplication.noOfInstallments}</td>
                  </tr>
                  <tr>
                    <td>Date of First Installment</td>
                    <td>{SelectedLoanApplication.dateOfFirstInstallment}</td>
                  </tr>
                  <tr>
                    <td>Rate Of Interest</td>
                    <td>{SelectedLoanApplication.rateOfInterest}</td>
                  </tr>
                  <tr>
                    <td>Bond #1 is signed</td>
                    <td>
                      {SelectedLoanApplication.isBondOneSigned === 1
                        ? "Yes"
                        : "No"}
                    </td>
                  </tr>
                  <tr>
                    <td>Bond #2 is signed</td>
                    <td>
                      {SelectedLoanApplication.isBondTwoSigned === 1
                        ? "Yes"
                        : "No"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="detailseditbuttondiv">
              {/* {personalForm.map} */}
            </div>
          </AccordionDetails>
        </Accordion>
      </fieldset>
    </>
  );
};

export default LoanApplicationDetailsForPreview;

// Accordion
// expanded={expanded === "panel3"}
// onChange={handleChange("panel3")}
// >
// <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//   <span>Payment Details</span>
// </AccordionSummary>
// <AccordionDetails>
//   <div>
//     {/* {Bank details here)} */}
//     <table className="LoanApplicationStatusTable">
//       <tbody>
//         <tr>
//           <td>No of Shares</td>
//           <td>{SelectedLoanApplication.salaryBankName}</td>
//         </tr>
//         <tr>
//           <td>Share Amount</td>
//           <td>{SelectedLoanApplication.salaryBankIFSC}</td>
//         </tr>
//         <tr>
//           <td>Cheque Amount</td>
//           <td>{SelectedLoanApplication.salaryBankAccountNo}</td>
//         </tr>
//         <tr>
//           <td>Cheque Bearer Name</td>
//           <td>{SelectedLoanApplication.salaryBankIFSC}</td>
//         </tr>
//         <tr>
//           <td>Cheque Bearer Account No.</td>
//           <td>{SelectedLoanApplication.salaryBankAccountNo}</td>
//         </tr>
//         <tr>
//           <td>Cheque No.</td>
//           <td>{SelectedLoanApplication.salaryBankAccountNo}</td>
//         </tr>
//         <tr>
//           <td>Cheque Bank Name</td>
//           <td>{SelectedLoanApplication.salaryBankAccountNo}</td>
//         </tr>
//         <tr>
//           <td>Cheque Date</td>
//           <td>{SelectedLoanApplication.salaryBankAccountNo}</td>
//         </tr>
//       </tbody>
//     </table>
//   </div>
//   <div className="detailseditbuttondiv">
//     {/* {personalForm.map} */}
//   </div>
// </AccordionDetails>
// </Accordion>
