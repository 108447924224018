import React, { useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const MenuLoanDisbursalReports = ({
  anchorActionsMenu,
  openMenu,
  handleCloseMenu,
  
}) => {
  
  return (
    <>
      <Menu
        anchorEl={anchorActionsMenu}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
        //   onClick={() => {
        //     handleClickOpenLoanDetailsInApplication();
        //   }}
        >
          🔍 View Loan Applications Disbursed
        </MenuItem>
              </Menu>
    </>
  );
};

export default MenuLoanDisbursalReports;
