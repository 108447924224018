import React, { useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const MenuMembershipApplicationsVerification = ({
  handleClickOpenMemberDetailsInApplication,
  handleOpenPaySlipDisplayer,
  handleOpenMemberhsipFormDocsDisplayer,
  handleCheckPaymentDetails,
  handleClickOpenMarkApplicationForReview,
  handleClickOpenVerifyApplication,
  selectedMemberApplication,
  handleClickOpenNomineeDetailsInApplication,
  handleClickOpenOtherSocietyDetailsInApplication,
  anchorActionsMenu,
  openMenu,
  handleCloseMenu,
  applicationStatus
}) => {
    
    
  return (
    <div>
        
      <Menu
        anchorEl={anchorActionsMenu}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
       
        <MenuItem
              onClick={()=>{handleClickOpenMemberDetailsInApplication(
                selectedMemberApplication.rbmembers.rbMemberRUID
              )}}
            >
              🔍 View Member Details
            </MenuItem>
            <MenuItem
              onClick={()=>{handleOpenMemberhsipFormDocsDisplayer(
                selectedMemberApplication.rbmembers.rbMemberRUID
              )}}
            >
              🔍 View Membership Form & Docs
            </MenuItem>
            <MenuItem
              onClick={(e) =>
                handleOpenPaySlipDisplayer(
                  selectedMemberApplication.rbmembers.rbMemberRUID
                )
              }
            >
              🔍 View Salary Slip
            </MenuItem>
            <MenuItem onClick={handleClickOpenOtherSocietyDetailsInApplication}>
              🔍 View Other Society Details
            </MenuItem>
            <MenuItem onClick={handleClickOpenNomineeDetailsInApplication}>
              🔍 View Nominee Details
            </MenuItem>

            {applicationStatus === "MASWP" || applicationStatus === "MASPP" || applicationStatus === "MASPR" ||
            applicationStatus === "MASRP" ? (<MenuItem
              onClick={(e) =>
                handleCheckPaymentDetails(
                  selectedMemberApplication.rbmembers.rbMemberRUID
                )
              }
            >
              🔍 Check Payment Details
            </MenuItem>) : (
              ""
            )}

            {applicationStatus === "MASPV" || applicationStatus === "MAMR" || applicationStatus === "MARS"  ? (
              <MenuItem
                onClick={handleClickOpenMarkApplicationForReview(
                  selectedMemberApplication
                )}
              >
                ✍️ Mark For Review
              </MenuItem>
            ) : (
              ""
            )}

            {applicationStatus === "MASPV" || applicationStatus === "MARS"? (
              <MenuItem
                onClick={handleClickOpenVerifyApplication(
                  selectedMemberApplication
                )}
              >
                ✔️ Verify Details
              </MenuItem>
            ) : (
              ""
            )}

        {/* {selectedMemberApplication !== undefined ? (
          <>
            
          </>
        ) : (
          ""
        )} */}
      </Menu>
    </div>
  );
};

export default MenuMembershipApplicationsVerification;
