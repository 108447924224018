import React from "react";
import "./MembershipApplicationPreview.css";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import EditRoundedIcon from "@mui/icons-material/EditRounded";

function MembershipApplicationPreview(props) {
  const [expanded, setExpanded] = React.useState(false);

  const {
    personalForm,
    officialForm,
    bankForm,
    society,
    nominee,
    activeStep,
    setActiveStep,
    
  } = props;

  //console.log(personalForm);

  let societytablerow = null;
  if (society !== undefined) {
    societytablerow = society.map((society, index) => {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{society.societyName}</td>
          <td>{society.societyEmail}</td>
          <td>{society.societyMobile}</td>
          <td>{society.societyAddress}</td>
        </tr>
      );
    });
  }

  let nomineetablerow = null;
  if (nominee !== undefined) {
    nomineetablerow = nominee.map((nominee, index) => {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{nominee.nomineeName}</td>
          <td>{nominee.nomineeRelation}</td>
          <td>{nominee.amountPayableToNominee}</td>
          <td>{nominee.nomineeMobile}</td>
        </tr>
      );
    });
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const movetoeditdetails = (active) => {
    setActiveStep((activeStep) => active);
  };

  

  return (
    <div className="membershipApplicationPreviewMainDiv">
      <fieldset>
        <legend>🪟 Membership Application Preview</legend>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <span> Member Personal Details </span>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {/* {console.log(bankForm)} */}
              <ul>
                {Object.entries(personalForm).map(([key, value]) => {
                  // Pretty straightforward - use key for the key and value for the value.
                  // Just to clarify: unlike object destructuring, the parameter names don't matter here.
                  return (
                    <li key={key}>
                      {key} {} {personalForm[key]}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="detailseditbuttondiv">
              <EditRoundedIcon onClick={() => movetoeditdetails(1)} />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <span>Member Official Details</span>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {/* {console.log(bankForm)} */}
              <ul>
                {Object.entries(officialForm).map(([key, value]) => {
                  // Pretty straightforward - use key for the key and value for the value.
                  // Just to clarify: unlike object destructuring, the parameter names don't matter here.
                  return (
                    <li key={key}>
                      {key} {} {officialForm[key]}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="detailseditbuttondiv">
              {/* {personalForm.map} */}
              <EditRoundedIcon onClick={() => movetoeditdetails(2)} />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <span>Member Bank Details</span>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {/* {console.log(bankForm)} */}
              <ul>
                {Object.entries(bankForm).map(([key, value]) => {
                  // Pretty straightforward - use key for the key and value for the value.
                  // Just to clarify: unlike object destructuring, the parameter names don't matter here.
                  return (
                    <li key={key}>
                      {key} {} {bankForm[key]}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="detailseditbuttondiv">
              {/* {personalForm.map} */}
              <EditRoundedIcon onClick={() => movetoeditdetails(3)} />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <span>Member Other Society Details</span>
          </AccordionSummary>
          <AccordionDetails>
            <div className="societyDetailsTableDiv">
              <table className="societyDetailsTable" border="1">
                <thead>
                  <tr>
                    <td>Sl.</td>
                    <td>Society Name</td>
                    <td>Email Id</td>
                    <td>Mobile No.</td>
                    <td>Address</td>
                  </tr>
                </thead>
                <tbody>{societytablerow}</tbody>
              </table>
            </div>
            <div className="detailseditbuttondiv">
              {/* {personalForm.map} */}
              <EditRoundedIcon onClick={() => movetoeditdetails(4)} />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <span>Member Nominees</span>
          </AccordionSummary>
          <AccordionDetails>
            <div className="nomineeDetailsTableDiv">
              <table className="nomineeDetailsTable" border="1">
                <thead>
                  <tr>
                    <td>Sl.</td>
                    <td>Nominee Name</td>
                    <td>Relation</td>
                    <td>Amount Payable</td>
                    <td>Mobile</td>
                  </tr>
                </thead>
                <tbody>{nomineetablerow}</tbody>
              </table>
            </div>
            <div className="detailseditbuttondiv">
              {/* {personalForm.map} */}
              <EditRoundedIcon onClick={() => movetoeditdetails(5)} />
            </div>
          </AccordionDetails>
        </Accordion>
        <div className="FormActionDiv">
        <button type="Submit" > Submit Your Memberhsip Form</button>
          {/* {
            (emptyFields.length>0)?
            (emptyFields.map((field, index)=>{
                 <p key={index}>{field}</p>
            })):()
          } */}
        </div>
        
      </fieldset>
    </div>
  );
}

export default MembershipApplicationPreview;
// onClick={() => moveToAfterSubmitScreen()}