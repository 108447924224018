import "./ReviewLoanSureties.css";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import useMyAuthService from "../../../authenticationServiceProvider/useMyAuthService";

import DialogContainer from "../DialogComponents/DialogContainer/DialogContainer";

const ReviewLoanSureties = (props) => {
  const { loggedInUserRUID, api } = useMyAuthService();

  const navigate = useNavigate();

  const data = useLocation();
  //console.log(data);
  const loanApplicationId = data.state.loanApplicationId;

  const [loanLimitDetails, setLoanLimitDetails] = useState({});
  const [loanApplication, setLoanApplication] = useState({});
  const [loggedInMemberDetails, setLoggedInMemberDetails] = useState({});
  const [prevLoanSureties, setPrevLoanSureties] = useState([]);

  let loanSuretyData = [];
  const [loanSurety, setLoanSuretydata] = React.useState(loanSuretyData);

  const [loggedInMemberSuretyWeight, setLoggedInMemberSuretyWeight] = useState(0);

  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);
  const [openExistingSuretiesDialog, setOpenExistingSuretiesDialog] =
    useState(false);

  useEffect(() => {
    getLoanSuretyDetailsForReview();
  }, []);

  const getLoanSuretyDetailsForReview = () => {
    setOpenProgressBarDialog(true);
    let config = {
      method: "GET",
      url: `/GetLoanSuretyDetailsForReview/${loanApplicationId}`,
      headers: {
        Accept: "multipart/FormData",

        //  Authorization: varToken
      },
      responseType: "json",
    };

    api(config).then((response) => {
      // console.log(response.data['loanLimits']);
      setLoanApplication(response.data["loanApplication"]);
      setLoggedInMemberDetails(response.data["loanApplication"].rbmembers);

      let currentLoggedInMember = response.data["loanApplication"].rbmembers;
      setLoggedInMemberSuretyWeight(
        currentLoggedInMember.compulsoryDeposit >= 50000 &&
          currentLoggedInMember.compulsoryDeposit <= 100000
          ? 0.5
          : currentLoggedInMember.compulsoryDeposit > 100000
          ? 1
          : 0
      );
    

      // getting the verified and accepted sureties only.................
      const loanSureties = response.data["loanApplication"].loan_sureties 
        .map((surety) => {
          if (
            surety.suretyRequestStatus === "Verified" ||
            surety.suretyRequestStatus === "Accepted"
          ) {
             return surety;
          }
          return null;
        })
        .filter(Boolean);

      //  console.log(loanSureties);

      setPrevLoanSureties(loanSureties);
     
      setLoanLimitDetails(response.data["loanLimits"]);
      //navigate("../ViewYourLoanApplications", { replace: true });
      setOpenProgressBarDialog(false);
    });
  };

  //loan surety..........

  let loanSuretytablerow = null;

  if (loanSurety !== undefined) {
    loanSuretytablerow = loanSurety.map((LS, index) => {
      return (
        <tr key={index}>
          <td>
            <DeleteForeverIcon onClick={() => deleteloanSuretydetail(index)} />
          </td>
          <td>{index + 1}</td>
          <td>
            {LS.name} {LS.RUID}
          </td>
          <td>{LS.mobile}</td>
          <td>{LS.compulsoryDeposit}</td>
          <td>
            {LS.compulsoryDeposit >= 50000 && LS.compulsoryDeposit <= 100000
              ? 0.5
              : LS.compulsoryDeposit > 100000
              ? 1
              : 0}
          </td>
        </tr>
      );
    });
  }

  const [suretyselected, setsuretyselected] = useState();

  const [suretiesDataList, setSuretiesList] = useState([]);
  const [suretiesData, setSuretiesData] = useState({});

  //getting the surety details........................
  useEffect(() => {
    getSuretiesList();
  }, []);

  const getSuretiesList = () => {
    api.get(`/getRBMembersListForSurety`).then((response) => {
      //console.log(response.data);
      setSuretiesList(response.data);
      //console.log(regData);
    });
  };

  const getSetSuretyData = (ruid) => {
    api.get(`/getSuretyData/${ruid}`).then((response) => {
      //console.log(response.data);
      setSuretiesData(response.data);
      //console.log(regData);
    });
  };

  const deleteloanSuretydetail = (index) => {
    var array = [...loanSurety];
    if (index !== -1) {
      array.splice(index, 1);

      setLoanSuretydata((oldarray) => array);
    }
  };

  const addloanSuretydetail = () => {
    if (suretiesData !== undefined) {
      // console.log(suretiesData);
      if (suretiesData.noOfSuretiesGiven < loanLimitDetails.noOfSurety) {
        setLoanSuretydata((oldArray) => [...oldArray, suretiesData]);
      } else {
        alert(
          "Shri " +
            suretiesData.name +
            " has already given " +
            loanLimitDetails.noOfSurety +
            " sureties. As such he cannot be added to your surety list"
        );
      }
    }
  };

  const showSelectedSurety = () => {
    getSetSuretyData(suretyselected);
    // console.log(suretiesData);
  };

  const handleOpenExistingSuretiesDialog = () => {
    setOpenExistingSuretiesDialog(true);
  };

  const handleCloseExistingSuretiesDialog = () => {
    setOpenExistingSuretiesDialog(false);
  };
  const handleViewVerifiedAcceptedSureties = () => {
    handleOpenExistingSuretiesDialog();
  };

  // handle submit the Surety Reviewer.........
  const handleSubmitSuretyReviewer = () => {

    let newLoanSuretiesWeight = loanSurety.reduce((totalWeight, surety) => totalWeight + surety.suretyWeight, 0);
    //console.log(newLoanSuretiesWeight);
    
    let totalSuretyWeightRequired = prevLoanSureties
                                        .filter(surety => surety.suretyRequestStatus === "Verified" || surety.suretyRequestStatus === "Accepted")
                                        .reduce((sum, surety) => sum + surety.suretyWeight, 0);
   // console.log(newLoanSuretiesWeight === totalSuretyWeightRequired);

    if(newLoanSuretiesWeight >= totalSuretyWeightRequired){
      //alert("yes");
      //pass the data of new sureties.... to be saved on the server......
      //console.log(loanSurety);

      let config = {
        method: "POST",
        url: `/UpdateLoanSuretiesForLoan/${loanApplicationId}`, // Corrected the URL, remove the extra backtick
        data:{
            rbMemberRUID:loggedInUserRUID,
            loanSurety:loanSurety
        },
        headers: {
          Accept: "multipart/FormData",
          // Authorization: varToken
        },
        responseType: "json",
      };
    
      api(config)
        .then((response) => {
          //setSuretyData(response.data);
          //console.log(response.data);
          navigate("../ViewYourLoanApplications", {replace:true})
        })
        .catch((error) => {
          console.error("Error while saving sureties:", error);
          // Handle errors if any
        });
      
        
    }

  };

  return (
    <div className="LoanSuretyReviewFormMainDiv">
      <fieldset>
        <legend>🖥️ Please Enter the Surety Details</legend>
        <div>
          <h2 className="jutifyContent">Choose the number of sureties so that, <br/>Total surety weight of selected sureties  = Total surety weight required</h2>
        </div> 
        {/* {JSON.stringify(loggedInMemberDetails)} */}
        <div className="gridRow">
          <table className="suretyWeightTable">
            <thead>
              <tr>
                <td>Item</td>
                <td>Value</td>
                <td>Sureties Weight</td>
              </tr>
            </thead>
            <tbody>
            <tr>
                <td>Previous Outstanding</td>
                <td>{loggedInMemberDetails.currentMLOutstanding}</td>
                <td rowSpan={2}>
                  +{" "}
                  {parseInt(loggedInMemberDetails.currentMLOutstanding) +
                    parseInt(loanApplication.loanAmountSought) <
                  100000
                    ? 0.5
                    : Math.round(
                        Math.floor(
                          loggedInMemberDetails.currentMLOutstanding / 100000
                        ) +
                          Math.floor(
                            loggedInMemberDetails.currentMLOutstanding /
                              100000 +
                              loanApplication.loanAmountSought / 100000
                          )
                      )}
                </td>
                {/* <td> + {loggedInMemberDetails.currentMLOutstanding < 100000 ? 0.5 : Math.round(loggedInMemberDetails.currentMLOutstanding / 100000)}</td> */}
              </tr>
              <tr>
                <td>
                  Loan Amount sought for Loan Application #{" "}
                  {loanApplication.loanApplicationId}
                </td>
                <td>{loanApplication.loanAmountSought}</td>
                {/* <td >
                
                </td> */}
              </tr>
              <tr>
                <td>Your CD Amount</td>
                <td>{parseInt(loggedInMemberDetails.compulsoryDeposit)}</td>
                <td>
                  -{loggedInMemberSuretyWeight}
                </td>
              </tr>

              <tr>
                <td>Number of sureties already Accepted/Verified</td>
                <td>{prevLoanSureties.length}</td>
                <td>
                  {prevLoanSureties.filter(surety => surety.suretyRequestStatus === "Verified" || surety.suretyRequestStatus === "Accepted").reduce((sum, surety) => sum + surety.suretyWeight, 0)}
                </td>
              </tr>
              {/* <tr>
                <td>Number of new sureties Added</td>
                <td>{.CD}</td>;
                <td> - {.suretyWeight}</td>
              </tr> */}
              <tr>
                <td colSpan={2}>Total Sureties Weight Required</td>
                <td>
                  {parseInt(loggedInMemberDetails.currentMLOutstanding) +
                    parseInt(loanApplication.loanAmountSought) <
                  100000
                    ? 0.5
                    : Math.round(
                        Math.floor(
                          loggedInMemberDetails.currentMLOutstanding / 100000
                        ) +
                          Math.floor(
                            loggedInMemberDetails.currentMLOutstanding /
                              100000 +
                              loanApplication.loanAmountSought / 100000
                          )
                      ) -
                    (loggedInMemberSuretyWeight) -
                      prevLoanSureties.reduce((sum, surety) => sum + surety.suretyWeight, 0)}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="gridCol">
            <input
              type="button"
              value={"View Sureties Added previously"}
              onClick={() => handleViewVerifiedAcceptedSureties()}
            />
          </div>
        </div>

        <hr />
        <div className="gridCol">
          <div className="gridRow">
            <div className="FormFieldDiv">
              <input
                list="Sureties"
                name="SuretyDropList"
                id="SuretyDropList"
                onChangeCapture={(e) => setsuretyselected(e.target.value)}
                placeholder="Select a Surety"
              />
              <datalist id="Sureties">
                {suretiesDataList.map((surety, index) => {
                  return (
                    <option key={index} value={surety.rbMemberRUID}>
                      {surety.name}
                    </option>
                  );
                })}
              </datalist>
              <input
                className="suretyViewButton"
                type={"button"}
                value="🔍 Surety"
                onClick={(e) => showSelectedSurety()}
              />
            </div>
          </div>
          <div className="gridRow">
            <div>
              <p>RUID : {suretiesData.RUID}</p>
              <p>Name : {suretiesData.name}</p>
              <p>
                Posting : {suretiesData.designation}/{suretiesData.branch}
              </p>
              <p>
                No of Sureties already given : {suretiesData.noOfSuretiesGiven}
              </p>
              <p>
                Compulsory Deposit (Surety Weight) :{" "}
                {suretiesData.compulsoryDeposit} ({suretiesData.suretyWeight})
              </p>
              <p>
                Mobile/Railway : {suretiesData.mobile}/
                {suretiesData.railwayTelNo}
              </p>
            </div>
          </div>
        </div>
        <div className="gridCol">
          <div className="gridRow">
            <input
              type={"button"}
              value="➕ Surety"
              onClick={addloanSuretydetail}
            />
          </div>
          {/* the table of new sureties */}
          <div className="gridRow">
            <div className="loanSuretyDetailsTableDiv">
              <table className="loanSuretyDetailsTable" border="1">
                <thead>
                  <tr>
                    <td></td>
                    <td>Sl.</td>
                    <td>Surety Name (RUID)</td>
                    <td>Mobile No.</td>
                    <td>Compulsory Deposit</td>
                    <td>Surety Weight</td>
                  </tr>
                </thead>
                <tbody>{loanSuretytablerow}</tbody>
              </table>
            </div>
          </div>
        </div>

        <hr />
        <div className="gridRow centerContent">
          <div className="FormActionDiv gridCol">
            <input
              type={"button"}
              onClick={() => handleSubmitSuretyReviewer()}
              value="Click to Review the Sureties For Loan Application"
            />
          </div>
        </div>
      </fieldset>

      <ShowPreviousSuretiesForReview
        loanApplicationId={loanApplicationId}
        openExistingSuretiesDialog={openExistingSuretiesDialog}
        handleCloseExistingSuretiesDialog={handleCloseExistingSuretiesDialog}
        prevLoanSureties={prevLoanSureties}
      />
    </div>
  );
};

export default ReviewLoanSureties;

// show previous loan sureties
const ShowPreviousSuretiesForReview = ({
  loanApplicationId,
  openExistingSuretiesDialog,
  handleCloseExistingSuretiesDialog,
  prevLoanSureties,
}) => {
  return (
    <>
      {/* Dialog upon to show up the number of sureties verified, rejected, diapproved...... */}
      <DialogContainer
        titleMessage={
          "Existing Sureties on the Loan Application #" + loanApplicationId
        }
        openDialogBox={openExistingSuretiesDialog}
        maxWidth={"md"}
        handleCloseDialog={handleCloseExistingSuretiesDialog}
      >
        <div className="gridRow">
          <div className="loanSuretyDetailsTableDiv2">
            <table className="loanSuretyDetailsTable" border="1">
              <thead>
                <tr>
                  <td>Sl.</td>
                  <td>Surety Name</td>
                  <td>Surety Ledger No</td>
                  <td>Compulsory Deposit</td>
                  <td>Surety Weight</td>
                  <td>Mobile No.</td>
                  <td>Status</td>
                </tr>
              </thead>
              <tbody>
                {prevLoanSureties.map((surety, index) => {
                  return (
                    <tr key={surety.id}>
                      <td>{index + 1}</td>
                      <td>{surety.rbmembers.name}</td>
                      <td>{surety.suretyLedgerNo}</td>
                      <td>{surety.rbmembers.compulsoryDeposit}</td>
                      <td>{surety.suretyWeight}</td>
                      <td>{surety.rbmembers.railwayTelNo}</td>
                      <td>{surety.suretyRequestStatus}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </DialogContainer>
    </>
  );
};
