import React from "react";

const LoanApplicationsMarkForReview = ({
  api,
  staffLoggedIn,
  selectedLoanApplication,
  applicationPartForReview,
  setApplicationPartForReview,
  setOpenProgressBarDialog,
  setOpenMarkLoanApplicationForReview,
  setUpdatedForReRender,
  setReviewRemarks,
  reviewRemarks,
}) => {
  //function to hanlde the logic for mark for review of the Loandhip application.............
  const handleCheckBoxMarkForReview = (e, part) => {
    switch (part) {
      // case "Loan_Details":
      //   setApplicationPartForReview({
      //     ...applicationPartForReview,
      //     Loan_Details: e.target.checked,
      //   });
      //   break;
      case "Surety_Details":
        setApplicationPartForReview({
          ...applicationPartForReview,
          Surety_Details: e.target.checked,
        });
        break;
      // case "Bonds_Details":
      //   //alert("bond");
      //   setApplicationPartForReview({
      //     ...applicationPartForReview,
      //     Bonds_Details: e.target.checked,
      //   });
      //   break;
      case "Pay_Slip":
        setApplicationPartForReview({
          ...applicationPartForReview,
          Pay_Slip: e.target.checked,
        });
        break;
    } //end switch

    // alert(JSON.stringify(applicationPartForReview));
  }; //end func

  const handleSubmitMarkLoanApplicationForReview = async () => {
    // const data = applicationPartForReview;
    const data = {
      LoanApplicationID: selectedLoanApplication.loanApplicationId,
      staffId: staffLoggedIn,
      applicationStatus: "LAMR",
      applicationPartForReview: applicationPartForReview,
      reviewRemarks: reviewRemarks,
    };
    //console.log(data);
    let config = {
      method: "PUT",
      url: `/MarkLoanApplicationForReview`,
      data: { data },
      headers: {
        Accept: "application/json",
      },
      responseType: "json",
    };

    setOpenProgressBarDialog(true);

    const response = await api(config);
   // console.log(response);

    setUpdatedForReRender((updatedForReRender) => !updatedForReRender);
    setOpenMarkLoanApplicationForReview(false);
    setOpenProgressBarDialog(false);
    // console.log(response.data);
  };

  return (
    <>
      <div className="markForReviewDialogDiv">
        <p>please select the part of the application which is incorrect.</p>
        <table>
          <tbody>
            <tr>
              {/* <td>
                <input
                  type={"checkbox"}
                  checked={applicationPartForReview.Loan_Details}
                  onChange={(e) =>
                    handleCheckBoxMarkForReview(e, "Loan_Details")
                  }
                />
                Tick if Loan Details are incorrect
              </td> */}
            </tr>
            <tr>
              <td>
                <input
                  type={"checkbox"}
                  checked={applicationPartForReview.Surety_Details}
                  onChange={(e) =>
                    handleCheckBoxMarkForReview(e, "Surety_Details")
                  }
                />
               Tick if Surety Details are incorrect
              </td>
            </tr>
            <tr>
              <td>
                {/* <input
                  type={"checkbox"}
                  checked={applicationPartForReview.Bonds_Details}
                  onChange={(e) =>
                    handleCheckBoxMarkForReview(e, "Bonds_Details")
                  }
                />
                Bonds Details */}
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type={"checkbox"}
                  checked={applicationPartForReview.Pay_Slip}
                  onChange={(e) => handleCheckBoxMarkForReview(e, "Pay_Slip")}
                />
                Tick if, Latest Pay Slip is not uploaded
              </td>
            </tr>
            <tr>
              <td>
                <hr />
              </td>
            </tr>
            <tr>
              <td>
                Review Remarks
                <br />
                <textarea
                  rows="4"
                  cols="60"
                  value={reviewRemarks}
                  onChange={(e) => {
                    setReviewRemarks(e.target.value);
                  }}
                  placeholder="enter any remarks"
                ></textarea>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <div className="FormActionDiv">
          <button onClick={handleSubmitMarkLoanApplicationForReview}>
            Submit for Review ✍️
          </button>
        </div>
      </div>
    </>
  );
};

export default LoanApplicationsMarkForReview;
