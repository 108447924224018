import React, { useEffect, useState } from "react";
import "./MembershipApprovals.css";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import LinearProgress from "@mui/material/LinearProgress";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import useMyAuthService from "../../../../authenticationServiceProvider/useMyAuthService";
import DialogContainer from "../../../generalComponents/DialogComponents/DialogContainer/DialogContainer";
import ProgressorBarDisplayer from "../../../generalComponents/DialogComponents/ProgressorBarDisplayer/ProgressorBarDisplayer";

import { formatDate } from "../../../../ComonFunctionsProvider/CommonFunctionsProvider";
import MembershipApprovalsTable from "../../../generalComponents/TableDataDisplayer/MembershipApprovalsTable/MembershipApprovalsTable";
import MembershipApprovalsMenu from "./MembershipApprovalsMenu/MembershipApprovalsMenu";


function MembershipApprovals() {
  
   const {loggedInUserRUID, api} = useMyAuthService();
  
   const [memberNomineeDetailsData, setMemberNomineeDetailsData] = useState({});
  const [memberOtherSocietyDetailsData, setMemberOtherSocietyDetailsData] = useState({});
  const [membersDetails, setMembersDetails] = useState([]);
  const [memberApplicationDetails, setMemberApplicationDetails] = useState([]);

  const [membershipPaymentDetails, setMembershipPaymentDetails] = useState([]);

  const [selectedMember, setSelectedMember] = useState({});

  const [adminRBCTCSDetails, setAdminRBCTCSDetails] = useState({}); 
  const [staffRBCTCSDetails, setStaffRBCTCSDetails] = useState({});
  const [adminName, setAdminName] = useState();

  const [expanded, setExpanded] = React.useState(false);

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);
  const [updatedForReRender, setUpdatedForReRender] = useState(false);

  const [applicationStatus, setApplicationStatus] = React.useState("MASPP");
  const [selectedApplicationId, setSelectedApplicationId] = useState(1);
  //const markForReviewMsg = "Select For Correction";

  useEffect(() => {
    getApplicationStatus();
  }, [applicationStatus, updatedForReRender]);

  const getApplicationStatus = () => {
    api
      .get(
        `/ViewMembershipApplicationsForVerification/${applicationStatus}`
      )
      .then((response) => {
        setMemberApplicationDetails(response.data);
        // setMemberApplicationDetails(response.data);
        // console.log(applicationStatus);
        setIsLoadingData(false);
      });
  };

  const getMemberDetails = () => {
    api
      .get(
        `/ViewMembershipApplicationsForVerification`
      )
      .then((response) => {
        setMembersDetails(response.data);
      });
  };

  //Other Society details/..................................
  let societyTableRow = null;
  let societiesDataArray = Object.values(memberOtherSocietyDetailsData);
  //console.log(societiesDataArray);
  if (societiesDataArray !== undefined) {
    societyTableRow = societiesDataArray.map((society, index) => {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{society.societyName} </td>
          <td>{society.societyEmailId} </td>
          <td>{society.societyMobileNo} </td>
          <td>{society.societyAddress} </td>
        </tr>
      );
    });
  }

  //////////Nominee details..................................
  let nomineetablerow = null;
  let nomineeDataArray = Object.values(memberNomineeDetailsData);

  if (nomineeDataArray !== undefined) {
    nomineetablerow = nomineeDataArray.map((nominee, index) => {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{nominee.name} </td>
          <td>{nominee.relationWithMember} </td>
          <td>{formatDate(nominee.dateOfBirth)} </td>
          <td>{nominee.amountPayable} % </td>
          <td>{nominee.mobileNo} </td>
          <td>{nominee.email} </td>
          <td>{nominee.homeAddress} </td>
          <td>{nominee.nomineeHeirName} </td>
          <td>{formatDate(nominee.nomineeHeirDOB)} </td>
          <td>{nominee.nomineeHeirRelationWithMember} </td>
        </tr>
      );
    });
  }
  //console.log(nomineetablerow);

  //action menu for the verification............................of the applications................start.....
  const [anchorActionsMenu, setAnchorActionsMenu] = React.useState(null);
  const openMenu = Boolean(anchorActionsMenu);
  const handleClickOpenMenu = (event, memberApp) => {
    // console.log(member);
    setSelectedMember(memberApp.rbmembers);
    setSelectedApplicationId(memberApp.applicationId);
    setMemberNomineeDetailsData(memberApp.rbmembers.nominees);
    setMemberOtherSocietyDetailsData(memberApp.rbmembers.other_societies);
    //console.log(selectedMember);
    setAnchorActionsMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorActionsMenu(null);
  };
  //action menu for the verification of the applications............................................end

  //function for open and close of the Dialog Box....................................starts
  const [openMemberDetailsInApplication, setOpenMemberDetailsInApplication] =
    React.useState(false);
  const [
    openMemberPaymentDetailsForMembership,
    setOpenMemberPaymentDetailsForMembership,
  ] = React.useState(false);
  const [
    openOtherSocietyDetailsInApplication,
    setOpenOtherSocietyDetailsInApplication,
  ] = React.useState(false);
  const [openNomineeDetailsInApplication, setOpenNomineeDetailsInApplication] =
    React.useState(false);
  const [openAdminDisplayDialog, setOpenAdminDisplayDialog] =
    React.useState(false);
    const [openStaffDisplayDialog, setOpenStaffDisplayDialog] =
    React.useState(false);

  const [openVerifyApplication, setOpenApplicationApproval] =
    React.useState(false);

  const handleClickOpenMemberDetailsInApplication = (rbMemberRUID) => () => {
    //console.log(rbMemberRUID);
    setAnchorActionsMenu(null);
    setOpenMemberDetailsInApplication(true);
  };

  const handleClickOpenMemberPaymentDetailsForMembership = (rbMemberRUID) => {

    api.get();
    api({
      url: `/GetPaymentRequestForNewMembership/${rbMemberRUID}`,
      method: "GET",
    }).then((response) => {
      //console.log(response.data);
      setMembershipPaymentDetails(response.data);
      setAnchorActionsMenu(null);
      setOpenMemberPaymentDetailsForMembership(true);
    });
    
  };

  const handleClickOpenNomineeDetailsInApplication = () => {
    setAnchorActionsMenu(null);
    setOpenNomineeDetailsInApplication(true);
  };
  const handleClickOpenOtherSocietyDetailsInApplication = () => {
    setAnchorActionsMenu(null);
    setOpenOtherSocietyDetailsInApplication(true);
  };

  const handleClickOpenMembershipApprovalDialog = () => {
    setAnchorActionsMenu(null);
    setOpenApplicationApproval(true);
  };

  const handleCloseMemberDetailsInApplication = () => {
    setOpenMemberDetailsInApplication(false);
  };

  const handleCloseMemberPaymentDetailsForMembership = () => {
    setOpenMemberPaymentDetailsForMembership(false);
  };

  const handleCloseNomineeDetailsInApplication = () => {
    setOpenNomineeDetailsInApplication(false);
  };

  const handleCloseOtherSocietyDetailsInApplication = () => {
    setOpenOtherSocietyDetailsInApplication(false);
  };

  const handleCloseApplicationApproval = () => {
    setOpenApplicationApproval(false);
  };

  const handleCloseAdminDisplayDialog = () => {
    setOpenAdminDisplayDialog(false);
  };

  const handleCloseStaffDisplayDialog = () =>{
    setOpenStaffDisplayDialog(false);
  }

  //function for open and close of the Dialog Box....................................ends

  const handleSubmitMembershipApplicationApproval = async () => {
    //console.log(memberApp);
    var today = new Date(),
      approvalDate =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();

    const data = {
      memberApplicationID: selectedApplicationId,
      adminId: loggedInUserRUID,
      approvalStatus: "MAA",
      dateOfApproval: approvalDate,
      selectedMemberRUID: selectedMember.rbMemberRUID,
    };
    //console.log(data);
    let config = {
      method: "PUT",
      url: `/MembershipApplicationApprovalOk`,
      data: { data },
      headers: {
        Accept: "application/json",

        //  Authorization: varToken
      },
      responseType: "json",
    };
    //console.log(config);
    setOpenProgressBarDialog(true);
    const response = await api(config);
    setUpdatedForReRender(!updatedForReRender);
    setOpenApplicationApproval(false);
    setOpenProgressBarDialog(false);
    // console.log(response);
  };

  //fucntions for the accordian..................
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const displayAdminDetails = (e, adminId) => {
    api
      .get(`/getAdminRBCTCSDetailsByAdminId/${adminId}`)
      .then((response) => {
        setAdminRBCTCSDetails(response.data);
        setAdminName(response.data.users.name)
        setOpenAdminDisplayDialog(true);
      });
  };

  const displayStaffDetails = (e, staffId) => {
    api
      .get(`/ViewRBCTCSStaffDetails/${staffId}`)
      .then((response) => {
        //console.log(response.data);
        setStaffRBCTCSDetails(response.data[0]);
        setOpenStaffDisplayDialog(true);
      });
  };

  const handleChangeApplicationsForVerificationStatus = (status) =>{
    // console.log(status);
     setApplicationStatus(status);
}




  return (
    // Div to show the applications table for the incoming verification request of the members................
    <div className="VerifyMembershipApplicationsMainDiv">
      <fieldset>
        <legend>📜 Membership Applications For Approval</legend>
        {/* {membersDetails.map((member) => {
          return JSON.stringify(member);
        })} */}
        <div className="FilterApplicationsDiv">
          <select
            onChange={(e) =>
              handleChangeApplicationsForVerificationStatus(e.target.value)
            }
          >
            <option value="MASWP">
              Membership Application Submitted With Payment
            </option>
            <option value="MASPP">
              Membership Application Submitted & Payment Pending
            </option>
            <option value="MAMR">
              Membership Application Marked For Review
            </option>
            <option value="MARSP">
              Membership Application Re-submitted with Payment
            </option>
            <option value="MAPDV">
              Membership Application and Payment Details Verified{" "}
            </option>
            <option value="MAA">Member Application Approved</option>
          </select>
        </div>
        {isLoadingData ? (
          <div className="isLoadingDiv">Loading Data .....</div>
        ) : (
          <>
            <MembershipApprovalsTable
              memberApplicationDetails={memberApplicationDetails}
              displayStaffDetails={displayStaffDetails}
              displayAdminDetails={displayAdminDetails}
              handleClickOpenMenu={handleClickOpenMenu}
              openMenu={openMenu}
            />

            <MembershipApprovalsMenu 
              handleClickOpenMemberDetailsInApplication ={handleClickOpenMemberDetailsInApplication}
              handleClickOpenMemberPaymentDetailsForMembership ={handleClickOpenMemberPaymentDetailsForMembership}
              handleClickOpenMembershipApprovalDialog = {handleClickOpenMembershipApprovalDialog}
              selectedMember = {selectedMember}
              handleClickOpenNomineeDetailsInApplication = {handleClickOpenNomineeDetailsInApplication}
              handleClickOpenOtherSocietyDetailsInApplication = {handleClickOpenOtherSocietyDetailsInApplication}
              anchorActionsMenu ={anchorActionsMenu}
              openMenu ={openMenu}
              handleCloseMenu = {handleCloseMenu}
              applicationStatus = {applicationStatus}
            />
          </>

          
        )}
      </fieldset>

      {/* Dialog to display Staff Details ................ */}
      <DialogContainer
        titleMessage={"RBCTCS Staff Details"}
        openDialogBox={openStaffDisplayDialog}
        maxWidth="sm"
        handleCloseDialog={handleCloseStaffDisplayDialog}
      >
        <div className="adminDisplayDiv">
          <table>
            <tbody>
              <tr>
                <td>Staff ID:</td>
                <td>{staffRBCTCSDetails.staffRBCTCSId}</td>
              </tr>
              <tr>
                <td>Shri/Smt./Ms</td>
                <td>{staffRBCTCSDetails.name}</td>
              </tr>
              <tr>
                <td>Designation</td>
                <td>{staffRBCTCSDetails.designation}/RBCTCS</td>
              </tr>
            </tbody>
          </table>
        </div>
      </DialogContainer>

      {/* Dialog to display Admin Details ................ */}
      <DialogContainer
        titleMessage={"RBCTCS Admin Details"}
        openDialogBox={openAdminDisplayDialog}
        maxWidth="sm"
        handleCloseDialog={handleCloseAdminDisplayDialog}
      >
        <div className="adminDisplayDiv">
          <table>
            <tbody>
              <tr>
                <td>RUID:</td>
                <td>{adminRBCTCSDetails.adminRBCTCSRUID}</td>
              </tr>
              <tr>
                <td>Shri/Smt./Ms</td>
                <td>{adminName}</td>
              </tr>
              <tr>
                <td>Designation</td>
                <td>{adminRBCTCSDetails.designationRBCTCS}/RBCTCS</td>
              </tr>
            </tbody>
          </table>
        </div>
      </DialogContainer>

      {/* Dialog to implement the Progess bar ................ */}
      <ProgressorBarDisplayer
        titleMessage={"Please wait! Approving the Membership Applications ...."}
        openProgressBarDialog={openProgressBarDialog}
      />

      {/* Dialog to implement the Approval logic for the submitted application ................ */}
      <DialogContainer
        titleMessage={"Membership Verification OK Submit"}
        openDialogBox={openVerifyApplication}
        maxWidth="sm"
        handleCloseDialog={handleCloseApplicationApproval}
      >
        <div className="finalVerificationSubmitDialogDiv">
          <p>
            If the details of the application are succesfully verified, please
            click on Approve OK button.
          </p>
          <div className="FormActionDiv">
            <button onClick={handleSubmitMembershipApplicationApproval}>
              👌 Approve OK
            </button>
          </div>
        </div>
      </DialogContainer>

      {/* Dialog to show the Nominee Details.............................. */}
      <DialogContainer
        titleMessage={"Member Nominee Details"}
        openDialogBox={openNomineeDetailsInApplication}
        maxWidth="md"
        handleCloseDialog={handleCloseNomineeDetailsInApplication}
      >
        <div className="nomineeDetailsTableDiv">
          <table className="nomineeDetailsTable" border="1">
            <thead>
              <tr>
                <td>Sl.</td>
                <td>Nominee Name</td>
                <td>Relation with Member</td>
                <td>Nominee DoB</td>
                <td>Amount Payable</td>
                <td>Mobile</td>
                <td>Email</td>
                <td>Home Address</td>
                <td>Nominee Heir Name</td>
                <td>Nominee Heir DoB</td>
                <td>Nominee Heir Relation</td>
              </tr>
            </thead>
            <tbody>{nomineetablerow}</tbody>
          </table>
        </div>
      </DialogContainer>

      {/* Dialog to view the other society details of the members............................ */}
      <DialogContainer
        titleMessage={"Membership Application Other Society Details"}
        openDialogBox={openOtherSocietyDetailsInApplication}
        maxWidth="md"
        handleCloseDialog={handleCloseOtherSocietyDetailsInApplication}
      >
        <div className="societyDetailsTableDiv">
          <table className="societyDetailsTable" border="1">
            <thead>
              <tr>
                <td>Sl.</td>
                <td>Society Name</td>
                <td>Email Id</td>
                <td>Mobile No.</td>
                <td>Address</td>
              </tr>
            </thead>
            <tbody>{societyTableRow}</tbody>
          </table>
        </div>
      </DialogContainer>

      {/* Dialog to see the payment details */}
      <DialogContainer
        titleMessage={"Payment Details of Membership Application"}
        openDialogBox={openMemberPaymentDetailsForMembership}
        maxWidth="md"
        handleCloseDialog={handleCloseMemberPaymentDetailsForMembership}
      >
        <div className="finalVerificationSubmitDialogDiv">
          {membershipPaymentDetails.length !== 0 ? (
            <table className="membershipApplicationStatusTable">
              <tbody>
                <tr>
                  <td>Payment From</td>
                  <td>{membershipPaymentDetails.rbMemberRUID}</td>
                </tr>
                <tr>
                  <td>Payment Request Date</td>
                  <td>{formatDate(membershipPaymentDetails.requestDate)}</td>
                </tr>
                <tr>
                  <td>Payment Status</td>
                  <td>{membershipPaymentDetails.paymentStatus}</td>
                </tr>
                <tr>
                  <td>Payment Mode</td>
                  <td>{membershipPaymentDetails.paymentMode}</td>
                </tr>
                <tr>
                  <td>Cheque Amount</td>
                  <td>{membershipPaymentDetails.paymentAmount}</td>
                </tr>
                <tr>
                  <td>Cheque No./Transaction Reference No.</td>
                  <td>{membershipPaymentDetails.tranRefNoOrChequeNo}</td>
                </tr>

                <tr>
                  <td>Payment Verifier</td>
                  <td>{membershipPaymentDetails.staffId}</td>
                </tr>
                <tr>
                  <td>Payment Verification Date</td>
                  <td>
                    {formatDate(
                      membershipPaymentDetails.paymentVerificationDate
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Ledger No. Alloted</td>
                  <td>
                    {
                      membershipPaymentDetails.membership_applications.rbmembers
                        .ledgerNo
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            ""
          )}
        </div>
      </DialogContainer>

      {/* Dialog to view the details of the member............................. */}
      <DialogContainer
        titleMessage={"Membership Application Verification"}
        openDialogBox={openMemberDetailsInApplication}
        maxWidth="md"
        handleCloseDialog={handleCloseMemberDetailsInApplication}
      >
        <fieldset>
          <legend>🪟 Membership Application Preview</legend>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <span> Member Personal Details </span>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                {/* Personal Details here */}

                <table className="membershipApplicationStatusTable">
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>Shri {selectedMember.name}</td>
                    </tr>
                    <tr>
                      <td>Father's Name</td>
                      <td>Shri {selectedMember.fatherName}</td>
                    </tr>
                    <tr>
                      <td>Date of Birth</td>
                      <td>{selectedMember.dateOfBirth}</td>
                    </tr>
                    <tr>
                      <td>Pan No.</td>
                      <td>{selectedMember.panNo}</td>
                    </tr>
                    <tr>
                      <td>Residential Address</td>
                      <td>{selectedMember.homeAddress}</td>
                    </tr>
                    <tr>
                      <td>Permanent Address</td>
                      <td>{selectedMember.permanentAddress}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="detailseditbuttondiv"></div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <span>Member Official Details</span>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                {/* {official details here)} */}
                <table className="membershipApplicationStatusTable">
                  <tbody>
                    <tr>
                      <td>RUID</td>
                      <td>{selectedMember.rbMemberRUID}</td>
                    </tr>
                    <tr>
                      <td>Employee Id</td>
                      <td>{selectedMember.employeeId}</td>
                    </tr>
                    <tr>
                      <td>Designation</td>
                      <td>{selectedMember.designation}</td>
                    </tr>
                    <tr>
                      <td>Date of Appointment</td>
                      <td>{selectedMember.dateOfAppointment}</td>
                    </tr>
                    <tr>
                      <td>Date of Retirement</td>
                      <td>{selectedMember.dateOfRetirement}</td>
                    </tr>
                    <tr>
                      <td>Branch</td>
                      <td>{selectedMember.branch}</td>
                    </tr>
                    <tr>
                      <td>Grade Pay</td>
                      <td>{selectedMember.gradePay}</td>
                    </tr>
                    <tr>
                      <td>Pay Level</td>
                      <td>{selectedMember.payLevel}</td>
                    </tr>
                    <tr>
                      <td>Basic Pay</td>
                      <td>{selectedMember.basicPay}</td>
                    </tr>
                    <tr>
                      <td>Railway Tel. No.</td>
                      <td>{selectedMember.railwayTelNo}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="detailseditbuttondiv">
                {/* {personalForm.map} */}
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <span>Member Bank Details</span>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                {/* {Bank details here)} */}
                <table className="membershipApplicationStatusTable">
                  <tbody>
                    <tr>
                      <td>Bank Name</td>
                      <td>{selectedMember.salaryBankName}</td>
                    </tr>
                    <tr>
                      <td>IFSC Code</td>
                      <td>{selectedMember.salaryBankIFSC}</td>
                    </tr>
                    <tr>
                      <td>Account No.</td>
                      <td>{selectedMember.salaryBankAccountNo}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="detailseditbuttondiv">
                {/* {personalForm.map} */}
              </div>
            </AccordionDetails>
          </Accordion>
        </fieldset>
      </DialogContainer>
    </div>
  );
}

export default MembershipApprovals;
