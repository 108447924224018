import React from "react";
import "./SettingForCompulsoryDeposit.css";

import { useState } from "react";
import axios from "axios";
import { useGetAPIServer } from "../../../../BackEndServerServiceProvider/BackEndServerServiceProvider";

let createSettingCDForm = {
  classMembersStartGP: " ",
  classMembersEndGP: " ",
  CompulsoryDeposit: " ",
 };

function SettingForCompulsoryDeposit() {

  const apiServer = useGetAPIServer();
   /** The data elements of the form to be stored in these variables */
   const [createSettingCDFormData, setCreateSettingCDFormData] = useState(
    createSettingCDForm
  );
  const [errorMessage, setErrorMessage] = useState();
  const [message, setMessage] = useState();

  async function handleSubmit(e) {
    e.preventDefault();
    //console.log("form has been submitted");
    // console.log(createStaffFormData);

    //  if(createStaffFormData.staffSign ==="" && createStaffFormData.staffPhoto ==="" ){
    //     setErrorMessage("Please Upload the Photo and Signatures for proceeding further" );
    //  }else{

    const data = createSettingCDFormData;
    //console.log(data);
    let config = {
      method: "POST",
      url: `${apiServer.serverBaseRoute}/CreateSettingForCompulsoryDeposit`,
      data: { data },
      headers: {
        Accept: "application/json",

        //  Authorization: varToken
      },
      responseType: "json",
    };

    const response = await axios(config);
    //console.log(response.data);
    setMessage(response.data);

    // }
  }



  return (
    <div className="SettingForCompulsoryDeposit">
      <fieldset>
        <legend>Set Compulsory Deposit Limits</legend>
        <div className="errorDiv">{errorMessage}</div>
        <div className="msgDiv">{message}</div>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="gridRow">
            <div className="gridCol">
              <div className="FormFieldDiv">
                <div>
                  <label>Class Start GP</label>
                </div>
                <div>
                  <input
                    type={"text"}
                    name="classMembersStartGP"
                    placeholder="Enter the Start GP "
                    value={createSettingCDFormData.classMembersStartGP}
                    onChange={(e) =>
                      setCreateSettingCDFormData({
                        ...createSettingCDFormData,
                        classMembersStartGP: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>

              <div className="FormFieldDiv">
                <div>
                  <label>Class End GP</label>
                </div>
                <div>
                  <input
                    type={"text"}
                    name="classMembersEndGP"
                    placeholder="Enter the End GP "
                    value={createSettingCDFormData.classMembersEndGP}
                    onChange={(e) =>
                      setCreateSettingCDFormData({
                        ...createSettingCDFormData,
                        classMembersEndGP: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>

              <div className="FormFieldDiv">
                <div>
                  <label>Compulsory Deposit</label>
                </div>
                <div>
                  <input
                    type={"number"}
                    name="compulsoryDeposit"
                    placeholder="Enter Loan Limit"
                    value={createSettingCDFormData.CompulsoryDeposit}
                    onChange={(e) =>
                      setCreateSettingCDFormData({
                        ...createSettingCDFormData,
                        CompulsoryDeposit: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="FormActionDiv">
            <input type={"button"} value="Clear Form" />
            <input type={"submit"} value="Save Settings" />
          </div>
        </form>
      </fieldset>
    </div>
  );
}

export default SettingForCompulsoryDeposit;
