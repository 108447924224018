import React from 'react'

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Tooltip } from "@mui/material";



const MembershipApplicationPreview = ({selectedMember}) => {

    const [expanded, setExpanded] = React.useState(false);

    //fucntions for the accordian..................
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <div>
        <fieldset>
            <legend>🪟 Membership Application Preview</legend>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span> Member Personal Details </span>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  {/* Personal Details here */}

                  <table className="membershipApplicationStatusTable">
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td>Shri {selectedMember.name}</td>
                      </tr>
                      <tr>
                        <td>Father's Name</td>
                        <td>Shri {selectedMember.fatherName}</td>
                      </tr>
                      <tr>
                        <td>Date of Birth</td>
                        <td>{selectedMember.dateOfBirth}</td>
                      </tr>
                      <tr>
                        <td>Pan No.</td>
                        <td>{selectedMember.panNo}</td>
                      </tr>
                      <tr>
                        <td>Residential Address</td>
                        <td>{selectedMember.homeAddress}</td>
                      </tr>
                      <tr>
                        <td>Permanent Address</td>
                        <td>{selectedMember.permanentAddress}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="detailseditbuttondiv"></div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span>Member Official Details</span>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  {/* {official details here)} */}
                  <table className="membershipApplicationStatusTable">
                    <tbody>
                      <tr>
                        <td>RUID</td>
                        <td>{selectedMember.rbMemberRUID}</td>
                      </tr>
                      <tr>
                        <td>Employee Id</td>
                        <td>{selectedMember.employeeId}</td>
                      </tr>
                      <tr>
                        <td>Designation</td>
                        <td>{selectedMember.designation}</td>
                      </tr>
                      <tr>
                        <td>Date of Appointment</td>
                        <td>{selectedMember.dateOfAppointment}</td>
                      </tr>
                      <tr>
                        <td>Date of Retirement</td>
                        <td>{selectedMember.dateOfRetirement}</td>
                      </tr>
                      <tr>
                        <td>Branch</td>
                        <td>{selectedMember.branch}</td>
                      </tr>
                      <tr>
                        <td>Grade Pay</td>
                        <td>{selectedMember.gradePay}</td>
                      </tr>
                      <tr>
                        <td>Pay Level</td>
                        <td>{selectedMember.payLevel}</td>
                      </tr>
                      <tr>
                        <td>Basic Pay</td>
                        <td>{selectedMember.basicPay}</td>
                      </tr>
                      <tr>
                        <td>Railway Tel. No.</td>
                        <td>{selectedMember.railwayTelNo}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="detailseditbuttondiv">
                  {/* {personalForm.map} */}
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span>Member Bank Details</span>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  {/* {Bank details here)} */}
                  <table className="membershipApplicationStatusTable">
                    <tbody>
                      <tr>
                        <td>Bank Name</td>
                        <td>{selectedMember.salaryBankName}</td>
                      </tr>
                      <tr>
                        <td>IFSC Code</td>
                        <td>{selectedMember.salaryBankIFSC}</td>
                      </tr>
                      <tr>
                        <td>Account No.</td>
                        <td>{selectedMember.salaryBankAccountNo}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="detailseditbuttondiv">
                  {/* {personalForm.map} */}
                </div>
              </AccordionDetails>
            </Accordion>
          </fieldset>
    </div>
  )
}

export default MembershipApplicationPreview