import "./MemberPersonalDetailsViewer.css";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import profileImage from "../../../../images/profile2.jpg";
import signImage from "../../../../images/sign.jpg";

import  useMyAuthService  from "../../../../authenticationServiceProvider/useMyAuthService";

const personalFormFields = {
  name: " ",
  fatherName: " ",
  spouseName:" ",
  gender:" ",
  dateOfBirth: " ",
  email: " ",
  mobile: " ",
  panNo: " ",
  residentialAddress: " ",
  permanentAddress: " ",
 
};


const PersonalDetailsViewer = () => {
  
  const navigate = useNavigate();
 const {loggedInUserRUID, api} = useMyAuthService();

  const [currentLoggedInRUID, setCurrentLoggedInRUID] = useState(loggedInUserRUID);
  const [personalForm, setFormFieldValues] = useState(personalFormFields);
  
  const [memberPhoto, setMemberPhoto] = useState(profileImage);
  const [memberSign, setMemberSign] = useState(signImage);

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isLoadingPhoto, setIsLoadingPhoto] = useState(true);
  const [isLoadingSign, setIsLoadingSign] = useState(true);

  useEffect(() => {
    getRBMemberPersonalDetails();
    getRBMemberPhotoDetails();
    getRBMemberSignatureDetails();
       
  }, []);

  const getRBMemberPersonalDetails = () => {
    api
      .get(
        `/getRBMemberPersonalDetails/${currentLoggedInRUID}`
      )
      .then((response) => {
        // console.log(response.data);
         setFormFieldValues({
          ...personalForm,
          name: response.data.name,
          fatherName: response.data.fatherName,
          mobile: response.data.users.mobile,
          email: response.data.users.email,
          residentialAddress: response.data.homeAddress,
          permanentAddress: response.data.permanentAddress,
          panNo: response.data.panNo,
          dateOfBirth: response.data.dateOfBirth,
          spouseName:response.data.spouseName,
          gender:response.data.gender,
        });
        //console.log(regData);
        setIsLoadingData(false);
      });
  };


  const getRBMemberPhotoDetails = () => {

    api({
      url: `/GetMemberPhoto/${currentLoggedInRUID}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
       const url = window.URL.createObjectURL(new Blob([response.data]));
       //console.log(url);
       setMemberPhoto(url);
       setIsLoadingPhoto(false);
    });
  
  };

  const getRBMemberSignatureDetails = () => {

    api({
      url: `/GetMemberSignature/${currentLoggedInRUID}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
       const url = window.URL.createObjectURL(new Blob([response.data]));
       setMemberSign(url);
       setIsLoadingSign(false); 
    });
  
  };

  const handleOpenPersonalDetailsUpdaterComponent = () => {
    navigate("../UpdatePersonalDetails");
  };

  const handleOpenImageUploaderComponent = () => {
    navigate("../UploadPhotoSign");
    // { state: response.data }
  };

  return (
    <div className="PersonalDetailsViewerMainDiv">
      <fieldset>
        <legend>🔍 View Your personal Details here</legend>
        {isLoadingData || isLoadingPhoto || isLoadingSign? (
          <div className="isLoadingDiv">Loading Data ...... </div>
        ) : (
          <>
            <div className="gridRow">
              <div className="gridCol">
                <table>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{personalForm.name}</td>
                    </tr>
                    <tr>
                      <td>Father Name</td>
                      <td>{personalForm.fatherName}</td>
                    </tr>
                    <tr>
                      <td>Spouse Name</td>
                      <td>{personalForm.spouseName}</td>
                    </tr>
                    <tr>
                      <td>Date of Birth</td>
                      <td>{personalForm.dateOfBirth}</td>
                    </tr>
                    <tr>
                      <td>Gender</td>
                      <td>{personalForm.gender}</td>
                    </tr>
                    <tr>
                      <td>Mobile No.</td>
                      <td>{personalForm.mobile}</td>
                    </tr>
                    <tr>
                      <td>PAN No.</td>
                      <td>{personalForm.panNo}</td>
                    </tr>
                    <tr>
                      <td>Email Id</td>
                      <td>{personalForm.email}</td>
                    </tr>
                    <tr>
                      <td>Mobile No.</td>
                      <td>{personalForm.mobile}</td>
                    </tr>
                    <tr>
                      <td>Residential Address</td>
                      <td>{personalForm.residentialAddress}</td>
                    </tr>
                    <tr>
                      <td>Permanent Address</td>
                      <td>{personalForm.permanentAddress}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="gridCol">
                <table>
                  <tbody>
                    <tr>
                      <td>Member Photo</td>
                      <td>Member Signature</td>
                    </tr>
                    <tr>
                      <td>
                        <img src={memberPhoto}></img>
                      </td>
                      <td>
                        <img src={memberSign}></img>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr></hr>
            <div className="gridRow">
              <div className="FormActionDiv gridCol">
                <input
                  type={"button"}
                  onClick={() => handleOpenPersonalDetailsUpdaterComponent()}
                  value="🖆 Update Personal Details"
                />
                <div className="FormActionDiv gridCol">
                  <input
                    type={"button"}
                    onClick={() => handleOpenImageUploaderComponent()}
                    value="🖆 Update Photo & Sign"
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </fieldset>
    </div>
  );
};

export default PersonalDetailsViewer;
