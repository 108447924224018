import "./ManageLoanDisbursalReports.css"
import React, { useState, useEffect } from 'react'
import LoanDisbursalReportsTable from '../TableDataDisplayer/LoanDisbursalReportsTable/LoanDisbursalReportsTable'
import MenuLoanDisbursalReports from './MenuLoanDisbursalReports/MenuLoanDisbursalReports';
import useMyAuthService from '../../../authenticationServiceProvider/useMyAuthService';
import DialogContainer from '../DialogComponents/DialogContainer/DialogContainer';

const ManageLoanDisbursalReports = () => {

    const {loggedInUserRUID, api} = useMyAuthService();

    const [isLoadingData, setIsLoadingData] = useState(true);
    const [loanDisbursalReports, setLoanDisbursalReports] = useState([]);

    const [selectedLDR, setSelectedLDR] = useState({});
    const [staffRBCTCSDetails, setStaffRBCTCSDetails] = useState({});

    const [openStaffDisplayDialog, setOpenStaffDisplayDialog] =
    React.useState(false);

    //  Functions for the MENU open and closure...................
  //action menu for the verification............................of the applications................start.....
  const [anchorActionsMenu, setAnchorActionsMenu] = React.useState(null);
  const openMenu = Boolean(anchorActionsMenu);

  const handleClickOpenMenu = (event, LDR) => {
    //console.log(openMenu);
   //  console.log(LoanApp);
    setSelectedLDR(LDR);
   //setLoanSuretyDetailsData(LoanApp.loan_sureties_by_loan_id);
    setAnchorActionsMenu(event.currentTarget);
  };

  // Function for the Menu close
  const handleCloseMenu = () => {
    setAnchorActionsMenu(null);
  };  
  
  //function for open and close of the Dialog Box....................................starts

  
    useEffect(() => {
        getLoanDisbursalReports();
      }, []);
    
      const getLoanDisbursalReports = () => {
        api.get(`/GetLoanDisbursalReports/`).then((response) => {
          //console.log(response.data);
    
          setLoanDisbursalReports(response.data);
         
          setIsLoadingData(false);
        });
      };

      const displayStaffDetails = (e, staffId) => {
        api
          .get(`/ViewRBCTCSStaffDetails/${staffId}`)
          .then((response) => {
            //console.log(response.data);
            setStaffRBCTCSDetails(response.data[0]);
            setOpenStaffDisplayDialog(true);
          });
      };
    
      const handleCloseStaffDisplayDialog = () => {
        setOpenStaffDisplayDialog(false);
      };
    
    
    

  return (
    <>
        <div>
            <fieldset>
                <legend>Loan Disbursal Reports</legend>
                {!isLoadingData?(
                <>
                
                    <LoanDisbursalReportsTable 
                        loanDisbursalReports = {loanDisbursalReports}
                        displayStaffDetails = {displayStaffDetails}
                        handleClickOpenMenu = {handleClickOpenMenu}
                        openMenu = {openMenu}
                    />
                    <MenuLoanDisbursalReports 
                        anchorActionsMenu = {anchorActionsMenu}
                        openMenu = {openMenu}
                        handleCloseMenu = {handleCloseMenu}
                    />
                    
                </>):("Loading Data... Please wait....")}
                
            </fieldset>
        </div>

        {/* Dialog to display Staff Details ................ */}
      <DialogContainer
        titleMessage={"Verifying Staff"}
        openDialogBox={openStaffDisplayDialog}
        maxWidth="sm"
        handleCloseDialog={handleCloseStaffDisplayDialog}
      >
        <div className="adminDisplayDiv">
          <table>
            <tbody>
              <tr>
                <td>Staff ID:</td>
                <td>{staffRBCTCSDetails.staffRBCTCSId}</td>
              </tr>
              <tr>
                <td>Shri/Smt./Ms</td>
                <td>{staffRBCTCSDetails.name}</td>
              </tr>
              <tr>
                <td>Designation</td>
                <td>{staffRBCTCSDetails.designation}/RBCTCS</td>
              </tr>
            </tbody>
          </table>
        </div>
      </DialogContainer>

    </>
    
  )
}

export default ManageLoanDisbursalReports