import "./DashBoardHeader.css";
import MenuIcon from "@mui/icons-material/Menu";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

import React, {useState} from "react";
import { Button, Divider, Stack } from "@mui/material";
import { red, yellow } from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import DialogContainer from "../DialogComponents/DialogContainer/DialogContainer"

import  useMyAuthService  from "../../../authenticationServiceProvider/useMyAuthService";
import {useTokenRevocation} from "../../../authenticationServiceProvider/useTokenRevocation";


const DashBoardHeader = () => {

  const navigate = useNavigate();
  const {loggedInUserName} = useMyAuthService();
  const handleTokenRevocation = useTokenRevocation();

  const[openDialogBox, setOpenDialogBox] = useState(false);

   // State to manage success/failure messages
   const [message, setMessage] = useState("");

     // State to control progress bar dialog visibility
  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

  //function to handle open Menu on Click
  const handleClick = () => {
    //alert("this is clicked");
  };

 
  //function to handle log out of the system
  const handleSystemLogOut = () => {

    setOpenProgressBarDialog(true);

    handleTokenRevocation().then((message) => {
       // Set the state to show the message after 2 seconds
      setTimeout(() => {
        setMessage(message);
      }, 1000000); // 2000 milliseconds = 2 seconds
      
      setOpenProgressBarDialog(false);

      navigate("/", { replace: true });
    })
    .catch((error) => {
      console.error("Token Revocation failed:", error);
      setOpenProgressBarDialog(false);
      setMessage("Failed");
    });

    
  };

  const openLogOutDialogBox = () =>{
    setOpenDialogBox(true);
  }

  const closeLogOutDialogBox = () =>{
       setOpenDialogBox(false);
  }

  return (
    <div className="dashBoardHeaderDiv">
      <Tooltip title="OPEN ACTION MENU" arrow>
        <Button onClick={() => handleClick()} variant="filled" color="primary">
          <MenuIcon fontSize="large" sx={{ color: yellow[500] }} />
        </Button>
      </Tooltip>

      <span className="rbctcsNameBanner">
        RAILWAY BOARD CO-OPERATIVE THRIFT & CREDIT SOCIETY LTD.
      </span>

      <span className="rbctcsWelcomeMessage">Welcome, {loggedInUserName}</span>
      <span className="logOutButton">
        <Stack direction="row" spacing={1}>
          <Tooltip title={loggedInUserName} arrow>
            <Avatar alt={loggedInUserName} src="/static/images/avatar/1.jpg" />
          </Tooltip>
          <Divider></Divider>
          <Tooltip title="LOG OUT FROM RBCTCS PORTAL" arrow>
            <Button
              onClick={() => openLogOutDialogBox()}
              variant="filled"
              tooltip="Log Out of the System"
              color="error"
            >
              <PowerSettingsNewIcon fontSize="large" sx={{ color: red[500] }} />
            </Button>
          </Tooltip>
        </Stack>
      </span>

      {/* Dialog to implement the Progess bar ................ */}
      <Dialog open={openProgressBarDialog} fullWidth maxWidth="sm">
        <DialogTitle id="scroll-dialog-title">
          <b>Please wait! Logging Out ....</b>
        </DialogTitle>
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      </Dialog>

      <DialogContainer
        titleMessage="LOG OUT from the RBCTCS Portal"
        openDialogBox={openDialogBox}
        maxWidth={"sm"}
        handleCloseDialog={closeLogOutDialogBox}
      >
        <div>
          <center>
            <h2>Do You want to Log Out ?</h2>
            <Button value={""}  variant="contained" onClick={() => handleSystemLogOut()}>
              if yes, Click here to Log Out
            </Button>
          </center>
        </div>
      </DialogContainer>
    </div>
  );
};

export default DashBoardHeader;
