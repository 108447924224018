import React, { useEffect, useState } from 'react'
import "./PaymentsForLoan.css"
import useMyAuthService from '../../../../authenticationServiceProvider/useMyAuthService';


function PaymentsForLoan(props) {
    const { sharesForm, setsharesFormFieldValues, Banks, loanForm, setLoanFormValues } = props;
    const [isSharesPayRequired, setIsSharesPayRequired] = useState(true);

    const [currentLoggedInRUID, setCurrentLoggedInRUID] = useState(9925141);
    
    const {api} = useMyAuthService();
  
    useEffect(() => {
       getRBCTCSMembershipDetails();
    }, [])
    
    const getRBCTCSMembershipDetails = () => {
      api
        .get(
          `/getRBCTCSMembershipDetails/${currentLoggedInRUID}`
        )
        .then((response) => {
          //console.log(response.data);
         // setRBCTCSMembershipDetails(response.data);
          setShareAmountAndChequeAmount(response.data);
        });
    };

    const setShareAmountAndChequeAmount = (membershipData) =>{
      //getRBCTCSMembershipDetails();
      let shareAmount=0; let noOfShares = 0; let chequeAmount = 0;
       //console.log(membershipData);
       if (membershipData !== undefined || membershipData != null) {
         if (loanForm.loanAmount !== 0 || loanForm.loanAmount !== null || loanForm.loanAmount !== " ") 
         {
           // console.log(parseInt(loanForm.loanAmount));
           noOfShares = Math.ceil(parseInt(loanForm.loanAmount) / 3000) - parseInt(membershipData.noOfShares);
           (noOfShares <= 0)?noOfShares = 0:noOfShares=noOfShares;
           shareAmount = noOfShares * 100;
           chequeAmount = shareAmount;

           if(noOfShares === 0){
            setLoanFormValues({...loanForm, isSharesPayRequired:false});
            setIsSharesPayRequired(isSharesPayRequired => isSharesPayRequired=false);
           }else{
            setLoanFormValues({...loanForm, isSharesPayRequired:true});
            setIsSharesPayRequired(isSharesPayRequired => isSharesPayRequired=true);
           }

           setsharesFormFieldValues({
             ...sharesForm,
             noOfShares: noOfShares,
             shareAmount: shareAmount,
             chequeAmount: chequeAmount,
           });
         }
       }
      
  }
    
   

  return (
    <div className='PaymentsForLoanMainDiv'>
        <fieldset>
            <legend>💰 Payment Details</legend>
            <div className="gridCol">
          <div className="gridRow">
            <div className="FormFieldDiv">
              <div>
                <label>No of Shares</label>
              </div>
              <div>
                <input
                  type={"text"}
                  value={sharesForm.noOfShares}
                  placeholder="Shares to be purchased"
                 // onChange = {() => setShareAmountAndChequeAmount()} 
                 readOnly
                />
              </div>
            </div>

            <div className="FormFieldDiv">
              <div>
                <label>Share Amount</label>
              </div>
              <div>
                <input
                  type={"text"}
                  value={sharesForm.shareAmount}
                  placeholder="Shares Amount" readOnly/>
              </div>
            </div>

          </div>
          {
            (isSharesPayRequired)?
            (
                <>
                <div className="gridRow">
                          
                            <div className="FormFieldDiv">
                              <div>
                                <label>Cheque Amount </label>
                              </div>
                              <div>
                                <input type={"text"} value={sharesForm.chequeAmount} readOnly />
                              </div>
                            </div>
                            <div className="FormFieldDiv">
                              <div>
                                <label>Cheque Bearer </label>
                              </div>
                              <div>
                                <input type={"text"} value={sharesForm.chequeBearer} readOnly />
                              </div>
                            </div>
                            <div className="FormFieldDiv">
                              <div>
                                <label>Cheque Bearer Account No.</label>
                              </div>
                              <div>
                                <input  type={"text"} value={sharesForm.chequeBearerAccountNo} readOnly />
                              </div>
                            </div>
                            
                          </div>
                          <div className="gridRow">
                          <div className="FormFieldDiv">
                              <div>
                                <label>Cheque No.</label>
                              </div>
                              <div>
                                <input
                                  type={"text"}
                                  value={sharesForm.chequeNo}
                                  onChange={(e) =>
                                    setsharesFormFieldValues({
                                      ...sharesForm,
                                      chequeNo: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="FormFieldDiv">
                              <div>
                                <label>Cheque Bank </label>
                              </div>
                              <div>
                              <input list="bankNames2"  value={sharesForm.chequeBank}
                                  onChange={(e) =>
                                    setsharesFormFieldValues({
                                      ...sharesForm,
                                      chequeBank: e.target.value,
                                    })
                                  }
                                />
                                <datalist id="bankNames2">
                                  {Banks.map((bank, index) => {
                                    return (
                                      <option key={index} value={bank.Code}>
                                        {bank.FullForm}
                                      </option>
                                    );
                                  })}
                                </datalist>
                              </div>
                            </div>
                            <div className="FormFieldDiv">
                              <div>
                                <label>Cheque Date </label>
                              </div>
                              <div>
                                <input type={"date"}
                                  value={sharesForm.chequeDate}
                                  onChange={(e) =>
                                    setsharesFormFieldValues({
                                      ...sharesForm,
                                      chequeDate: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                </>
            ):
            (
              <>
                <div className='gridRow'>
                  <div className='sharesNotRequiredDiv'>
                    <p>Please fill the Loan Amount, if it is zero.</p>
                    <p>OR</p>
                    <p>
                      If the Loan Amount is not zero, <br/> then You are already having the maximum share subscription. <br/>Please proceed to add the Surety Details.
                    </p>
                  </div>
                </div>
              </>
            )
          }
          
          </div>
        </fieldset>
    </div>
  )
}

export default PaymentsForLoan