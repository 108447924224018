import "./MembershipFeesPaymentsTab.css";

import React, { useState, useEffect } from "react";

import TableDataDisplayer from "../../../TableDataDisplayer/MiscPaymentTable/MiscPaymentTable";

import SubmitPaymentRequestPosting from "../../../DialogComponents/SubmitPaymentRequestPosting/SubmitPaymentRequestPosting";

import OpenDocumentDisplayer from "../../../DialogComponents/OpenDocumentDisplayer/OpenDocumentDisplayer";

import useMyAuthService  from "../../../../../authenticationServiceProvider/useMyAuthService";

const MembershipFeesPaymentsTab = () => {

  const {api, loggedInUserRUID} = useMyAuthService();

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [selectedStatus, setSelectedStatus] = useState("Payment Submitted");
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [dataUpdateRender, setDataUpdateRender] = useState(false);

  const [selectedItem, setSelectedItem] = useState({});

  const [docFile, setDocFile] = useState();
  const [openChequeOnlineReceiptDisplayer, setOpenChequeOnlineReceiptDisplayer] = useState(false);


  const tableHeaders = [
    "RB Member RUID",
    "Payment Mode",
    "Transaction Ref No or Cheque No",
    "Request Type",
    "Request Date",
    "Payment Status",
  ];

  const displayColumns = [
    "rbMemberRUID",
    "paymentMode",
    "tranRefNoOrChequeNo",
    "requestType",
    "requestDate",
    "paymentStatus",
  ];

  useEffect(() => {
    fetchData();
  }, [selectedStatus, currentPage, itemsPerPage,dataUpdateRender]);

  const fetchData = async () => {
    try {
      setIsLoadingData(true);
      const response = await api.get(`/GetAllPaymentRequest`, {
        params: {
          requestType: "NMFP",
          requestStatus: selectedStatus,
        },
      });
      setData(response.data);
      setIsLoadingData(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoadingData(false);
    }
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleMenuOptionClick = (option) => {
    switch (option) {
      case 'view-payment-details':
        // Handle view payment details option
        //console.log('View payment details');
        handleOpenChequeOnlineReceiptForNewMembership(selectedItem.paymentRequestId);
        break;
      case 'post-payment-details':
        // Handle post payment details option
       // console.log(selectedItem);
        handleDialogOpen();
        break;
      default:
        break;
    }
  };

 //function to get the Check / Online Receipt of the Pyaments made...........
 //function to get the Check / Online Receipt of the Pyaments made...........
 const handleOpenChequeOnlineReceiptForNewMembership = (
   paymentRequestId
 ) => {
   try {
     api
       .get(`/GetChequeOnlineReceiptFile/${paymentRequestId}`, {
        //  params: {
        //   paymentRequestId: paymentRequestId,
        //   // requestType: "NMFP",
        //  },
         responseType: "blob",
       })
       .then((response) => {

         const file = new Blob([response.data], { type: "application/pdf" });
         const fileURL = URL.createObjectURL(file);
         setDocFile(fileURL);
         //console.log(fileURL);
         setOpenChequeOnlineReceiptDisplayer(true);
       });
   } catch (error) {
     // Handle error here
     console.error(error);
   }
 };


  

  return (
    <div className="membershipFeesPaymentTabMainDiv">
      <div className="payment-status-div">
        <label htmlFor="status-select">Payment Status:</label>
        <select
          id="status-select"
          value={selectedStatus}
          onChange={handleStatusChange}
        >
          <option value="Payment Submitted">Payment Submitted</option>
          <option value="Payment Re-Submitted">Payment Re-Submitted</option>
          <option value="Payment Accepted">Payment Accepted</option>
          <option value="Payment Rejected">Payment Rejected</option>
        </select>
      </div>

      {isLoadingData ? (
        <p>Loading Data...</p>
      ) : (
        <div>
          <TableDataDisplayer
            tableHeaders={tableHeaders}
            displayColumns={displayColumns}
            data={data}
            isLoading={isLoadingData}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            handleMenuOptionClick={handleMenuOptionClick}
            setSelectedItem={setSelectedItem}
            paymentStatus = {selectedStatus}
          />
        </div>
      )}

      {/* Dialog to display to the payment posting form for submission */}
      <SubmitPaymentRequestPosting
        openDialog={openDialog}
        handleDialogClose={handleDialogClose}
        selectedItem={selectedItem}
        setDataUpdateRender = {setDataUpdateRender}
        staffId={loggedInUserRUID}
        paymentRequestType={"NMFP"}
        // handleDialogSubmit={handleDialogSubmit}
      />

      {/* Dialog to display to the Payment Receipt/Cheque Uploaded */}
      <OpenDocumentDisplayer
        docTitle={"View Uploaded Cheque/Online Receipt"}
        docFile={docFile}
        openDocDisplayer={openChequeOnlineReceiptDisplayer}
        setOpenDocDisplayer={setOpenChequeOnlineReceiptDisplayer}
      />

     

    </div>
  );
};

export default MembershipFeesPaymentsTab;
