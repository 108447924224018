import React, { useEffect, useState } from "react";
import useMyAuthService from "../../../authenticationServiceProvider/useMyAuthService";

const RBMembersListDropDown = ({
   setSelectedMemberRUID,
   actionButtonText,
  actionButtonFunction,
}) => {

   const [rbMemberListForFilters, setRBMemberListForFilters] = useState();
   const { api } = useMyAuthService();


  useEffect(() => {
    getAllRBMembersListForFilters();
    //getAccountHeads();
  }, []);

  const getAllRBMembersListForFilters = () => {
    api.get(`/getRBMembersList`).then((response) => {
      // console.log(response.data);

      setRBMemberListForFilters(response.data);
    });
  };

  return (
    <div className="gridRow">
      
      <div className="FormFieldDiv">
        <div>
          <input
            list="members"
            name="memberList"
            id="memberList"
            onChangeCapture={(e) => setSelectedMemberRUID(e.target.value)}
            placeholder="Select a Member"
          />
          <datalist id="members">
            {rbMemberListForFilters
              ? rbMemberListForFilters.map((rbMember, index) => {
                  return (
                    <option key={index} value={rbMember.rbMemberRUID}>
                      {rbMember.name} --- {rbMember.ledgerNo}
                    </option>
                  );
                })
              : ""}
          </datalist>
        </div>
      </div>

      <div className="FormActionDiv">
        <input
          type={"button"}
          value={actionButtonText}
          onClick={actionButtonFunction}
        />
      </div>
    </div>
  );
};

export default RBMembersListDropDown;
