import React from "react";
import "./SettingLoanLimits.css";

import { useState } from "react";
import axios from "axios";
import { useGetAPIServer } from "../../../../BackEndServerServiceProvider/BackEndServerServiceProvider";

let createLoanLimitsForm = {
  classMembersStartGP: " ",
  classMembersEndGP: " ",
  loanType: " ",
  noOfShares: " ",
  noOfSurety: " ",
  maxTenure: " ",
  loanLimits: " "

 };


function SettingLoanLimits() {

  const apiServer  = useGetAPIServer();
/** The data elements of the form to be stored in these variables */
const [createLoanLimitsFormData, setCreateLoanLimitsFormData] = useState(
  createLoanLimitsForm
);
const [errorMessage, setErrorMessage] = useState();
const [message, setMessage] = useState();

async function handleSubmit(e) {
  e.preventDefault();
  //console.log("form has been submitted");
  // console.log(createStaffFormData);

  //  if(createStaffFormData.staffSign ==="" && createStaffFormData.staffPhoto ==="" ){
  //     setErrorMessage("Please Upload the Photo and Signatures for proceeding further" );
  //  }else{

  const data = createLoanLimitsFormData;
  //console.log(data);
  let config = {
    method: "POST",
    url: `${apiServer.serverBaseRoute}/CreateSettingForLoanLimits`,
    data: { data },
    headers: {
      Accept: "application/json",

      //  Authorization: varToken
    },
    responseType: "json",
  };

  const response = await axios(config);
  //console.log(response.data);
  setMessage(response.data);

  // }
}



  return (
    <div className="SettingLoanLimits">
      <fieldset>
        <legend>Loan Settings</legend>
        <div className="errorDiv">{errorMessage}</div>
        <div className="msgDiv">{message}</div>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="gridRow">
            <div className="gridCol">
              <div className="FormFieldDiv">
                <div>
                  <label>Loan Type</label>
                </div>
                <div>
                  <select
                    name="loanType"
                    placeholder="Enter the Loan Type "
                    onChange={(e) =>
                      setCreateLoanLimitsFormData({
                        ...createLoanLimitsFormData,
                        loanType: e.target.value,
                      })
                    }
                    required
                  >
                    <option selected={'selected'} disabled>Select Loan Type</option>
                    <option>Main Loan</option>
                    <option>Emergent Loan</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="gridRow">
            <div className="gridCol">
              <div className="FormFieldDiv">
                <div>
                  <label>Class Start GP</label>
                </div>
                <div>
                  <input
                    type={"text"}
                    name="classMembersStartGP"
                    placeholder="Enter the Staff Name "
                    value={createLoanLimitsFormData.classMembersStartGP}
                    onChange={(e) =>
                      setCreateLoanLimitsFormData({
                        ...createLoanLimitsFormData,
                        classMembersStartGP: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
              <div className="FormFieldDiv">
                <div>
                  <label>No Of Shares</label>
                </div>
                <div>
                  <input
                    type={"text"}
                    name="noOfShares"
                    placeholder="Enter Shares Required "
                    value={createLoanLimitsFormData.noOfShares}
                    onChange={(e) =>
                      setCreateLoanLimitsFormData({
                        ...createLoanLimitsFormData,
                        noOfShares: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>

              <div className="FormFieldDiv">
                <div>
                  <label>Loan Limit</label>
                </div>
                <div>
                  <input
                    type={"number"}
                    name="loanLimit"
                    placeholder="Enter Loan Limit"
                    value={createLoanLimitsFormData.loanLimits}
                    onChange={(e) =>
                      setCreateLoanLimitsFormData({
                        ...createLoanLimitsFormData,
                        loanLimits: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
            </div>
            <div className="gridCol">
              <div className="FormFieldDiv">
                <div>
                  <label>Class End GP</label>
                </div>
                <div>
                  <input
                    type={"text"}
                    name="classMembersEndGP"
                    placeholder="Enter the Staff Name "
                    value={createLoanLimitsFormData.classMembersEndGP}
                    onChange={(e) =>
                      setCreateLoanLimitsFormData({
                        ...createLoanLimitsFormData,
                        classMembersEndGP: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>

              <div className="FormFieldDiv">
                <div>
                  <label>No Of Surety</label>
                </div>
                <div>
                  <input
                    type={"number"}
                    name="noOfSurety"
                    placeholder="Enter Surety Required "
                    value={createLoanLimitsFormData.noOfSurety}
                    onChange={(e) =>
                      setCreateLoanLimitsFormData({
                        ...createLoanLimitsFormData,
                        noOfSurety: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
              <div className="FormFieldDiv">
                <div>
                  <label>Max Loan Tenure</label>
                </div>
                <div>
                  <input
                    type={"number"}
                    name="maxTenure"
                    placeholder="Enter Max Loan Tenure "
                    value={createLoanLimitsFormData.maxTenure}
                    onChange={(e) =>
                      setCreateLoanLimitsFormData({
                        ...createLoanLimitsFormData,
                        maxTenure: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="FormActionDiv">
            <input type={"button"} value="Clear Form" />
            <input type={"submit"} value="Save Settings" />
          </div>
        </form>
      </fieldset>
    </div>
  );
}

export default SettingLoanLimits;
