const membershipApplicationVerificationSteps = [
    {
      title: "#1: Verify Payment",
      description: "Check the Payment Submitted/Re-Submitted by the RB Member",
    },
    {
      title: "#2: Verify Application",
      description: "Check the Application Details Submitted/Re-Submitted by the RB Member. Mark the Application for Review, if any errors. Otherwise, if found correct, Submit the Application for approval by alloting a ledger No. to the RB Member.",
    },
    {
      title: "#3: View Approvals",
      description: "See/Check the Status of Approval of the Membership Applications ",
    },
  ];
  
export default membershipApplicationVerificationSteps;
  