import React from "react";
import MultiStepStepper from "../../MultiStepStepper/MultiStepStepper";
import CDPayDeductions from "./CDPayDeductions/CDPayDeductions";
import MainLoanDeductions from "./MainLoanDeductions/MainLoanDeductions";
import EmergentLoanDeductions from "./EmergentLoanDeductions/EmergentLoanDeductions";
import StepperContext from "../../MultiStepStepper/StepperContext";

const EMIDeductionReport = () => {
  const steps = [
    { label: "Step 1 # CD Payment Deductions", component: <CDPayDeductions /> },
    {
      label: "Step 2 # Main Loan Deductions",
      component: <MainLoanDeductions />,
    },
    {
      label: "Step 3 # Emergent Loan Deductions",
      component: <EmergentLoanDeductions />,
    },
  ];

  return (
    <>
      <StepperContext>
        <MultiStepStepper steps={steps} />
      </StepperContext>
    </>
  );
};

export default EMIDeductionReport;