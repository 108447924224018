import React from "react";
import "./MemberhsipApplicationStatusExplanation.css"

const MembershipApplicationStatusExplanation = ({ applicationStatus }) => {
  const getStatusExplanation = () => {
    switch (applicationStatus) {
      case "MASDP":
        return {
          explanation: "Membership Application Submitted and Documentation Upload Pending.",
          fullForm: "MASDP: Membership Application Submitted and Documentation Upload Pending",
        };
      case "MASPP":
        return {
          explanation: "Membership Application Submitted with Documentation but payment pending.",
          fullForm: "MASPP: Membership Application Submitted with Documentation but payment pending",
        };
      case "MASWP":
        return {
          explanation: "Membership Application Submitted with Documentation and Payment.",
          fullForm: "MASWP: Membership Application Submitted with Documentation and Payment",
        };
      case "MASPR":
        return {
          explanation: "Membership Application Submitted but payment Rejected.",
          fullForm: "MASPR: Membership Application Submitted but payment Rejected",
        };
      case "MASRP":
        return {
          explanation: "Membership Application Submitted with Revised Payment.",
          fullForm: "MASRP: Membership Application Submitted with Revised Payment",
        };
      case "MAMR":
        return {
          explanation: "Membership Application marked For Review and Resubmit details.",
          fullForm: "MAMR: Membership Application marked For Review and Resubmit details",
        };
      case "MARS":
        return {
          explanation: "Membership Application Re-submitted with Corrections.",
          fullForm: "MARS: Membership Application Re-submitted with Corrections",
        };
      case "MASPV":
        return {
          explanation: "Membership Application Submitted and Payment Verified.",
          fullForm: "MASPV: Membership Application Submitted and Payment Verified",
        };
      case "MAPDV":
        return {
          explanation: "Membership Application Details and Payment Verified.",
          fullForm: "MADPV: Membership Application Details and Payment Verified",
        };
      case "MAA":
        return {
          explanation: "Membership Application Approved.",
          fullForm: "MAA: Membership Application Approved",
        };
      default:
        return {
          explanation: "Unknown application status.",
          fullForm: "Unknown",
        };
    }
  };

  const { explanation, fullForm } = getStatusExplanation();

  return (
    <div className="container">
      <p>Your Application Status: <u><strong>{applicationStatus}</strong></u></p>
      <hr/>
      <p className="full-form">Full Form: {fullForm}</p>
      <p className="explanation">Explanation: {explanation}</p>
    </div>
  );
};

export default MembershipApplicationStatusExplanation;
