import "./LoanPrePaymentTab.css";

import React, { useState, useEffect } from "react";

import TableDataDisplayer from "../../../TableDataDisplayer/MiscPaymentTable/MiscPaymentTable";
import SubmitPaymentRequestPosting from "../../../DialogComponents/SubmitPaymentRequestPosting/SubmitPaymentRequestPosting";
import useMyAuthService from "../../../../../authenticationServiceProvider/useMyAuthService";



const LoanPrePaymentTab = () => {

  const {api} = useMyAuthService();

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [selectedStatus, setSelectedStatus] = useState("Payment Submitted");
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);

  const [selectedItem, setSelectedItem] = useState({});


  const tableHeaders = [
    "RB Member RUID",
    "Payment Mode",
    "Transaction Ref No or Cheque No",
    "Request Type",
    "Request Date",
    "Status",
  ];

  const displayColumns = [
    "rbMemberRUID",
    "paymentMode",
    "tranRefNoOrChequeNo",
    "requestType",
    "requestDate",
    "status",
  ];

  useEffect(() => {
    fetchData();
  }, [selectedStatus, currentPage, itemsPerPage]);

  const fetchData = async () => {
    try {
      setIsLoadingData(true);
      const response = await api.get(`/GetAllPaymentRequest`, {
        params: {
          requestType: "LPRP",
          requestStatus: selectedStatus,
        },
      });
      setData(response.data);
      setIsLoadingData(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoadingData(false);
    }
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleMenuOptionClick = (option) => {
    switch (option) {
      case 'view-payment-details':
        // Handle view payment details option
        //console.log('View payment details');
        break;
      case 'post-payment-details':
        // Handle post payment details option
       // console.log(selectedItem);
        handleDialogOpen();
        break;
      default:
        break;
    }
  };


  const handleDialogSubmit = () => {
    // Handle the submit action here
    //console.log('Submit action triggered');
    // You can perform any necessary logic or API calls upon submit
    // Close the dialog if needed
    handleDialogClose();
  };
  

  return (
    <div className="sharesPurchasePaymentTabMainDiv">
      <div className="payment-status-div">
        <label htmlFor="status-select">Payment Status:</label>
        <select
          id="status-select"
          value={selectedStatus}
          onChange={handleStatusChange}
        >
          <option value="">All</option>
          <option value="Payment Submitted">Payment Submitted</option>
          <option value="Payment Accepted">Payment Accepted</option>
          <option value="Payment Rejected">Payment Rejected</option>
        </select>
      </div>

      {isLoadingData ? (
        <p>Loading Data...</p>
      ) : (
        <div>
          <TableDataDisplayer
            tableHeaders={tableHeaders}
            displayColumns={displayColumns}
            data={data}
            isLoading={isLoadingData}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            handleMenuOptionClick={handleMenuOptionClick}
            setSelectedItem={setSelectedItem}
          />
        </div>
      )}

      {/* Dialog to display to the payment posting form for submission */}
      <SubmitPaymentRequestPosting
        openDialog={openDialog}
        handleDialogClose={handleDialogClose}
        selectedItem={selectedItem}
        paymentRequestType={"LPP"}
      />
    </div>
  );
};


export default LoanPrePaymentTab