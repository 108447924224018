import React, { useState } from 'react'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const MemberOtherSocietyDetailsUpdater = () => {
  
  const [personalForm, setFormFieldValues] = useState({});
  const [societyForm, setsocietyFormFieldValues] = useState({});
  const [society, setsocietydata] = useState([]);

  const [isOtherSocietyFieldsDisabled , setIsOtherSocietyFieldsDisabled] = useState(false);
  
  let societytablerow = null;

  
  if(society !== undefined){
     societytablerow = society.map(
      (society, index) =>
                        {
                        return(<tr key={index}>
                                <td><DeleteForeverIcon onClick={() =>deletesocietydetail(index) } /></td>
                                <td>{ index+1 }</td>
                                <td>{society.societyName}</td>
                                <td>{society.societyEmail}</td>
                                <td>{society.societyMobile}</td>
                                <td>{society.societyAddress}</td>
                              </tr>)
                        }
      );
 }
  
 const deletesocietydetail = (index) =>{
     
    var array = [...society];
    if (index !== -1) {
      array.splice(index, 1);
     // console.log(society[index]);
      setsocietydata(oldarray => array)
    }  
   // console.log('removing from society details');
    // console.log(society[index]);
 }

  const addsocietydetail = () =>{
     
     //console.log(societyForm);
     setsocietydata(oldArray => [...oldArray, societyForm])
    // console.log('adding to society details');
     //console.log(society.length);
  }

  const handleIsOtherSociety = (e) =>{
    //alert(e.target.value);
    setIsOtherSocietyFieldsDisabled((prevActiveStep) => !prevActiveStep);
    setFormFieldValues({
      ...personalForm,
      isOtherSociety: e.target.value,
    })

}

  return (
    <div className="otherSocietyDetailsMainDiv">
      <fieldset>
        <legend>✍️ Fill Other Society Details</legend>
        {/* {JSON.stringify(societyForm.isothersociety)}{societyForm.isothersociety} */}
        <div className="gridRow">
          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Are you Member of any other society ?</label>
              </div>
              <div>
                <select value={personalForm.isOtherSociety}
                  onChange={(e) => handleIsOtherSociety(e)
                  }> 
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                  
                </select>
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Society Name</label>
              </div>
              <div>
                <input
                  disabled = {isOtherSocietyFieldsDisabled}
                  type={"text"}
                  value={societyForm.societyName}
                  onChange={(e) =>
                    setsocietyFormFieldValues({
                      ...societyForm,
                      societyName: e.target.value,
                    })
                  }
                  placeholder="Name of the Society"
                />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Society Address</label>
              </div>
              <div>
                <textarea rows="3" cols="38" 
                  disabled = {isOtherSocietyFieldsDisabled}
                  value={societyForm.societyAddress}
                  onChange={(e) =>
                    setsocietyFormFieldValues({
                      ...societyForm,
                      societyAddress: e.target.value,
                    })
                  }></textarea>
              </div>
            </div>

            <div className="FormFieldDiv">
              <div>
                <label>Society Email</label>
              </div>
              <div>
                <input
                  type={"text"}
                  disabled = {isOtherSocietyFieldsDisabled}
                  value={societyForm.societyEmail}
                  onChange={(e) =>
                    setsocietyFormFieldValues({
                      ...societyForm,
                      societyEmail: e.target.value,
                    })
                  }
                  placeholder="Enter Society Email, if any"
                />
              </div>
            </div>

            <div className="FormFieldDiv">
              <div>
                <label>Society Mobile No.</label>
              </div>
              <div>
                <input
                  type={"text"}
                  disabled = {isOtherSocietyFieldsDisabled}
                  value={societyForm.societyMobile}
                  onChange={(e) =>
                    setsocietyFormFieldValues({
                      ...societyForm,
                      societyMobile: e.target.value,
                    })
                  }
                  placeholder="Enter Society Mobile No., if any"
                />
              </div>
            </div>
          </div>
          {/* column 1 ends  --- column 2 starts */}
          <div className="gridCol">
            <div className="FormActionDiv">
              <input type={"button"} 
              disabled = {isOtherSocietyFieldsDisabled}
              value="➕ SOCIETY DETAILS" 
              onClick={addsocietydetail}
              />
            </div>
            <div className="addSocietyDetailsTableDiv">
              <table className="addSocietyDetailsTable" border="1">
                <thead>
                  <tr>
                    <td></td>
                    <td>Sl.</td>
                    <td>Society Name</td>
                    <td>Email Id</td>
                    <td>Mobile No.</td>
                    <td>Address</td>
                  </tr>
                </thead>
                <tbody>
                  {societytablerow}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
}

export default MemberOtherSocietyDetailsUpdater