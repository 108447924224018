import React from 'react'
import "./MembershipApplicationInstructions.css"

const MembershipApplicationInstructions = () => {
    return (
        <div className='membershipInstructionsMainDiv'>
          <fieldset>
            <legend>🎯 Membership Instructions</legend>
            <div className="membershipInstructionsContentDiv">
              <p>🎯 Membership Application will be entertained only from first to tenth of every month. So, kindly apply between first and tenth of a month only.</p> 
              <p>🎯 Only Confirmed Employees on the Roll of Railway Board may apply. Employees on Deputation to Railway Board are not eligible to Apply.</p>
              <p>🎯 Before Application, Please contact RBCTCS Staff (9871111477, 9650274375).</p>
            </div>
          </fieldset>
        </div>
      );
}

export default MembershipApplicationInstructions