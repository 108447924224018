import React, {useEffect, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ProgressorBarDisplayer from '../DialogComponents/ProgressorBarDisplayer/ProgressorBarDisplayer';
import useMyAuthService from '../../../authenticationServiceProvider/useMyAuthService';
import LRMWTableLoanLedgerData from '../TableDataDisplayer/LRMWTableLoanLedgerData/LRMWTableLoanLedgerData';

const ViewAndDownloadLoanStatement = () => {
  
  
    const data = useLocation();
    const selectedLoanAppId = data.state.loanAppId;

    const {api} = useMyAuthService();

    const navigate = useNavigate();

    const [loanLedgerData, setLoanLedgerData] = useState([]);
    const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false)

    useEffect(() => {
        loadLoanLedgerDataForLoan(selectedLoanAppId);
    }, []);

    const loadLoanLedgerDataForLoan = async (selectedLoanAppId) => {
      //console.log(filterFields);
      let config = {
        method: "GET",
        url: `/GetLoanTransactionsOfParticularLoanForMember/${selectedLoanAppId}`,
        headers: {
          Accept: "application/json",
  
          //  Authorization: varToken
        },
        responseType: "json",
      };
  
      setOpenProgressBarDialog(true);
      const response = await api(config);
      // console.log(response.data);
      setLoanLedgerData(response.data);
      setOpenProgressBarDialog(false);
    };

  const moveBacktoLoanApps = () =>{
    navigate(-1, {
      replace:true
    });
  }


  return (
    <div className='ledgerReportMemberWiseMainDiv'>
      <fieldset className="scrollWithoutScrollBar">
        <legend>📝 Loan Statement View/Download for Loan ID # {selectedLoanAppId}</legend>
        
        <LRMWTableLoanLedgerData 
                className={"LRMWFiltersSearchDivTable centerContent"}
                loanTransactionsList={loanLedgerData}
                moveBacktoLoanApps={moveBacktoLoanApps}
        />
      </fieldset>
    

      <ProgressorBarDisplayer 
        titleMessage={'Please wait loading data.....'}
        openProgressBarDialog={openProgressBarDialog}
      />
    </div>
  )
}

export default ViewAndDownloadLoanStatement