import React, { useEffect, useState } from "react";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import LoanPrePaymentTab from "./LoanPrePaymentTab/LoanPrePaymentTab";
import SharesPurchasePaymentTab from "./SharesPurchasePaymentTab/SharesPurchasePaymentTab";
import MembershipFeesPaymentsTab from "./MembershipFeesPaymentsTab/MembershipFeesPaymentsTab";

const MiscPaymentsTabView = () => {

  // const [ruidLoggedIn, setRUIDLoggedIn] = useState(5184658);
 
  // const [updateForReRender, setUpdateForReRender] = useState(false);


  //for tabs
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //for tabs



  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Manage Surety Tabs"
          indicatorColor="secondary"
        >
          <Tab label="Loan Pre-payments" {...a11yProps(0)} />
          <Tab label="Share Purchase Payments" {...a11yProps(1)} />
          <Tab label="Membership Fees Payments" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div>
          <LoanPrePaymentTab />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div>
          <SharesPurchasePaymentTab />
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div>
          <MembershipFeesPaymentsTab />
        </div>
      </TabPanel>
      
    </div>
  );
}

export default MiscPaymentsTabView

////////functions for tab implementation.......................

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }