// CustomDatePicker.js
import React, { useState } from 'react';
import "./CustomDatePicker.css"

const CustomDatePicker = ({ label, onSelectDate }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onSelectDate(date);
  };

  return (
    <div className="custom-date-picker">
      <label>{label}:</label>
      <input
        type="date"
        value={selectedDate ? selectedDate.toISOString().split('T')[0] : ''}
        onChange={(e) => handleDateChange(new Date(e.target.value))}
      />
    </div>
  );
};

export default CustomDatePicker;
