import "./ViewAndDownloadSharePurchaseStatement.css";

import React, { useState, useEffect } from "react";
import axios from "axios";

import Button from "@mui/material/Button";

import ProgressorBarDisplayer from "../DialogComponents/ProgressorBarDisplayer/ProgressorBarDisplayer";
import LRMWTableSharesLedgerData from "../TableDataDisplayer/LRMWTableSharesLedgerData/LRMWTableSharesLedgerData";
import useMyAuthService from "../../../authenticationServiceProvider/useMyAuthService";

const ViewAndDownloadSharePurchaseStatement = () => {
  const { api, loggedInUserRUID } = useMyAuthService();

  //filter fileds that have been selected for getting data from the server........
  const filters = {
    selectedRBMemberRUID: loggedInUserRUID,
    // selectedLoanType: " ",
  };

  const [filterFields, setFilterFields] = useState(filters);

  const [memberSharesDetails, setMemberSharesDetails] = useState([]);
  const [isMemberSharesDataLoaded, setIsMemberSharesDataLoaded] =
    useState(false);

  const [sharesLedgerData, setSharesLedgerData] = useState([]);
  const [isSharesLedgerDataLoaded, setIsSharesLedgerDataLoaded] =
    useState(false);

  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

  useEffect(() => {
    loadCDLedgerDetailsForMember();
  }, []);

  const loadCDLedgerDetailsForMember = async () => {
    //console.log(filterFields);
    let config = {
      method: "POST",
      url: "/GetMemberShareDetailsForSharesLRMW",
      data: { filterFields },
      headers: {
        Accept: "application/json",
        //  Authorization: varToken
      },
      responseType: "json",
    };

    setOpenProgressBarDialog(true);
    const response = await api(config);
    // console.log(response.data);
    setMemberSharesDetails(response.data);
    //   setIsMemberSharesDataLoaded(true);
    setOpenProgressBarDialog(false);
  };

  const handleClickGetSharesLedgerData = async (selectedMemberRUID) => {
    //console.log(filterFields);
    let config = {
      method: "POST",
      url: `/GetShareTransactionsForLRMW`,
      data: { selectedMemberRUID },
      headers: {
        Accept: "application/json",

        //  Authorization: varToken
      },
      responseType: "json",
    };

    setOpenProgressBarDialog(true);
    const response = await api(config);
    // console.log(response.data);
    setSharesLedgerData(response.data);
    setIsMemberSharesDataLoaded(false);
    setIsSharesLedgerDataLoaded(true);

    setOpenProgressBarDialog(false);
  };

  const goBackToStepOneFilters = () => {
    //alert("cle")
    setIsMemberSharesDataLoaded(false);
    setIsSharesLedgerDataLoaded(false);
  };

  return (
    <>
      <div className="ledgerReportMemberWiseMainDiv">
        <fieldset className="scrollWithoutScrollBar">
          <legend>
            📝 Share Purchase Statement View/Download for # {loggedInUserRUID}
          </legend>
          {!isMemberSharesDataLoaded && !isSharesLedgerDataLoaded ? (
            <div className="sharesStatementDiv">
              <div>
                <h3>
                  <center>Shares Ledger Details</center>
                </h3>
              </div>
              <table className="sharesLedgerTable centerContent">
                <thead>
                  <tr>
                    <td>Sl.</td>
                    <td>RUID </td>
                    <td>Member </td>
                    <td>Ledger No.</td>
                    <td>No of Shares</td>
                    <td>Total Share Amount</td>
                    <td>View Statement</td>
                  </tr>
                </thead>
                <tbody>
                  {memberSharesDetails.map((share, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{share.rbMemberRUID}</td>
                        <td>
                          {share.name} ({share.designation})
                        </td>
                        <td>{share.ledgerNo}</td>
                        <td>{share.noOfShares}</td>
                        <td>{share.totalShareAmount}</td>
                        <td>
                          <Button
                            variant="outlined"
                            onClick={() =>
                              handleClickGetSharesLedgerData(share.rbMemberRUID)
                            }
                          >
                            View
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="sharesStatementDiv">
              <LRMWTableSharesLedgerData
                className={"sharesLedgerTable centerContent"}
                sharesTransactionsList={sharesLedgerData}
              />
            </div>
          )}
        </fieldset>
      </div>

      {/* Dialog to implement the Progess bar ................ */}
      <ProgressorBarDisplayer
        titleMessage={"Please Wait! Loading Shares transactions data....."}
        openProgressBarDialog={openProgressBarDialog}
      />
    </>
  );
};

export default ViewAndDownloadSharePurchaseStatement;
