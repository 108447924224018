import React from "react";
import "./ViewStaffDetails.css";

import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import profileImage from "../../../images/profile2.jpg";
import signImage from "../../../images/sign.jpg";

import  useMyAuthService  from "../../../authenticationServiceProvider/useMyAuthService";


function ViewStaffDetails(props) {

  
  const navigate = useNavigate();

  const { staffIdProp } = props;

  const {loggedInUserRUID, loggedInUserRole, api} = useMyAuthService();
 
  //console.log(staffIdProp);
  const [staffData, setStaffData] = useState({});
  const [selectedStaffId, setSelectedStaffId] = useState(null);

  const [allStaffData, setAllStaffData] = useState([]);

  const [isloadingData, setIsLoadingData] = useState(true);
  const [isLoadingPhoto, setIsLoadingPhoto] = useState(true);
  const [isLoadingSign, setIsLoadingSign] = useState(true);

  const [staffPhoto, setStaffPhoto] = useState(profileImage);
  const [staffSign, setStaffSign] = useState(signImage);
  

  useEffect(() => {

      
    if (loggedInUserRole === "Staff") {
      //get particular staff details
      viewParticularStaffDetails(loggedInUserRUID);
      getRBCTCSStaffPhotoDetails(loggedInUserRUID);
      getRBCTCSStaffSignatureDetails(loggedInUserRUID);

    } else {
      //get all the staff details and set to the selected staffID details
      getAllRBCTCSStaffList(staffIdProp);
      
    }
  }, []);

  const viewParticularStaffDetails = (staff) => {
    api
      .get(`/ViewRBCTCSStaffDetails/${staff}`)
      .then((response) => {
        //console.log(response.data);
        setStaffData(response.data[0]);
        //console.log(staffData);
        setIsLoadingData(false);
      });
  };

  const getAllRBCTCSStaffList = (staff) => {
    api
      .get(`/getAllRBCTCSStaffList`)
      .then((response) => {
        // console.log(response.data.filter());

        setAllStaffData(response.data);
        //console.log(staffData);
        setIsLoadingData(false);
      });
  };

  const displayDetailsParticularStaff = () => {
    if (selectedStaffId === null) {
      alert("Please select Staff ID to display details");
    } else {
      allStaffData.forEach((data) => {
        if (data.staffRBCTCSId === selectedStaffId) {
          getRBCTCSStaffPhotoDetails(selectedStaffId);
          getRBCTCSStaffSignatureDetails(selectedStaffId);
          setStaffData(data);
          
        }
      });
    }
  };


  const handleOpenImageUploaderComponent = () => {
    navigate("../UploadPhotoSign");
    // { state: response.data }
  };

  const handleOpenStaffDetailsUpdaterComponent = () =>{
    navigate("../UpdateStaffDetails");
  }


  const getRBCTCSStaffPhotoDetails = (staffId) => {

    api({
      url: `/GetStaffPhoto/${staffId}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
       const url = window.URL.createObjectURL(new Blob([response.data]));
       setStaffPhoto(url);
       setIsLoadingPhoto(false);
    });
  
  };

  const getRBCTCSStaffSignatureDetails = (staffId) => {

    api({
      url: `/GetStaffSignature/${staffId}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
       const url = window.URL.createObjectURL(new Blob([response.data]));
       setStaffSign(url);
       setIsLoadingSign(false); 
    });
  
  };

  return (
    <div className="ViewStaffDetailsMainDiv">
      {/* {JSON.stringify(staffData)} */}
      <fieldset>
        <legend>🔍 View Staff Details</legend>
        {staffIdProp !== undefined ? (
          <div>
            <div className="FormFieldDiv">
              <div>
                <input
                  list="staffs"
                  name="staffList"
                  id="staffList"
                  onChangeCapture={(e) => setSelectedStaffId(e.target.value)}
                  placeholder="Select a Staff"
                />
                <datalist id="staffs">
                  {allStaffData.map((staff, index) => {
                    return (
                      <option key={index} value={staff.staffRBCTCSId}>
                        {staff.name}
                      </option>
                    );
                  })}
                </datalist>
                <input
                  type={"button"}
                  value="View Staff Details"
                  onClick={displayDetailsParticularStaff}
                />
              </div>
            </div>
            <hr />
          </div>
        ) : (
          ""
        )}
        {isloadingData || isLoadingPhoto || isLoadingSign ? (
          <div className="isLoadingDiv">Loading Data .....</div>
        ) : (
          <div className="gridRow">
            <div className="gridCol">
              <table>
                <tbody>
                  <tr>
                    <td>Staff Name</td>
                    <td>{staffData.name}</td>
                  </tr>
                  <tr>
                    <td>Staff ID</td>
                    <td>{staffData.staffRBCTCSId}</td>
                  </tr>
                  <tr>
                    <td>Designation</td>
                    <td>{staffData.designation}</td>
                  </tr>
                  <tr>
                    <td>Mobile No/ Railway Tel.</td>
                    <td>
                      {staffData.mobileNo}/{staffData.railwayTelNo}
                    </td>
                  </tr>
                  <tr>
                    <td>Email Id</td>
                    <td>{staffData.emailId}</td>
                  </tr>
                  <tr>
                    <td>Office Room</td>
                    <td>{staffData.branchRoomNo}</td>
                  </tr>
                  <tr>
                    <td>Residential Address</td>
                    <td>{staffData.homeAddress}</td>
                  </tr>
                  <tr>
                    <td>Permanent Address</td>
                    <td>{staffData.permanentAddress}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="gridCol">
              <table>
                <tbody>
                  <tr>
                    <td>Date of Birth</td>
                    <td>{staffData.dateOfBirth}</td>
                  </tr>
                  <tr>
                    <td>Date of Appointment</td>
                    <td>{staffData.dateOfAppointment}</td>
                  </tr>
                  <tr>
                    <td>Date of Retirement</td>
                    <td>{staffData.dateOfRetirement}</td>
                  </tr>
                  <tr>
                    <td>PAN No.</td>
                    <td>{staffData.panNo}</td>
                  </tr>
                  <tr>
                    <td>Pay Detail</td>
                    <td>{staffData.basicPay}</td>
                  </tr>
                  <tr>
                    <td>Salary Bank</td>
                    <td>{staffData.salaryBankName}</td>
                  </tr>
                  <tr>
                    <td>Bank Account No</td>
                    <td>{staffData.salaryBankAccountNo}</td>
                  </tr>
                  <tr>
                    <td>Bank IFSC</td>
                    <td>{staffData.salaryBankIFSC}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="gridCol">
              <table className="centerContent">
                <tbody>
                  <tr>
                    <td >Staff Photo</td>
                    <td>Staff Signature</td>
                  </tr>
                  <tr>
                    <td>
                      <img src={staffPhoto}></img>
                    </td>
                    <td>
                      <img src={staffSign}></img>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}

        {staffIdProp !== undefined ? (
          ""
        ) : (
          <>
            <hr></hr>
            <div className="gridRow">
              <div className="FormActionDiv gridCol">
                <input
                  type={"button"}
                  onClick={() => handleOpenStaffDetailsUpdaterComponent()}
                  value="🖆 Update Staff Details"
                />
                <div className="FormActionDiv gridCol">
                  <input
                    type={"button"}
                    onClick={() => handleOpenImageUploaderComponent()}
                    value="🖆 Update Photo & Sign"
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </fieldset>
    </div>
  );
}

export default ViewStaffDetails;
