import "./SubmitPaymentRequestPosting.css"
import React, { useState } from "react";
import axios
 from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import MembershipFeesPaymentPostingForm from "./MembershipFeesPaymentPostingForm/MembershipFeesPaymentPostingForm";
import SharesPurchasePaymentPostingForm from "./SharesPurchasePaymentPostingForm/SharesPurchasePaymentPostingForm";
import LoanPrePaymentPostingForm from "./LoanPrePaymentPostingForm/LoanPrePaymentPostingForm";


const SubmitPaymentRequestPosting = ({ openDialog, handleDialogClose, selectedItem, setDataUpdateRender, staffId, paymentRequestType }) => {
  

 

  // const [
  //   openChequeOnlineReceiptDisplayer,
  //   setOpenChequeOnlineReceiptDisplayer,
  // ] = useState(false);

  
  const renderPaymentPostingForm = (paymentRequestType) =>{
        switch (paymentRequestType) {
          case "NMFP":
            return (
              <MembershipFeesPaymentPostingForm
                selectedItem={selectedItem}
                handleDialogClose={handleDialogClose}
                setDataUpdateRender={setDataUpdateRender}
                staffId={staffId}
              />
            );

          case "SMPAY":
            return (
              <SharesPurchasePaymentPostingForm
                selectedItem={selectedItem}
                handleDialogClose={handleDialogClose}
                setDataUpdateRender={setDataUpdateRender}
                staffId={staffId}
              />
            );

          case "LPP":
            return (
              <LoanPrePaymentPostingForm
                selectedItem={selectedItem}
                handleDialogClose={handleDialogClose}
                setDataUpdateRender={setDataUpdateRender}
                staffId={staffId}
              />
            );

          default:
            return null;
        }
  }

  return (
    <div className="submitPaymentRequestMainDiv">
      {/* Dialog to implement the Pay slip Uploader ................ */}
      <Dialog open={openDialog} fullWidth maxWidth="md">
        <DialogActions id="scroll-dialog-ActionDialogActions">
          <span className="titleDialogBox">Payment Request Posting....</span>
          <Button variant="outlined" onClick={handleDialogClose}>
            ❌
          </Button>
        </DialogActions>
        <DialogContent>
          {renderPaymentPostingForm(paymentRequestType)}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SubmitPaymentRequestPosting;
