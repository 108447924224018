import React from "react";
import Button from "@mui/material/Button";

const PaginationNumberRendering = ({
  maxPageNumbersToShow,
  totalPages,
  currentPage,
  handlePageChange,
}) => {
  const renderPageNumbers = () => {
    //const maxPageNumbersToShow = ;
    const pagesToShow = Math.min(totalPages, maxPageNumbersToShow);

    const pageNumbers = [];
    let startPage;
    if (totalPages <= maxPageNumbersToShow) {
      startPage = 1;
    } else if (currentPage <= Math.ceil(maxPageNumbersToShow / 2)) {
      startPage = 1;
    } else if (
      currentPage + Math.floor(maxPageNumbersToShow / 2) >=
      totalPages
    ) {
      startPage = totalPages - maxPageNumbersToShow + 1;
    } else {
      startPage = currentPage - Math.floor(maxPageNumbersToShow / 2);
    }

    for (let i = 0; i < pagesToShow; i++) {
      pageNumbers.push(startPage + i);
    }

    return (// retunrn of the function
      <>
        {currentPage > Math.floor(maxPageNumbersToShow / 2) + 1 && (
          <Button onClick={() => handlePageChange(1)}>1</Button>
        )}
        {currentPage > Math.floor(maxPageNumbersToShow / 2) + 2 && (
          <span>...</span>
        )}
        {pageNumbers.map((pageNumber) => (
          <Button
            key={pageNumber}
            onClick={() => handlePageChange(pageNumber)}
            disabled={currentPage === pageNumber}
          >
            {pageNumber}
          </Button>
        ))}
        {currentPage <
          totalPages - Math.floor(maxPageNumbersToShow / 2) - 1 && (
          <span>...</span>
        )}
        {currentPage < totalPages - Math.floor(maxPageNumbersToShow / 2) && (
          <Button onClick={() => handlePageChange(totalPages)}>
            {totalPages}
          </Button>
        )}
      </>
    );
  };

  return (
    <div className="pagination">
      <Button
        disabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
      >
        Previous
      </Button>
      {renderPageNumbers()}
      <Button
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(currentPage + 1)}
      >
        Next
      </Button>
    </div>
  );
};

export default PaginationNumberRendering;
