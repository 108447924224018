import "./ViewAdminDetails.css"

import React, { useEffect, useState } from "react";

import profileImage from "../../../images/profile2.jpg";
import signImage from "../../../images/sign.jpg";

import { useNavigate } from "react-router-dom";

import LinearProgress from "@mui/material/LinearProgress";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import useMyAuthService  from "../../../authenticationServiceProvider/useMyAuthService";

const AdminFormFields = {
  name: " ",
  adminRUID: " ",
  adminDesignation: " ",
  email: " ",
  mobile: " ",
  adminType: " ",
  electedDate: " ",
  tenureInYears: " ",
  ledgerNo: " ",
  railwayTelNo: " ",
  roomNo:" ",
 
};

const ViewAdminDetails = () => {

    const navigate = useNavigate();
    
    const {loggedInUserRUID, api} = useMyAuthService();
  
    const [currentLoggedInRUID, setCurrentLoggedInRUID] = useState(loggedInUserRUID);
    const [AdminForm, setFormFieldValues] = useState(AdminFormFields);
    
    const [memberPhoto, setMemberPhoto] = useState(profileImage);
    const [memberSign, setMemberSign] = useState(signImage);
  
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [isLoadingPhoto, setIsLoadingPhoto] = useState(true);
    const [isLoadingSign, setIsLoadingSign] = useState(true);
  
    useEffect(() => {
      getRBCTCSAdminDetails();
      getRBMemberPhotoDetails();
      getRBMemberSignatureDetails();
         
    }, []);
  
    const getRBCTCSAdminDetails = () => {
      api
        .get(
          `/getAdminRBCTCSDetails/${currentLoggedInRUID}`
        )
        .then((response) => {
          // console.log(response.data[0]);
           setFormFieldValues({
            ...AdminForm,
            name: response.data[0].users.name,
            adminRUID: response.data[0].adminRBCTCSRUID,
            adminDesignation:response.data[0].designationRBCTCS,
            mobile: response.data[0].users.mobile,
            email: response.data[0].users.email,
            electedDate: response.data[0].electedDate,
            tenureInYears: response.data[0].tenureInYears,
            adminType:response.data[0].users.role2,
            roomNo:response.data[0].roomNo 
            
          });
          //console.log(regData);
          setIsLoadingData(false);
        });
    };
  
  
    const getRBMemberPhotoDetails = () => {
  
      api({
        url: `/GetMemberPhoto/${currentLoggedInRUID}`,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
         const url = window.URL.createObjectURL(new Blob([response.data]));
         //console.log(response.data);
         setMemberPhoto(url);
         setIsLoadingPhoto(false);
      });
    
    };
  
    const getRBMemberSignatureDetails = () => {
  
      api({
        url: `/GetMemberSignature/${currentLoggedInRUID}`,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
         const url = window.URL.createObjectURL(new Blob([response.data]));
         setMemberSign(url);
         setIsLoadingSign(false); 
      });
    
    };
  
    const handleOpenAdminDetailsUpdaterComponent = () => {
      navigate("../UpdateAdminOfficialDetails");
    };
  
    const handleOpenImageUploaderComponent = () => {
      navigate("../UploadPhotoSign");
      // { state: response.data }
    };
  
    return (
      <div className="AdminDetailsViewerMainDiv">
        <fieldset>
          <legend>🔍 View Your ADMIN Official Details here</legend>
          {isLoadingData || isLoadingPhoto || isLoadingSign? (
            <div className="isLoadingDiv">Loading Data ...... </div>
          ) : (
            <>
              <div className="gridRow">
                <div className="gridCol">
                  <table>
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td>{AdminForm.name}</td>
                      </tr>
                      <tr>
                        <td>Admin RUID</td>
                        <td>{AdminForm.adminRUID}</td>
                      </tr>
                      <tr>
                        <td>Designation</td>
                        <td>{AdminForm.adminDesignation}</td>
                      </tr>
                      <tr>
                        <td>Admin Type</td>
                        <td>{AdminForm.adminType}</td>
                      </tr>
                      <tr>
                        <td>Elected Date</td>
                        <td>{AdminForm.electedDate}</td>
                      </tr>
                      <tr>
                        <td>Tenure (Years)</td>
                        <td>{AdminForm.tenureInYears}</td>
                      </tr>
                      <tr>
                        <td>Email Id</td>
                        <td>{AdminForm.email}</td>
                      </tr>
                      <tr>
                        <td>Mobile No.</td>
                        <td>{AdminForm.mobile}</td>
                      </tr>
                      <tr>
                        <td>Room No.</td>
                        <td>{AdminForm.roomNo}</td>
                      </tr>
                      
                    </tbody>
                  </table>
                </div>
                <div className="gridCol">
                  <table>
                    <tbody>
                      <tr>
                        <td>Member Photo</td>
                        <td>Member Signature</td>
                      </tr>
                      <tr>
                        <td>
                          <img src={memberPhoto}></img>
                        </td>
                        <td>
                          <img src={memberSign}></img>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <hr></hr>
              <div className="gridRow">
                <div className="FormActionDiv gridCol">
                  <input
                    type={"button"}
                    onClick={() => handleOpenAdminDetailsUpdaterComponent()}
                    value="🖆 Update Admin Details"
                  />
                  <div className="FormActionDiv gridCol">
                    <input
                      type={"button"}
                      onClick={() => handleOpenImageUploaderComponent()}
                      value="🖆 Update Photo & Sign"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </fieldset>
      </div>
    );
  
}

export default ViewAdminDetails