import React, { useState } from "react";
import "./LoanBond2.css";

import { convertToWords } from "../../../../ComonFunctionsProvider/CommonFunctionsProvider";

const LondBond2 = (props) => {

  const {loanBond2, setLoanBond2Values, loanForm, setLoanFormValues,loggedInMemberDetails} = props;

  const signBond2CheckHandler = (e) => {
    setLoanBond2Values({... loanBond2, isBond2Signed: e.target.checked});
     //console.log(e.target.checked+'---'+loanBond2.isBondSigned);
 } 

  return (
    <div className="loanBond2MainDiv">
      <fieldset>
        <legend>🗞️ Loan Bond</legend>
        <div className="loanBond1HeaderDiv">
          <h3>RAILWAY BOARD CO-OPERATIVE THRIFT & CREDIT SOCIETY LTD.</h3>
          <hr />
          <div>Room No. 415-C, Rail Bhawan, New Delhi</div>
          <div>(Registered under the Co-Op. Societies Act. 11 of 1912)</div>
          <div>(Registration No. 374U(A) Dated 01-07-1931)</div> 
          <h2><u>BOND</u></h2>
        </div>
        <div className="loanBond2ContentDiv">
          <p>
            I, <u>{loggedInMemberDetails.name}</u>, S/O Sh. <u>{}</u> RIO <u>{}</u>, member of above named Society, received
            subject to the bye laws of the society, an Emergent/House Building
            loan of Rs. <u>{loanForm.loanAmount}</u> only (<u>{convertToWords(loanForm.loanAmount)}</u> Rupees only) today 
            i.e. on this <u>{}</u> of <u>{}</u> 
          </p>
          <p>
            I hereby authorize Administratkm / Drawing and Disbursing Officer
            (DDO)/Pay & Accounts Officer (PAO), Railway Board to recover the
            total outstanding dues towards my loan account from my Gratuity /
            Final Settlement for payment to the RBCTCS in case of my death.
          </p>
          <p>
            NEW DELHI, Dated {loanBond2.bond2SignDate}
           
          </p>
          <p>
            <input id='signBond2' type={'checkbox'} checked={loanBond2.isBond2Signed} onChange={(e) => signBond2CheckHandler(e)} /> <label htmlFor="signBond2"> please accept the terms and conditions of the bond. you will be deemed to have signed the bond on accepting the same.</label>
          </p>
        </div>
      </fieldset>
    </div>
  );
};

export default LondBond2;
