import "./MemberNomineesDetailsViewByStaffOrAdmin.css";

import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";


import useMyAuthService from "../../../../authenticationServiceProvider/useMyAuthService";
import ProgressorBarDisplayer from "../../DialogComponents/ProgressorBarDisplayer/ProgressorBarDisplayer";
import RBMembersListDropDown from "../../RBMembersListDropDown/RBMembersListDropDown";


function MemberNomineesDetailsViewByStaffOrAdmin() {

  
  const {loggedInUserRUID, api} = useMyAuthService();
  
  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

  const [selectedMemberRUID, setSelectedMemberRUID] = useState("");

  const [rbMemberNomineesData, setRBMemberNomineesDetails] = useState([]);

  const [selectedNominee, setSelectedNominee] =useState(null);

  const [isLoadingData, setIsLoadingData] = useState(true);

  const navigate = useNavigate();

 

  const getRBMemberNomineesDetails = () => {
    api
      .get(
        `/getRBMemberNomineesDetails/${selectedMemberRUID}`
      )
      .then((response) => {
        // console.log(response.data);
        setRBMemberNomineesDetails(response.data);
        //console.log(regData);
        setIsLoadingData(false);
      });
  };

  const GetMemberNomineeDetails = ()=>{
    setOpenProgressBarDialog(true);
    getRBMemberNomineesDetails();
     setOpenProgressBarDialog(false);
  }

  return (
    <div className="MemberNomineesDetailsViewByStaffOrAdminMainDiv">
      <fieldset>
        <legend>🔍 View Member Nominees Details</legend>
        <RBMembersListDropDown 
            setSelectedMemberRUID={setSelectedMemberRUID}
            actionButtonText={"Click to View Member Nominee Details"}
            actionButtonFunction={GetMemberNomineeDetails}
          />
         <hr/>
          <div className="gridRow">
            <div className="gridCol">
              {rbMemberNomineesData.noOfNominees === 0 ? (
                "You have not declared any Nominees"
              ) : (
                <>
                  <table>
                    <thead>
                      <tr>
                        <td>Sl. No.</td>
                        <td>Nominee Name</td>
                        <td>Nominee Date of Birth</td>
                        <td>Relation with Member</td>
                        <td>Mobile No</td>
                        <td>Email</td>
                        <td>Address</td>
                        <td>Amount Payable</td>
                        <td>Nominee Heir Name</td>
                        <td>Nominee Heir DoB</td>
                        <td>Nominee Heir Relation</td>
                        {/* <td>Photo / Sign</td> */}
                      </tr>
                    </thead>
                    <tbody>
                      {rbMemberNomineesData.map((nominee, index) => {
                        return (
                          <tr key={nominee.nomineeID}>
                            <td>{index + 1}</td>
                            <td>{nominee.name}</td>
                            <td>{nominee.dateOfBirth}</td>
                            <td>{nominee.relationWithMember}</td>
                            <td>{nominee.mobileNo}</td>
                            <td>{nominee.email}</td>
                            <td>{nominee.homeAddress}</td>
                            <td>{nominee.amountPayable} %</td>
                            <td>{nominee.nomineeHeirName}</td>
                            <td>{nominee.nomineeHeirDOB}</td>
                            <td>{nominee.nomineeHeirRelationWithMember}</td>
                            {/* <td>
                              <input type={"button"} value={"🔍"} />
                            </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                 
                </>
              )}
            </div>
          </div>

          <ProgressorBarDisplayer
             titleMessage = {'Loading Member Personal Details'} openProgressBarDialog = {openProgressBarDialog} />

       
      </fieldset>
    </div>
  );
}

export default MemberNomineesDetailsViewByStaffOrAdmin;
