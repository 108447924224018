import React from 'react'

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";


const OpenDocumentDisplayer = (props) => {
    const {docTitle, docFile, openDocDisplayer, setOpenDocDisplayer} = props;
    //const [ ] = useState(false);
    
    const handleCloseDocDisplayerDialog = () =>{
        setOpenDocDisplayer(false);
    }

    
  return (
    <div>
        <div>
           
        </div>
        {/* Dialog to implement the Progess bar ................ */}
        <Dialog open={openDocDisplayer} fullWidth maxWidth="lg">
          <DialogActions id="scroll-dialog-ActionDialogActions">
            <span className="titleDialogBox">
              {docTitle}
            </span>
            <Button
              variant="outlined"
              onClick={handleCloseDocDisplayerDialog}
            >
              ❌
            </Button>
          </DialogActions>
          <DialogContent>
            <div className="pdfDisplayerDialogContentDiv">
              <iframe src={docFile}></iframe>
            </div>
          </DialogContent>
        </Dialog>
    </div>
  )
}

export default OpenDocumentDisplayer

// export const useOpenDocDisplayer = () =>{

//     return 1;
// }