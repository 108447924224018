import "./MemberBankDetailsUpdater.css";
import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import LinearProgress from "@mui/material/LinearProgress";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import useMyAuthService  from "../../../../authenticationServiceProvider/useMyAuthService";



const bankFormFields = {
  salaryBankName: " ",
  salaryBankAccountNo: " ",
  salaryBankIFSC: " ",
};

const MemberBankPaymentDetailsUpdater = (props) => {

  const {loggedInUserRUID , api} = useMyAuthService();

  const [currentLoggedInRUID, setCurrentLoggedInRUID] = useState(loggedInUserRUID);

  const [bankForm, setBankFormFieldValues] = useState(bankFormFields);
  const [Banks, setBankNames] = useState([]);

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);


  const updateType = props.updateType;

  const navigate = useNavigate();

  //getting the XML resources..........................
  useEffect(() => {
    const getXMLResources = () => {
      api
        .get(`/getXMLResources/Banks`)
        .then((response) => {
          //console.log(response.data.Bank);
          setBankNames(response.data.Bank);
        });
    };
    getXMLResources();
  }, []);

  useEffect(() => {
    getRBMemberOfficialDetails();
  }, []);

  const getRBMemberOfficialDetails = () => {
    api
      .get(
        `/getRBMemberOfficialDetails/${currentLoggedInRUID}`
      )
      .then((response) => {
        //console.log(response.data);
        //setRBMemberofficialDetails(response.data);
        setBankFormFieldValues({
          ...bankForm,
          salaryBankName: response.data.salaryBankName,
          salaryBankAccountNo: response.data.salaryBankAccountNo,
          salaryBankIFSC: response.data.salaryBankIFSC,
        });
        //console.log(regData);
        setIsLoadingData(false);
      });
  };

  const handleSubmitUpdateBankDetails = async() => {
     //console.log(personalForm);
     let emptyFields = [];

     //check if any of the properties of the personalForm  is empty.......... and dont allow to move forward
     for (var key in bankForm) {
       if (bankForm[key] === null || bankForm[key] === " ") {
         //console.log("please fill in the complete details of the following : "+key)
         emptyFields.push("Bank Form: " + key);
         // return false;
       }
     }
 
     /////////sending the data to the server id all the data is present
 
     if (emptyFields.length === 0) {
       // console.log("The lenght is "+ emptyFields.length)
       //console.log("The data being sent..................");
       //console.log({ ...personalForm, ...officialForm, ...bankForm, ...societyForm, ...nomineeForm});
       let config = {
         method: "PUT",
         url: `/UpdateRBMemberBankDetails`,
         data: { ...bankForm, currentLoggedInRUID, updateType },
         headers: {
           Accept: "application/json",
           //  Authorization: varToken
         },
         responseType: "application/json",
       };
 
       setOpenProgressBarDialog(true);
       const response = await api(config);
       setOpenProgressBarDialog(false);
       //console.log("data sent from the server.............................");
       //console.log(response.data);
       let message = response.data[0];
       navigate(-1);
     } else {
       //form is not ready..................
       // console.log(emptyFields);
       alert(
         "Please fill in all the details" +
           "\r\n" +
           emptyFields.map((field, index) => {
             return field + "\r\n";
           })
       );
     }
  };

  return (
    <div className="bankDetailsMainDiv">
      <fieldset>
        <legend>✍️ Update Your Salary Bank Details</legend>
        {isLoadingData ? (
          <div className="isLoadingDiv">Loading Data ...... </div>
        ) : (
          <>
            Salary Bank Details
            <hr />
            <div className="gridRow">
              <div className="gridCol">
                <div className="FormFieldDiv">
                  <div>
                    <label>Bank Name</label>
                  </div>
                  <div>
                    <input
                      list="bankNames"
                      value={bankForm.salaryBankName}
                      onChange={(e) =>
                        setBankFormFieldValues({
                          ...bankForm,
                          salaryBankName: e.target.value,
                        })
                      }
                    />
                    <datalist id="bankNames">
                      {Banks.map((bank, index) => {
                        return (
                          <option key={index} value={bank.Code}>
                            {bank.FullForm}
                          </option>
                        );
                      })}
                    </datalist>
                  </div>
                </div>
              </div>
              <div className="gridCol">
                <div className="FormFieldDiv">
                  <div>
                    <label>Account No.</label>
                  </div>
                  <div>
                    <input
                      type={"number"}
                      value={bankForm.salaryBankAccountNo}
                      onChange={(e) =>
                        setBankFormFieldValues({
                          ...bankForm,
                          salaryBankAccountNo: e.target.value,
                        })
                      }
                      placeholder="Enter your Bank Account No."
                    />
                  </div>
                </div>
              </div>
              <div className="gridCol">
                <div className="FormFieldDiv">
                  <div>
                    <label>IFSC Code</label>
                  </div>
                  <div>
                    <input
                      type={"text"}
                      value={bankForm.salaryBankIFSC}
                      onChange={(e) =>
                        setBankFormFieldValues({
                          ...bankForm,
                          salaryBankIFSC: e.target.value,
                        })
                      }
                      placeholder="Enter Bank IFSC Code"
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="FormActionDiv actionsDiv">
              <input
                type={"button"}
                onClick={() => handleSubmitUpdateBankDetails()}
                value="💾 Save Bank Details"
              />
            </div>

             {/* Dialog to implement the Progess bar ................ */}
             <Dialog open={openProgressBarDialog} fullWidth maxWidth="sm">
              <DialogTitle id="scroll-dialog-title">
                <b>Please wait! work under process ....</b>
              </DialogTitle>
              <DialogContent>
                <LinearProgress />
              </DialogContent>
            </Dialog>
          </>
        )}
      </fieldset>
    </div>
  );
};

export default MemberBankPaymentDetailsUpdater;
