import "./UploadRBCTCSOrdersCirculars.css";
import React, { useState } from "react";


import LinearProgress from "@mui/material/LinearProgress";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import useMyAuthService  from "../../../authenticationServiceProvider/useMyAuthService";

const UploadRBCTCSOrdersCirculars = () => {

   const {api, loggedInUserRUID, loggedInUserName} = useMyAuthService();

  const [orderTitle, setOrderTitle] = useState();
  const [orderDate, setOrderDate] = useState();
  const [orderUploader, setOrderUploader] = useState(
    loggedInUserRUID
  );
  
  const [orderFileDetails, setOrderFileDetails] = useState();

  const [errorMessage, setErrorMessage] = useState();
    const [message, setMessage] = useState();

  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);


  const handleFileChange = (e) =>{
    if (e.target.files[0]) {
     
      setOrderFileDetails(e.target.files[0])
       
    }
  }

   const handleSubmitUploadOrdersCirculars = async (e) => {
     e.preventDefault();
     //console.log("form has been submitted");
     if (orderTitle === "" || orderDate === "" || orderFileDetails === "") {
       setErrorMessage("Please fill in all the details");
     } else {

       const fd = new FormData();
       fd.append("orderTitle", orderTitle);
       fd.append("orderDate", orderDate);
       fd.append("orderUploader", orderUploader);
       fd.append("orderFileName", orderFileDetails.name);
       fd.append("orderFile", orderFileDetails);
       
       //console.log(data);
       let config = {
         headers: {
           Accept: "multipart/FormData",

           //  Authorization: varToken
         },
         responseType: "json",
       };

       setOpenProgressBarDialog(true);

       api
         .post(`/UploadOrdersCirculars`, fd, config)
         .then((response) => {
           //console.log(response.data);
           console.log(response.data);
           setOpenProgressBarDialog(false);
          
         });
     }
   };


  return (
    <div className="UploadRBCTCSOrdersCirculgarsMainDiv">
      <fieldset>
        <legend>🚀 Upload Circulars and Orders : RBCTCS</legend>
        <form onSubmit={(e) => {
            handleSubmitUploadOrdersCirculars(e);
          }}>
          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Order Title</label>
              </div>
              <div>
                <textarea
                  rows={4}
                  cols={52}
                  placeholder=" Enter Your Order Title"
                  min="1"
                  onChange={(e) => {
                    setOrderTitle(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="gridRow">
              <div className="FormFieldDiv">
                <div>
                  <label>Order Date</label>
                </div>
                <div>
                  <input
                    type={"date"}
                    placeholder="👤 Enter Your Order Date"
                     onChange={(e) => {
                      setOrderDate(e.target.value);
                    }}
                    required
                  />
                </div>
              </div>

              <div className="FormFieldDiv">
                <div>
                  <label>Order Uploader</label>
                </div>
                <div>
                  <input
                    type={"text"}
                    placeholder="👤 Enter Your Name"
                    value={loggedInUserName}
                    readOnly
                  />
                </div>
              </div>
            </div>

            <div className="FormFieldDiv">
              <div>
                <label>Order File</label>
              </div>
              <div>
                <input
                  accept=".pdf"
                  type={"file"}
                  placeholder=" Enter Your Order Date"
                  onChange={(e) => {
                    handleFileChange(e);
                  }}
                  required
                />
              </div>
            </div>
            <hr />
            <div className="FormActionDiv">
              <input type={"button"} value="Clear Form" />
              <input type={"submit"} value="💾 Save and Upload" />
            </div>
          </div>
        </form>
      </fieldset>

       {/* Dialog to implement the Progess bar ................ */}
       <Dialog
        open={openProgressBarDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="scroll-dialog-title">
          <b>Please wait! work under process ....</b>
        </DialogTitle>
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UploadRBCTCSOrdersCirculars;
