import React, { useState } from "react";
import { Button } from "@mui/material";
import "./LoginBox.css";
import { Link, useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import useMyAuthService from "../../authenticationServiceProvider/useMyAuthService";
import { useTokenCreation } from "../../authenticationServiceProvider/useTokenCreation";

// import bcrypt from "bcryptjs";

// Define initial state for user data
const initialUserData = {
  userID: " ",
  userRole: "Member",
  userPassword: " ",
};

const LoginBox = () => {
  const navigate = useNavigate();
  const { loggedInUserRole } = useMyAuthService();
  const handleTokenCreation = useTokenCreation();

  // State to manage user data before login
  const [userDataBeforeLogIn, setUserDataBeforeLogIn] =
    useState(initialUserData);

  // State to manage success/failure messages
  const [message, setErrorMessage] = useState(" ");

  // State to control progress bar dialog visibility
  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    setOpenProgressBarDialog(true);

    // Call handleTokenCreation function to create the token and handle login
    await handleTokenCreation(
      userDataBeforeLogIn.userID,
      userDataBeforeLogIn.userPassword,
      userDataBeforeLogIn.userRole
    )
      .then((response) => {
        //console.log("From Login Box :-"+ JSON.stringify(response.status));
        setOpenProgressBarDialog(false);
        //console.log(userDataBeforeLogIn.userRole);
        // Handle login based on user role
        switch (userDataBeforeLogIn.userRole) {
          case "New User":
            //console.log(loggedInUserRole);
            navigate("/NewUserDashBoard", { replace: true });
            break;
          case "Member":
            navigate("/MemberDashBoard", { replace: true });

            break;
          case "Staff":
            navigate("/StaffRBCTCSDashBoard", { replace: true });

            break;
          case "Admin-AA":
            navigate("/AdminDashBoard", { replace: true });

            break;
          case "Admin-OB":
            navigate("/AdminDashBoard", { replace: true });

            break;
          default:
            break;
        }
      })
      .catch((error) => {
        //   //console.error("Token creation failed:", error);
        //   setOpenProgressBarDialog(false);
        if (error.response) {
          if (error.response.status === 401) {
            setErrorMessage(
              "Unauthorized: Please log in with valid credentials."
            );
          } else if (error.response.status === 403) {
            setErrorMessage(
              "Forbidden: You do not have permission to access this resource."
            );
          } else if (error.response.status === 500) {
            setErrorMessage(
              "Internal Server Error: Something went wrong on the server."
            );
          } else {
            // Handle other errors, such as network issues
            setErrorMessage("An error occurred. Please try again later.");
          }
        }

        setOpenProgressBarDialog(false);
        //   //throw error; // Rethrow the error to handle it in the calling function if needed
        //   //setErrorMessage(error);
      });
  };

  // render() {
  return (
    <div className="loginBoxDiv">
      <fieldset>
        <legend>🔐 LOG INTO RBCTCS PORTAL</legend>
        <div>{message}</div>
        <div>
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <table>
              <tbody>
                <tr>
                  <td>
                    <input list="login" value={userDataBeforeLogIn.userRole}
                      onChange={(e) => {
                        setUserDataBeforeLogIn({
                          ...userDataBeforeLogIn,
                          userRole: e.target.value,
                        });
                      }}
                    />
                      <datalist id="login">
                       <option value="Member">RBCTCS Member</option>
                      <option value="New User">RBCTCS New User</option>
                      <option value="Staff">RBCTCS Staff</option>
                      <option value="Admin-AA">
                        RBCTCS Admin - Approval Authority
                      </option>
                      <option value="Admin-OB">
                        RBCTCS Admin - Office Bearer
                      </option>
                      </datalist>
                     
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      placeholder="👤 Enter User RUID/StaffId/AdminId"
                      type={"text"}
                      autoComplete="Off"
                      onChange={(e) => {
                        setUserDataBeforeLogIn({
                          ...userDataBeforeLogIn,
                          userID: e.target.value,
                        });
                      }}
                      id="userID"
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      placeholder="🔑 Enter Password"
                      type={"password"}
                      autoComplete="Off"
                      onChange={(e) => {
                        setUserDataBeforeLogIn({
                          ...userDataBeforeLogIn,
                          userPassword: e.target.value,
                        });
                      }}
                      id="password"
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <Button variant="contained" type="button" color="inherit">
                      {"RESET"}
                    </Button>
                    <span>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    <Button variant="contained" type="submit">
                      {"LOG INTO RBCTCS"}
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <hr />
                  </td>
                </tr>
                <tr className="centerContent" colSpan={2}>
                  <td>
                    <Link to="/RegisterNewUser" underline="none">
                      <Button variant="contained" type="button" color="inherit">
                        {"Register New User"}
                      </Button>
                    </Link>
                    <span>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    <Link to="/ForgotPassword" underline="none">
                      <Button variant="contained" type="button" color="inherit">
                        {"Forgot Password"}
                      </Button>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </fieldset>

      {/* Dialog to implement the Progess bar ................ */}
      <Dialog open={openProgressBarDialog} fullWidth maxWidth="sm">
        <DialogTitle id="scroll-dialog-title">
          <b>Please wait! Logging In ....</b>
        </DialogTitle>
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      </Dialog>
    </div>
  );
  // }
};

LoginBox.propTypes = {};

LoginBox.defaultProps = {};

export default LoginBox;
