import React, { useState, useEffect } from "react";
import "./MemberPersonalDetailsUpdater.css";

import { useNavigate } from "react-router-dom";

import LinearProgress from "@mui/material/LinearProgress";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import useMyAuthService  from "../../../../authenticationServiceProvider/useMyAuthService";


const personalFormFields = {
  name: " ",
  fatherName: " ",
  spouseName:" ",
  gender:" ",
  dateOfBirth: " ",
  email: " ",
  mobile: " ",
  panNo: " ",
  residentialAddress: " ",
  permanentAddress: " ",
};

const MemberPersonalDetailsUpdater = (props) => {

   const {api, loggedInUserRUID} = useMyAuthService();
  const [currentLoggedInRUID, setCurrentLoggedInRUID] = useState(loggedInUserRUID);

  const [personalForm, setFormFieldValues] = useState(personalFormFields);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

  const updateType = props.updateType;
   
  //console.log(props.updateType);

  const navigate = useNavigate();

  useEffect(() => {
    getRBMemberPersonalDetails();
  }, []);

  const getRBMemberPersonalDetails = () => {
    api
      .get(
        `/getRBMemberPersonalDetails/${currentLoggedInRUID}`
      )
      .then((response) => {
       // console.log(response.data);
        setFormFieldValues({
          ...personalForm,
          name: response.data.name,
          fatherName: response.data.fatherName,
          mobile: response.data.users.mobile,
          email: response.data.users.email,
          residentialAddress: response.data.homeAddress,
          permanentAddress: response.data.permanentAddress,
          panNo: response.data.panNo,
          dateOfBirth: response.data.dateOfBirth,
          spouseName: response.data.spouseName,
          gender: response.data.gender,
        });
        //console.log(regData);
        setIsLoadingData(false);
      });
  };

  const handleSubmitUpdatePersonalDetails = async () => {
      
      //console.log(personalForm);
      // if (emptyFields.length === 0) {
      // console.log("The lenght is "+ emptyFields.length)
      //console.log(updateType);
      //console.log({ ...personalForm, ...officialForm, ...bankForm, ...societyForm, ...nomineeForm});
      let config = {
        method: "PUT",
        url: `/UpdateRBMemberPersonalDetails`,
        data: { ...personalForm, currentLoggedInRUID, updateType },
        headers: {
          Accept: "application/json",
          //  Authorization: varToken
        },
        responseType: "application/json",
      };

      setOpenProgressBarDialog(true);
      const response = await api(config);
      setOpenProgressBarDialog(false);
      //console.log("data sent from the server.............................");
      //console.log(response.data);
      let message = response.data[0];
      navigate(-1, {message});
    
  };

  return (
    <div className="personalDetailsMainDiv">
      <fieldset>
        <legend>✍️ Update Your Personal Details</legend>
        {isLoadingData ? (
          <div className="isLoadingDiv">Loading Data ...... </div>
        ) : (
          <>
            <div className="gridRow">
              <div className="gridCol">
                <div className="FormFieldDiv">
                  <div>
                    <label>Name</label>
                  </div>
                  <div>
                    <input
                      type={"text"}
                      value={personalForm.name}
                      onChange={(e) =>
                        setFormFieldValues({
                          ...personalForm,
                          name: e.target.value,
                        })
                      }
                      placeholder="Enter your name"
                    />
                  </div>
                </div>
                <div className="FormFieldDiv">
                  <div>
                    <label>Father's Name</label>
                  </div>
                  <div>
                    <input
                      type={"text"}
                      value={personalForm.fatherName}
                      onChange={(e) =>
                        setFormFieldValues({
                          ...personalForm,
                          fatherName: e.target.value,
                        })
                      }
                      placeholder="Enter your father's name"
                    />
                  </div>
                </div>
                <div className="FormFieldDiv">
                  <div>
                    <label>Mobile No.</label>
                  </div>
                  <div>
                    <input
                      type={"text"}
                      value={personalForm.mobile}
                      onChange={(e) =>
                        setFormFieldValues({
                          ...personalForm,
                          mobile: e.target.value,
                        })
                      }
                      placeholder="Enter your mobile no"
                    />
                  </div>
                </div>
                <div className="FormFieldDiv">
              <div>
                <label>Spouse's Name</label>
              </div>
              <div>
                <input
                  type={"text"}
                  value={personalForm.spouseName}
                  onChange={(e) =>
                    setFormFieldValues({
                      ...personalForm,
                      spouseName: e.target.value,
                    })
                  }
                  placeholder="Enter your spouse's name"
                />
              </div>
            </div>
                <div className="FormFieldDiv">
                  <div>
                    <label>Residential Address</label>
                  </div>
                  <div>
                    <textarea
                      rows="5"
                      cols="40"
                      value={personalForm.residentialAddress}
                      onChange={(e) =>
                        setFormFieldValues({
                          ...personalForm,
                          residentialAddress: e.target.value,
                        })
                      }
                    ></textarea>
                  </div>
                </div>
              </div>

              <div className="gridCol">
                <div className="FormFieldDiv">
                  <div>
                    <label>Date of Birth</label>
                  </div>
                  <div>
                    <input
                      type={"date"}
                      value={personalForm.dateOfBirth}
                      onChange={(e) =>
                        setFormFieldValues({
                          ...personalForm,
                          dateOfBirth: e.target.value,
                        })
                      }
                      placeholder="Enter your date of birth"
                    />
                  </div>
                </div>

                <div className="FormFieldDiv">
                  <div>
                    <label>Email Id</label>
                  </div>
                  <div>
                    <input
                      type={"email"}
                      value={personalForm.email}
                      onChange={(e) =>
                        setFormFieldValues({
                          ...personalForm,
                          email: e.target.value,
                        })
                      }
                      placeholder="Enter your email id"
                    />
                  </div>
                </div>
                <div className="FormFieldDiv">
                  <div>
                    <label>Pan No.</label>
                  </div>
                  <div>
                    <input
                      type={"text"}
                      value={personalForm.panNo}
                      onChange={(e) =>
                        setFormFieldValues({
                          ...personalForm,
                          panNo: e.target.value,
                        })
                      }
                      placeholder="Enter your pan no."
                    />
                  </div>
                </div>
                <div className="FormFieldDiv">
              <div>
                <label>Gender</label>
              </div>
              <div>
              <input list="genders" value={personalForm.gender}
                    onChange={(e) =>
                      setFormFieldValues({
                        ...personalForm,
                        gender: e.target.value,
                      })
                    }/>
                <datalist id="genders">
                  <option  value={'Male'}>Male</option>
                  <option  value={'Female'}>Female</option> 
                  <option  value={'Others'}>Others</option>
                </datalist>
              </div>
            </div>
                <div className="FormFieldDiv">
                  <div>
                    <label>Permanent Address</label>
                  </div>
                  <div>
                    <textarea
                      rows="5"
                      cols="40"
                      value={personalForm.permanentAddress}
                      onChange={(e) =>
                        setFormFieldValues({
                          ...personalForm,
                          permanentAddress: e.target.value,
                        })
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            {/* <hr></hr> */}
            <div className="FormActionDiv actionsDiv">
              <input
                type={"button"}
                onClick={() => handleSubmitUpdatePersonalDetails()}
                value="💾 SAVE UPDATED DETAILS"
              />
            </div>

            {/* Dialog to implement the Progess bar ................ */}
            <Dialog open={openProgressBarDialog} fullWidth maxWidth="sm">
              <DialogTitle id="scroll-dialog-title">
                <b>Please wait! work under process ....</b>
              </DialogTitle>
              <DialogContent>
                <LinearProgress />
              </DialogContent>
            </Dialog>
          </>
        )}
      </fieldset>
    </div>
  );
};

export default MemberPersonalDetailsUpdater;
