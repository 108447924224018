import React, { useEffect, useState } from "react";
import "./ViewSuretiesReceived.css";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import useMyAuthService from "../../../authenticationServiceProvider/useMyAuthService";
import DialogContainer from "../DialogComponents/DialogContainer/DialogContainer";
import RBMembersListDropDown from "../RBMembersListDropDown/RBMembersListDropDown";

const ViewSuretiesGivenByMember = () => {
  const { api } = useMyAuthService();

  const [selectedMemberRUID, setSelectedMemberRUID] = useState("");
  const [suretyRequests, setSuretyRequests] = useState([]);

  const [updateForReRender, setUpdateForReRender] = useState(false);

  

  async function GetMemberLoanApplicationsWithSureties() {
    const data = {
        requestStatus:"Accepted",
        ruidLoggedIn:selectedMemberRUID
      }

    let config = {
      method: "POST",
      url: `/GetLoanApplicationsSeekingSurety`,
         data:{data},
      headers: {
        Accept: "application/json",

        //  Authorization: varToken
      },
      responseType: "json",
    };

    //console.log(config);
    const response = await api(config);
    //console.log(response.data)
    setSuretyRequests(response.data);

  }

  return (
    <div className="tabPanelMainDiv">
      <fieldset className="scrollWithoutScrollBar">
        <legend>📑 Loan Sureties : View the Loan Sureties Given By a Particular Member</legend>
        <div className="tabPanelMainDiv">
          <RBMembersListDropDown 
             setSelectedMemberRUID={setSelectedMemberRUID}
             actionButtonText={"Click to View Sureties By Member"}
             actionButtonFunction={GetMemberLoanApplicationsWithSureties}
          />
          <hr />

          <LoanSuretyReqTable
            suretyRequests={suretyRequests}
            tableIndex={1}
            updateForReRender={updateForReRender}
            setUpdateForReRender={setUpdateForReRender}
            api={api}
          />
        </div>
      </fieldset>
    </div>
  );
};

export default ViewSuretiesGivenByMember;

function LoanSuretyReqTable(props) {
  const {
    suretyRequests,
    tableIndex,
    updateForReRender,
    setUpdateForReRender,
    api,
  } = props;

  const [anchorActionsMenu, setAnchorActionsMenu] = React.useState(null);
  const open = Boolean(anchorActionsMenu);

  const [openManageSuretyDialog, setOpenManageSuretyDialog] =
    React.useState(false);

  const [selectedSuretyRequestForSubmit, setSelectedSuretyRequestForSubmit] =
    useState({});

  const [suretyProvider, setSuretyProvider] = useState({});
  const [openSuretyProviderDialog, setOpenSuretyProviderDialog] =
    React.useState(false);

  const handleActionMenuOpen = (event, suretyReq) => {
    setSelectedSuretyRequestForSubmit(suretyReq);
    setAnchorActionsMenu(event.currentTarget);
  };

  const handleActionMenuClose = () => {
    setAnchorActionsMenu(null);
  };

  const handleMenuAcceptRejectSurety = () => {
    setAnchorActionsMenu(null);
    setOpenManageSuretyDialog(true);
  };

  const handleCloseManageSuretyRequestDialog = () => {
    setOpenManageSuretyDialog(false);
  };

  const handleCloseSuretyProviderDialog = () => {
    setOpenSuretyProviderDialog(false);
  };

  const handleSubmitManageSuretyRequest = async (requestStatus) => {
    const data = {
      requestStatus: requestStatus,
      loanSuretyTableId: selectedSuretyRequestForSubmit.id,
    };
    //console.log(data);
    let config = {
      method: "PUT",
      url: `/UpdateSuretyRequestStatus`,
      data: { data },
      headers: {
        Accept: "application/json",
      },
      responseType: "json",
    };

    const response = await api(config);
    //console.log(response);
    setUpdateForReRender(!updateForReRender);
    setOpenManageSuretyDialog(false);
    // console.log(response.data);
  };

  const getSuretyProviderDetails = async (ledgerNo) => {
    try {
      // Encode the ledger number so as to handle the slash in the ledger no
      const formattedLedgerNo = ledgerNo.replace("/", "_");

      let config = {
        method: "GET",
        url: `/GetRBMemberDetailsByLedgerNo/${formattedLedgerNo}`,
        headers: {
          Accept: "application/json",
        },
        responseType: "json",
      };

      const response = await api(config);
      //console.log("API Response:", response.data);

      setSuretyProvider(response.data);
      setOpenSuretyProviderDialog(true);
    } catch (error) {
      console.error("API Error:", error);
      // Handle the error, e.g., show an error message to the user
    }
  };

  return (
    <>
      <table>
        <thead>
          <tr>
            <td>Sl.</td>
            <td>Surety Seeker/Your Name</td>
            <td>Surety Provider </td>
            <td>Loan Id</td>
            <td>Loan Type</td>
            <td>Loan Amount</td>
            <td>Loan Purpose</td>
            <td>Request Status</td>
            {tableIndex === 0 ? <td>Actions</td> : ""}
          </tr>
        </thead>
        <tbody>
          {Object.keys(suretyRequests).map((key, index) => {
            return (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>
                  {suretyRequests[key].loan_applications.rbmembers.name} (
                  {suretyRequests[key].rbMemberLedgerNo})
                </td>
                <td>
                  <input
                    type="button"
                    value={suretyRequests[key].suretyLedgerNo}
                    onClick={() =>
                      getSuretyProviderDetails(
                        suretyRequests[key].suretyLedgerNo
                      )
                    }
                  />
                </td>
                <td>
                  {suretyRequests[key].loan_applications.loanApplicationId}
                </td>
                <td>{suretyRequests[key].loan_applications.loanType}</td>
                <td>
                  {suretyRequests[key].loan_applications.loanAmountSought}
                </td>
                <td>{suretyRequests[key].loan_applications.loanPurpose}</td>
                <td>{suretyRequests[key].suretyRequestStatus}</td>
                {tableIndex === 0 ? (
                  <td>
                    {
                      <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={(e) =>
                          handleActionMenuOpen(e, suretyRequests[key])
                        }
                      >
                        ⚙️
                      </Button>
                    }{" "}
                    {/* this is the actions menu activated on the button click to take actions on a particular selected Loan */}
                    <Menu
                      anchorEl={anchorActionsMenu}
                      open={open}
                      onClose={handleActionMenuClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={handleMenuAcceptRejectSurety}>
                        💡 Accept/Reject Surety Requests
                      </MenuItem>
                    </Menu>
                  </td>
                ) : (
                  ""
                )}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Dialog to implement the verification logic for the submitted application ................ */}
      <Dialog
        open={openManageSuretyDialog}
        onClose={handleCloseManageSuretyRequestDialog}
        //scroll={body}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="scroll-dialog-title">
          <div className="dialogTitle">
            Submit Acceptance/Rejection for Surety Request
          </div>
        </DialogTitle>
        <DialogContent>
          {/* get the nominee table here */}
          <div className="finalVerificationSubmitDialogDiv">
            <p>
              To Accept the Surety Request, Please Click "Accepted" button
              otherwise click "Rejected" button to Reject the Surety Request.
            </p>
            <div className="FormActionDiv">
              <button
                onClick={() => handleSubmitManageSuretyRequest("Rejected")}
              >
                ❌ Rejected
              </button>
              <button
                onClick={() => handleSubmitManageSuretyRequest("Accepted")}
              >
                ✔️ Accepted
              </button>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseManageSuretyRequestDialog}>
            ❌ Close
          </Button>
        </DialogActions>
      </Dialog>

      <DialogContainer
        titleMessage={"Surety Provider Details"}
        maxWidth={"sm"}
        openDialogBox={openSuretyProviderDialog}
        handleCloseDialog={handleCloseSuretyProviderDialog}
      >
        <div className="suretyProviderDisplayDiv">
          <table className="suretyProviderDisplayTable">
            <tr>
              <td>Name :- </td>
              <td>{suretyProvider.name}</td>
            </tr>
            <tr>
              <td>RUID :- </td>
              <td>{suretyProvider.rbMemberRUID}</td>
            </tr>
          </table>
        </div>
      </DialogContainer>
    </>
  );
}
