import { useState, useEffect } from "react";
import useMyAuthService from "../authenticationServiceProvider/useMyAuthService";

const MembershipVerificationsDataLoaderFunctions = (
 
  applicationStatus,
  setOpenProgressBarDialog,
  updated
) => {
  //getting the server base route to fetch the data from the server........
 // const apiServerRoute = useGetAPIServer();

  const {api} = useMyAuthService();
  //variable to hold the incoming data from the server.......
  const [memberApplicationDetails, setMemberApplicationDetails] = useState([]);

  //flag to decide if the data is loaded..............................
  const [isLoadingData, setIsLoadingData] = useState(true);


  //console.log("The api object "+api);

  useEffect(() => {
    getMembershipApplicationsForVerification();
  }, [applicationStatus, updated]);

  const getMembershipApplicationsForVerification = () => {
   
    const url = `/ViewMembershipApplicationsForVerification/${applicationStatus}`;
    api.get(url).then((response) => {
      //console.log(response.data);
      setOpenProgressBarDialog(true);
      setMemberApplicationDetails(response.data);
      setIsLoadingData(false);
      setOpenProgressBarDialog(false);
    });
  };

  // Return any additional data or functions as needed
  return { memberApplicationDetails, isLoadingData };
};

export default MembershipVerificationsDataLoaderFunctions;
