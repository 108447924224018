import React, { useEffect, useState } from "react";
import "./ViewMembershipApplicationStatus.css";

import { useNavigate } from "react-router-dom";

import useMyAuthService from "../../../authenticationServiceProvider/useMyAuthService";

import { formatDate } from "../../../ComonFunctionsProvider/CommonFunctionsProvider";
import MembershipApplicationStatusExplanation from "./MembershipApplicationStatusExplanation/MembershipApplicationStatusExplanation";

function ViewMembershipApplicationStatus() {
  const navigate = useNavigate();

  const { loggedInUserRUID, api } = useMyAuthService();

  const [memberDetails, setmemberDetails] = useState({});
  const [memberApplicationDetails, setmemberApplicationDetails] = useState({});
  const [partMarkedForReview, setPartMarkedForReview] = useState();

  const [isLoadingData, setIsLoadingData] = useState(true);

  const [isDataExisting, setIsDataExisting] = useState();

  useEffect(() => {
    getApplicationStatus();
  }, []);

  const getApplicationStatus = () => {
    api
      .get(`/ViewMembershipApplicationStatus/${loggedInUserRUID}`)
      .then((response) => {
        //if (response.data === '') {
        // console.log(response.data);
        if (response.data === "" || response.data.rbmember === null) {
          //no application exists
          //console.log(response.data);
          setIsLoadingData(false);
          setIsDataExisting(false);
        } else {
          //alert('hello')
          setIsDataExisting(true);
          setmemberDetails(response.data);
          setmemberApplicationDetails(response.data);
          setPartMarkedForReview(JSON.parse(response.data.partMarkedForReview));

          // console.log(
          //   response.data.partMarkedForReview.applicationPartForReview
          // );
          setIsLoadingData(false);
        }

        //console.log(partMarkedForReview);
      });
  };

  const handleButtonPartMarkForReview = (partName) => {
    //alert(partName);
    switch (partName) {
      case "Personal_Details":
        navigate("../ReviewPersonalDetails");
        break;
      case "Official_Details":
        navigate("../ReviewOfficialDetails");
        break;
      case "Bank_Details":
        navigate("../ReviewBankDetails");
        break;
      case "Document_Uploads":
        navigateToDocumentUploadDetails("Review");
        break;
      case "Other_Society_Details":
        navigate("../ReviewOtherSocietyDetails");
        break;
      case "Nominee_Details":
        navigate("../ReviewNomineeDetails");
        break;
      default:
        break;
    }
  };

  const navigateToUploadPaymentDetails = (applicationId) => {
    navigate("../UploadPaymentDetails&SalarySlip", {
      state: { applicationId: applicationId },
    });
  };

  const navigateToDocumentUploadDetails = (updateTypeParam) => {
    api
      .get(`/GetMembershipApplicationFormDetails/${loggedInUserRUID}`)
      .then((response) => {
        //console.log(response.data);
        navigate("../UploadMembershipDocumentation", {
          state: { memberDetails: response.data ,
            updateType: updateTypeParam // Add updateType with value "review" in the instance of the part marked for review other wise it iwll be original
          },
        });
      });
  };

  const navigateToUpdatePaymentDetails = (applicationId) => {
    navigate("../UpdatePaymentDetails&SalarySlip", {
      state: { applicationId: applicationId },
    });
  };

  return (
    <div className="ViewMembershipApplicationStatusMainDiv">
      <fieldset>
        <legend>📜 Your Application Status</legend>
        {!isLoadingData ? (
          !isDataExisting ? (
            <div className="noApplicationSubmitted">"No Applications have been submitted. Please Apply for new membership"
               <p>Membership Application will be entertained only from first to tenth of every month. So, kindly apply between first and tenth of a month only.</p>
            </div>
          ) : (
            <>
              <div>
                <table className="membershipApplicationStatusTable">
                  <thead>
                    <tr>
                      <td>Sl No.</td>
                      <td>RUID</td>
                      <td>Name</td>
                      <td>Application Date</td>
                      <td>Application status</td>
                      {memberApplicationDetails.approvalStatus === "MASDP" ? (
                        <td>Documentation</td>
                      ) : (
                        ""
                      )}
                      {memberApplicationDetails.approvalStatus === "MASPP" ? (
                        <td>New Membership Fees</td>
                      ) : (
                        ""
                      )}
                      {memberApplicationDetails.approvalStatus === "MASPR" ? (
                        <td>Update Fees Details</td>
                      ) : (
                        ""
                      )}
                      {memberApplicationDetails.approvalStatus === "MAMR" ? (
                        <><td>Parts Marked For Review</td>
                        <td>Remarks</td>
                        </>
                        
                      ) : (
                        ""
                      )}
                      <td>Verified By</td>
                      <td>Approved By</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>{memberDetails.rbMemberRUID}</td>
                      <td>{memberDetails.rbmembers.name}</td>
                      <td>
                        {formatDate(
                          memberApplicationDetails.dateOfMembershipApplication
                        )}
                      </td>
                      <td>{memberApplicationDetails.approvalStatus}</td>
                      {memberApplicationDetails.approvalStatus === "MASDP" ? (
                        <td>
                          <input
                            type="button"
                            value={"Documents Upload"}
                            onClick={() => {
                              navigateToDocumentUploadDetails(
                                "Original"
                              );
                            }}
                          />
                        </td>
                      ) : (
                        ""
                      )}

                      {memberApplicationDetails.approvalStatus === "MASPP" ? (
                        <td>
                          <input
                            type="button"
                            value={"Make Payment"}
                            onClick={() => {
                              navigateToUploadPaymentDetails(
                                memberApplicationDetails.applicationId
                              );
                            }}
                          />
                        </td>
                      ) : (
                        ""
                      )}

                      {memberApplicationDetails.approvalStatus === "MASPR" ? (
                        <td>
                          <input
                            type="button"
                            value={"Update Payment"}
                            onClick={() => {
                              navigateToUpdatePaymentDetails(
                                memberApplicationDetails.applicationId
                              );
                            }}
                          />
                        </td>
                      ) : (
                        ""
                      )}
                      {memberApplicationDetails.approvalStatus === "MAMR" ? (
                        <>
                          <td>
                          <ul>
                            {Object.entries(partMarkedForReview).map(
                              ([key, index]) => {
                                return partMarkedForReview[key] ? (
                                  <li key={key} className="FormActionDiv">
                                    <input
                                      type={"button"}
                                      onClick={() =>
                                        handleButtonPartMarkForReview(key)
                                      }
                                      value={key}
                                    />
                                  </li>
                                ) : (
                                  ""
                                );
                              }
                            )}
                          </ul>
                        </td>
                        <td>
                         {memberApplicationDetails.reviewRemarks}
                        </td>
                        </>
                        
                      ) : (
                        ""
                      )}
                      <td>{memberApplicationDetails.MVSRUID}</td>
                      <td>{memberApplicationDetails.MAAID}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr/>
              <div>
                 <MembershipApplicationStatusExplanation applicationStatus={memberApplicationDetails.approvalStatus}/>
              </div>
            </>
          )
        ) : (
          <div className="isLoadingDiv">Loading Data ......</div>
        )}
      </fieldset>
    </div>
  );
}

export default ViewMembershipApplicationStatus;
