import React from "react";
import "./WriteToRBCTCS.css";
import { Button, Stack } from "@mui/material";

import { useState } from "react";
import axios from "axios";

import LinearProgress from "@mui/material/LinearProgress";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { useGetAPIServer } from "../../BackEndServerServiceProvider/BackEndServerServiceProvider";

const WriteToRBCTCS = () =>{

  const apiServer = useGetAPIServer();

   /** The data elements of the form to be stored in these variables */
   const [writerName, setWriterName] = useState('');
   const [subject, setSubject] = useState('');
   const [content, setContent] = useState('');
   const [email, setEmail] = useState('');
   
   const [errorMessage, setErrorMessage] = useState();
   const [message, setMessage] = useState();

   const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

  const handleSubmitWriteToRBCTCS = async (e) => {
        //alert("hello world");
        e.preventDefault();
       // console.log("form has been submitted");
        if(writerName === "" || subject ==="" || email ==="" || content ==="" ){
          setErrorMessage("Please fill in all the details")
       }else{
              
                      const data = {"writerName": writerName, "subject":subject, "content":content, "email":email};
                      //console.log(data);
                      let config = {
                          method: "POST",
                          url: `${apiServer.serverBaseRoute}/WriteToRBCTCS`,
                          data:{data},
                          headers: {
                            Accept: "application/json",
                           
                            //  Authorization: varToken
                          },
                          responseType: "json",
                        };
                         
                        setOpenProgressBarDialog(true);
                        const response = await axios(config);
                        //console.log(response.data);
                        setMessage(response.data);
                        setOpenProgressBarDialog(false);
              
       } 
  }


  return(
    <div className="WriteToRBCTCS">
    <fieldset>
      <legend>✍️ Write to RBCTCS</legend>
      <div className="errorDiv">
            {errorMessage}
        </div>
        <div className="msgDiv">
            {message}
        </div>
      <form onSubmit={(e) => {
            handleSubmitWriteToRBCTCS(e);
          }}>
        <Stack direction={"column"}>
          <input type={'text'} value={writerName} onChange={(e) => {setWriterName(e.target.value);}} placeholder={'Enter Your Name'}/>
          <input type={'text'} value={email} onChange={(e) => {setEmail(e.target.value);}} placeholder={'Enter Your Email Id'}/>
          <input type={'text'} value={subject} onChange={(e) => {setSubject(e.target.value);}} placeholder={'Enter Subject'}/>
          
          <textarea
            label="Your Message here"
            rows={8}
            cols={60}
            onChange={(e) => {setContent(e.target.value);}}
          ></textarea>
          <div className="actionButton">
            <Button variant="contained" sx={{ m: 1, width: "30ch" }} type={'submit'}>
              {"Send Message"}
            </Button>
          </div>
        </Stack>
      </form>
    </fieldset>
    {/* Dialog to implement the Progess bar ................ */}
    <Dialog
        open={openProgressBarDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="scroll-dialog-title">
          <b>Please wait! work under process ....</b>
        </DialogTitle>
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      </Dialog>
      
  </div>
  );
}

export default WriteToRBCTCS;
