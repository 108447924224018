import React from "react";
import "./MemberLoanApplicationStatusExplanation.css";

const MemberLoanApplicationStatusExplanation = ({ applicationStatus }) => {
  const getStatusExplanation = () => {
    if (applicationStatus === undefined) {
      applicationStatus = "";
    }
    switch (applicationStatus) {
      case "LAS":
        return {
          explanation:
            "Member's Loan Application Submitted for Verification and Approval",
          fullForm: "LAS: Member's Loan Application Submitted",
        };
      case "LADV":
        return {
          explanation: "Loan Application Details Verified",
          fullForm:
            "LADV: Loan Application Details have been Verified by the RBCTCS staff",
        };
      case "LAMR":
        return {
          explanation: "Loan Application Marked For Review",
          fullForm:
            "LAMR: Loan Application is Marked for Review and Resubmit after due corrections",
        };
      case "LARS":
        return {
          explanation: "Loan Application Re-Submitted",
          fullForm: "LARS: Loan Application Re-Submitted with due corrections",
        };
      case "LAPA":
        return {
          explanation: "Loan Application Partially Approved",
          fullForm: "LAPA: Loan Application Partially Approved",
        };
      case "LAA":
        return {
          explanation: "Loan Application Approved and ready for disbursal",
          fullForm: "LAPA: Loan Application Approved",
        };

      default:
        return {
          explanation:
            "If you have a Loan Application, select any of them to see the status.",
          fullForm: "",
        };
    }
  };

  const { explanation, fullForm } = getStatusExplanation();

  return (
    <div className="container">
      <p>
        Your Application Status:{" "}
        <u>
          <strong>{applicationStatus}</strong>
        </u>
      </p>
      <hr />
      <p className="full-form">Full Form: {fullForm}</p>
      <p className="explanation">Explanation: {explanation}</p>
    </div>
  );
};

export default MemberLoanApplicationStatusExplanation;
