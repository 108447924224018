import React, { useEffect, useState } from "react";
import "./ViewSharesPurchaseRequestsStatus.css";

import useMyAuthService from "../../../../authenticationServiceProvider/useMyAuthService";
import OpenDocumentDisplayer from "../../DialogComponents/OpenDocumentDisplayer/OpenDocumentDisplayer";

const ViewSharesPurchaseRequestsStatus = () => {
  const { loggedInUserRUID, api } = useMyAuthService();

  const [sharesApplicationDetails, setSharesApplicationDetails] = useState({});

  const [isLoadingData, setIsLoadingData] = useState(true);

  const [isDataExisting, setIsDataExisting] = useState();

  const [docFile, setDocFile] = useState();
  const [
    openChequeOnlineReceiptDisplayer,
    setOpenChequeOnlineReceiptDisplayer,
  ] = useState(false);

  useEffect(() => {
    getApplicationStatus();
  }, []);

  //function to get the Check / Online Receipt of the Pyaments made...........
  const handleOpenChequeOnlineReceiptForSharesPurchaseOfParticularMember =
    async (paymentRequestId) => {
      const response = await api.get(
        `/GetChequeOnlineReceiptFile/${paymentRequestId}`,
        {
          responseType: "blob",
        }
      );

      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      // console.log(response.data);
      setDocFile(fileURL);
      setOpenChequeOnlineReceiptDisplayer(true);
    };

  const getApplicationStatus = () => {
    api
      .get(`/GetPaymentRequestForSharePurchase/${loggedInUserRUID}`)
      .then((response) => {
        //if (response.data === '') {
        //console.log(response.data);
        if (response.data.length === 0) {
          //no application exists

          setIsLoadingData(false);
          setIsDataExisting(false);
        } else {
          //alert('hello')
          setIsDataExisting(true);
          setSharesApplicationDetails(response.data);
          // console.log(response.data);
          setIsLoadingData(false);
        }

        //console.log(partMarkedForReview);
      });
  };

  return (
    <div className="ViewMembershipApplicationStatusMainDiv">
      <fieldset>
        <legend>📜 Your Application Status</legend>
        {!isLoadingData ? (
          !isDataExisting ? (
            "No Applications have been submitted. Please Apply for Shares Purchase"
          ) : (
            <table className="membershipApplicationStatusTable">
              <thead>
                <tr>
                  <td>RUID</td>
                  <td>Request Type</td>
                  <td>Payment Date</td>
                  <td>Payment Status</td>
                  <td>Payment Amount</td>
                  <td>Verified By</td>
                  <td>Payment Remarks</td>
                  <td>View Payment Doc</td>
                </tr>
              </thead>
              <tbody>
                {sharesApplicationDetails.map((shareApp, index) => {
                  return (
                    <tr key="index">
                      <td>{shareApp.rbMemberRUID}</td>
                      <td>{shareApp.requestType}</td>
                      <td>{shareApp.requestDate}</td>
                      <td>{shareApp.paymentStatus}</td>
                      <td>{shareApp.paymentAmount}</td>
                      <td>{shareApp.staffId}</td>
                      <td>{shareApp.paymentRemarks}</td>
                      <td>
                        <input
                          type="button"
                          value="Payment File"
                          onClick={() => {
                            handleOpenChequeOnlineReceiptForSharesPurchaseOfParticularMember(
                              shareApp.paymentRequestId
                            );
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )
        ) : (
          <div className="isLoadingDiv">Loading Data ......</div>
        )}
      </fieldset>

      {/* Dialog to display to the Payment Receipt/Cheque Uploaded */}
      <OpenDocumentDisplayer
        docTitle={"View Uploaded Cheque/Online Receipt"}
        docFile={docFile}
        openDocDisplayer={openChequeOnlineReceiptDisplayer}
        setOpenDocDisplayer={setOpenChequeOnlineReceiptDisplayer}
      />
    </div>
  );
};

export default ViewSharesPurchaseRequestsStatus;
