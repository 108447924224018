import "./ChequeOnlineReceiptUploader.css";
import React, { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
// import { useLocation } from 'react-router-dom';

import useMyAuthService from "../../../../authenticationServiceProvider/useMyAuthService";
import { useNavigate } from "react-router-dom";

const ChequeOnlineReceiptUploader = (props) => {

  const {api} = useMyAuthService();

  // const location = useLocation();
  // const memberApp = location.state; // Access the passed props

  const {
    openChequeOnlineReceiptUpdator,
    setOpenChequeOnlineReceiptUpdator,
    RUID,
    setChequeOrTransactionRefNo,
    setOpenProgressBarDialog,
    requestType,
    setIsLoadingCheque,
    originalOrUpdate,
    applicationId,
    applicationStatus
  } = props;

  const [paymentMode, setPaymentMode ] = useState (" ");
  const [transactionRefOrChequeNo, setTransactionRefOrChequeNo] = useState(" ");
  const [paymentAmount, setPaymentAmount] = useState(0);

  const navigate = useNavigate();
  
  const [
    chequeOnlineReceiptFileDetailsForUpload,
    setChequeOnlineReceiptFileDetailsForUpload,
  ] = useState(null);

  const handleCloseChequeOnlineReceiptUpdatorDialog = () => {
    setOpenChequeOnlineReceiptUpdator(false);
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setChequeOnlineReceiptFileDetailsForUpload(e.target.files[0]);
    }
  };

  const handleOpenChequeOnlineReceiptUploader = () => {
    setOpenChequeOnlineReceiptUpdator(true);
  };

 

  const handleSubmitUploadChequeOnlineReceipt = async (e) => {
    e.preventDefault();

    if(paymentMode === " "){
        alert("Please select the payment mode");
        return 0;
    }

    if(chequeOnlineReceiptFileDetailsForUpload == null){
      alert("please select the Cheque/Online Receipt file")
      return 0;
    }
  
    const formData = new FormData();
    formData.append("fileName", chequeOnlineReceiptFileDetailsForUpload.name);
    formData.append("File", chequeOnlineReceiptFileDetailsForUpload);
    formData.append("RUID", RUID);
    formData.append("paymentMode", paymentMode);
    formData.append("tranRefNoOrChequeNo", transactionRefOrChequeNo);
    formData.append("requestType", requestType);

    if(originalOrUpdate === "Original"){
      formData.append("paymentStatus", "Payment Submitted");
    }

    if(originalOrUpdate === "Update"){
      formData.append("paymentStatus", "Payment Re-Submitted");
    }
    
    formData.append("paymentAmount", paymentAmount);
    
    if(requestType !== "SPP"){
      // if this is not the case shares purchase....................
      formData.append("applicationId", applicationId);
      formData.append("applicationStatus", applicationStatus);
    }

 
  
    setChequeOrTransactionRefNo(transactionRefOrChequeNo);
  
    setOpenProgressBarDialog(true);
  
    let url = "";
    if (originalOrUpdate === "Original") {
      url = `/PaymentRequestMadeByRBMember`;
    }
  
    if (originalOrUpdate === "Update") {
      url = `/UpdatePaymentRequestByRBMember`;
    }

    // console.log(url);
    // console.log(JSON.stringify(Object.fromEntries(formData)));
  
    try {
      const response = await api.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
        //console.log("Response from the server:");
       // console.log(response.data);
  
      setOpenChequeOnlineReceiptUpdator(false);
      setIsLoadingCheque((value) => !value);
      if(requestType === "SMPAY"){
          navigate("../ViewSharesPurchaseRequestsStatus", {replace:true});
      }else{
        navigate(-1);
      }
      
      setOpenProgressBarDialog(false);
    } catch (error) {
      console.error("Error during request:");
      console.error(error);
      // Handle error state or display an error message
    }
  };


  return (
    <div className="chequeOnlineReceiptUploaderMainDiv">
      <input
        type={"button"}
        onClick={() => handleOpenChequeOnlineReceiptUploader()}
        value="🖆 Upload Cheque/Online Receipt"
      />

      {/* Dialog to implement the Pay slip Uploader ................ */}
      <Dialog open={openChequeOnlineReceiptUpdator} fullWidth maxWidth="sm">
        <DialogActions id="scroll-dialog-ActionDialogActions">
          <span className="titleDialogBox">
            Cheque/Online Receipt Uploader Dialog 
            {/* for {applicationId} */}
          </span>
          <Button
            variant="outlined"
            onClick={handleCloseChequeOnlineReceiptUpdatorDialog}
          >
            ❌
          </Button>
        </DialogActions>
        <DialogContent>
          <form
            onSubmit={(e) => {
              handleSubmitUploadChequeOnlineReceipt(e);
            }}
            className="chequeOnlineReceiptUploaderMainDiv"
          >
            <div className="gridRow">
              <div className="gridCol">
                <div className="FormFieldDiv">
                  <div>
                    <label>Payment Mode:</label>
                  </div>
                  <div>
                    <select
                      value={paymentMode}
                      onChange={(e) => setPaymentMode(e.target.value)}
                      required
                    >
                      <option value=" ">Select Payment Mode</option>
                      <option value="Cheque">Cheque</option>
                      <option value="Online">Online</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="gridCol">
                <div className="FormFieldDiv">
                  <div>
                    <label>Transaction No./Cheque No.</label>
                  </div>
                  <div>
                    <input
                      type={"text"}
                      value={transactionRefOrChequeNo}
                      onChange={(e) =>
                        setTransactionRefOrChequeNo(e.target.value)
                      }
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="gridRow">
              <div className="gridCol">
                <div className="FormFieldDiv">
                  <div>
                    <label>Payment Amount</label>
                  </div>
                  <div>
                    <input
                      type={"number"}
                      value={paymentAmount}
                      onChange={(e) => setPaymentAmount(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="gridRow">
              <div className="gridCol">
                <div className="FormFieldDiv">
                  <div>
                    <label>Cheque/Online Receipt Slip File (Max Size - 2MB)</label>
                  </div>
                  <div>
                    <input
                      accept=".pdf"
                      type={"file"}
                      placeholder=" Enter Your Order Date"
                      onChange={(e) => {
                        handleFileChange(e);
                      }}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <div className="FormActionDiv">
              <input type={"button"} value="Clear Form" />
              <input type={"submit"} value="💾 Save and Upload" />
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ChequeOnlineReceiptUploader;
