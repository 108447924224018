import React from 'react'

const menuListStyle = {
  color: "blue",
  padding: "10px",
  fontFamily: "monospace",
  cursor: "pointer"
};

function MenuList(Props) {
    //destructure the parameters in the child
    const {menuList, selectedMenuItem} = Props;

    const list = menuList;
    const menu = list.map((item, index) => <li style={menuListStyle} key={index} value={item}
                                            onClick={()=>{selectedMenuItem(item)}}
                                            > 
                                            📦{item}
                                            </li>);
    
    

   // console.log(Props);
  return (
    <div>
        {menu}
    </div>
  )
}

export default MenuList