import React, {useState} from "react";

import "../TableDataDisplayer.css"
import {formatDate} from "../../../../ComonFunctionsProvider/CommonFunctionsProvider"
import PaginationNumberRendering from "../PaginationNumberRendering/PaginationNumberRendering";


const LRMWTableCDLedgerData = ({ className, cdTransactionsList }) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const itemsPerPage = 12; // Number of items to display per page
  
    const maxPageNumbersToShow = 5; // Adjust the number of page numbers to show
    
    // Calculate the total number of pages
    const totalPages = Math.ceil(cdTransactionsList.length / itemsPerPage);
  
    // Get the current page's items based on the currentPage and itemsPerPage
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = cdTransactionsList
      .filter((loanApp) =>
        Object.values(loanApp)
          .join(" ")
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
      .slice(indexOfFirstItem, indexOfLastItem);
  
    // Handle page change
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
  
    // Handle search query change
    const handleSearchQueryChange = (e) => {
      setSearchQuery(e.target.value);
      setCurrentPage(1); // Reset to the first page when search query changes
    };
  
    return (
      <div className="table-data-displayer">
        <div className="quickSearchBoxDiv">
          <input
            type="text"
            placeholder="Search the Table..."
            value={searchQuery}
            onChange={handleSearchQueryChange}
          />
        </div>
       
        <table className={className}>
          <thead>
            <tr>
            <td>Sl.</td>
              <td>Member</td>
              <td>Reference No</td>
              <td>Mode</td>
              <td>Account Head</td>
              <td>Opening Balance</td>
              <td>Transaction Amount</td>
              <td>Closing Balance</td>
              <td>Transaction Date</td>
              <td>Dr/Cr</td>
              <td>Status</td>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((cdTrans, index) => {
           
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{cdTrans.rbMemberLedgerNo}</td>
                  <td>{cdTrans.transaction.transactionReferenceNo}</td>
                  <td className="justifyContent">{cdTrans.transaction.transactionModeCode}</td>
                  <td>{cdTrans.transaction.transactionHeadCode}</td>
                  <td>{cdTrans.compulsoryDepositOpeningBalance}</td>
                  <td>{cdTrans.transaction.transactionAmount}</td>
                  <td>{cdTrans.compulsoryDepositClosingBalance}</td>
                  <td>{formatDate(cdTrans.transaction.transactionDate)}</td>
                  <td>{cdTrans.transaction.transactionDrOrCr}</td>
                  <td>{cdTrans.transaction.transactionStatus}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* Pagination */}
        <PaginationNumberRendering 
            maxPageNumbersToShow={maxPageNumbersToShow}
            totalPages={totalPages}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
        />
      </div>
    );
  };

export default LRMWTableCDLedgerData