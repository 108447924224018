import "../VerifyMembershipApplications.css";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import OpenDocumentDisplayer from "../../../../generalComponents/DialogComponents/OpenDocumentDisplayer/OpenDocumentDisplayer";
import DialogContainer from "../../../../generalComponents/DialogComponents/DialogContainer/DialogContainer";
import MembershipApplicationPreview from "../MembershipApplicationPreview/MembershipApplicationPreview";
import ProgressorBarDisplayer from "../../../../generalComponents/DialogComponents/ProgressorBarDisplayer/ProgressorBarDisplayer";
import MembershipApplicationMarkForReview from "../MembershipApplicationMarkForReview/MembershipApplicationMarkForReview";
import { formatDate } from "../../../../../ComonFunctionsProvider/CommonFunctionsProvider";

import {
  partForReview,
  useVerifyMembershipApplicationsState,
} from "../StateForVerifyMembershipApplications";

import MembershipApplicationsTable from "../../../../generalComponents/TableDataDisplayer/MembershipApplicationsTable/MembershipApplicationsTable";

import MembershipVerificationsDataLoaderFunctions from "../../../../../dataLoaderInitFunctions/membershipVerificationsDataLoaderFunctions";
import MenuMembershipApplicationsVerification from "../MenuMembershipApplicationsVerification/MenuMembershipApplicationsVerification";


// jkgkfjdsgh
const MembershipApplicationsToBeVerified = ({applicationStatusOptions}) => {
  
  const navigate = useNavigate();

 const state = useVerifyMembershipApplicationsState();

  const {
    loggedInUserRUID, // staff RUID
    api,// for calling the api on the server.....
    memberNomineeDetailsData,
    setMemberNomineeDetailsData,
    memberOtherSocietyDetailsData,
    setMemberOtherSocietyDetailsData,
    //memberApplicationDetails,
    // setMemberApplicationDetails,
    selectedMemberApplication,
    setSelectedMemberApplication,
    applicationPartForReview,
    setApplicationPartForReview,
    //isLoadingData,
    //000setIsLoadingData,
    openProgressBarDialog,
    setOpenProgressBarDialog,
    ledgerNoAlloted,
    setLedgerNoAlloted,
    openAdminDisplayDialog,
    setOpenAdminDisplayDialog,
    openStaffDisplayDialog,
    setOpenStaffDisplayDialog,
    adminRBCTCSDetails,
    setAdminRBCTCSDetails,
    staffRBCTCSDetails,
    setStaffRBCTCSDetails,
    adminName,
    setAdminName,
    openPaySlipDisplayer,
    setOpenPaySlipDisplayer,

    openMembershipFormDocsDisplayer, 
    setOpenMembershipFormDocsDisplayer,

    docFile,
    setDocFile,
    updated,
    setUpdated,
     // anchorActionsMenu,
    // setAnchorActionsMenu,
    // openMenu

    openMarkApplicationForReview,
    setOpenMarkApplicationForReview,

    openMemberDetailsInApplication,
    setOpenMemberDetailsInApplication,

    openNomineeDetailsInApplication,
    setOpenNomineeDetailsInApplication,

    openOtherSocietyDetailsInApplication,
    setOpenOtherSocietyDetailsInApplication,

    openVerifyApplication,
    setOpenVerifyApplication,
    reviewRemarks, setReviewRemarks
  } = state;


  //The application status is required here to be befitting every version.........
  const [applicationStatus, setApplicationStatus] = useState(applicationStatusOptions[0].value);
  
  //Function to retrivee membership applications with a particular application status from the server and setting the data in memberhsipApplicationDetails.
   // the use effect for initial data loads...............
   const { memberApplicationDetails, isLoadingData } =
   MembershipVerificationsDataLoaderFunctions(
   applicationStatus,
   setOpenProgressBarDialog,
   updated
 );


  //Other Society details/..................................
  let societyTableRow = null;
  let societiesDataArray = Object.values(memberOtherSocietyDetailsData);
  //console.log(societiesDataArray);
  if (societiesDataArray !== undefined) {
    societyTableRow = societiesDataArray.map((society, index) => {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{society.societyName} </td>
          <td>{society.societyEmailId} </td>
          <td>{society.societyMobileNo} </td>
          <td>{society.societyAddress} </td>
        </tr>
      );
    });
  }

  //////////Nominee details..................................
  let nomineetablerow = null;
  let nomineeDataArray = Object.values(memberNomineeDetailsData);

  if (nomineeDataArray !== undefined) {
    nomineetablerow = nomineeDataArray.map((nominee, index) => {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{nominee.name} </td>
          <td>{nominee.relationWithMember} </td>
          <td>{formatDate(nominee.dateOfBirth)} </td>
          <td>{nominee.amountPayable} % </td>
          <td>{nominee.mobileNo} </td>
          <td>{nominee.email} </td>
          <td>{nominee.homeAddress} </td>
          <td>{nominee.nomineeHeirName} </td>
          <td>{formatDate(nominee.nomineeHeirDOB)} </td>
          <td>{nominee.nomineeHeirRelationWithMember} </td>
        </tr>
      );
    });
  }
  //console.log(nomineetablerow);

  //function for open and Close of the Dialog Box....................................starts
  const [
    openMemberPaymentDetailsForMembership,
    setOpenMemberPaymentDetailsForMembership,
  ] = React.useState(false);
  
  
  //  Functions for the MENU open and closure...................
  //action menu for the verification............................of the applications................start.....
  const [anchorActionsMenu, setAnchorActionsMenu] = React.useState(null);
  const openMenu = Boolean(anchorActionsMenu);

  const handleClickOpenMenu = (event, memberApp) => {
     //console.log(openMenu);
    // console.log(memberApp);
     setSelectedMemberApplication(memberApp);
     //console.log("selected : - "+selectedMemberApplication.rbmembers.rbMemberRUID);
     setMemberNomineeDetailsData(memberApp.rbmembers.nominees);
     setMemberOtherSocietyDetailsData(memberApp.rbmembers.other_societies);
    //console.log(selectedMember);
    setAnchorActionsMenu(event.currentTarget);

  };

  // Function for the Menu close
  const handleCloseMenu = () => {
    setAnchorActionsMenu(null);
  };  

  //MENU    Fucntions end.....

  const handleClickOpenMemberDetailsInApplication = (rbMemberRUID)  => {
     //console.log(rbMemberRUID);
    setAnchorActionsMenu(null);
    setOpenMemberDetailsInApplication(true);
  };

  const handleClickOpenMemberPaymentDetailsForMembership = () => {
    setAnchorActionsMenu(null);
    setOpenMemberPaymentDetailsForMembership(true);
  };

  const handleClickOpenNomineeDetailsInApplication = () => {
     setAnchorActionsMenu(null);
    setOpenNomineeDetailsInApplication(true);
  };
  const handleClickOpenOtherSocietyDetailsInApplication = () => {
    setAnchorActionsMenu(null);
    setOpenOtherSocietyDetailsInApplication(true);
  };

  const handleClickOpenMarkApplicationForReview =
    (membership_applications) => () => {
      setApplicationPartForReview({
        ...applicationPartForReview,
        staffId: loggedInUserRUID,
        memberApplicationID: membership_applications.applicationId,
      });

      setAnchorActionsMenu(null);
      setOpenMarkApplicationForReview(true);
    };
  const handleClickOpenVerifyApplication = (membership_applications) => () => {
    setApplicationPartForReview({
      ...applicationPartForReview,
      staffId: loggedInUserRUID,
      memberApplicationID: membership_applications.applicationId,
    });

    setAnchorActionsMenu(null);
    setOpenVerifyApplication(true);
  };

  const handleCloseMemberDetailsInApplication = () => {
    setOpenMemberDetailsInApplication(false);
  };

  const handleCloseMemberPaymentDetailsForMembership = () => {
    setOpenMemberPaymentDetailsForMembership(false);
  };

  const handleCloseNomineeDetailsInApplication = () => {
    setOpenNomineeDetailsInApplication(false);
  };

  const handleCloseOtherSocietyDetailsInApplication = () => {
    setOpenOtherSocietyDetailsInApplication(false);
  };

  const handleCloseVerifyApplication = () => {
    setOpenVerifyApplication(false);
  };

  //function for open and close of the Dialog Box....................................ends

  const handleCloseMarkApplicationForReview = () => {
    setOpenMarkApplicationForReview(false);
    // setApplicationPartForReview({partForReview});

    // alert(JSON.stringify(applicationPartForReview));
  };

  const handleSubmitVerifyOkMembershipApplication = async () => {
    const data = {
      memberApplicationID: applicationPartForReview.memberApplicationID,
      selectedMemberRUID: selectedMemberApplication.rbmembers.rbMemberRUID,
      staffId: loggedInUserRUID,
      ledgerNoAlloted: ledgerNoAlloted,
      approvalStatus: "MAPDV",
    };
    //console.log(data);
    let config = {
      method: "PUT",
      url: `/MembershipApplicationVerifiedOk`,
      data: { data },
      headers: {
        Accept: "application/json",

        //  Authorization: varToken
      },
      responseType: "json",
    };

    setOpenProgressBarDialog(true);
    const response = await api(config);
    setOpenVerifyApplication(false);
    setUpdated(!updated);
    setOpenProgressBarDialog(false);
   // console.log(response.data);
  };

  const displayAdminDetails = (e, adminId) => {
    api
      .get(
        `/getAdminRBCTCSDetailsByAdminId/${adminId}`
      )
      .then((response) => {
        setAdminRBCTCSDetails(response.data);
        setAdminName(response.data.users.name);
        setOpenAdminDisplayDialog(true);
      });
  };

  const displayStaffDetails = (e, staffId) => {
    api
      .get(`/ViewRBCTCSStaffDetails/${staffId}`)
      .then((response) => {
        //console.log(response.data);
        setStaffRBCTCSDetails(response.data[0]);
        setOpenStaffDisplayDialog(true);
      });
  };

  const handleCloseAdminDisplayDialog = () => {
    setOpenAdminDisplayDialog(false);
  };

  const handleCloseStaffDisplayDialog = () => {
    setOpenStaffDisplayDialog(false);
  };

  const handleCheckPaymentDetails = (RUID) => {
    navigate("/StaffRBCTCSDashBoard/ManageMiscPayments");
  };

  const handleOpenPaySlipDisplayer = (RUID) => {
    api({
      url: `/GetPaySlipFile/${RUID}`,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      //console.log(response.data);
      setDocFile(fileURL);
      // setOpenChequeOnlineReceiptDisplayer(false);
      setOpenPaySlipDisplayer(true);
    });
  };


  const handleOpenMemberhsipFormDocsDisplayer = (RUID) => {
    api({
      url: `/GetMemberhsipFormDocsFile/${RUID}`,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      //console.log(response.data);
      setDocFile(fileURL);
      // setOpenChequeOnlineReceiptDisplayer(false);
      setOpenMembershipFormDocsDisplayer(true);
    });
  };


  const handleChangeApplicationsStatusOptions = (status) => {
    // console.log(status);
    setApplicationStatus(status);
  };

  return (
    // Div to show the applications table for the incoming verification request of the members................
    <div className="VerifyMembershipApplicationsMainDiv">
      <div className="FilterApplicationsDiv">
      <select onChange={(e) => handleChangeApplicationsStatusOptions(e.target.value)}>
        {applicationStatusOptions.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>

      {/* {memberApplicationDetails.map((member) => {
         return JSON.stringify(member);
       })} */}
      {isLoadingData ? (
        <div className="isLoadingDiv">Loading Data .....</div>
      ) : (
        <>
          <MembershipApplicationsTable
            memberApplicationDetails={memberApplicationDetails}
            displayStaffDetails={displayStaffDetails}
            displayAdminDetails={displayAdminDetails}

            // setSelectedMemberApplication={setSelectedMemberApplication}
            // setMemberNomineeDetailsData={setMemberNomineeDetailsData}
            // setMemberOtherSocietyDetailsData={setMemberOtherSocietyDetailsData}

            handleClickOpenMenu={handleClickOpenMenu}
            // anchorActionsMenu={anchorActionsMenu}
            // setAnchorActionsMenu={setAnchorActionsMenu}
            openMenu={openMenu}
          />
          
          <MenuMembershipApplicationsVerification
            selectedMemberApplication={selectedMemberApplication}
            displayStaffDetails={displayStaffDetails}
            displayAdminDetails={displayAdminDetails}

            anchorActionsMenu={anchorActionsMenu}
            setAnchorActionsMenu={setAnchorActionsMenu}
            openMenu={openMenu}
            handleCloseMenu = {handleCloseMenu}

            applicationStatus={applicationStatus}

            handleClickOpenMemberDetailsInApplication={handleClickOpenMemberDetailsInApplication}
            handleOpenPaySlipDisplayer={handleOpenPaySlipDisplayer}
            handleOpenMemberhsipFormDocsDisplayer = {handleOpenMemberhsipFormDocsDisplayer}
            handleCheckPaymentDetails={handleCheckPaymentDetails}
            handleClickOpenMarkApplicationForReview={handleClickOpenMarkApplicationForReview}
            handleClickOpenVerifyApplication={handleClickOpenVerifyApplication}
            handleClickOpenOtherSocietyDetailsInApplication={handleClickOpenOtherSocietyDetailsInApplication}
            handleClickOpenNomineeDetailsInApplication={handleClickOpenNomineeDetailsInApplication}
          />
        </>
      )}

      {/* Dialog to display Staff Details ................ */}
      <DialogContainer
        titleMessage={"Verifying Staff"}
        openDialogBox={openStaffDisplayDialog}
        maxWidth="sm"
        handleCloseDialog={handleCloseStaffDisplayDialog}
      >
        <div className="adminDisplayDiv">
          <table>
            <tbody>
              <tr>
                <td>Staff ID:</td>
                <td>{staffRBCTCSDetails.staffRBCTCSId}</td>
              </tr>
              <tr>
                <td>Shri/Smt./Ms</td>
                <td>{staffRBCTCSDetails.name}</td>
              </tr>
              <tr>
                <td>Designation</td>
                <td>{staffRBCTCSDetails.designation}/RBCTCS</td>
              </tr>
            </tbody>
          </table>
        </div>
      </DialogContainer>

      {/* Dialog to display Admin Details ................ */}
      <DialogContainer
        titleMessage={"Approving Admin"}
        openDialogBox={openAdminDisplayDialog}
        maxWidth="sm"
        handleCloseDialog={handleCloseAdminDisplayDialog}
      >
        <div className="adminDisplayDiv">
          <table>
            <tbody>
              <tr>
                <td>RUID:</td>
                <td>{adminRBCTCSDetails.adminRBCTCSRUID}</td>
              </tr>
              <tr>
                <td>Shri/Smt./Ms</td>
                <td>{adminName}</td>
              </tr>
              <tr>
                <td>Designation</td>
                <td>{adminRBCTCSDetails.designationRBCTCS}/RBCTCS</td>
              </tr>
            </tbody>
          </table>
        </div>
      </DialogContainer>

      {/* Dialog to implement the Progess bar ................ */}
      <ProgressorBarDisplayer
        titleMessage={"Please wait! work under process ...."}
        openProgressBarDialog={openProgressBarDialog}
      />

      {/* Dialog to implement the verification logic for the submitted application ................ */}
      <DialogContainer
        titleMessage={"Submit Verification of Membership Application"}
        openDialogBox={openVerifyApplication}
        handleCloseDialog={handleCloseVerifyApplication}
        maxWidth="sm"
      >
        <div className="finalVerificationSubmitDialogDiv">
          <p>
            If the details of the application are succesfully verified, please
            allot the ledger No. click on verify ok button.
          </p>
          <p>
            <span className="FormFieldDiv">
              <input
                type={"text"}
                value={ledgerNoAlloted}
                onChange={(e) => {
                  setLedgerNoAlloted(e.target.value);
                }}
                please
                Enter
                the
                ledgerNO
                here
              />
            </span>
            <span className="FormActionDiv">
              <button onClick={handleSubmitVerifyOkMembershipApplication}>
                👌 Verify OK
              </button>
            </span>
          </p>
        </div>
      </DialogContainer>

      {/* Dialog to Mark the submitted application for the review and resubmit................ */}
      <DialogContainer
        titleMessage={" Mark Application for Review"}
        openDialogBox={openMarkApplicationForReview}
        handleCloseDialog={handleCloseMarkApplicationForReview}
        maxWidth={"sm"}
      >
        <MembershipApplicationMarkForReview
          applicationPartForReview={applicationPartForReview}
          setApplicationPartForReview={setApplicationPartForReview}
          ruidLoggedIn={loggedInUserRUID}
          setOpenProgressBarDialog={setOpenProgressBarDialog}
          setUpdated={setUpdated}
          setOpenMarkApplicationForReview={setOpenMarkApplicationForReview}
          reviewRemarks={reviewRemarks} 
          setReviewRemarks = {setReviewRemarks}
        />
      </DialogContainer>

      {/* Dialog to show the Nominee Details.............................. */}
      <DialogContainer
        titleMessage={"Membership Application Nominee Details"}
        openDialogBox={openNomineeDetailsInApplication}
        handleCloseDialog={handleCloseNomineeDetailsInApplication}
        maxWidth={"lg"}
      >
        <div className="nomineeDetailsTableDiv">
          <table className="nomineeDetailsTable" border="1">
            <thead>
              <tr>
                <td>Sl.</td>
                <td>Nominee Name</td>
                <td>Relation with Member</td>
                <td>Nominee DoB</td>
                <td>Amount Payable</td>
                <td>Mobile</td>
                <td>Email</td>
                <td>Home Address</td>
                <td>Nominee Heir Name</td>
                <td>Nominee Heir DoB</td>
                <td>Nominee Heir Relation</td>
              </tr>
            </thead>
            <tbody>{nomineetablerow}</tbody>
          </table>
        </div>
      </DialogContainer>

      {/* Dialog to view the other society details of the members............................ */}
      <DialogContainer
        titleMessage={"Membership Application Other Society Details"}
        openDialogBox={openOtherSocietyDetailsInApplication}
        handleCloseDialog={handleCloseOtherSocietyDetailsInApplication}
        maxWidth={"sm"}
      >
        <div className="societyDetailsTableDiv">
          <table className="societyDetailsTable" border="1">
            <thead>
              <tr>
                <td>Sl.</td>
                <td>Society Name</td>
                <td>Email Id</td>
                <td>Mobile No.</td>
                <td>Address</td>
              </tr>
            </thead>
            <tbody>{societyTableRow}</tbody>
          </table>
        </div>
      </DialogContainer>

      {/* Dialog to view the details of the member............................. */}
      <DialogContainer
        titleMessage={"Membership Application Verification"}
        openDialogBox={openMemberDetailsInApplication}
        handleCloseDialog={handleCloseMemberDetailsInApplication}
        maxWidth={'sm'}
      >
        <MembershipApplicationPreview selectedMember={selectedMemberApplication.rbmembers} />
      </DialogContainer>
      

      {/* Dialog to open and display the Membership Form Docs */}
      <OpenDocumentDisplayer
        docTitle={"View Pay Slip"}
        docFile={docFile}
        openDocDisplayer={openMembershipFormDocsDisplayer}
        setOpenDocDisplayer={setOpenMembershipFormDocsDisplayer}
      />


      {/* Dialog to open and display the Pay Slip */}
      <OpenDocumentDisplayer
        docTitle={"View Pay Slip"}
        docFile={docFile}
        openDocDisplayer={openPaySlipDisplayer}
        setOpenDocDisplayer={setOpenPaySlipDisplayer}
      />
    </div>
  );
};

export default MembershipApplicationsToBeVerified;
