import "./AddMemberNomineeComponent.css";

import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom'

import LinearProgress from "@mui/material/LinearProgress";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMyAuthService from "../../../authenticationServiceProvider/useMyAuthService";

const nomineeFormFields = {
  nomineeName: " ",
  nomineeDateOfBirth: " ",
  nomineeRelation: " ",
  nomineeMobile: " ",
  nomineeHeirName: " ",
  nomineeHeirDateOfBirth: " ",
  nomineeHeirRelation: "",
  amountPayableToNominee: " ",
  nomineeAddress: " ",
  nomineeEmail: " ",
  nomineePhoto: "upload",
  nomineeSign: "upload",
  nomineeHeirPhoto: "upload",
  nomineeHeirSign: "upload",
};


const AddMemberNomineeComponent = () => {
  const {api, loggedInUserRUID} = useMyAuthService();
  
  const [currentLoggedInRUID, setCurrentLoggedInRUID] = useState(loggedInUserRUID);

  const [nomineeForm, setNomineeFormFieldValues] = useState(nomineeFormFields);
  const [nominee, setnomineedata] = useState([]);

  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

  const [messageSubmit, setMessageSubmit] = useState('');

  
  const navigate = useNavigate();

  

  
  const handleSubmitNomineeDetailsUpdaterComponent = async () => {
     //console.log(personalForm);
    //  let emptyFields = [];

    //  //check if any of the properties of the personalForm  is empty.......... and dont allow to move forward
    //  for (var key in nomineeForm) {
    //    if (nomineeForm[key] === null || nomineeForm[key] === " ") {
    //      //console.log("please fill in the complete details of the following : "+key)
    //      emptyFields.push("nominee Form: " + key);
    //      // return false;
    //    }
    //  }
 
     /////////sending the data to the server id all the data is present
 
    //  if (emptyFields.length === 0) {
       // console.log("The lenght is "+ emptyFields.length)
       //console.log("The data being sent..................");
       //console.log({ ...personalForm, ...officialForm, ...nomineeForm, ...societyForm, ...nomineeForm});
       let config = {
         method: "POST",
         url: `/AddRBMemberNomineeDetails`,
         data: { ...nomineeForm, loggedInUserRUID},
         headers: {
           Accept: "application/json",
           //  Authorization: varToken
         },
         responseType: "application/json",
       };
 
       setOpenProgressBarDialog(true);
       const response = await api(config);
       setOpenProgressBarDialog(false);
       //console.log("data sent from the server.............................");
       //console.log(response.data[0]);
       setMessageSubmit(response.data[0]);
       navigate(-1);
    //  } else {
       //form is not ready..................
       // console.log(emptyFields);
      //  alert(
      //    "Please fill in all the details" +
      //      "\r\n" +
      //      emptyFields.map((field, index) => {
      //        return field + "\r\n";
      //      })
      //  );
    //  }
  };

  return (
    <div className="nomineesDetailsUpdaterMainDiv">
      <fieldset>
        <legend>✍️ Fill the Nominee Details</legend>
        <div><p>{messageSubmit}</p></div>
      
            <div className="gridRow">
              <div className="gridCol">
                <div className="FormFieldDiv">
                  <div>
                    <label>Nominee Name</label>
                  </div>
                  <div>
                    <input
                      type={"text"}
                      value={nomineeForm.nomineeName}
                      onChange={(e) =>
                        setNomineeFormFieldValues({
                          ...nomineeForm,
                          nomineeName: e.target.value,
                        })
                      }
                      placeholder="Name of the Nominee"
                      required
                    />
                  </div>
                </div>
                <div className="FormFieldDiv">
                  <div>
                    <label>Date of Birth</label>
                  </div>
                  <div>
                    <input
                      type={"date"}
                      value={nomineeForm.nomineeDateOfBirth}
                      onChange={(e) =>
                        setNomineeFormFieldValues({
                          ...nomineeForm,
                          nomineeDateOfBirth: e.target.value,
                        })
                      }
                      placeholder="Date of Birth"
                      required
                    />
                  </div>
                </div>
                <div className="FormFieldDiv">
                  <div>
                    <label>Relation with Member</label>
                  </div>
                  <div>
                    <input
                      type={"text"}
                      value={nomineeForm.nomineeRelation}
                      onChange={(e) =>
                        setNomineeFormFieldValues({
                          ...nomineeForm,
                          nomineeRelation: e.target.value,
                        })
                      }
                      placeholder="Relation with the Member"
                      required
                    />
                  </div>
                </div>
                <div className="FormFieldDiv">
                  <div>
                    <label>Mobile No</label>
                  </div>
                  <div>
                    <input
                      type={"number"}
                      value={nomineeForm.nomineeMobile}
                      onChange={(e) =>
                        setNomineeFormFieldValues({
                          ...nomineeForm,
                          nomineeMobile: e.target.value,
                        })
                      }
                      placeholder="Enter Your Mobile No."
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="gridCol">
                <div className="FormFieldDiv">
                  <div>
                    <label>Amount Payable (in %)</label>
                  </div>
                  <div>
                    <input
                      type={"number"}
                      value={nomineeForm.amountPayableToNominee}
                      onChange={(e) =>
                        setNomineeFormFieldValues({
                          ...nomineeForm,
                          amountPayableToNominee: e.target.value,
                        })
                      }
                      placeholder="Amount Payable to the Nominee"
                      required
                    />
                  </div>
                </div>
                <div className="FormFieldDiv">
                  <div>
                    <label>Nominee Email Id</label>
                  </div>
                  <div>
                    <input
                      type={"email"}
                      value={nomineeForm.nomineeEmail}
                      onChange={(e) =>
                        setNomineeFormFieldValues({
                          ...nomineeForm,
                          nomineeEmail: e.target.value,
                        })
                      }
                      placeholder="Enter Email"
                      required
                    />
                  </div>
                </div>
                <div className="FormFieldDiv">
                  <div>
                    <label>Nominee Address</label>
                  </div>
                  <div>
                    <textarea
                      rows="6"
                      cols="31"
                      value={nomineeForm.nomineeAddress}
                      onChange={(e) =>
                        setNomineeFormFieldValues({
                          ...nomineeForm,
                          nomineeAddress: e.target.value,
                        })
                      }
                      placeholder="Enter the Nominees Address"
                      required
                    ></textarea>
                  </div>
                </div>
              </div>

              <div className="gridCol">
                <div className="FormFieldDiv">
                  <div>
                    <label>Nominee Heir Name</label>
                  </div>
                  <div>
                    <input
                      type={"text"}
                      value={nomineeForm.nomineeHeirName}
                      onChange={(e) =>
                        setNomineeFormFieldValues({
                          ...nomineeForm,
                          nomineeHeirName: e.target.value,
                        })
                      }
                      placeholder="Name of the Nominee"
                      required
                    />
                  </div>
                </div>
                <div className="FormFieldDiv">
                  <div>
                    <label>Heir Date of Birth</label>
                  </div>
                  <div>
                    <input
                      type={"date"}
                      value={nomineeForm.nomineeHeirDateOfBirth}
                      onChange={(e) =>
                        setNomineeFormFieldValues({
                          ...nomineeForm,
                          nomineeHeirDateOfBirth: e.target.value,
                        })
                      }
                      placeholder="Date of Birth"
                      required
                    />
                  </div>
                </div>
                <div className="FormFieldDiv">
                  <div>
                    <label>Relation with Member</label>
                  </div>
                  <div>
                    <input
                      type={"text"}
                      value={nomineeForm.nomineeHeirRelation}
                      onChange={(e) =>
                        setNomineeFormFieldValues({
                          ...nomineeForm,
                          nomineeHeirRelation: e.target.value,
                        })
                      }
                      placeholder="Relation Nominee Heir"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <hr></hr>
            <div className="FormActionDiv actionsDiv gridCol">
              <input
                type={"button"}
                onClick={() => handleSubmitNomineeDetailsUpdaterComponent()}
                value="💾 SAVE NEW NOMINEE DETAILS"
              />
            </div>

            {/* Dialog to implement the Progess bar ................ */}
            <Dialog open={openProgressBarDialog} fullWidth maxWidth="sm">
              <DialogTitle id="scroll-dialog-title">
                <b>Please wait! work under process ....</b>
              </DialogTitle>
              <DialogContent>
                <LinearProgress />
              </DialogContent>
            </Dialog>
          
      </fieldset>
    </div>
  );
};

export default AddMemberNomineeComponent