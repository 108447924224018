import React from "react";
import "./SettingFeesforNewMembership.css";

import { useState } from "react";
import axios from "axios";
import { useGetAPIServer } from "../../../../BackEndServerServiceProvider/BackEndServerServiceProvider";

let createFeesForNewMemberForm = {
  noOfShares: " ",
  admissionFees: " ",
  benevolentFund: " ",
 };

function SettingFeesforNewMembership() {

  const apiServer = useGetAPIServer();
  /** The data elements of the form to be stored in these variables */
  const [createFeesForNewMemberFormData, setCreateFeesForNewMemberFormData] = useState(
    createFeesForNewMemberForm
  );
  const [errorMessage, setErrorMessage] = useState();
  const [message, setMessage] = useState();

  async function handleSubmit(e) {
    e.preventDefault();
    //console.log("form has been submitted");
    // console.log(createStaffFormData);

    //  if(createStaffFormData.staffSign ==="" && createStaffFormData.staffPhoto ==="" ){
    //     setErrorMessage("Please Upload the Photo and Signatures for proceeding further" );
    //  }else{

    const data = createFeesForNewMemberFormData;
    //console.log(data);
    let config = {
      method: "POST",
      url: `${apiServer.serverBaseRoute}/CreateFeesForNewMembership`,
      data: { data },
      headers: {
        Accept: "application/json",

        //  Authorization: varToken
      },
      responseType: "json",
    };

    const response = await axios(config);
    //console.log(response.data);
    setMessage(response.data);

    // }
  }


  return (
    <div className="SettingFeesforNewMembership">
      <fieldset>
        <legend>Charges on New Membership</legend>
        <div className="errorDiv">{errorMessage}</div>
        <div className="msgDiv">{message}</div>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="gridRow">
            <div className="gridCol">
              <div className="FormFieldDiv">
                <div>
                  <label>Shares Required</label>
                </div>
                <div>
                  <input
                    type={"number"}
                    name="noOfShares"
                    placeholder="Enter Shares Required "
                    value={createFeesForNewMemberFormData.noOfShares}
                    onChange={(e) =>
                      setCreateFeesForNewMemberFormData({
                        ...createFeesForNewMemberFormData,
                        noOfShares: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>

              <div className="FormFieldDiv">
                <div>
                  <label>admission fees</label>
                </div>
                <div>
                  <input
                    type={"number"}
                    name="admissionFees"
                    placeholder="Enter Adminssion Fees Required "
                    value={createFeesForNewMemberFormData.admissionFees}
                    onChange={(e) =>
                      setCreateFeesForNewMemberFormData({
                        ...createFeesForNewMemberFormData,
                        admissionFees: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>

              <div className="FormFieldDiv">
                <div>
                  <label>Benevolent Fund</label>
                </div>
                <div>
                  <input
                    type={"number"}
                    name="benevolentFund"
                    placeholder="Enter Loan Limit"
                    value={createFeesForNewMemberFormData.benevolentFund}
                    onChange={(e) =>
                      setCreateFeesForNewMemberFormData({
                        ...createFeesForNewMemberFormData,
                        benevolentFund: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="FormActionDiv">
            <input type={"button"} value="Clear Form" />
            <input type={"submit"} value="Save Settings" />
          </div>
        </form>
      </fieldset>
    </div>
  );
}

export default SettingFeesforNewMembership;
