import React from 'react';
import { useNavigate } from 'react-router-dom';
import DashBoardFooter from '../../generalComponents/DashBoardFooter/DashBoardFooter';
import DashBoardHeader from '../../generalComponents/DashBoardHeader/DashBoardHeader';
import DashBoardSideNav from '../../generalComponents/DashBoardSideNav/DashBoardSideNav';
import DashWorkSpace from '../../generalComponents/DashWorkSpace/DashWorkSpace';

import "./AdminDashHome.css";


const menuTree = [
  {
    id: 1,
    head: "Manage Your Details",
    subMenu: [
      // "Manage Personal Details", 
      "Manage Admin Official Details", "Update LOG In Password"],
  },
  {
    id: 2,
    head: "Manage Approvals",
    subMenu: ["Approve Loans ", "Approve New Memberships"],
  },
  {
    id: 3,
    head: "Manage Reports",
    subMenu: [
                "Manage Loan Disbursement Reports", 
                "Ledger Report Head Wise",
                "Ledger Report Member Wise",
                "Bank Book",
                "Trial Balance",
                "EMI deductions Report for PAO"
              ]
  },
  {
    id: 4,
    head: "Manage RBCTCS Staff",
    subMenu: ["Manage Staff Details"],
  },
  {
    id: 5,
    head: "Manage Messages To RBCTCS",
    subMenu: ["Messages Written To RBCTCS"],
  },
  {
    id: 6,
    head: "Manage Member Details",
    subMenu: ["View Sureties Given By Member", "View Sureties Given To Member", "View Member Personal Details", "View Member Official Details" ,"View Member Nominee Details", 
              // "View Member Bank Details", 
              "View Member RBCTCS Details"],
  },
  {
    id: 7,
    head: "Manage Settings",
    subMenu: ["Set Fees for New Membership", "Set Loan Limits", "Set Other Limits", "Set Compulsory Deposit Limits"],
  },
  // {
  //   id: 4,
  //   head: "Manage Membership Details",
  //   subMenu: ["View Membership Details"],
  // },
  
];


const AdminDashHome = () => {
  const navigate = useNavigate();
  const selectedMenuItem =item=>{
      navigate(item.replace(/ /g,''));
  }

  return (
    <div className='memberDashMainDiv'>
      <DashBoardHeader></DashBoardHeader>
      <div className='memberDashBoardBody'>
        <DashBoardSideNav menuTree={menuTree} selectedMenuItem={selectedMenuItem}></DashBoardSideNav>
        <DashWorkSpace></DashWorkSpace>
      </div>
      <DashBoardFooter></DashBoardFooter>
    </div>
  )
}

export default AdminDashHome