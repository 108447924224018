import { useContext } from 'react';
import { AuthContext } from './authServiceProvider'; // Correct the import statement by destructuring AuthContext from its module

const useMyAuthService = () => {
  const { loggedInUserRUID, loggedInUserRole, loggedInUserName, api} = useContext(AuthContext);
  
 // console.log(authToken);
  // Return an object with the data for easier usage in components
  return {
    loggedInUserRUID,
    loggedInUserRole,
    loggedInUserName,
    api,
    
  };
};

export default useMyAuthService;
