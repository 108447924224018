import React from "react";
import PropTypes from "prop-types";
import styles from "./FooterRBCTCS.css";

import FormDownLoadWidget from "../../components/FormDownLoadWidget/FormDownLoadWidget";

import newMemberFormPDF from "../../documents/newMemberForm_merged.pdf";
import OrdinaryLoanFormPDF from "../../documents/houseOrdinaryLoan.pdf";
import emergentLoanFormPDF from "../../documents/emergentLoan.pdf";
import rbctcsByeLawsPDF from "../../documents/bye-laws.pdf";
import { Stack } from "@mui/material";

function FooterRBCTCS () {


  return (
    <div className="FooterRBCTCS" id="Forms&Bye-Laws">
      <Stack direction={'row'} spacing={1}>
        <FormDownLoadWidget
          title="New Membership Form"
          fileName={newMemberFormPDF}
        ></FormDownLoadWidget>
        <FormDownLoadWidget
          title="Ordinary Loan Form"
          fileName={OrdinaryLoanFormPDF}
        ></FormDownLoadWidget>
        <FormDownLoadWidget
          title="Emergent Loan Form"
          fileName={emergentLoanFormPDF}
        ></FormDownLoadWidget>
        <FormDownLoadWidget
          title="Bye Laws"
          fileName={rbctcsByeLawsPDF}
        ></FormDownLoadWidget>
      </Stack>
      <div className="footerNote">
        Developed, Designed and Maintained @ Krishana Meena and Team
      </div>
    </div>
  );
}

export default FooterRBCTCS;
