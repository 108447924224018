import React, { useEffect, useState } from "react";
import "./ViewMemberLoanApplications.css";

import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import useMyAuthService from "../../../authenticationServiceProvider/useMyAuthService";
import { formatDate } from "../../../ComonFunctionsProvider/CommonFunctionsProvider";
import MemberLoanApplicationStatusExplanation from "./MemberLoanApplicationStatusExplanation/MemberLoanApplicationStatusExplanation";

function ViewMemberLoanApplications() {
  const navigate = useNavigate();

  const { loggedInUserRUID, api } = useMyAuthService();

  const [ruidLoggedIn, setRUIDLoggedIn] = useState(loggedInUserRUID);
  const [LoanApplicationDetails, setLoanApplicationDetails] = useState([]);

  const [selectedLoanApplication, setSelectedLoanApplication] = useState({});

  const [isLoadingData, setIsLoadingData] = useState(true);

  const [adminRBCTCSDetails, setAdminRBCTCSDetails] = useState({});
  const [staffRBCTCSDetails, setStaffRBCTCSDetails] = useState({});
  const [adminName, setAdminName] = useState();

  const [openAdminDisplayDialog, setOpenAdminDisplayDialog] =
    React.useState(false);
  const [openStaffDisplayDialog, setOpenStaffDisplayDialog] =
    React.useState(false);

  useEffect(() => {
    getApplicationStatus();
  }, []);

  const getApplicationStatus = () => {
    api.get(`/ViewLoanApplicationsStatus/${ruidLoggedIn}`).then((response) => {
      //console.log(response.data);

      setLoanApplicationDetails(response.data);
      // setmemberDetails(loanApplicationDetails.rbmembers);

      // setPartMarkedForReview(JSON.parse(response.data.partMarkedForReview));
      //console.log(response.data.partMarkedForReview);
      setIsLoadingData(false);
    });
  };

  const handleButtonPartMarkForReview = (partName, loanApp) => {

  //  alert(partName);
    switch (partName) {
      // case "Loan_Details":
      //   navigate("../ReviewLoanApplicationForm", { replace: true , state:{loanApplicationId:loanAppId} });
      //   break;
      case "Pay_Slip":
       // alert("heloo");
        navigate("../ReviewPaySlipUpload", { replace: true, state:{loanApplicationId:loanApp.loanApplicationId} });
        break;
      case "Surety_Details":
        navigate("../ReviewLoanSuretyFormDetails", { replace: true,  state:{loanApplicationId:loanApp.loanApplicationId}  });
        break;
      // case "Bonds_Details":
      //   navigate("../ReviewLoanFormBondDetails", { replace: true });
      //   break;

      default:
        break;
    }
  };

  const displayAdminDetails = (e, adminId) => {
    api.get(`/getAdminRBCTCSDetailsByAdminId/${adminId}`).then((response) => {
      setAdminRBCTCSDetails(response.data);
      setAdminName(response.data.users.name);
      setOpenAdminDisplayDialog(true);
    });
  };

  const displayStaffDetails = (e, staffId) => {
    api.get(`/ViewRBCTCSStaffDetails/${staffId}`).then((response) => {
      //console.log(response.data);
      setStaffRBCTCSDetails(response.data[0]);
      setOpenStaffDisplayDialog(true);
    });
  };

  const handleCloseAdminDisplayDialog = () => {
    setOpenAdminDisplayDialog(false);
  };

  const handleCloseStaffDisplayDialog = () => {
    setOpenStaffDisplayDialog(false);
  };

  const handleChangeCheckBox = (e, loan) => {
    setSelectedLoanApplication(loan);
    // console.log(e);
    // alert(nominee);
  };

  return (
    <div className="ViewMembershipApplicationStatusMainDiv">
      <fieldset>
        <legend>📜 Your Application Status</legend>
        {isLoadingData ? (
          <div className="isLoadingDiv">Loading Data .....</div>
        ) : (
          <table className="membershipApplicationStatusTable centerContent">
            <thead>
              <tr>
                <td></td>
                <td>Sl No.</td>
                <td>RUID</td>
                <td className="justifyContent">Name</td>
                <td>Application Date</td>
                <td>Loan Type</td>
                <td>Loan Amount</td>
                <td>Application status</td>

                {LoanApplicationDetails.some(
                  (loan) => loan.applicationStatus === "LAMR"
                ) && (
                  <>
                    <td>Parts Marked For Review</td>
                    <td>Remarks</td>
                  </>
                )}

                <td>Verified By</td>
                <td>Approved By</td>
                <td>Counter Approved By</td>
                {/* <td>Parts Marked For Review</td> */}
              </tr>
            </thead>
            <tbody>
              {LoanApplicationDetails.map((Loan, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <input
                        type={"radio"}
                        name="loanApp"
                        onClick={(e) => handleChangeCheckBox(e, Loan)}
                      />
                    </td>
                    <td>{index + 1}</td>
                    <td>{Loan.rbmembers.ledgerNo}</td>
                    <td>{Loan.rbmembers.name}</td>
                    <td>{formatDate(Loan.dateOfApplication)}</td>
                    <td>
                      {Loan.loanType === "ML" ? "Main Loan" : "Emergent Loan"}
                    </td>
                    <td>{Loan.loanAmountSought}</td>
                    <td>{Loan.applicationStatus}</td>

                    {Loan.applicationStatus === "LAMR" ? (
                      <>
                        <td>
                          <ul>
                            {Object.entries(
                              JSON.parse(Loan.partMarkedForReview)
                            ).map(([key, value]) => {
                              return value?(<li key={key} className="FormActionDiv">
                                <input
                                  type="button"
                                  onClick={() =>
                                    handleButtonPartMarkForReview(key,Loan)
                                  }
                                  value={key}
                                />
                              </li>):("")
                            })}
                          </ul>
                        </td>
                        <td>{Loan.reviewRemarks}</td>
                      </>
                    ) : (
                      ""
                    )}
                    <td>
                      {Loan.verifierStaffID ? (
                        <Button
                          variant="outlined"
                          onClick={(e) => {
                            displayStaffDetails(e, Loan.verifierStaffID);
                          }}
                        >
                          {Loan.verifierStaffID}
                        </Button>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {Loan.approverAdminID ? (
                        <Button
                          variant="outlined"
                          onClick={(e) => {
                            displayAdminDetails(e, Loan.approverAdminID);
                          }}
                        >
                          {Loan.approverAdminID}
                        </Button>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {Loan.approverAdmin2ID ? (
                        <Button
                          variant="outlined"
                          onClick={(e) => {
                            displayAdminDetails(e, Loan.approverAdmin2ID);
                          }}
                        >
                          {Loan.approverAdmin2ID}
                        </Button>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        <hr />
        <div>
          <MemberLoanApplicationStatusExplanation
            applicationStatus={selectedLoanApplication.applicationStatus}
          />
        </div>

        {/* Dialog to display Staff Details ................ */}
        <Dialog open={openStaffDisplayDialog} fullWidth maxWidth="sm">
          <DialogContent>
            <div className="adminDisplayDiv">
              <table>
                <tbody>
                  <tr>
                    <td>Staff ID:</td>
                    <td>{staffRBCTCSDetails.staffRBCTCSId}</td>
                  </tr>
                  <tr>
                    <td>Shri/Smt./Ms</td>
                    <td>{staffRBCTCSDetails.name}</td>
                  </tr>
                  <tr>
                    <td>Designation</td>
                    <td>{staffRBCTCSDetails.designation}/RBCTCS</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseStaffDisplayDialog}>❌ Close</Button>
          </DialogActions>
        </Dialog>

        {/* Dialog to display Admin Details ................ */}
        <Dialog open={openAdminDisplayDialog} fullWidth maxWidth="sm">
          <DialogContent>
            <div className="adminDisplayDiv">
              <table>
                <tbody>
                  <tr>
                    <td>RUID:</td>
                    <td>{adminRBCTCSDetails.adminRBCTCSRUID}</td>
                  </tr>
                  <tr>
                    <td>Shri/Smt./Ms</td>
                    <td>{adminName}</td>
                  </tr>
                  <tr>
                    <td>Designation</td>
                    <td>{adminRBCTCSDetails.designationRBCTCS}/RBCTCS</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAdminDisplayDialog}>❌ Close</Button>
          </DialogActions>
        </Dialog>
      </fieldset>
    </div>
  );
}

export default ViewMemberLoanApplications;
