// BankBook.js
import React, { useState, useEffect } from 'react';
import CustomDatePicker from '../../../CustomDatePicker/CustomDatePicker';
import '../BBRFilterInputsForReportGeneration/BBRFilterInputsForReportGeneration.css'; // Include your custom styles

const BBRFilterInputsForReportGeneration = () => {
  const [bankBookData, setBankBookData] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      // Simulate fetching data. Replace with actual data fetching logic.
      setIsLoading(true);

      // In a real application, fetch bank book data from your API here
      // For this example, we're using dummy data
      setTimeout(() => {
        const dummyData = [
          { id: 1, date: '2024-01-01', description: 'Deposit', debit: 5000, credit: 0, balance: 5000 },
          { id: 2, date: '2024-02-01', description: 'Withdrawal', debit: 2000, credit: 0, balance: 3000 },
          // Add more dummy data as needed
        ];

        setBankBookData(dummyData);
        setIsLoading(false);
      }, 1000);
    }
  }, [selectedStartDate, selectedEndDate]); // Fetch data whenever the selected dates change

  const handleStartDateSelect = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateSelect = (date) => {
    setSelectedEndDate(date);
  };

  return (
    <div className="bank-book-container">
      <h2 className="bank-book-title">RBCTCS Bank Book</h2>

      {/* Custom Date Pickers */}
      <CustomDatePicker label="Select Start Date" onSelectDate={handleStartDateSelect} />
      <CustomDatePicker label="Select End Date" onSelectDate={handleEndDateSelect} />

      {isLoading && <p className="loading-message">Loading data...</p>}

      {!isLoading && !bankBookData.length && (
        <p className="instruction-message">Select start and end dates to fetch bank book data.</p>
      )}

      {bankBookData.length > 0 && (
        <table className="bank-book-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Description</th>
              <th>Debit</th>
              <th>Credit</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>
            {bankBookData.map((entry) => (
              <tr key={entry.id}>
                <td>{entry.date}</td>
                <td>{entry.description}</td>
                <td>{entry.debit}</td>
                <td>{entry.credit}</td>
                <td>{entry.balance}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default BBRFilterInputsForReportGeneration