import "../NewOrdinaryLoanApplicationForm/NewOrdinaryLoanApplicationForm.css";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import LoanApplicationInstructions from "../NewLoanApplicationComponents/LoanApplicationInstructions/LoanApplicationInstructions";
import LoanFormDetails from "../NewLoanApplicationComponents/LoanFormDetails/LoanFormDetails";
import LoanSuretyFormDetails from "../NewLoanApplicationComponents/LoanSuretyFormDetails/LoanSuretyFormDetails";
import LoanBond1 from "../NewLoanApplicationComponents/LoanBond1/LondBond1";
import LoanBond2 from "../NewLoanApplicationComponents/LoanBond2/LondBond2";

import React, { useEffect, useState, useLocation} from "react";

import PaymentsForLoan from "../NewLoanApplicationComponents/PaymentsForLoan/PaymentsForLoan";
import LoanApplicationPreview from "../NewLoanApplicationComponents/LoanApplicationPreview/LoanApplicationPreview";

import { useNavigate } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import LinearProgress from "@mui/material/LinearProgress";

import useMyAuthService from "../../../authenticationServiceProvider/useMyAuthService";


const steps = [
  "Instructions",
  "Loan Details",
  // "Payment Details",
  "Surety Details",
  "Loan Bond-1",
  "Loan Bond-2",
  "Preview"
];


const  loanFormFields = {
  loanType:"Main Loan TopUp",
  loanAmount:0,
  loanPurpose:"",
  rateOfInterest:"9",
  noOfIntallments:" ",
  dateFirstInstallment:" ",
  isSharesPayRequired:true
};

const loanSuretyFormFields = {
  ruid: " ", 
  Name: " ", 
  Mobile:" ",
  suretyStatus:"Pending"
  
};

let loanSuretyData = [];




const  loanBond1FormFields = {
  isBond1Signed: false,
  bond1SignDate: new Date().toLocaleDateString('en-GB'),
  bond1SignPlace:" ",
  
};

const  loanBond2FormFields = {
  isBond2Signed: false,
  bond2SignDate: new Date().toLocaleDateString('en-GB'),
  bond2SignPlace:" ",
  
};

//array to help in checking the empty fields on the form submission...................
let emptyFields = [];

const NewOrdinaryLoanTopUpComponent = () => {

    // const data = useLocation();
    // const loanForTopUp = data.state.loanForTopUp;
    const {api, loggedInUserRUID} = useMyAuthService();
  
    const navigate = useNavigate();
  
    const [activeStep, setActiveStep] = React.useState(0);
    const activeprops = { activeStep, setActiveStep};
  
    const [ruidLoggedIn, setRUIDLoggedIn] = useState(loggedInUserRUID);
    const [loggedInMemberDetails, setLoggedInMemberDetails] = useState({});
   
    const [loanType, setLoanType] = useState("Main Loan");
  
    const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);
    
   
    //getting the details of the member logged in currently from the DB...........
    useEffect(() => {
      getLoanLimitDetails();
      getLoggedInRBMemberDetails();
    
    }, []);
  
    const getLoggedInRBMemberDetails = () => {
      api.get(`/getRBMemberDetails/${ruidLoggedIn}`)
        .then((response) => {
           //onsole.log(response);
          setLoggedInMemberDetails(response.data);
          //console.log(response.data);
        });
    };
  
    const getLoanLimitDetails = () => {

      //to pass more than one argument.......
      var params = new URLSearchParams();
          params.append("gradePay", loggedInMemberDetails.gradePay);
          params.append("loanType", loanType);
          
      var request = {
        params: params
      }
      
      api.get(`/getLoanLimitDetails`, request)
        .then((response) => {
    
          console.log(response.data);
          setLoanLimitDetails(response.data[0]);
        });
    };
  
  
    //varaibles contatining the loan form details......................................
   
    const [loanForm, setLoanFormValues]= React.useState(loanFormFields);
    const [loanLimitDetails, setLoanLimitDetails] = useState({});
    const loanformprops = {loanForm, setLoanFormValues, loggedInMemberDetails,  loanType, loanLimitDetails};
  
  
    const [loanSuretyForm, setLoanSuretyFormFieldValues]= React.useState(loanSuretyFormFields);
    const  [loanSurety, setLoanSuretydata] = React.useState(loanSuretyData);
    const loanSuretyFormProps = {loanForm, loanSuretyForm, setLoanSuretyFormFieldValues, loanSurety, setLoanSuretydata, loggedInMemberDetails,  loanType};
  
  
    
    const [loanBond1, setLoanBond1Values]= React.useState(loanBond1FormFields);
    const loanBond1props = {loanBond1, setLoanBond1Values, loanForm, setLoanFormValues, loggedInMemberDetails};
  
    const [loanBond2, setLoanBond2Values]= React.useState(loanBond2FormFields);
    const loanBond2props = {loanBond2, setLoanBond2Values, loanForm, setLoanFormValues, loggedInMemberDetails};
  
    
  
   //functions to take care of the multi step form navigations............................. 
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };
    
    const showStepContents = (step) =>{
          // get the contents as per the active state.
        switch (step) {
          case 0:
            return <LoanApplicationInstructions />;
          case 1:
            return <LoanFormDetails {... loanformprops} />;
          // case 2:
          //   return <PaymentsForLoan {...sharesFormprops}/>
          case 2:
            return <LoanSuretyFormDetails  {... loanSuretyFormProps}/>;
          case 3:
            return <LoanBond1 { ... loanBond1props} />;
          case 4:
            return <LoanBond2 { ... loanBond2props} />;
          case 5:
            return <LoanApplicationPreview   {... loanformprops} 
                                            //  {...sharesFormprops} 
                                             {... loanSuretyFormProps}
                                             { ... loanBond1props}
                                             { ... loanBond2props}
                                             {...activeprops}/>;
          default:
            return null;
        }
    };
  
    async function handleSubmit (e) {
      //preventing the default behavior on submit
      e.preventDefault();
  
      //prepare the loanSuretyForm and NomineeForm objects.......................
      let loanSuretyForm = {
        loanSuretyArr : loanSurety
      }
  
      emptyFields = [];
  
      //check if any of the properties of the loanForm  is empty.......... and dont allow to move forward
      for (var key in loanForm) {
        if (loanForm[key] === null  || loanForm[key] === " "){
          //console.log("please fill in the complete details of the following : "+key)
          emptyFields.push("Loan Form: "+key);
         // return false;
        }
      }  
  
      const amountAvailable = parseInt(loanLimitDetails.loanLimits)-parseInt(loggedInMemberDetails.currentMLOutstanding);
      if(loanForm.loanAmount > amountAvailable){
        alert("Loan Amount sought is greater than the Loan Amount Available. Cannot be submitted");
        return 0;
      }
      // //check if any of the properties of the sharesForm  is empty.......... and dont allow to move forward
      // if(sharesForm.noOfShares !== 0){
      //   for (var key in sharesForm) {
      //     if (sharesForm[key] === null || sharesForm[key] === " ")
      //       emptyFields.push("Shares Form: "+key);
      //   } 
      // }else{
      //     for (var key in sharesForm) {
      //      sharesForm[key]="not required"
      //      } 
      //      sharesForm.noOfShares = 0;
      // }
  
      const noOfSuretiesRequired = parseInt(loanForm.loanAmount/100000)-parseInt(loggedInMemberDetails.compulsoryDeposit/100000);
      if(loanSurety.length !== noOfSuretiesRequired){
         alert("The No of Sureties You have selected is inappropriate. Please select "+noOfSuretiesRequired+ " Sureties to proceed");
           return 0;
      }
      
      //check if any of the properties of the societyForm  is empty.......... and dont allow to move forward
      loanSurety.map((loanSuretyDetails)=>{
        for(var key in loanSuretyDetails){
          if (loanSuretyDetails[key] === null || loanSuretyDetails[key] === " ")
          emptyFields.push("Other loanSurety Form: "+key);
        }
        return 0;
      });
  
  
      //check if any of the properties of the loanbonds  is empty.......... and dont allow to move forward
      if(!loanBond1.isBond1Signed){
          emptyFields.push("Bond 1 is not signed/checked" )
      }
      if(!loanBond2.isBond2Signed){
        emptyFields.push("Bonds 2 is not signed/checked")
      }
  
  
      /////////sending the data to the server id all the data is present
      
      if(emptyFields.length === 0){
          // console.log("The lenght is "+ emptyFields.length)
          //console.log("The data being sent..................");
          //console.log({ ...loanForm, ...sharesForm, ...loanSuretyForm, ...loanBond1, ...loanBond2 });
          let config = {
              method: "POST",
              url: `/NewOrdinaryLoanApplicationTopUp/${ruidLoggedIn}`,
              data:{ ...loanForm,...loanSuretyForm, ...loanBond1, ...loanBond2, applicationStatus:"LAS"},
              headers: {
                Accept: "application/json",
                //  Authorization: varToken
              },
              responseType: "application/json",
            };
  
            setOpenProgressBarDialog(true);
        
            const response = await api(config);
            console.log("data sent from the server.............................");
            console.log(response.data);
           setOpenProgressBarDialog(false);
           // navigate("../MembershipFormSubmission", {replace:true}, { state: response.data });
      }else{
             //form is not ready..................
                 // console.log(emptyFields);
                  alert("Please fill in all the details" +"\r\n"+ emptyFields.map((field, index)=>{
                            return field+"\r\n";
                  }));
      }
        
    };
  
    
  
  
    return (
      <Box sx={{ width: "96%" }} m={2}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
  
            return (
              <Step key={label} {...stepProps}>
                <StepButton>{label}</StepButton>
              </Step>
            );
          })}
        </Stepper>
      
        {<div>
            <form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
            {showStepContents(activeStep)}
            </form> 
          </div>  
        }
            
        {activeStep === steps.length ? (
          <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
  
              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </React.Fragment>
        )}
  
         {/* Dialog to implement the Progess bar ................ */}
         <Dialog
          open={openProgressBarDialog}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="scroll-dialog-title">
            <b>Please wait! work under process ....</b>
          </DialogTitle>
          <DialogContent>
            <LinearProgress />
          </DialogContent>
        </Dialog>
      </Box>
    );
  }
  

export default NewOrdinaryLoanTopUpComponent