import React from "react";
import "./NewUserPortalRegistration.css";

import { useState } from "react";
import axios from "axios";

import LinearProgress from "@mui/material/LinearProgress";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useGetAPIServer
 } from "../../BackEndServerServiceProvider/BackEndServerServiceProvider";
 import bcrypt from 'bcryptjs';

 
const NewUserPortalRegistration = () => {

   const apiServer = useGetAPIServer();
    
    /** The data elements of the form to be stored in these variables */
    const [ruid, setRUID] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [email, setEmail] = useState();
    const [mobile, setMobileNo] = useState();
    const [role, setRole] = useState("New User");
    const [errorMessage, setErrorMessage] = useState();
    const [message, setMessage] = useState();

    const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

    

  async function handleSubmit (e) {
    e.preventDefault();
     //console.log("form has been submitted");
     if(ruid === "" || userName ==="" || password ==="" || confirmPassword ==="" || email===""){
        setErrorMessage("Please fill in all the details")
     }else{
             //setRole("New User");
            if(password !== confirmPassword){
                setErrorMessage("Password Mismatch")
            }else{

                     // Hash the password
                   // const hashedPassword = await bcrypt.hash(password, 10);

                    // prepare the data array
                    const data = {"ruid": ruid, "userName":userName, "pass":password, "role":role, "mobile":mobile, "email":email};
                    //console.log(data);
                    
                    //prepare the cofig array
                    let config = {
                        method: "POST",
                        url: `${apiServer.serverBaseRoute}/NewUserRegistrationRequest`,
                        data:{data},
                        headers: {
                          Accept: "application/json",
                         
                          //  Authorization: varToken
                        },
                        responseType: "json",
                      };
                       
                      setOpenProgressBarDialog(true);
                      const response = await axios(config);
                      //console.log(response.data);

                      if(response.data.length>1){
                        setMessage(response.data[1]);
                      }else{
                        setMessage(response.data);
                      }
                      
                      setOpenProgressBarDialog(false);
            }
        
     } 
  }

  

  return (
    <div className="NewUserRegsitrationForm">
      <fieldset>
        <legend>New User Registration</legend>
        <div className="errorDiv">
            {errorMessage}
        </div>
        <div className="msgDiv">
            {message}
        </div>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}>
          <div className="FormFieldDiv">
            <div>
              <label>RUID (length should be of 7 characters/numbers)</label>
            </div>
            <div>
              <input type={"number"} placeholder="🆔 Enter Your RUID"  min="1000000" max="9999999"  onChange={(e) => {setRUID(e.target.value);}} required />
            </div>
          </div>
          <div className="FormFieldDiv">
            <div>
              <label>Name</label>
            </div>
            <div>
              <input type={"text"} placeholder="👤 Enter Your Name"  onChange={(e) => {setUserName(e.target.value);}} required />
            </div>
          </div>
          <div className="FormFieldDiv">
            <div>
              <label>Mobile No</label>
            </div>
            <div>
              <input type={"number"} placeholder="📱 Enter Your Mobile No"  onChange={(e) => {setMobileNo(e.target.value);}} required />
            </div>
          </div>
          <div className="FormFieldDiv">
            <div>
              <label>Email</label>
            </div>
            <div>
              <input type={"email"} placeholder="📩 Enter Your Email Id"  onChange={(e) => {setEmail(e.target.value);}} required />
            </div>
          </div>
          <div className="FormFieldDiv">
            <div>
              <label>Password</label>
            </div>
            <div>
              <input type={"password"} placeholder="🔑 Enter Your Password"  onChange={(e) => {setPassword(e.target.value);}} required />
            </div>
          </div>
          <div className="FormFieldDiv">
            <div>
              <label>Confirm Password</label>
            </div>
            <div>
              <input
                type={"text"}
                placeholder="🔑 Confirm Your Password"
                onChange={(e) => {setConfirmPassword(e.target.value);}}
                required
              />
            </div>
          </div>
          <hr/>
          <div className="FormActionDiv">
            <input type={"reset"} value="Clear Form" />
            <input type={"submit"} value="Register User" />
          </div>
        </form>
      </fieldset>

      

      {/* Dialog to implement the Progess bar ................ */}
      <Dialog
        open={openProgressBarDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="scroll-dialog-title">
          <b>Please wait! work under process ....</b>
        </DialogTitle>
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      </Dialog>
      

    </div>
  );
};


export default NewUserPortalRegistration;