import React from 'react';
import "./TimeLine.css";

const TimeLine = ({ steps }) => {
  return (
    <div className="timeline">
      {steps.map((step, index) => (
        <div key={index} className="timeline-item">
          <div className="timeline-content">
            <h3>{step.title}</h3>
            <p>{step.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TimeLine;
