import React, { useEffect, useState } from "react";
import "./ManageSharePurchaseRequests.css";


import LinearProgress from "@mui/material/LinearProgress";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";


import useMyAuthService from "../../../../authenticationServiceProvider/useMyAuthService";

import OpenDocumentDisplayer from "../../DialogComponents/OpenDocumentDisplayer/OpenDocumentDisplayer";
import ChequeOnlineReceiptUploader from "../../DialogComponents/ChequeOnlineReceiptUploader/ChequeOnlineReceiptUploader";


const paymentFormFields = {
  noOfShares: 0,
  sharePrice: 100,
  shareAmount: 0,
  beneficieryName: "RBCTCS",
  beneficieryAccountNo: "10211841032",
  beneficieryIFSCCode: "SBIN00003771",
  totalAmount: 0,
};

//let  = " ";

const ManageSharePurchaseRequests = () => {

   const {loggedInUserRUID, api} = useMyAuthService();
  const [currentLoggedInRUID, setCurrentLoggedInRUID] = useState(loggedInUserRUID);


  const [chequeOrTransactionRefNo, setChequeOrTransactionRefNo] =
  useState(null);

  const [loanAmount, setLoanAmount] = useState(0);
  const [RBCTCSMembershipDetails, setRBCTCSMembershipDetails]= useState({});

const [paymentForm, setPaymentFormFieldValues] =
  React.useState(paymentFormFields);
  
  const [isLoadingCheque, setIsLoadingCheque] = useState(false);


const [
  openChequeOnlineReceiptDisplayer,
  setOpenChequeOnlineReceiptDisplayer,
] = useState(false);
const [docFile, setDocFile] = useState();



const [openChequeOnlineReceiptUpdator, setOpenChequeOnlineReceiptUpdator] =
  useState(false);

const [
  isPaymentRequestForMemmbershipMade,
  setIsPaymentRequestForMembershipMade,
] = useState(false);

const [paymentReqData, setPaymentReqData] = useState([]);

const [isLoadingData, setIsLoadingData] = useState(true);
const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);


//getting the XML resources..........................
useEffect(() => {
  getRBCTCSMembershipDetails();
  getPaymentRequestForSharePurchase();
}, [isLoadingCheque]);


const getRBCTCSMembershipDetails = () => {
  api
    .get(
      `/getRBCTCSMembershipDetails/${currentLoggedInRUID}`
    )
    .then((response) => {
      //console.log(response.data);
      setRBCTCSMembershipDetails(response.data);
      //setShareAmountAndChequeAmount(response.data);
    });
};

const getPaymentRequestForSharePurchase = () => {
  const RUID = currentLoggedInRUID;
  api.get(`/GetPaymentRequestForSharePurchase/${RUID}`)
  .then((response) => {
    //console.log(response.data);
    setPaymentReqData(response.data[0]);
  });
};

const setLoanAmountShareAmountAndChequeAmount = (membershipData, LoanReq) =>{
  //getRBCTCSMembershipDetails();
   setLoanAmount(LoanReq);
  let shareAmount=0; let noOfShares = 0; let chequeAmount = 0;
   //console.log(membershipData);
   if (membershipData !== undefined || membershipData !== null) {
    
     if (loanAmount !== null && loanAmount>10) {
       // console.log(parseInt(loanForm.loanAmount));loanAmount !== 0 || loanAmount !== null || loanAmount !== " "
      
       noOfShares =
         Math.ceil(parseInt(loanAmount) / 300) -
         parseInt(membershipData.noOfShares);
       noOfShares <= 0 ? (noOfShares = 0) : (noOfShares = noOfShares);
       shareAmount = noOfShares * 100;
       chequeAmount = shareAmount;

       setPaymentFormFieldValues({
         ...paymentForm,
         noOfShares: noOfShares,
         shareAmount: shareAmount,
         totalAmount: chequeAmount,
       });
     } else {
       setPaymentFormFieldValues({
         ...paymentForm,
         noOfShares: 0,
         shareAmount: 0,
         totalAmount: 0,
       });
     }

     
   }
  
}

const handleOpenChequeOnlineReceiptDisplayer = () => {
  //setIsLoadingCheque(value=>!value);
  let refNo = paymentReqData.tranRefNoOrChequeNo;
  //console.log(paymentReqData);
  api({
    url: `/GetChequeOrOnlineReceiptFile/${refNo}`,
    method: "GET",
    responseType: "blob", // important
    // data:{
    //   RUID:currentLoggedInRUID,
    //   refNo:
    // }
  }).then((response) => {
    const file = new Blob([response.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    //console.log(response.data);
    setDocFile(fileURL);
    setOpenChequeOnlineReceiptDisplayer(true);
  });
};




return (
  <div className="paymentDetailsMainDiv">
    <fieldset>
      <legend>
        🖥️ Upload Your Cheque Details For Shares Purchase
      </legend>
      <div className="gridRow"></div>

      {/* row 2 starts............. */}
      <div className="gridRow">
        <div className="FormFieldDiv">
          <div>
            <label>Beneficiery Name </label>
          </div>
          <div>
            <input
              type={"text"}
              value={paymentForm.beneficieryName}
              readOnly
            />
          </div>
        </div>

        <div className="FormFieldDiv">
          <div>
            <label>Beneficiery Account No.</label>
          </div>
          <div>
            <input
              type={"text"}
              value={paymentForm.beneficieryAccountNo}
              readOnly
            />
          </div>
        </div>

        <div className="FormFieldDiv">
          <div>
            <label>Beneficiery IFSC Code</label>
          </div>
          <div>
            <input
              type={"text"}
              value={paymentForm.beneficieryIFSCCode}
              readOnly
            />
          </div>
        </div>
      </div>

      {/* row 3 starts............. */}
      <div className="gridRow">
        <div className="gridCol">
          <div className="FormFieldDiv">
            <div>
              <label>Loan Amount Required</label>
            </div>
            <div>
              <input
                type={"number"}
                value={loanAmount}
                placeholder="Enter Loan Amount"
                onChange={(e)=>{setLoanAmountShareAmountAndChequeAmount(RBCTCSMembershipDetails, e.target.value)}}
              />
            </div>
          </div>
          
          <div className="FormFieldDiv">
            <div>
              <label>No of Shares to be Purchased</label>
            </div>
            <div>
              <input
                type={"text"}
                value={paymentForm.noOfShares}
                placeholder="Shares to be purchased"
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="gridCol">
          
        </div>
        <div className="gridCol">
          <table className="paymentTable">
            <thead>
              <tr>
                <td>Sl.</td>
                <td>Head</td>
                <td>No of Shares</td>
                <td>Share Amount</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Existing Shares</td>
                <td>{RBCTCSMembershipDetails.noOfShares}</td>
                <td>{RBCTCSMembershipDetails.totalShareAmount}</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Shares Required</td>
                <td>{paymentForm.noOfShares}</td>
                <td>{paymentForm.shareAmount}</td>
              </tr>
              
              <tr>
                <td colSpan={2}>Total Amount/Shares after Purchase</td>
                <td>{RBCTCSMembershipDetails.noOfShares + paymentForm.noOfShares}</td>
                <td>{paymentForm.shareAmount + RBCTCSMembershipDetails.totalShareAmount}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* row 3 ends................. */}
      <hr></hr>
      
        <>
          <div className="gridRow">
            <div className="FormActionDiv gridCol">
              <ChequeOnlineReceiptUploader
                  openChequeOnlineReceiptUpdator={
                    openChequeOnlineReceiptUpdator
                  }
                  setOpenChequeOnlineReceiptUpdator={
                    setOpenChequeOnlineReceiptUpdator
                  }
                  RUID={currentLoggedInRUID}
                  setChequeOrTransactionRefNo={setChequeOrTransactionRefNo}
                  setOpenProgressBarDialog={setOpenProgressBarDialog}
                  requestType={"SMPAY"}
                  setIsLoadingCheque = {setIsLoadingCheque}
                  originalOrUpdate = {'Original'}
                  applicationStatus = {'nothing'}
                  applicationId = {" "}
                />
            </div>
            <div className="FormActionDiv gridCol">
              <input
                type={"button"}
                onClick={() => handleOpenChequeOnlineReceiptDisplayer()}
                value={"💰 View Uploaded Cheque/Online Receipt"}
              />
              <OpenDocumentDisplayer
                docTitle={"View Uploaded Cheque/Online Receipt"}
                docFile={docFile}
                openDocDisplayer={openChequeOnlineReceiptDisplayer}
                setOpenDocDisplayer={setOpenChequeOnlineReceiptDisplayer}
              />
            </div>
          </div>
          
        </>
   
      {/* Dialog to implement the Progess bar ................ */}
      <Dialog open={openProgressBarDialog} fullWidth maxWidth="sm">
        <DialogTitle id="scroll-dialog-title">
          <b>Document being uploaded ......... </b>
        </DialogTitle>
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      </Dialog>
    </fieldset>
  </div>
);
}

export default ManageSharePurchaseRequests