import React from "react";
import "./MemberBankDetailsForm.css";

const MemberBankDetailsForm = (props) => {
  const { bankForm, setbankFormFieldValues, Banks } = props;

  const setCDAmountAndChequeAmount = (cdAmount) =>{

   // console.log(cdAmount);
    let Amount = parseInt(bankForm.shareAmount)+parseInt(bankForm.admissionFees)+parseInt(bankForm.benevolentFund)+parseInt(cdAmount);
    
    //console.log(typeof parseInt(Amount));
    //setting the CD amount and total amount to be paid vide cheque...........
    setbankFormFieldValues({...bankForm, compulsoryDeposit: cdAmount, chequeAmount:Amount});
   // console.log(bankForm.compulsoryDeposit);
   
  }

  return (
    <div className="bankDetailsMainDiv">
      <fieldset>
        <legend>🖥️ Fill Your Bank and Payment Details</legend>
        Salary Bank Details
        <hr />
        <div className="gridRow">
          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Bank Name</label>
              </div>
              <div>
                <input
                  list="bankNames"
                  value={bankForm.salaryBankName}
                  onChange={(e) =>
                    setbankFormFieldValues({
                      ...bankForm,
                      salaryBankName: e.target.value,
                    })
                  }
                />
                <datalist id="bankNames">
                  {Banks.map((bank, index) => {
                    return (
                      <option key={index} value={bank.Code}>
                        {bank.FullForm}
                      </option>
                    );
                  })}
                </datalist>
              </div>
            </div>
          </div>
          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Account No.</label>
              </div>
              <div>
                <input
                  type={"number"}
                  value={bankForm.salaryBankAccountNo}
                  onChange={(e) =>
                    setbankFormFieldValues({
                      ...bankForm,
                      salaryBankAccountNo: e.target.value,
                    })
                  }
                  placeholder="Enter your Bank Account No."
                />
              </div>
            </div>
          </div>
          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>IFSC Code</label>
              </div>
              <div>
                <input
                  type={"text"}
                  value={bankForm.salaryBankIFSC}
                  onChange={(e) =>
                    setbankFormFieldValues({
                      ...bankForm,
                      salaryBankIFSC: e.target.value,
                    })
                  }
                  placeholder="Enter Bank IFSC Code"
                />
              </div>
            </div>
          </div>
        </div>
       
      </fieldset>
    </div>
  );
};

export default MemberBankDetailsForm;
