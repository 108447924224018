import React from 'react'
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

function SaveMembershipApplicationToServer(props) {

  const { state } = useLocation();

   console.log(state);

 // saveFormDataToDataBase();

  return (
    <div className='SaveMembershipApplicationToServerMainDiv'>
      {(state === null || state === undefined)?('Your Form Couldn\'t be save succesfully'): 
      (
        <>
          <p>{'Your Application has been submitted'}</p>
        </>
        
        )}
      
    </div>
  )
}

export default SaveMembershipApplicationToServer