import "./ReviewLoanApplicationForm"

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import useMyAuthService from "../../../authenticationServiceProvider/useMyAuthService";


const ReviewLoanApplicationForm = () => {


  const navigate = useNavigate();

  const data = useLocation();
  //console.log(data);
  const loanApplicationId = data.state.loanApplicationId;

  const {loggedInUserRUID, api} = useMyAuthService();

  const [loanForm, setLoanFormValues] = useState({
    currentOutstanding:0,
    AmountAvailableForLoan:0,
    loanType: " ",
    loanCategory: " ",
    loanAmount: 0,
    loanPurpose: "",
    rateOfInterest: " ",
    dateFirstInstallment: " ",
   
  });

 
  //getting the details of the member logged in currently from the DB...........
  useEffect(() => {
    getLoanApplicationDetailsForReview();
  
  }, []);

  const getLoanApplicationDetailsForReview = () => {
    api.get(`/GetLoanApplicationDetailsForReview/${loanApplicationId}`)
      .then((response) => {
        // console.log(response.data);
        setLoanFormValues({
          ...loanForm, 
          currentOutstanding:response.data.currentOutstanding,
          amountAvailableForLoan:response.data.amountAvailableForLoan,
          loanType: response.data.loanType,
          loanCategory: response.data.loanCategory,
          loanAmount: response.data.loanAmount,
          loanPurpose: response.data.loanPurpose,
          rateOfInterest: response.data.rateOfInterest,
          dateFirstInstallment: response.data.dateFirstInstallment,

        });
        //console.log(regData);
      });
  };

 
  const handleSubmitLoanApplicationFormForReview = ()=>{

  }



  //console.log(loanForm);
  return (
    <div className="LoanFormDetailsMainDiv">
      <fieldset>
        <legend>🖥️ Please fill in the Loan Details</legend>
        <div className="eligibilityDetailsDiv">
          <span>Your Current Outstanding is : {loanForm.currentOutstanding}</span>
          <span></span>
          <span>Amount Avail for loan :  {loanForm.amountAvailableForLoan}</span>
        </div>
        <div className="gridRow">
          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Loan Amount</label>
              </div>
              <div>
                <input
                  type={"number"}
                  name="loanAmount"
                  placeholder="Fill Required Loan Amount "
                  min="1000"
                  value={loanForm.loanAmount}
                  onChange={(e) => setLoanFormValues({...loanForm, loanAmount:e.target.value})}
                />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Loan Purpose</label>
              </div>
              <div>
                <input
                  type={"text"}
                  name="loanPurpose"
                  placeholder="Fill Your Loan Purpose"
                  value={loanForm.loanPurpose}
                  onChange={(e) =>
                    setLoanFormValues({
                      ...loanForm,
                      loanPurpose: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Rate Of Interest</label>
              </div>
              <div>
                <input
                  type={"number"}
                  name="loanRateOfInt"
                  placeholder="Rate of Interest"
                  value={loanForm.rateOfInterest}
                  readOnly
                />
              </div>
            </div>
          </div>
          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>No of Installments</label>
              </div>
              <div>
                <input
                  type={"number"}
                  name="noOfInstallments"
                  placeholder="No. of Installments Required"
                  min="1"
                  max="60"
                  value={loanForm.noOfIntallments}
                  onChange={(e) =>
                    setLoanFormValues({
                      ...loanForm,
                      noOfIntallments: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Month of First Installment</label>
              </div>
              <div>
                <input
                  type={"month"}
                  name="dateFirstInstallment"
                  placeholder="First Installment Month"
                  value={loanForm.dateFirstInstallment}
                  onChange={(e) =>
                    setLoanFormValues({
                      ...loanForm,
                      dateFirstInstallment: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
       
        <div className="gridRow">
          <br />
          <div className="FormActionDiv gridCol">
            <input
              type={"button"}
              onClick={() => handleSubmitLoanApplicationFormForReview()}
              value="Submit Loan Application Form For Review"
            />
          </div>
        </div>
      </fieldset>
    </div>
  );
}

export default ReviewLoanApplicationForm