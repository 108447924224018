import React, {useState} from "react";
import Button from "@mui/material/Button";

import { formatDate } from "../../../../ComonFunctionsProvider/CommonFunctionsProvider";

import "../TableDataDisplayer.css"
import PaginationNumberRendering from "../PaginationNumberRendering/PaginationNumberRendering";


const LoanDisbursalReportsTable = ({
  loanDisbursalReports, //details of all the membership 
  displayStaffDetails,
  handleClickOpenMenu,
  openMenu,
}) => {

  const toCamelCase = (str) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+|-|_/g, "");
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPage = 12; // Number of items to display per page

  const maxPageNumbersToShow = 5; // Adjust the number of page numbers to show

  // Calculate the total number of pages
  const totalPages = Math.ceil(loanDisbursalReports.length / itemsPerPage);

  // Get the current page's items based on the currentPage and itemsPerPage
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = loanDisbursalReports
    .filter((loanDisbursalReport) =>
      Object.values(loanDisbursalReport)
        .join(" ")
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    )
    .slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle search query change
  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page when search query changes
  };

  


  return (
    <div className="table-data-displayer">
      <div className="quickSearchBoxDiv">
        <input
          type="text"
          placeholder="Search RUID..."
          value={searchQuery}
          onChange={handleSearchQueryChange}
        />
      </div>

      <table className="dataDisplayerTable centerContent">
        <thead>
          <tr>
            <td>Sl.</td>
            <td>ReportId</td>
            <td>Disbursal Date</td>
            <td>Disbursal ChequeNo</td>
            <td>Disbursal Cheque Date</td>
            <td>Disbursal Cheque Amount</td>
            <td>Disbursal StaffID </td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((LDR, index) => {
            return (
              <tr key={index + 1}>
                <td>{index + 1}</td>
                <td>{LDR.disbursalReportId}</td>
                <td>{formatDate(LDR.disbursalDate)}</td>
                <td>{LDR.disbursalChequeNo}</td>
                <td>{formatDate(LDR.disbursalChequeDate)}</td>
                <td>{LDR.disbursalChequeAmount}</td>
                <td>
                  {LDR.disbursalStaffID  ? (
                    <Button
                      variant="outlined"
                      onClick={(e) => {
                        displayStaffDetails(e, LDR.disbursalStaffID );
                      }}
                    >
                      {LDR.disbursalStaffID}
                    </Button>
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  <Button
                    id="basic-button"
                    aria-controls={openMenu ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? "true" : undefined}
                    onClick={(e) => handleClickOpenMenu(e, LDR)}
                  >
                    ⚙️
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Pagination */}
      <PaginationNumberRendering 
          maxPageNumbersToShow={maxPageNumbersToShow}
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default LoanDisbursalReportsTable;
