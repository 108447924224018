import React from 'react';
import { useNavigate } from 'react-router-dom';
import DashBoardFooter from '../../generalComponents/DashBoardFooter/DashBoardFooter';
import DashBoardHeader from '../../generalComponents/DashBoardHeader/DashBoardHeader';
import DashBoardSideNav from '../../generalComponents/DashBoardSideNav/DashBoardSideNav';
import DashWorkSpace from '../../generalComponents/DashWorkSpace/DashWorkSpace';
import "./MemberDashHome.css";


const menuTree = [
  {
    id: 1,
    head: "Manage your profile Details",
    subMenu: ["Manage Personal Details", "Manage Official Details", "Manage Bank Details",  "Manage Nominees Details", "Update LOG In Password"],
  },
  // "Manage Other Society Details"
  {
    id: 2,
    head: "Manage Membership Details",
    subMenu: ["Manage Membership Details"],
  },
  {
    id: 3,
    head: "Manage Surety Details",
    subMenu: ["Manage Sureties You Received", "Manage Sureties You Gave"],
  },
  {
    id: 4,
    head: "Manage Your Loans",
    subMenu: ["Manage Your Existing Loans", 
              "Apply For New Ordinary Loan", 
              "Apply For New Emergent Loan", 
              "View Your Loan Applications"],
  },
  {
    id: 5,
    head: "Manage Your Shares & CD Payment Requests",
    subMenu: ["Share Purchase Requests", "View Shares Purchase Requests Status", "View & Download Shares Purchase Statement", "View & Download CD Statement"],
  }
];

const MemberDashHome = () => {
   const navigate = useNavigate();
  const selectedMenuItem =item=>{
      navigate(item.replace(/ /g,''));
  }

  return (
    <div className='memberDashMainDiv'>
      <DashBoardHeader></DashBoardHeader>
      <div className='memberDashBoardBody'>
        <DashBoardSideNav menuTree={menuTree} selectedMenuItem={selectedMenuItem}></DashBoardSideNav>
        <DashWorkSpace></DashWorkSpace>
      </div>
      <DashBoardFooter></DashBoardFooter>
    </div>
  )
}

export default MemberDashHome