import "./ViewAndDownloadCDStatement.css";
import React, { useState, useEffect } from "react";

import Button from "@mui/material/Button";

import ProgressorBarDisplayer from "../DialogComponents/ProgressorBarDisplayer/ProgressorBarDisplayer";
import LRMWTableCDLedgerData from "../TableDataDisplayer/LRMWTableCDLedgerData/LRMWTableCDLedgerData";
import useMyAuthService from "../../../authenticationServiceProvider/useMyAuthService";

const ViewAndDownloadCDStatement = () => {
  const { api, loggedInUserRUID } = useMyAuthService();
  //getting the list to populate the drop down list in the filters menu..................
  //const rbMemberListForFilters = props.rbMemberListForFilters;

  //filter fileds that have been selected for getting data from the server........
  const filters = {
    selectedRBMemberRUID: loggedInUserRUID,
  };

  const [filterFields, setFilterFields] = useState(filters);

  const [memberCompulsoryDeposit, setMemberCompulsoryDeposit] = useState([]);
  const [
    isMemberCompulsoryDepositDataLoaded,
    setIsMemberCompulsoryDepositDataLoaded,
  ] = useState(false);

  const [CompulsoryDepositLedgerData, setCompulsoryDepositLedgerData] =
    useState([]);
  const [
    isCompulsoryDepositLedgerDataLoaded,
    setIsCompulsoryDepositLedgerDataLoaded,
  ] = useState(false);

  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

  useEffect(() => {
    loadCDLedgerDetailsForMember();
  }, []);

  const loadCDLedgerDetailsForMember = async () => {
    //console.log(filterFields);
    let config = {
      method: "POST",
      url: `/GetMemberCompulsoryDepositForCDLRMW`,
      data: { filterFields },
      headers: {
        Accept: "application/json",
      },
      responseType: "json",
    };

    setOpenProgressBarDialog(true);
    const response = await api(config);
    //console.log(response.data);
    setMemberCompulsoryDeposit(response.data);
    //   setIsMemberCompulsoryDepositDataLoaded(true);
    setOpenProgressBarDialog(false);
  };

  const handleClickGetCompulsoryDepositLedgerData = async (
    selectedMemberRUID
  ) => {
    //console.log(filterFields);
    let config = {
      method: "POST",
      url: `/GetCompulsoryDepositTransactionsForCDLRMW`,
      data: { selectedMemberRUID },
      headers: {
        Accept: "application/json",

        //  Authorization: varToken
      },
      responseType: "json",
    };

    setOpenProgressBarDialog(true);
    const response = await api(config);
    //console.log(response.data);
    setCompulsoryDepositLedgerData(response.data);
    setIsMemberCompulsoryDepositDataLoaded(false);
    setIsCompulsoryDepositLedgerDataLoaded(true);

    setOpenProgressBarDialog(false);
  };

  const goBackToStepOneFilters = () => {
    //alert("cle")
    setIsMemberCompulsoryDepositDataLoaded(false);
    setIsCompulsoryDepositLedgerDataLoaded(false);
  };

  return (
    <>
      <div className='ledgerReportMemberWiseMainDiv'>
        <fieldset className="scrollWithoutScrollBar">
         <legend>📝 CD Statement View/Download for  # {loggedInUserRUID}</legend>
          {!isMemberCompulsoryDepositDataLoaded &&
          !isCompulsoryDepositLedgerDataLoaded ? (
            <div className="cdStatementDiv">
              <div>
                <h3>
                  <center>Compulsory Deposit Ledger Details</center>
                </h3>
              </div>
              <table className="cdLedgerTableTable  centerContent">
                <thead>
                  <tr>
                    <td>Sl.</td>
                    <td>RUID </td>
                    <td>Member </td>
                    <td>Ledger No.</td>
                    <td>Grade Pay</td>
                    <td>Compulsory Deposit</td>
                    <td>View Statement</td>
                  </tr>
                </thead>
                <tbody>
                  {memberCompulsoryDeposit.map((cd, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{cd.rbMemberRUID}</td>
                        <td>
                          {cd.name} ({cd.designation})
                        </td>
                        <td>{cd.ledgerNo}</td>
                        <td>{cd.gradePay}</td>
                        <td>{cd.compulsoryDeposit}</td>
                        <td>
                          <Button
                            variant="outlined"
                            onClick={() =>
                              handleClickGetCompulsoryDepositLedgerData(
                                cd.rbMemberRUID
                              )
                            }
                          >
                            View
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="cdStatementDiv">
              <LRMWTableCDLedgerData
                className={"cdLedgerTableTable centerContent"}
                cdTransactionsList={CompulsoryDepositLedgerData}
              />
            </div>
          )}
        </fieldset>
      </div>

      {/* Dialog to implement the Progess bar ................ */}
      <ProgressorBarDisplayer
        titleMessage={"Please Wait! Loading CD transactions data....."}
        openProgressBarDialog={openProgressBarDialog}
      />
    </>
  );
};

export default ViewAndDownloadCDStatement;
