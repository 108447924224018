import "./FinalPrintReportForDeduction.css";

import React, { useState, useRef } from "react";

import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import EDRTableData from "../../../TableDataDisplayer/EDRTableData/EDRTableData";

const FinalPrintReportForDeduction = () => {
  let totalDeductionAmount = 0,
    totalInterest = 0;

  const data = useLocation();
  const membersDeductionList = data.state.membersDeductionList;
  //const chequeForm = data.state.chequeForm;

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div className="LDRActionsDiv">
        <input type={"button"} value={"🖨 Print Report"} onClick={handlePrint} />
      </div>
      <ComponentToPrint
        ref={componentRef}
        membersDeductionList={membersDeductionList}
      />
    </>
  );
};

export default FinalPrintReportForDeduction;

export const ComponentToPrint = React.forwardRef((props, ref) => {
  const { membersDeductionList } = props;
  let totalDeductionAmount = 0,
    totalInterest = 0;
  return (
    <div ref={ref} className="reportMainDiv" id="printablediv">
      <div className="loanBond1HeaderDiv">
        <h3>RAILWAY BOARD CO-OPERATIVE THRIFT & CREDIT SOCIETY LTD.</h3>
        <hr />
        <div>Room No. 415-C, Rail Bhawan, New Delhi. Contact No.23303596</div>
        <div>(Registered under the Co-Op. Societies Act. 11 of 1912)</div>
        <div>(Registration No. 374U(A) Dated 01-07-1931)</div>
      </div>
      <div className="divAddressee">
        The PAO, <br />
        Railway Board, <br />
        Rail Bhavan, New Delhi <br />
      </div>
      <div className="divSalutation">Dear Sir/Madam,</div>
      <div className="divPara">
        <p>We enclose a list of deductions to be made.</p>
      </div>
      <div>
        <table className="EDRContentTable2 centerContent">
          <thead>
            <tr>
              <td>Sl.</td>
              <td>Employee Id</td>
              <td>Employee Name</td>
              <td>Ledger No.</td>
              <td>Compulsory Deposit</td>
              <td>Main Loan O/S</td>
              <td>ML Int.</td>
              <td>Emergent Loan O/S</td>
              <td>EL Int.</td>
              <td>Misc.</td>
              <td>Final EMI Amount</td>
              {/* <td></td> */}
            </tr>
          </thead>
          <tbody>
            {membersDeductionList.map((member, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{member.EmployeeId}</td>
                  <td className="justifyContent">{member.EmployeeName}</td>
                  <td>{member.LedgerNo}</td>
                  <td>{member.CompulsoryDeposit}</td>
                  <td>{member.MainLoanDeductionAmt}</td>
                  <td>{member.MainLoanInterest}</td>
                  <td>{member.EmergentLoanDeductionAmt}</td>
                  <td>{member.EmergentLoanInterest}</td>
                  <td>{member.MiscAmt}</td>
                  <td>{member.emiAmount}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="divClosureStars">*****</div>
    </div>
  );
});
