import "./RBCTCSMembershipDetailsViewByStaffOrAdmin.css";

import React, { useEffect, useState } from "react";

import useMyAuthService  from "../../../../authenticationServiceProvider/useMyAuthService";
import ProgressorBarDisplayer from "../../DialogComponents/ProgressorBarDisplayer/ProgressorBarDisplayer";
import RBMembersListDropDown from "../../RBMembersListDropDown/RBMembersListDropDown";

function RBCTCSMembershipDetailsViewByStaffOrAdmin() {

  const sharesPurchasePaymentObj = {
    rbMemberRUID: "",
    loanApplicationId: "",
    prePaymentAmount: "",
    fileOnlineReceiptOrCheque:"",
    loanPrePaymentMode:""
  };


  const {loggedInUserRUID, api} = useMyAuthService();
  
  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

 const [selectedMemberRUID, setSelectedMemberRUID] = useState("");

  const [sharesPurchasePaymentForm, setSharesPurchasePaymentForm] = useState(sharesPurchasePaymentObj);
  const [RBCTCSMembershipData, setRBCTCSMembershipDetails] = useState({});

  const [isLoadingData, setIsLoadingData] = useState(true);

  const [openSharesPurchasePaymentDialog, setOpenSharesPurchasePaymentDialog] =
    React.useState(false);


    

  const getRBCTCSMembershipDetails = () => {
    api
      .get(
        `/getRBCTCSMembershipDetails/${selectedMemberRUID}`
      )
      .then((response) => {
        // console.log(response.data);
        setRBCTCSMembershipDetails(response.data);
        //console.log(regData);
        setIsLoadingData(false);
      });
  };


  const GetMemberRBCTCSDetails = ()=>{
    setOpenProgressBarDialog(true);
      getRBCTCSMembershipDetails();
     setOpenProgressBarDialog(false);
  }

  return (
    <div className="RBCTCSMembershipDetailsViewByStaffOrAdminMainDiv">
      <fieldset>
        <legend>🔍 View Society Membership Details</legend>
          <RBMembersListDropDown 
            setSelectedMemberRUID={setSelectedMemberRUID}
            actionButtonText={"Click to View Member Society Details"}
            actionButtonFunction={GetMemberRBCTCSDetails}
          />
         <hr/>
          <>
            <div className="gridRow">
              <div className="gridCol">
              <table>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{RBCTCSMembershipData.name}</td>
                    </tr>
                    <tr>
                      <td>RUID</td>
                      <td>{RBCTCSMembershipData.rbMemberRUID}</td>
                    </tr>
                    <tr>
                      <td>Ledger No.</td>
                      <td>{RBCTCSMembershipData.ledgerNo}</td>
                    </tr>
                    <tr>
                      <td>No of shares</td>
                      <td>{RBCTCSMembershipData.noOfShares}</td>
                    </tr>
                    <tr>
                      <td>Total Share Amount</td>
                      <td>{RBCTCSMembershipData.totalShareAmount}</td>
                    </tr>
                    <tr>
                      <td>Compulsory Deposit Amount</td>
                      <td>{RBCTCSMembershipData.compulsoryDeposit}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="gridCol">
                <table>
                  <tbody>
                    <tr>
                      <td>Date of Membership Application</td>
                      <td>
                        {RBCTCSMembershipData.dateOfMembershipApplication}
                      </td>
                    </tr>
                    <tr>
                      <td>Date of Membership Approval</td>
                      <td>{RBCTCSMembershipData.dateOfMembershipApproval}</td>
                    </tr>
                    <tr>
                      <td>Application Verified By</td>
                      <td>{RBCTCSMembershipData.MVSRUID}</td>
                    </tr>
                    <tr>
                      <td>Membership Approved By</td>
                      <td>{RBCTCSMembershipData.MAAID}</td>
                    </tr>
                    <tr>
                      <td>Main Loan Outstanding</td>
                      <td>{RBCTCSMembershipData.currentMLOutstanding}</td>
                    </tr>
                    <tr>
                      <td>Emergent Loan Outstanding</td>
                      <td>{RBCTCSMembershipData.currentELOutstanding}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
          </>
          <ProgressorBarDisplayer
             titleMessage = {'Loading Member Personal Details'} openProgressBarDialog = {openProgressBarDialog} />

        
      </fieldset>
    </div>
  );
}

export default RBCTCSMembershipDetailsViewByStaffOrAdmin;
