import React from "react";
import "./SettingOtherLimits.css";

import { useState } from "react";
import axios from "axios";
import { useGetAPIServer } from "../../../../BackEndServerServiceProvider/BackEndServerServiceProvider";

let createOtherSettingForm = {
  sharesPrice: " ",
  cdForSurety: " ",
  retirementLimitForSurety: " ",
};

function SettingOtherLimits() {

  const apiServer = useGetAPIServer();
  /** The data elements of the form to be stored in these variables */
  const [createOtherSettingFormData, setCreateOtherSettingFormData] =
    useState(createOtherSettingForm);
  const [errorMessage, setErrorMessage] = useState();
  const [message, setMessage] = useState();

  async function handleSubmit(e) {
    e.preventDefault();
    //console.log("form has been submitted");
    // console.log(createStaffFormData);

    //  if(createStaffFormData.staffSign ==="" && createStaffFormData.staffPhoto ==="" ){
    //     setErrorMessage("Please Upload the Photo and Signatures for proceeding further" );
    //  }else{

    const data = createOtherSettingFormData;
    //console.log(data);
    let config = {
      method: "POST",
      url: `${apiServer.serverBaseRoute}/CreateOtherSettingDetails`,
      data: { data },
      headers: {
        Accept: "application/json",

        //  Authorization: varToken
      },
      responseType: "json",
    };

    const response = await axios(config);
    //console.log(response.data);
    setMessage(response.data);

    // }
  }

  return (
    <div className="SettingOtherLimits">
      <fieldset>
        <legend>Other Details Settings</legend>
        <div className="errorDiv">{errorMessage}</div>
        <div className="msgDiv">{message}</div>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="gridRow">
            <div className="gridCol">
              <div className="FormFieldDiv">
                <div>
                  <label>Shares Price</label>
                </div>
                <div>
                  <input
                    type={"number"}
                    name="sharesPrice"
                    placeholder="Enter Shares Required "
                    value={createOtherSettingFormData.sharesPrice}
                    onChange={(e) =>
                      setCreateOtherSettingFormData({
                        ...createOtherSettingFormData,
                        sharesPrice: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>

              <div className="FormFieldDiv">
                <div>
                  <label>Compulsory Deposit For Surety</label>
                </div>
                <div>
                  <input
                    type={"number"}
                    name="cdForSurety"
                    placeholder="Enter CD for giving Surety "
                    value={createOtherSettingFormData.cdForSurety}
                    onChange={(e) =>
                      setCreateOtherSettingFormData({
                        ...createOtherSettingFormData,
                        cdForSurety: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>

              <div className="FormFieldDiv">
                <div>
                  <label>Retirement Gap</label>
                </div>
                <div>
                  <input
                    type={"number"}
                    name="retirementLimitForSurety"
                    placeholder="Enter Retirement Limit"
                    value={createOtherSettingFormData.retirementLimitForSurety}
                    onChange={(e) =>
                      setCreateOtherSettingFormData({
                        ...createOtherSettingFormData,
                        retirementLimitForSurety: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="FormActionDiv">
            <input type={"button"} value="Clear Form" />
            <input type={"submit"} value="Save Settings" />
          </div>
        </form>
      </fieldset>
    </div>
  );
}

export default SettingOtherLimits;
