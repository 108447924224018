import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Outlet } from 'react-router-dom';

import Banner from '../BannerRBCTCS/BannerRBCTCS';
import FooterRBCTCS from '../FooterRBCTCS/FooterRBCTCS';
import NavigateBar from '../NavigateBar/NavigateBar';
import AvtarProfileWidget from '../../components/AvtarProfileWidget/AvtarProfileWidget';
import defaultImage from '../../images/memberpics/defaultMemberPic.png';

import { useGetAPIServer } from '../../BackEndServerServiceProvider/BackEndServerServiceProvider';

import './PortalWithoutLogin.css';

function PortalWithoutLogin() {

  const [adminProfiles, setAdminProfiles] = useState([]);
  const apiServer = useGetAPIServer();

   
  // useEffect(() => {
  //   const getRBMemberPhotoDetails = (RUID) => {
  //     return axios
  //       .get(`${apiServer.serverBaseRoute}/GetMemberPhotoForAdminProfiles/${RUID}`, {
  //         responseType: 'blob', // important
  //       })
  //       .then((response) => {
  //         const url = window.URL.createObjectURL(new Blob([response.data]));
  //         return url;
  //       })
  //       .catch((error) => {
  //         // console.error(`Error fetching photo for RUID ${RUID}:`, error);
  //         // Return a default image URL or placeholder
  //         return defaultImage;
  //       });
  //   };
  
  //   const getXMLResources = () => {
  //     axios
  //       .get(`${apiServer.serverBaseRoute}/getAdminProfilesXMLResources/adminProfiles`)
  //       .then((response) => {

  //         const profilePromises = response.data.AdminProfile.map((profile) => {
  //           if (profile.RUID) {
  //             return getRBMemberPhotoDetails(profile.RUID)
  //               .then((photoUrl) => {
  //                 profile.Image = photoUrl;
  //                 return profile;
  //               });
  //           } else {
  //             return profile; // Return profile as is if no RUID
  //           }
  //         });
  
  //         // Wait for all promises to resolve
  //          return Promise.all(profilePromises);
  //       })
  //       .then((profiles) => {
  //         setAdminProfiles(profiles);
  //       })
  //       .catch((error) => {
  //         console.error('Error fetching admin profiles:', error);
  //       });
  //   };
  
  //   // Call the function
  //   getXMLResources();
  // }, [apiServer.serverBaseRoute]);
  
  
  

  useEffect(() => {
  const getXMLResources = () => {
    axios
      .get(`${apiServer.serverBaseRoute}/getAdminProfilesXMLResources/adminProfiles`)
      .then((response) => {
      //  console.log(response.data.AdminProfile);
        setAdminProfiles(response.data.AdminProfile);
      })
      .catch((error) => {
        console.error('Error fetching admin profiles:', error);
      });
  };

  getXMLResources();
}, []);


  return (
    <div className="PortalWithoutLogin">
      <Banner />
      <hr />
      <NavigateBar />
      <hr />
      <div className="bodyContentDiv">
        <div className="avtarProfileDiv">
          <div className="marquee-wrapper-css">
            {adminProfiles.map((profile, index) => (
              <span key={index}>
                <AvtarProfileWidget
                  name={profile.Name}
                  designation={profile.Designation}
                  mobileNo={profile.MobileNo}
                  roomNo={profile.RoomNo}
                  RUID={profile.RUID}
                  // image={profile.Image}
                  
                />
              </span>
            ))}
          </div>
        </div>
        <div className="outletDiv">
          <Outlet />
        </div>
      </div>
      <hr />
      <FooterRBCTCS />
    </div>
  );
}

export default PortalWithoutLogin;
