import React from 'react';
import { useNavigate } from 'react-router-dom';
import DashBoardFooter from '../../generalComponents/DashBoardFooter/DashBoardFooter';
import DashBoardHeader from '../../generalComponents/DashBoardHeader/DashBoardHeader';
import DashBoardSideNav from '../../generalComponents/DashBoardSideNav/DashBoardSideNav';
import DashWorkSpace from '../../generalComponents/DashWorkSpace/DashWorkSpace';
import "./StaffRBCTCSDashHome.css";


const menuTree = [
  {
    id: 1,
    head: "Manage Your Details",
    subMenu: ["Manage Your Details", "Update LOG In Password"],
  },
 
  {
    id: 2,
    head: "Verifications",
    subMenu: ["Verify New Membership Application", "Verify New Loan Application"],
  },
  {
    id: 3,
    head: "Manage Payments",
    subMenu: ["Manage Misc Payments"],
  },
  {
    id: 4,
    head: "Manage Bulletin Board",
    subMenu: ["Upload RBCTCS Orders & Circulars"],
  },
  {
    id: 5,
    head: "Manage Messages To RBCTCS",
    subMenu: ["Save Replies to the Messages"],
  },
  {
    id: 6,
    head: "Reports",
    subMenu: [
              "Loan Disbursement Report", 
              "Ledger Report Head Wise",
              "Ledger Report Member Wise",
              "Bank Book",
              "Trial Balance",
              "EMI deductions Report for PAO"
             ],
  },
  {
    id: 7,
    head: "Manage Member Details",
    subMenu: ["View Sureties Given By Member", "View Sureties Given To Member", "View Member Personal Details", "View Member Official Details" ,"View Member Nominee Details", "View Member Bank Details", "View Member RBCTCS Details"],
  },
  {
    id: 8,
    head: "Settings",
    subMenu: ["Create Accounts Head", "Create New Staff Member", "Create New Admin"],
  },
];

const StaffRBCTCSDashHome = () => {
  const navigate = useNavigate();
  const selectedMenuItem =item=>{
      navigate(item.replace(/ /g,''));
  }

  return (
    <div className='StaffRBCTCSDashMainDiv'>
      <DashBoardHeader></DashBoardHeader>
      <div className='StaffRBCTCSDashBoardBody'>
        <DashBoardSideNav menuTree={menuTree} selectedMenuItem={selectedMenuItem}></DashBoardSideNav>
        <DashWorkSpace></DashWorkSpace>
      </div>
      <DashBoardFooter></DashBoardFooter>
    </div>
  )
}

export default StaffRBCTCSDashHome