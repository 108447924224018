import "./LoanSuretyFormDetails.css";
import React, { useState, useEffect } from "react";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import useMyAuthService from "../../../../authenticationServiceProvider/useMyAuthService";

const LoanSuretyFormDetails = (props) => {
  const { api } = useMyAuthService();

  const {
    loanForm,
    loanLimitDetails,
    loanSuretyForm,
    setLoanSuretyFormFieldValues,
    loanSurety,
    suretyStatus,
    setLoanSuretydata,
    loggedInMemberDetails,
    loggedInMemberSuretyWeight,
    selectedSuretiesWeight,
    setSelectedSuretiesWeight,
    loanType,
  } = props;

  //getting the loan limits details based on the gradepay and loanType....................

  const [suretyselected, setsuretyselected] = useState();

  const [suretiesDataList, setSuretiesList] = useState([]);
  const [suretiesData, setSuretiesData] = useState({});

  //console.log(loanType);

  useEffect(() => {
    getSuretiesList();
  }, [selectedSuretiesWeight]);

  //loan surety..........

  let loanSuretytablerow = null;

  if (loanSurety !== undefined) {
    loanSuretytablerow = loanSurety.map((LS, index) => {
      return (
        <tr key={index}>
          <td>
            <DeleteForeverIcon onClick={() => deleteloanSuretydetail(index)} />
          </td>
          <td>{index + 1}</td>
          <td>
            {LS.name} {LS.RUID}
          </td>
          <td>{LS.mobile}</td>
          <td>{LS.compulsoryDeposit}</td>
          <td>
            {LS.compulsoryDeposit >= 50000 && LS.compulsoryDeposit <= 100000
              ? 0.5
              : LS.compulsoryDeposit > 100000
              ? 1
              : 0}
          </td>
        </tr>
      );
    });
  }

  const getSuretiesList = () => {
    api.get(`/getRBMembersListForSurety`).then((response) => {
      //  console.log(response.data);
      setSuretiesList(response.data);
      //console.log(regData);
    });
  };

  const getSetSuretyData = (ruid) => {
    api.get(`/getSuretyData/${ruid}`).then((response) => {
      //console.log(response.data);
      setSuretiesData(response.data);
      //console.log(regData);
    });
  };

  const deleteloanSuretydetail = (index) => {
    var array = [...loanSurety];
    if (index !== -1) {
      array.splice(index, 1);

      setLoanSuretydata((oldarray) => array);
      setSelectedSuretiesWeight(
        (oldWeight) => oldWeight - suretiesData.suretyWeight
      );
    }
  };

  const addloanSuretydetail = () => {
    if (suretiesData !== undefined) {
      // console.log(suretiesData);
      if (suretiesData.noOfSuretiesGiven < loanLimitDetails.noOfSurety) {
        setLoanSuretydata((oldArray) => [...oldArray, suretiesData]);
        setSelectedSuretiesWeight(
          (oldWeight) => oldWeight + suretiesData.suretyWeight
        );
      } else {
        alert(
          "Shri " +
            suretiesData.name +
            " has already given " +
            loanLimitDetails.noOfSurety +
            " sureties. As such he cannot be added to your surety list"
        );
      }
    }
  };

  const showSelectedSurety = () => {
    getSetSuretyData(suretyselected);
    // console.log(suretiesData);
  };

  return (
    <div className="LoanSuretyFormDetailsMainDiv">
      <fieldset>
        <legend>🖥️ Please Enter the Surety Details</legend>
        {/* {JSON.stringify(loggedInMemberDetails)} */}
        {/* <div>Your CD Amount is:-  <u>{parseInt(loggedInMemberDetails.compulsoryDeposit)}</u>. You need to add   <u>{parseInt(loanForm.loanAmount/100000)-parseInt(loggedInMemberDetails.compulsoryDeposit/100000)}</u>   Sureties</div> */}
        <div>
          <h2 className="jutifyContent">
            {/* Choose the number of sureties so that, <br/>Total surety weight of selected sureties({loanForm.totalSuretyWeightRequired})  = Total surety weight required ({selectedSuretiesWeight}). */}
            {/* {loanForm.totalSuretyWeightRequired === selectedSuretiesWeight?('✅'):('❌')} */}
          </h2>
        </div>
        <div className="gridRow">
          <table className="suretyWeightTable">
            <thead>
              <tr>
                <td>Item</td>
                <td>Value</td>
                <td>Sureties Weight</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Previous Outstanding</td>
                <td>{loggedInMemberDetails.currentMLOutstanding}</td>
                <td rowSpan={2}>
                  +{" "}
                  {parseInt(loggedInMemberDetails.currentMLOutstanding) +
                    parseInt(loanForm.loanAmount) <
                  100000
                    ? 0.5
                    : Math.round(
                        Math.floor(
                          loggedInMemberDetails.currentMLOutstanding / 100000
                        ) +
                          Math.floor(
                            loggedInMemberDetails.currentMLOutstanding /
                              100000 +
                              loanForm.loanAmount / 100000
                          )
                      )}
                </td>
                {/* <td> + {loggedInMemberDetails.currentMLOutstanding < 100000 ? 0.5 : Math.round(loggedInMemberDetails.currentMLOutstanding / 100000)}</td> */}
              </tr>
              <tr>
                <td>Loan Amount sought for Loan Application #</td>
                <td>{loanForm.loanAmount}</td>
                {/* <td >
                
                </td> */}
              </tr>
              <tr>
                <td>Your CD Amount</td>
                <td>{parseInt(loggedInMemberDetails.compulsoryDeposit)}</td>
                <td>-{loggedInMemberSuretyWeight}</td>
              </tr>

              <tr>
                <td colSpan={2}>Total Sureties Weight Required</td>
                <td>
                  {parseInt(loggedInMemberDetails.currentMLOutstanding) +
                    parseInt(loanForm.loanAmount) <
                  100000
                    ? 0.5
                    : Math.round(
                        Math.floor(
                          loggedInMemberDetails.currentMLOutstanding / 100000
                        ) +
                          Math.floor(
                            loggedInMemberDetails.currentMLOutstanding /
                              100000 +
                              loanForm.loanAmount / 100000
                          )
                      ) - loggedInMemberSuretyWeight}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <hr />

        <div className="gridCol">
          <div className="gridRow">
            <div className="FormFieldDiv">
              <input
                list="Sureties"
                name="SuretyDropList"
                id="SuretyDropList"
                onChangeCapture={(e) => setsuretyselected(e.target.value)}
                placeholder="Select a Surety"
              />
              <datalist id="Sureties">
                {suretiesDataList.map((surety, index) => {
                  return (
                    <option key={index} value={surety.rbMemberRUID}>
                      {surety.name}
                    </option>
                  );
                })}
              </datalist>
              <input
                className="suretyViewButton"
                type={"button"}
                value="🔍 Surety"
                onClick={(e) => showSelectedSurety()}
              />
            </div>
          </div>
          <div className="gridRow">
            <div>
              <p>RUID : {suretiesData.RUID}</p>
              <p>Name : {suretiesData.name}</p>
              <p>
                Posting : {suretiesData.designation}/{suretiesData.branch}
              </p>
              <p>
                No of Sureties already given : {suretiesData.noOfSuretiesGiven}
              </p>
              <p>
                Compulsory Deposit (Surety Weight) :{" "}
                {suretiesData.compulsoryDeposit} ({suretiesData.suretyWeight})
              </p>
              <p>
                Mobile/Railway : {suretiesData.mobile}/
                {suretiesData.railwayTelNo}
              </p>
            </div>
          </div>
        </div>
        <div className="gridCol">
          <div className="gridRow">
            <input
              type={"button"}
              value="➕ Surety"
              onClick={addloanSuretydetail}
            />
          </div>
          <div className="gridRow">
            <div className="loanSuretyDetailsTableDiv">
              <table className="loanSuretyDetailsTable" border="1">
                <thead>
                  <tr>
                    <td></td>
                    <td>Sl.</td>
                    <td>Surety Name (RUID)</td>
                    <td>Mobile No.</td>
                    <td>Compulsory Deposit</td>
                    <td>Surety Weight</td>
                  </tr>
                </thead>
                <tbody>{loanSuretytablerow}</tbody>
              </table>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default LoanSuretyFormDetails;
