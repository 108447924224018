import "./DashWorkSpace.css";
import React, { Component } from 'react';
import { Outlet } from "react-router-dom";


export class MemberDashWorkSpace extends Component {
  render() {
    return (
      <div className="MemberDashWorkSpaceDiv">
          <Outlet></Outlet>
      </div>
    )
  }
}

export default MemberDashWorkSpace