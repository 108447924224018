import "./LRMWFilterInputsForReportGeneration.css"

import React, { useState, useEffect} from 'react'



import axios from "axios";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


import LoanLedgerReport from "./LoanLedgerReport/LoanLedgerReport";
import ShareLedgerReport from "./ShareLedgerReport/ShareLedgerReport";
import CompulsoryDepositLedgerReport from "./compulsoryDepositLedgerReport/CompulsoryDepositLedgerReport";
import useMyAuthService from "../../../../../authenticationServiceProvider/useMyAuthService";




const LRMWFilterInputsForReportGeneration = () => {
  
  const {loggedInUserRUID, api} = useMyAuthService();

  const loanTypeForFilters = [
      {loanType:"Main Loan", loanTypeCode:"ML"},
      {loanType:"Emergent Loan", loanTypeCode:"EL"}
  ]
  
  const [rbMemberListForFilters, setRBMemberListForFilters] = useState();
  const [accountHeads, setAccountHeads] = useState();
 
  const [isMemberListLoaded, setIsMemberListLoaded] = useState(true);


  //for tabs
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //for tabs

  
 
  useEffect(() => {
    getAllRBMembersListForFilters();
    getAccountHeads();
  }, []);

  const getAllRBMembersListForFilters = () => {
    api
      .get(`/getRBMembersList`)
      .then((response) => {
        // console.log(response.data);

        setRBMemberListForFilters(response.data);
       
      });
  };

  const getAccountHeads = () => {
    api
      .get(`/GetAccountHeads`)
      .then((response) => {
         
         setAccountHeads(response.data);
         setIsMemberListLoaded(false);
      });
  };


  return (
    <div className="LRMFilterInputsMainDiv">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Manage Surety Tabs"
          indicatorColor="secondary"
        >
          <Tab label="Loan Ledger" {...a11yProps(0)} />
          <Tab label="Share Ledger" {...a11yProps(1)} />
          <Tab label="Compulsory Deposit  Ledger" {...a11yProps(2)} />
          
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className="tabPanelMainDiv">
          {
              isMemberListLoaded?(<div className="isLoadingDiv">Loading Data ....</div>):(
                <LoanLedgerReport rbMemberListForFilters= {rbMemberListForFilters} />
              )
            }
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="tabPanelMainDiv">
          
            {
              isMemberListLoaded?(<div className="isLoadingDiv">Loading Data ....</div>):(
                <ShareLedgerReport rbMemberListForFilters= {rbMemberListForFilters}/>
            )
          }
          
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="tabPanelMainDiv">
          
            {
              isMemberListLoaded?(<div className="isLoadingDiv">Loading Data ....</div>):(
                <CompulsoryDepositLedgerReport rbMemberListForFilters= {rbMemberListForFilters}/>
            )
          }
         
        </div>
      </TabPanel>
      
    </div>
  );
}

export default LRMWFilterInputsForReportGeneration


////////functions for tab implementation.......................

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

