import "./MemberNomineesDetailsViewer.css";

import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";


import useMyAuthService from "../../../../authenticationServiceProvider/useMyAuthService";


function MemberNomineesDetailsViewer(props) {

  const updateType = props.updateType;

  const {loggedInUserRUID, api} = useMyAuthService();
  const [currentLoggedInRUID, setCurrentLoggedInRUID] = useState(loggedInUserRUID);

  const [rbMemberNomineesData, setRBMemberNomineesDetails] = useState([]);

  const [selectedNominee, setSelectedNominee] = useState(null);

  const [isLoadingData, setIsLoadingData] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    getRBMemberNomineesDetails();
  }, [isLoadingData]);

  const getRBMemberNomineesDetails = () => {
    api
      .get(
        `/getRBMemberNomineesDetails/${currentLoggedInRUID}`
      )
      .then((response) => {
        // console.log(response.data);
        setRBMemberNomineesDetails(response.data);
        //console.log(regData);
        setIsLoadingData(false);
      });
  };

  const handleOpenNomineeDetailsUpdaterComponent = ()=>{
    if(selectedNominee === null){
      alert("Please select a nominee to update")
      return 0;
    }
    navigate("../UpdateNomineesDetails", {state:{selectedNominee:selectedNominee}});
  }

  const handleOpenNomineeDetailsAdderComponent = ()=>{
      navigate("../AddNomineesDetails");
  }

  const handleChangeCheckBox = (e, nominee) =>{
       setSelectedNominee(nominee)
      // console.log(e);
      // alert(nominee);
  }


  const handleReviewNomineeDetailsUpdaterComponent =() =>{

    api
    .post(
      `/UpdateReviewNomineesStatus/${currentLoggedInRUID}`
    )
    .then((response) => {
      //console.log(response.data);
      navigate("../View-ManageStatus", {replace:true})
      
    });
    
  }


  return (
    <div className="NomineesDetailsViewerMainDiv">
      <fieldset>
        <legend>🔍 View Your Nominees Details</legend>
        {isLoadingData ? (
          <div className="isLoadingDiv">Loading Data .....</div>
        ) : (
          <div className="gridRow">
            <div className="gridCol">
              {rbMemberNomineesData.noOfNominees === 0 ? (
                "You have not declared any Nominees"
              ) : (
                <>
                  <table>
                    <thead>
                      <tr>
                        <td></td>
                        <td>Sl. No.</td>
                        <td>Nominee Name</td>
                        <td>Nominee Date of Birth</td>
                        <td>Relation with Member</td>
                        <td>Mobile No</td>
                        <td>Email</td>
                        <td>Address</td>
                        <td>Amount Payable</td>
                        <td>Nominee Heir Name</td>
                        <td>Nominee Heir DoB</td>
                        <td>Nominee Heir Relation</td>
                        {/* <td>Photo / Sign</td> */}
                      </tr>
                    </thead>
                    <tbody>
                      {rbMemberNomineesData.map((nominee, index) => {
                        return (
                          <tr key={nominee.nomineeID}>
                            <td><input type={'radio'} name='nominee' onClick={(e) => handleChangeCheckBox(e, nominee.nomineeID)}/></td>
                            <td>{index + 1}</td>
                            <td>{nominee.name}</td>
                            <td>{nominee.dateOfBirth}</td>
                            <td>{nominee.relationWithMember}</td>
                            <td>{nominee.mobileNo}</td>
                            <td>{nominee.email}</td>
                            <td>{nominee.homeAddress}</td>
                            <td>{nominee.amountPayable} %</td>
                            <td>{nominee.nomineeHeirName}</td>
                            <td>{nominee.nomineeHeirDOB}</td>
                            <td>{nominee.nomineeHeirRelationWithMember}</td>
                            {/* <td>
                              <input type={"button"} value={"🔍"} />
                            </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <hr></hr>
                  <div className="gridRow">
                    <div className="FormActionDiv gridCol">
                      <input
                        type={"button"}
                        onClick={() =>
                          handleOpenNomineeDetailsAdderComponent()
                        }
                        value="🖆 Add New Nominee Details"
                      />
                    </div>

                    <div className="FormActionDiv gridCol">
                      <input
                        type={"button"}
                        onClick={() =>
                          handleOpenNomineeDetailsUpdaterComponent()
                        }
                        value="🖆 Update Nominee Details"
                      />
                    </div>

                    

                  </div>
                 
                 {
                  updateType === "Review" ?(
                    <>
                      <hr/>
                    <div className="FormActionDiv gridCol">
                      <h2>Once the details of Nominees are correctly added/updated, click to revise the nominee review status</h2>
                      <input
                        type={"button"}
                        onClick={() =>
                          handleReviewNomineeDetailsUpdaterComponent()
                        }
                        value="🖆 Submit Reviewed Nominee Details"
                      />
                    </div>
                    </>
                    
                  ):("")
                 }
                  
                </>
              )}
            </div>
          </div>
        )}
      </fieldset>
    </div>
  );
}

export default MemberNomineesDetailsViewer;
