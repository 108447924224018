import "./MemberOffcialDetailsUpdater.css";
import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import LinearProgress from "@mui/material/LinearProgress";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import useMyAuthService  from "../../../../authenticationServiceProvider/useMyAuthService";

const officialFormFields = {
  rbMemberRUID: " ",
  employeeId: "0",
  designation: " ",
  branch: " ",
  ccBranch: " ",
  srBranch: " ",
  payLevel: " ",
  railwayTelNo: " ",
  dateOfAppointment: " ",
  dateOfRetirement: " ",
  gradePay: " ",
  basicPay: " ",
};

const MemberOfficialDetailsUpdater = (props) => {

  const {loggedInUserRUID, api} = useMyAuthService();
  const [currentLoggedInRUID, setCurrentLoggedInRUID] = useState(loggedInUserRUID);

  const [officialForm, setOfficialFormFieldValues] =
    useState(officialFormFields);
  const [memberDesignations, setMemberDesignations] = useState([]);
  const [rbBranches, setRBBranches] = useState([]);

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);


  const updateType = props.updateType;


  const navigate = useNavigate();

  //getting the XML resources..........................
  useEffect(() => {
    const getXMLResources = () => {
      api
        .get(
          `/getXMLResources/memberDesignations`
        )
        .then((response) => {
         // console.log(response.data.Designation)
          setMemberDesignations(response.data.Designation);
        });

      api
        .get(`/getXMLResources/rbBranches`)
        .then((response) => {
          setRBBranches(response.data.Branch);
        });
    };
    getXMLResources();
  }, []);

  useEffect(() => {
    getRBMemberOfficialDetails();
  }, []);

  const getRBMemberOfficialDetails = () => {
    api
      .get(
        `/getRBMemberOfficialDetails/${currentLoggedInRUID}`
      )
      .then((response) => {
        //console.log(response.data);
        //setRBMemberofficialDetails(response.data);
        setOfficialFormFieldValues({
          ...officialForm,
          rbMemberRUID: response.data.rbMemberRUID,
          designation: response.data.designation,
          employeeId: response.data.employeeId,
          branch: response.data.branch,
          payLevel: response.data.payLevel,
          railwayTelNo: response.data.railwayTelNo,
          dateOfAppointment: response.data.dateOfAppointment,
          dateOfRetirement: response.data.dateOfRetirement,
          gradePay: response.data.gradePay,
          basicPay: response.data.basicPay,
          ccBranch: response.data.cadreControllingBranch,
          srBranch: response.data.salaryReportingBranch,
        });
        //console.log(regData);
        setIsLoadingData(false);
      });
  };

  // setting the pay level and the grade pay automatically when the user selects the designation...........
  const setPayLevelAndGradePay = (postCode) => {
    let selectedPost = memberDesignations.find((d) => d.Code === postCode);
    // console.log(memberDesignations.find(d => d.Code === postCode).PayLevel);
    if (selectedPost !== undefined) {
      setOfficialFormFieldValues({
        ...officialForm,
        designation: postCode,
        payLevel: selectedPost.PayLevel,
        gradePay: selectedPost.GradePay,
      });
    } else {
      setOfficialFormFieldValues({
        ...officialForm,
        designation: postCode,
        payLevel: " ",
        gradePay: " ",
      });
    }
  };

  //submit and save the updated details to the server.....................
  const handleSubmitOfficialDetailsUpdaterComponent = async () => {
    //console.log(personalForm);
    let emptyFields = [];

    //check if any of the properties of the personalForm  is empty.......... and dont allow to move forward
    for (var key in officialForm) {
      if (officialForm[key] === null || officialForm[key] === " ") {
        //console.log("please fill in the complete details of the following : "+key)
        emptyFields.push("Official Form: " + key);
        // return false;
      }
    }

    /////////sending the data to the server id all the data is present

    if (emptyFields.length === 0) {
      // console.log("The lenght is "+ emptyFields.length)
      //console.log("The data being sent..................");
      //console.log({ ...personalForm, ...officialForm, ...bankForm, ...societyForm, ...nomineeForm});
      let config = {
        method: "PUT",
        url: `/UpdateRBMemberOfficialDetails`,
        data: { ...officialForm, currentLoggedInRUID, updateType },
        headers: {
          Accept: "application/json",
          //  Authorization: varToken
        },
        //responseType: "application/json",
      };

      setOpenProgressBarDialog(true);
      const response = await api(config);
      setOpenProgressBarDialog(false);
      //console.log("data sent from the server.............................");
      //console.log(response.data);
      let message = response.data[0];
      navigate(-1);
    } else {
      //form is not ready..................
      // console.log(emptyFields);
      alert(
        "Please fill in all the details" +
          "\r\n" +
          emptyFields.map((field, index) => {
            return field + "\r\n";
          })
      );
    }
  };

  // the JSX to display the content of the form
  return (
    <div className="officialDetailsMainDiv">
      <fieldset>
        <legend>✍️ UpdateYour Official Details</legend>

        {isLoadingData ? (
          <div className="isLoadingDiv">Loading Data ...... </div>
        ) : (
          <>
            <div className="gridRow">
          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>RUID</label>
              </div>
              <div>
                <input type={"text"} value={officialForm.rbMemberRUID}
                 
                  readOnly />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Designation</label>
              </div>
              <div>
                <input list="designations" value={officialForm.designation}
                  onChange={ (e) => setPayLevelAndGradePay(e.target.value)}/>
                <datalist id="designations">
                  {/* {console.log(memberDesignations[0])} */}
                  {
                    memberDesignations.map((designation, index)=>{
                      return <option key={index} value={designation.Code}>{designation.FullForm}</option>
                    })
                  }
               </datalist>
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Grade Pay</label>
              </div>
              <div>
                <input name="gradePays" type={'text'} placeholder="Enter your Grade Pay" value={officialForm.gradePay} readOnly/>
                
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Branch</label>
              </div>
              <div>
                <input list="branches" value={officialForm.branch}
                  onChange={(e) =>
                    setOfficialFormFieldValues({
                      ...officialForm,
                      branch: e.target.value,
                    })
                  }/>
                <datalist id="branches">
                 {
                    rbBranches.map((Branch, index)=>{
                      return <option key={index} value={Branch.Code}>{Branch.FullForm}</option>
                    })
                  }
                </datalist>
              </div>
            </div>
            
            
          </div>

          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Date of Appointment</label>
              </div>
              <div>
                <input
                  type={"date"}
                  value={officialForm.dateOfAppointment}
                  onChange={(e) =>
                    setOfficialFormFieldValues({
                      ...officialForm,
                      dateOfAppointment: e.target.value,
                    })
                  }
                  placeholder="Enter your date of Appointment"
                />
              </div>
            </div>

            <div className="FormFieldDiv">
              <div>
                <label>Date of Retirement</label>
              </div>
              <div>
                <input
                  type={"date"}
                  value={officialForm.dateOfRetirement}
                  onChange={(e) =>
                    setOfficialFormFieldValues({
                      ...officialForm,
                      dateOfRetirement: e.target.value,
                    })
                  }
                  placeholder="Enter your Date of Retirement"
                />
              </div>
            </div>
            
            <div className="FormFieldDiv">
              <div>
                <label>Pay Level</label>
              </div>
              <div>
                <input name="payLevels" type="text" placeholder="Your Pay Level" value={officialForm.payLevel} readOnly/>
                
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Cadre Controlling Branch</label>
              </div>
              <div>
                <input list="ccbranches" value={officialForm.ccBranch}
                  onChange={(e) =>
                    setOfficialFormFieldValues({
                      ...officialForm,
                      ccBranch: e.target.value,
                    })
                  }/>
                <datalist id="ccbranches">
                  <option  value={'ERB-I'}>Establishment Railway Board - I</option>
                  <option  value={'ERB-II'}>Establishment Railway Board - II</option> 
                  <option  value={'ERB-V'}>Establishment Railway Board - V</option>
                </datalist>
              </div>
            </div>


            
          </div>
          <div className="gridCol">
          <div className="FormFieldDiv">
              <div>
                <label>Railway Telephone No.</label>
              </div>
              <div>
                <input
                  type={"number"}
                  value={officialForm.railwayTelNo}
                  onChange={(e) =>
                    setOfficialFormFieldValues({
                      ...officialForm,
                      railwayTelNo: e.target.value,
                    })
                  }
                  placeholder="Enter Railway Telephone No."
                />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Employee Id</label>
              </div>
              <div>
                <input
                  type={"text"}
                  value={officialForm.employeeId}
                  onChange={(e) =>
                    setOfficialFormFieldValues({
                      ...officialForm,
                      employeeId: e.target.value,
                    })
                  }
                  placeholder="Enter Employee Id."
                />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Basic Pay</label>
              </div>
              <div>
                <input type={"number"} placeholder="Enter your Basic Pay" value={officialForm.basicPay}
                  onChange={(e) =>
                    setOfficialFormFieldValues({
                      ...officialForm,
                      basicPay: e.target.value,
                    })
                  }/>
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>salary Reporting Branch</label>
              </div>
              <div>
                <input list="srbranches" value={officialForm.srBranch}
                  onChange={(e) =>
                    setOfficialFormFieldValues({
                      ...officialForm,
                      srBranch: e.target.value,
                    })
                  }/>
                <datalist id="srbranches">
                  <option  value={'CASH-I'}>Cash-I Branch</option>
                  <option  value={'CASH-II'}>Cash-II Branch</option> 
                  <option  value={'CASH-III'}>Cash-III Branch</option>
                </datalist>
              </div>
            </div>

            
          </div>
        </div>
            <hr></hr>
            <div className="FormActionDiv gridCol">
              <input
                type={"button"}
                onClick={() => handleSubmitOfficialDetailsUpdaterComponent()}
                value="💾 SAVE UPDATED DETAILS"
              />
            </div>

            {/* Dialog to implement the Progess bar ................ */}
            <Dialog open={openProgressBarDialog} fullWidth maxWidth="sm">
              <DialogTitle id="scroll-dialog-title">
                <b>Please wait! work under process ....</b>
              </DialogTitle>
              <DialogContent>
                <LinearProgress />
              </DialogContent>
            </Dialog>
          </>
        )}
      </fieldset>
    </div>
  );
};

export default MemberOfficialDetailsUpdater;
