import "./PhotoSignatureUploader.css";
import React, { useState } from "react";

import profileImage from "../../../images/profile2.jpg";
import signImage from "../../../images/sign.jpg";

import { useNavigate } from "react-router-dom";

import LinearProgress from "@mui/material/LinearProgress";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import useMyAuthService from "../../../authenticationServiceProvider/useMyAuthService";



const PhotoSignatureUploader = () => {

   const {loggedInUserRUID, loggedInUserRole, api} = useMyAuthService();

  const [imageFileDetails, setImageFileDetails] = useState(null);
  const [selectedImage, setSelectedImage] = useState(profileImage);
  const [signatureFileDetails, setSignatureFileDetails] = useState(null);
  const [selectedSignature, setSelectedSignature] = useState(signImage);

  const [messageUploadState, setMessageUploadState] = useState(null);

  const [openProgressBarDialog, setOpenProgressBarDialog] = useState(false);

  const navigate = useNavigate();

  const handleChangeImageForDisplay = (e, photoOrSign) => {

    if (e.target.files[0]) {
      //console.log("ImageFileDetails: ", e.target.files);
      const reader = new FileReader();

      switch (photoOrSign) {
        case "photo":
          setImageFileDetails(e.target.files[0]);
          reader.addEventListener("load", () => {
            setSelectedImage(reader.result);
          });
          break;
        case "sign":
          setSignatureFileDetails(e.target.files[0]);
          reader.addEventListener("load", () => {
            setSelectedSignature(reader.result);
          });
          break;
        default:
          break;
      }

      reader.readAsDataURL(e.target.files[0]);
      //  console.log(e.target.files[0]);
    }
  };

  const handleSubmitImageUploadOnServer = async (e) => {
    e.preventDefault();

    
    if (imageFileDetails === null) {
      alert("Please select photo image");
      return;
    }

    if (signatureFileDetails === null) {
      alert("Please select signature image");
      return;
    }

    setOpenProgressBarDialog(true);

   
    const fd = new FormData();
    fd.append("imagePhoto", imageFileDetails, imageFileDetails.name);
    fd.append("imageSign", signatureFileDetails, signatureFileDetails.name);
    fd.append("rbMemberRUID", loggedInUserRUID);
    fd.append("userRole", loggedInUserRole);
    //console.log(data);
    let config = {};

    api.post(`/imageSignUpload`, fd, config)
      .then((response) => {
        //console.log(response.data);
        
         setMessageUploadState(response.data[1]);
         setOpenProgressBarDialog(false);
        navigate(-1);
      });
  };

  return (
    <div className="PhotoSignatureUploaderMainDiv">
      <fieldset>
        <legend>📤 Upload Photo and Signature here</legend>
        <div>
          <p>{messageUploadState}</p>
          
        </div>
        <form onSubmit={handleSubmitImageUploadOnServer}>
          <div className="finalVerificationSubmitDialogDiv">
            <div className="imageHolderDiv">
              <table className="centerContent">
                <tbody>
                  <tr>
                    <td>
                      <b>Photo Here</b>
                    </td>
                    <td>
                      <b>Signature Here</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img className="imageDisplay" src={selectedImage}></img>
                    </td>
                    <td>
                      <img className="signDisplay" src={selectedSignature}></img>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {imageFileDetails === null ? (
                        <input
                          type={"file"}
                          onChange={(e) =>
                            handleChangeImageForDisplay(e, "photo")
                          }
                        />
                      ) : (
                        <input
                          type={"button"}
                          value={"change"}
                          onClick={() => setImageFileDetails(null)}
                        />
                      )}
                      <hr/>
                      <b>Photo should be in JPG or PNG format with Maximum size of 500 kb</b>
                    </td>
                    <td>
                      {signatureFileDetails === null ? (
                        <input
                          type={"file"}
                          onChange={(e) =>
                            handleChangeImageForDisplay(e, "sign")
                          }
                        />
                      ) : (
                        <input
                          type={"button"}
                          value={"change"}
                          onClick={() => setSignatureFileDetails(null)}
                        />
                      )}
                      <hr/>
                      <b>Sign should be in JPG or PNG format with Maximum size of 200 kb</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="FormActionDiv">
              <button type={"submit"}>📤 Upload Images</button>
            </div>
          </div>

          {/* Dialog to implement the Progess bar ................ */}
          <Dialog open={openProgressBarDialog} fullWidth maxWidth="sm">
            <DialogTitle id="scroll-dialog-title">
              <b>Please wait! work under process ....</b>
            </DialogTitle>
            <DialogContent>
              <LinearProgress />
            </DialogContent>
          </Dialog>
        </form>
      </fieldset>
    </div>
  );
};

export default PhotoSignatureUploader;
