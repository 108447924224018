import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./BannerRBCTCS.css";
import logosrc from "../../images/IndianRailwayLogo.jpg";
//import logoNamesrc from "../../images/LogoMoRName.jpg";

const BannerRBCTCS = () => (
  <div>
    <div className="BannerBody">
      <div className="LogoDiv">
        <div>
          <img className="logo" src={logosrc} />
        </div>
      </div>
      <div className="BannerContent">
        <div> RAILWAY BOARD CO-OPERATIVE THRIFT & CREDIT SOCIETY LTD. </div>
        <div>(Regd. No. 374(UA)/01-07-1931)</div>
      </div>
    </div>
    <div className="shlokaFlashDiv">
      <marquee>
        {" "} 
        कर्मण्येवाधिकारस्ते मे फलेषु कदाचन मा कर्मफलहेतुभुरमा ते
        सन्दगोअस्तवकर्मणि{" "}
      </marquee>
    </div>
  </div>
);

//BannerRBCTCS.propTypes = {};

//BannerRBCTCS.defaultProps = {};

export default BannerRBCTCS;
