import React from "react";
import { Outlet, Link } from "react-router-dom";

import PropTypes from "prop-types";
import styles from "./NavigateBar.css";


import { Stack, Button } from "@mui/material";
import Divider from "@mui/material/Divider";

const NavigateBar = () => (
  <div className="bodyDiv">
    <div className="NavigateBar">
      <div className="NavigateMenu">
        <Stack direction={"row"} spacing={1}>
          <Link to="/About-Us" underline="none">
            <input type={'button'} value={'🧊 About RBCTCS'} />
          </Link>
          {/* <Divider orientation="vertical" flexItem /> */}
          <Link to="/Bulletins" underline="none">
            <input type={'button'} value={'🎯 Bulletin Board'} />
          </Link>
         
          {/* <Divider orientation="vertical" flexItem /> */}
          <Link to="/WriteToUs" underline="none">
            <input type={'button'} value={'📧 Write To RBCTCS'} />
          </Link>
          {/* <Divider orientation="vertical" flexItem /> */}
          <Link to="/LogIn" underline="none">
            <input type={'button'} value={'🔐 LOG In'} />
          </Link>
          {/* <Link to="/" underline="none">
            <input type={'button'} value={'🤵‍♂️ Meet Office-Bearers'} />
          </Link> */}
           {/* <Divider orientation="vertical" flexItem /> */}
           <a href="#Forms&Bye-Laws">
            <input type={'button'} value={'📒 Forms'} />
          </a>
          <a href="#Forms&Bye-Laws">
            <input type={'button'} value={'🧾 Bye Laws'} />
          </a>
        </Stack>
      </div>
      <div>
        
      </div>
    </div>
    
  </div>
);

NavigateBar.propTypes = {};

NavigateBar.defaultProps = {};

export default NavigateBar;
