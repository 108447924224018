import React from "react";
import "./MemberOfficialDetailsForm.css";


const MemberOfficialDetailsForm = (props) => {

  
  //receiveing the props from the parent component...........
  const {
          officialForm, setofficialFormFieldValues, 
          memberDesignations, rbBranches
        } = props;
  
        
  // setting the pay level and the grade pay automatically when the user selects the designation...........
  const setPayLevelAndGradePay = (postCode) => {
    
    let selectedPost = memberDesignations.find(d => d.Code === postCode);
   // console.log(memberDesignations.find(d => d.Code === postCode).PayLevel); 
    if (selectedPost !== undefined) {
      
      setofficialFormFieldValues({
        ...officialForm,
        designation: postCode,
        payLevel: selectedPost.PayLevel,
        gradePay: selectedPost.GradePay,
      });

    } else {
      setofficialFormFieldValues({
        ...officialForm,
        designation: postCode,
        payLevel: " ",
        gradePay: " ",
      });
    }
   
   }


   // the JSX to display the content of the form
  return (
    <div className="officialDetailsMainDiv">
      <fieldset>
        <legend>🖥️ Fill Your Official Details</legend>

        <div className="gridRow">
          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>RUID</label>
              </div>
              <div>
                <input type={"text"} value={officialForm.rbMemberRUID}
                 
                  readOnly />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Designation</label>
              </div>
              <div>
                <input list="designations" value={officialForm.designation}
                  onChange={ (e) => setPayLevelAndGradePay(e.target.value)}/>
                <datalist id="designations">
                  {/* {console.log(memberDesignations[0])} */}
                  {
                    memberDesignations.map((designation, index)=>{
                      return <option key={index} value={designation.Code}>{designation.FullForm}</option>
                    })
                  }
               </datalist>
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Grade Pay</label>
              </div>
              <div>
                <input name="gradePays" type={'text'} placeholder="Enter your Grade Pay" value={officialForm.gradePay} readOnly/>
                
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Branch</label>
              </div>
              <div>
                <input list="branches" value={officialForm.branch}
                  onChange={(e) =>
                    setofficialFormFieldValues({
                      ...officialForm,
                      branch: e.target.value,
                    })
                  }/>
                <datalist id="branches">
                 {
                    rbBranches.map((Branch, index)=>{
                      return <option key={index} value={Branch.Code}>{Branch.FullForm}</option>
                    })
                  }
                </datalist>
              </div>
            </div>
            
            
          </div>

          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Date of Appointment</label>
              </div>
              <div>
                <input
                  type={"date"}
                  value={officialForm.dateOfAppointment}
                  onChange={(e) =>
                    setofficialFormFieldValues({
                      ...officialForm,
                      dateOfAppointment: e.target.value,
                    })
                  }
                  placeholder="Enter your date of Appointment"
                />
              </div>
            </div>

            <div className="FormFieldDiv">
              <div>
                <label>Date of Retirement</label>
              </div>
              <div>
                <input
                  type={"date"}
                  value={officialForm.dateOfRetirement}
                  onChange={(e) =>
                    setofficialFormFieldValues({
                      ...officialForm,
                      dateOfRetirement: e.target.value,
                    })
                  }
                  placeholder="Enter your Date of Retirement"
                />
              </div>
            </div>
            
            <div className="FormFieldDiv">
              <div>
                <label>Pay Level</label>
              </div>
              <div>
                <input name="payLevels" type="text" placeholder="Your Pay Level" value={officialForm.payLevel} readOnly/>
                
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Cadre Controlling Branch</label>
              </div>
              <div>
                <input list="ccbranches" value={officialForm.ccBranch}
                  onChange={(e) =>
                    setofficialFormFieldValues({
                      ...officialForm,
                      ccBranch: e.target.value,
                    })
                  }/>
                <datalist id="ccbranches">
                  <option  value={'ERB-I'}>Establishment Railway Board - I</option>
                  <option  value={'ERB-II'}>Establishment Railway Board - II</option> 
                  <option  value={'ERB-V'}>Establishment Railway Board - V</option>
                </datalist>
              </div>
            </div>


            
          </div>
          <div className="gridCol">
          <div className="FormFieldDiv">
              <div>
                <label>Railway Telephone No.</label>
              </div>
              <div>
                <input
                  type={"number"}
                  value={officialForm.railwayTelNo}
                  onChange={(e) =>
                    setofficialFormFieldValues({
                      ...officialForm,
                      railwayTelNo: e.target.value,
                    })
                  }
                  placeholder="Enter Railway Telephone No."
                />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Employee Id</label>
              </div>
              <div>
                <input
                  type={"text"}
                  value={officialForm.employeeId}
                  onChange={(e) =>
                    setofficialFormFieldValues({
                      ...officialForm,
                      employeeId: e.target.value,
                    })
                  }
                  placeholder="Enter Employee Id."
                />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Basic Pay</label>
              </div>
              <div>
                <input type={"number"} placeholder="Enter your Basic Pay" value={officialForm.basicPay}
                  onChange={(e) =>
                    setofficialFormFieldValues({
                      ...officialForm,
                      basicPay: e.target.value,
                    })
                  }/>
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>salary Reporting Branch</label>
              </div>
              <div>
                <input list="srbranches" value={officialForm.srBranch}
                  onChange={(e) =>
                    setofficialFormFieldValues({
                      ...officialForm,
                      srBranch: e.target.value,
                    })
                  }/>
                <datalist id="srbranches">
                  <option  value={'CASH-I'}>Cash-I Branch</option>
                  <option  value={'CASH-II'}>Cash-II Branch</option> 
                  <option  value={'CASH-III'}>Cash-III Branch</option>
                </datalist>
              </div>
            </div>

            
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default MemberOfficialDetailsForm;
