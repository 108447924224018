import { createContext, useContext } from "react";


const BackEndServerBaseRouteContext = createContext(null);


const BackEndServerServiceProvider = ({children}) => {

    const serverBaseRoute = process.env.REACT_APP_API_BASE_URL;
    const clientId = process.env.REACT_APP_PASSPORT_CLIENT_ID;
    const clientSecret = process.env.REACT_APP_PASSPORT_CLIENT_SECRET;
    
        
    return(
        <BackEndServerBaseRouteContext.Provider value={{serverBaseRoute, clientId, clientSecret}}>
            {children}
        </BackEndServerBaseRouteContext.Provider>
    )
}

export default BackEndServerServiceProvider

export const useGetAPIServer = () =>{
    return useContext(BackEndServerBaseRouteContext);
}

// export const useGetClientDetails = () =>{
//      return useContext(BackEndServerBaseRouteContext);
// }