import React from "react";
import "./MemberNomineesForm.css";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const MemberNomineesForm = (props) => {
  const { nomineeForm, setnomineeFormFieldValues, nominee, setnomineedata } =
    props;

  let nomineetablerow = null;

  if (nominee !== undefined) {
    nomineetablerow = nominee.map((nominee, index) => {
      return (
        <tr key={index}>
          <td>
            <DeleteForeverIcon onClick={() => deletenomineedetail(index)} />
          </td>
          <td>{index + 1}</td>
          <td>{nominee.nomineeName}</td>
          <td>{nominee.nomineeRelation}</td>
          <td>{nominee.amountPayableToNominee}</td>
          <td>{nominee.nomineeMobile}</td>
        </tr>
      );
    });
  }

  const deletenomineedetail = (index) => {
    var array = [...nominee];
    if (index !== -1) {
      array.splice(index, 1);
      // console.log(nominee[index]);
      setnomineedata((oldarray) => array);
    }
    // console.log('removing from nominee details');
    // console.log(nominee[index]);
  };

  const addnomineedetail = () => {
    //console.log(nomineeForm);
    setnomineedata((oldArray) => [...oldArray, nomineeForm]);
    // console.log('adding to nominee details');
    //console.log(nominee.length);
  };

  return (
    <div className="nomineesDetailsMainDiv">
      <fieldset>
        <legend>🖥️ Fill the Nominee Details</legend>
        <div className="gridRow">
          <div className="gridCol">
            <div className="FormFieldDiv">
              <div>
                <label>Nominee Name</label>
              </div>
              <div>
                <input
                  type={"text"}
                  value={nomineeForm.nomineeName}
                  onChange={(e) =>
                    setnomineeFormFieldValues({
                      ...nomineeForm,
                      nomineeName: e.target.value,
                    })
                  }
                  placeholder="Name of the Nominee"
                  required
                />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Date of Birth</label>
              </div>
              <div>
                <input
                  type={"date"}
                  value={nomineeForm.nomineeDateOfBirth}
                  onChange={(e) =>
                    setnomineeFormFieldValues({
                      ...nomineeForm,
                      nomineeDateOfBirth: e.target.value,
                    })
                  }
                  placeholder="Date of Birth"
                  required
                />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Relation with Member</label>
              </div>
              <div>
                <input
                  type={"text"}
                  value={nomineeForm.nomineeRelation}
                  onChange={(e) =>
                    setnomineeFormFieldValues({
                      ...nomineeForm,
                      nomineeRelation: e.target.value,
                    })
                  }
                  placeholder="Relation with the Member"
                  required
                />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Mobile No</label>
              </div>
              <div>
                <input
                  type={"number"}
                  value={nomineeForm.nomineeMobile}
                  onChange={(e) =>
                    setnomineeFormFieldValues({
                      ...nomineeForm,
                      nomineeMobile: e.target.value,
                    })
                  }
                  placeholder="Enter Your Mobile No."
                  required
                />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Amount Payable (in %)</label>
              </div>
              <div>
                <input
                  type={"number"}
                  value={nomineeForm.amountPayableToNominee}
                  onChange={(e) =>
                    setnomineeFormFieldValues({
                      ...nomineeForm,
                      amountPayableToNominee: e.target.value,
                    })
                  }
                  placeholder="Amount Payable to the Nominee"
                  required
                />
              </div>
            </div>
            <div className="FormFieldDiv">
              <div>
                <label>Nominee Email Id</label>
              </div>
              <div>
                <input
                  type={"email"}
                  value={nomineeForm.nomineeEmail}
                  onChange={(e) =>
                    setnomineeFormFieldValues({
                      ...nomineeForm,
                      nomineeEmail: e.target.value,
                    })
                  }
                  placeholder="Enter Email"
                  required
                />
              </div>
            </div>
          </div>

          <div className="gridCol">
            
            <div className="FormFieldDiv">
              <div>
                <label>Nominee Address</label>
              </div>
              <div>
                <textarea
                  rows="3"
                  cols="31"
                  value={nomineeForm.nomineeAddress}
                  onChange={(e) =>
                    setnomineeFormFieldValues({
                      ...nomineeForm,
                      nomineeAddress: e.target.value,
                    })
                  }
                  placeholder="Enter the Nominees Address"
                  required
                ></textarea>
              </div>
            </div>
          {/* </div>
          {/* <div className="FormActionDiv">
              <input type={"button"} value="➕ PHOTOS & SIGNATURES" />
            </div> */}
        

        {/* column 1 ends  --- column 2 starts */}
        {/* <div className="gridCol"> */} 
          <div className="FormFieldDiv">
            <div>
              <label>Nominee Heir Name</label>
            </div>
            <div>
              <input
                type={"text"}
                value={nomineeForm.nomineeHeirName}
                onChange={(e) =>
                  setnomineeFormFieldValues({
                    ...nomineeForm,
                    nomineeHeirName: e.target.value,
                  })
                }
                placeholder="Name of the Nominee"
                required
              />
            </div>
          </div>
          <div className="FormFieldDiv">
            <div>
              <label>Heir Date of Birth</label>
            </div>
            <div>
              <input
                type={"date"}
                value={nomineeForm.nomineeHeirDateOfBirth}
                onChange={(e) =>
                  setnomineeFormFieldValues({
                    ...nomineeForm,
                    nomineeHeirDateOfBirth: e.target.value,
                  })
                }
                placeholder="Date of Birth"
                required
              />
            </div>
          </div>
          <div className="FormFieldDiv">
            <div>
              <label>Relation with Member</label>
            </div>
            <div>
              <input
                type={"text"}
                value={nomineeForm.nomineeHeirRelation}
                onChange={(e) =>
                  setnomineeFormFieldValues({
                    ...nomineeForm,
                    nomineeHeirRelation: e.target.value,
                  })
                }
                placeholder="Relation with the Member"
                required
              />
            </div>
          </div>
          </div>
          {/* column 1 ends  --- column 2 starts */}
          <div className="gridCol">
            <div className="FormActionDiv">
              <input
                type={"button"}
                disabled={false}
                value="➕ NOMINEE DETAILS"
                onClick={addnomineedetail}
              />
            </div>
            <div className="addnomineeDetailsTableDiv">
              <table className="addnomineeDetailsTable" border="1">
                <thead>
                  <tr>
                    <td></td>
                    <td>Sl.</td>
                    <td>Nominee Name</td>
                    <td>Relation</td>
                    <td>Amount Payable</td>
                    <td>Mobile</td>
                  </tr>
                </thead>
                <tbody>{nomineetablerow}</tbody>
              </table>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default MemberNomineesForm;
