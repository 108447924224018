import { useState, useEffect } from "react";
import useMyAuthService from "../authenticationServiceProvider/useMyAuthService";

const LoanAppsVerificationsDataLoaderFunctions = (
  applicationStatus,
  setOpenProgressBarDialog,
  updatedForReRender
) => {
  //getting the server base route to fetch the data from the server........
  // const apiServerRoute = useGetAPIServer();

  const { api } = useMyAuthService();
  //variable to hold the incoming data from the server.......
  const [LoanApplicationDetails, setLoanApplicationDetails] = useState([]);

  //flag to decide if the data is loaded..............................
  const [isLoadingData, setIsLoadingData] = useState(true);

  //console.log("The api object "+api);

  useEffect(() => {
    getLoanApplicationsForVerification();
  }, [applicationStatus, updatedForReRender]);

  const getLoanApplicationsForVerification = () => {
    const url = `/ViewLoanApplicationsForVerification/${applicationStatus}`;
    api.get(url).then((response) => {
      //console.log(response.data);
      setOpenProgressBarDialog(true);
      setLoanApplicationDetails(response.data);
      setIsLoadingData(false);
      setOpenProgressBarDialog(false);
    });
  };

  // Return any additional data or functions as needed
  return { LoanApplicationDetails, isLoadingData };
};

export default LoanAppsVerificationsDataLoaderFunctions;
