import React, { useState } from "react";
import useMyAuthService from "../../../../../authenticationServiceProvider/useMyAuthService";

const CDPayDeductions = () => {
  const { api, loggedInUserRUID } = useMyAuthService();
  const [employees, setEmployees] = useState([]);
  const [isListGenerated, setIsListGenerated] = useState(false);

  const generateEmployeeList = () => {
    // Mock data for employees
    const employeeData = [
      { id: 1, name: "John Doe", deposit: 200 },
      { id: 2, name: "Jane Smith", deposit: 150 },
      { id: 3, name: "Alice Johnson", deposit: 180 },
      // Add more employees to test scrolling
      { id: 4, name: "Bob Brown", deposit: 220 },
      { id: 5, name: "Charlie Black", deposit: 170 },
      { id: 6, name: "Dana White", deposit: 210 },
      { id: 7, name: "Eva Green", deposit: 190 },
      { id: 8, name: "Frank Blue", deposit: 160 },
      { id: 9, name: "Grace Yellow", deposit: 175 },
      { id: 10, name: "Hank Purple", deposit: 165 },
    ];
    setEmployees(employeeData);
    setIsListGenerated(true);
  };

  const makeTransactions = () => {
    api
      .post("http://localhost:8000/api/transactions", employees)
      .then((response) => alert("Transactions successful"))
      .catch((error) => console.error("Transaction failed", error));
  };

  return (
    <div className="employee-list-container">
      <h1>Employee Deposit Deduction</h1>
      <div className="button-container">
        {!isListGenerated && (
          <button className="generate-button" onClick={generateEmployeeList}>
            Generate Employee List
          </button>
        )}
        {isListGenerated && (
          <button className="transaction-button" onClick={makeTransactions}>
            Make Transactions
          </button>
        )}
      </div>
      {isListGenerated && (
        <div className="employee-table-container">
          <table className="employee-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Deposit</th>
              </tr>
            </thead>
            <tbody>
              {employees.map((employee) => (
                <tr key={employee.id}>
                  <td>{employee.id}</td>
                  <td>{employee.name}</td>
                  <td>{employee.deposit}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CDPayDeductions;
