import React, { createContext, useState } from 'react';

const StepperContext = createContext();

export const StepperProvider = ({ children }) => {
  const [state, setState] = useState({});

  const updateStepState = (step, data) => {
    setState(prevState => ({ ...prevState, [step]: data }));
  };

  return (
    <StepperContext.Provider value={{ state, updateStepState }}>
      {children}
    </StepperContext.Provider>
  );
};

export default StepperContext;
